export enum JdfKonExitCode {
  Ok = 'ok',
  Warn = 'warn',
  Error = 'error',
  FatalError = 'fatalError',
}

export interface JdfProtocolApiModel {
  jdfKonExitCode: JdfKonExitCode
  errorText: string
  log: string
  stationsCount: number
  unknownStationsCount: number
  providersCount: number
  unknownProvidersCount: number
}