import React, { createRef, useContext, useEffect, useState } from 'react'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { Localizer } from '../../../utils/localizer'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { LineNumberExtended } from '../../../models/LineModels'
import { Logger } from '../../../utils/logger'
import { InputWithKeyActions } from '../../general/InputWithKeyActions'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  lineName: string
  onSave?: () => void
  onCancel?: () => void
}

interface ApiModel {
  lineName: string
}

export function LineNameEditor(props: Props): JSX.Element {
  const [lineName, setLineName] = useState<string>(props.lineName)
  const inputRef = createRef<HTMLInputElement>()
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  useEffect(() => {
    inputRef.current?.select()
  }, [])

  function save(): void {
    axios
      .put(
        UrlProvider.Api.Components.Lines.NameEditor.getUrl(props.dataId, props.lineNumber),
        getDataForSaving()
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line name was saved'),
            type: NotificationType.Success,
          },
        })

        if (props.onSave) props.onSave()
      })
      .catch((error) => {
        Logger.logError(
          `Error in ${
            LineNameEditor.name
          }. Line name could not be saved. Message: ${error.toString()}`
        )

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line name could not be saved'),
            type: NotificationType.Error,
          },
        })
      })
  }

  function getDataForSaving(): ApiModel {
    return { lineName: lineName }
  }

  return (
    <div className={'inline-editor pl-1 pr-2 py-1 d-flex align-items-center mb-2'}>
      <InputWithKeyActions
        onChange={(event) => setLineName(event.target.value)}
        onEscPressed={props.onCancel}
        onEnterPressed={save}
        defaultValue={lineName}
        ref={inputRef}
        className={'mr-2'}
      />
      <Button
        size={ButtonSize.ExtraSmall}
        shape={ButtonShape.Rounded}
        onClick={save}
        className={'m-0 mr-1'}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Button>
      <Button
        size={ButtonSize.ExtraSmall}
        shape={ButtonShape.Rounded}
        color={ButtonColor.Danger}
        onClick={props.onCancel}
        className={'m-0'}
      >
        <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
        {Localizer.localize('Cancel')}
      </Button>
    </div>
  )
}