import {Localizer} from "./localizer";
import {Code} from "../models/LineModels";
import {List} from "immutable";

// Marks 'm' and 'M' are not time code marks. They are marks from "Navaznosti", 
// just historically they were used here. 
export const timeCodeMarks = ['O','[','p','T','!', 'm', 'M'] as const;
export type TimeCodeMark = typeof timeCodeMarks[number];

export class TTTimeCodesService {
  public static isTimeCodeMark(mark: string): boolean {
    return ([...timeCodeMarks] as string[]).includes(mark);
  }

  public static getCodeDescription(mark: TimeCodeMark | string, relatedTo: 'connection'|'connectionStop'): string {
    let hint = '';

    switch (mark) {
      case 'O': hint = 'Tu môžete zadať podmienky pre prepravu bicykla.'; break;
      case 'm': hint = 'Spoj S počká v zastávke Z na príchod linky (vlaku) L najviac M minút.'; break;
      case 'M': hint = 'Na spoj S1 naväzuje v zastávke Z1 spoj S2 linky L do Z2.'; break;
      case '[': hint = 'Spoj prepravuje cestovnú batožinu.'; break;
      case 'p': hint =
        'Informačná poznámka ku spoju. Táto nesmie obsahovať informácie o časovej prevádzke spoja.'; break;
      case "!": hint = relatedTo === 'connection'
        ? 'Spoj je prevádzkovaný za podmienky … [uvedie sa informácia za akých podmienok spoj ide]'
        : 'Označené zastávky spoja sú obsluhované za podmienky … '
        + '[uvedie sa informácia za akých podmienok sú zastávky obsluhované]';
        break;
      case "T": hint = relatedTo === 'connection'
        ? 'Spoj je nutné objednať … [uvedie sa informácia o spôsobe objednania vrátane kontaktu]'
        : 'Obsluhu označených zastávok spoja je nutné objednať … '
        + '[uvedie se informácia o spôsobe objednania vrátane kontaktu]';
        break;
    }

    return Localizer.localize(hint);
  }

  public static getAllTimeCodes(relatedTo: 'connection'|'connectionStop'): List<Code> {
    return List<Code>(timeCodeMarks.map(_ => {
      return {mark: _, description: this.getCodeDescription(_, relatedTo)}
    }));
  }
}