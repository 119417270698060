/**
 * https://regex101.com/r/Yri8t4/1
 */
export const emailPattern = /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*([.]\w{2,3})+$/

/**
 * Special characters to be used in password
 * About using 'String.raw' see: https://stackoverflow.com/a/55793086/11889004
 * 
 */
export const specialCharacters = String.raw`!?@#$%^&*_~+\/\\`

/**
 * https://regex101.com/r/kB9ueR/1
 */
export const passwordPattern = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${specialCharacters}]).+$`)

/**
 * To be displayed for user
 */
export const escapedSpecialCharacters = specialCharacters.replace(/(?:\\(.))/g, '$1');

export const emailMaxLength = 200
export const userNameMaxLength = 30
export const userNameMinLength = 5
export const passwordMaxLength = 50
export const passwordMinLength = 8

export const cpEditEmailAddress = 'cpedit@inprop.sk'

export const badRequestStatusCode = 400
