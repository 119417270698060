import { Localizer } from './localizer'
import { List, Map } from 'immutable'

export class TTFixedCodesService {
  public static lineStopAllowedFixedCodes: string[] = ['(', ')', 'x', '§', 'A', 'B', 'C']
  public static connectionStopAllowedFixedCodes: string[] = [
    '(',
    ')',
    'x',
    '§',
    'A',
    'B',
    'C',
    'T',
    '!',
  ]
  public static connectionAlternativeProviderAllowedFixedCodes: string[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    'X',
    '+',
  ]

  /**
   * For every fixed code is defined array of other fixed codes, which are in conflict with it.
   */
  private static conflictingFixedCodes = Map<string[]>({
    '(': [')'],
    ')': ['('],
  })

  public static getDescription(code: string, type?: 'connection' | 'connectionStop'): string {
    switch (code) {
      case 'X':
        return Localizer.localize('tt-fixed-code-X')
      case '+':
        return Localizer.localize('tt-fixed-code-+')
      case '1':
        return Localizer.localize('tt-fixed-code-1')
      case '2':
        return Localizer.localize('tt-fixed-code-2')
      case '3':
        return Localizer.localize('tt-fixed-code-3')
      case '4':
        return Localizer.localize('tt-fixed-code-4')
      case '5':
        return Localizer.localize('tt-fixed-code-5')
      case '6':
        return Localizer.localize('tt-fixed-code-6')
      case '7':
        return Localizer.localize('tt-fixed-code-7')
      case 'R':
        return Localizer.localize('tt-fixed-code-R')
      case '#':
        return Localizer.localize('tt-fixed-code-#')
      case '|':
        return Localizer.localize('tt-fixed-code-|')
      case '<':
        return Localizer.localize('tt-fixed-code-<')
      case '@':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-@-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-@-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case '%':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-%-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-%-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case 'W':
        return Localizer.localize('tt-fixed-code-W')
      case 'w':
        return Localizer.localize('tt-fixed-code-w')
      case 'x':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-x-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-x-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case '~':
        return Localizer.localize('tt-fixed-code-~')
      case '(':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-(-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-(-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case ')':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-)-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-)')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case '$':
        return Localizer.localize('tt-fixed-code-$')
      case '{':
        return Localizer.localize('tt-fixed-code-{-connection')
      case '}':
        return Localizer.localize('tt-fixed-code-}-stop')
      case '[':
        return Localizer.localize('tt-fixed-code-[')
      case 'O':
        return Localizer.localize('tt-fixed-code-O')
      case 'v':
        return Localizer.localize('tt-fixed-code-v')
      case '§':
        return Localizer.localize('tt-fixed-code-§')
      case 'A':
        return Localizer.localize('tt-fixed-code-A')
      case 'B':
        return Localizer.localize('tt-fixed-code-B')
      case 'C':
        return Localizer.localize('tt-fixed-code-C')
      case 'T':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-T-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-T-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case '!':
        if (type === 'connection') return Localizer.localize('tt-fixed-code-!-connection')
        else if (type === 'connectionStop') return Localizer.localize('tt-fixed-code-!-stop')
        else
          throw new Error(
            `Cannot resolve fixed code description for '${code}' because no type was given`
          )
      case 't':
        return Localizer.localize('tt-fixed-code-t')
      case 'b':
        return Localizer.localize('tt-fixed-code-b')
      case 'U':
        return Localizer.localize('tt-fixed-code-U')
      case 'S':
        return Localizer.localize('tt-fixed-code-S')
      case 'J':
        return Localizer.localize('tt-fixed-code-J')
      case 'P':
        return Localizer.localize('tt-fixed-code-P')
      case 'I':
        return Localizer.localize('tt-fixed-code-I')
      case 's':
        return Localizer.localize('tt-fixed-code-s')
      case 'm':
        return Localizer.localize('tt-fixed-code-m')
      case 'M':
        return Localizer.localize('tt-fixed-code-M')
    }

    throw new Error(`Unknown fixed code '${code}'.`)
  }

  /**
   * Remove all fixed codes from fixedCodes which are in conflict with newFixedCode.
   * @param fixedCodes
   * @param newFixedCode
   */
  public static removeConflictingFixedCodes(
    fixedCodes: List<string>,
    newFixedCode: string
  ): List<string> {
    if (!this.conflictingFixedCodes.has(newFixedCode)) return fixedCodes

    const conflictingFixedCodes = this.conflictingFixedCodes.get(newFixedCode) ?? []

    return fixedCodes.filter((_) => !conflictingFixedCodes.includes(_))
  }
}