import { Localizer } from '../../../../utils/localizer'
import { TTFixedCodesService } from '../../../../utils/ttFixedCodesService'
import * as React from 'react'
import { useState } from 'react'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import { DateTime } from 'luxon'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { Provider } from '../../provider/ProvidersViewer'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { TTFixedCode } from '../../../general/TTFixedCode'
import './../../../../utils/stringExtensions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { guideContent } from '../../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { JdfAlternativeProvider } from '../../../../models/jdf/alternativeProvider'

interface Props {
  alternativeProvider?: JdfAlternativeProvider
  possibleAlternativeProviders: Provider[]
  onSave: (alternativeProvider: JdfAlternativeProvider) => void
  onCancel: () => void
}

interface State {
  fixedCodes: string[]
  validFrom?: DateTime
  validTo?: DateTime
  timeCode?: TimeCode
  selectedProvider: Provider

  availableAlternativeProviders: Provider[]
  isValidityDatesVisible: boolean
  infoNote?: string
  validationErrors?: string[]
}

enum TimeCode {
  RunsInOddWeeksOnly = '5',
  RunsInEvenWeeksOnly = '6',
}

export function ConnectionAlternativeProviderEditor(props: Props): JSX.Element {
  // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
  const jdfDateFormat = 'ddLLyyyy' // day in 2 digits, month in 2 digits, year in 4 digits

  const [state, setState] = useState<State>({
    fixedCodes: props.alternativeProvider?.fixedCodes ?? [],
    validFrom: props.alternativeProvider?.dateFrom
      ? DateTime.fromFormat(props.alternativeProvider.dateFrom, jdfDateFormat)
      : undefined,
    validTo: props.alternativeProvider?.dateTo
      ? DateTime.fromFormat(props.alternativeProvider.dateTo, jdfDateFormat)
      : undefined,
    selectedProvider: props.alternativeProvider
      ? getSelectedProvider(props.alternativeProvider)
      : props.possibleAlternativeProviders[0],
    timeCode: stringToTimeCode(props.alternativeProvider?.timeCodeType),

    availableAlternativeProviders: props.possibleAlternativeProviders,
    validationErrors: [],
    isValidityDatesVisible:
      !!props.alternativeProvider?.dateFrom || !!props.alternativeProvider?.dateTo,
  })

  useAppGuide([
    guideContent.connections.editor.providers.dropdown,
    guideContent.connections.editor.providers.fixedCodes,
    guideContent.connections.editor.providers.timeCode,
    guideContent.connections.editor.providers.validityCheckbox,
    guideContent.connections.editor.providers.confirmChanges,
    guideContent.connections.editor.providers.cancelChanges,
  ])
  useAppGuide([guideContent.connections.editor.providers.clearTimeCode], !!state.timeCode)

  function stringToTimeCode(value: string | undefined): TimeCode | undefined {
    let timeCode: TimeCode | undefined = undefined

    if (value === TimeCode.RunsInOddWeeksOnly || value === TimeCode.RunsInEvenWeeksOnly)
      timeCode = value as TimeCode

    return timeCode
  }

  function isFixedCodeChecked(fixedCode: string): boolean {
    return state.fixedCodes.includes(fixedCode)
  }

  function editorToggleFixedCode(fixedCode: string): void {
    const maxCountOfFixedCodes = 6
    const wasFixedCodeChecked = isFixedCodeChecked(fixedCode)
    let newFixedCodes: string[]
    let newInfoNote: string | undefined

    if (wasFixedCodeChecked) {
      newFixedCodes = state.fixedCodes.filter((_) => _ !== fixedCode)
      newInfoNote = undefined
    } else {
      if (state.fixedCodes.length < maxCountOfFixedCodes) {
        newFixedCodes = state.fixedCodes.slice(0, state.fixedCodes.length)
        newFixedCodes.push(fixedCode)

        if (state.fixedCodes.length >= maxCountOfFixedCodes - 1)
          newInfoNote = Localizer.localize(
            'You have reached maximum number of fixed codes that can be selected.'
          )
      } else {
        return
      }
    }

    setState((_) => {
      return { ..._, fixedCodes: newFixedCodes, infoNote: newInfoNote }
    })
  }

  function toggleShowValidityDates(): void {
    const isCurrentlyVisible = state.isValidityDatesVisible
    let validFrom = state.validFrom
    let validTo = state.validTo

    if (isCurrentlyVisible) {
      validFrom = undefined
      validTo = undefined
    }

    setState((_) => {
      return {
        ..._,
        isValidityDatesVisible: !_.isValidityDatesVisible,
        validFrom: validFrom,
        validTo: validTo,
      }
    })
  }

  function getFixedCode(index: number): string | undefined {
    return state.fixedCodes.length > index ? state.fixedCodes[index] : undefined
  }

  function onSave(): void {
    const newValidationErrors: string[] = []

    let bothDatesSet = false
    if (state.validFrom && state.validTo) {
      bothDatesSet = true

      if (state.validFrom > state.validTo)
        newValidationErrors.push(
          Localizer.localize("Date 'valid from' is later than date 'valid to'.")
        )
    }

    // if just "valid to" date is set
    if (!bothDatesSet && state.validTo) {
      newValidationErrors.push(
        Localizer.localize("When setting 'valid to' date, 'valid from' date must be set, too.")
      )
    }

    if (newValidationErrors.length > 0) {
      setState((_) => {
        return { ..._, validationErrors: newValidationErrors }
      })

      return
    }

    const alternativeProvider: JdfAlternativeProvider = {
      providerCompanyId: state.selectedProvider.companyId,
      providerDistinction: state.selectedProvider.providerDistinction,

      dateFrom: state.validFrom !== null ? state.validFrom?.toFormat(jdfDateFormat) : undefined,
      dateTo: state.validTo !== null ? state.validTo?.toFormat(jdfDateFormat) : undefined,

      timeCodeType: state.timeCode,
      fixedCodes: state.fixedCodes,

      lineNumber: {
        lineNumber: '',
        lineNumberExtension: '',
        isLineNumberUnique: false,
      },
      stringValue: getSelectedProviderStringValue(),
      reserve: '',
      connection: '',
    }

    props.onSave(alternativeProvider)
  }

  function getSelectedProvider(provider: JdfAlternativeProvider): Provider {
    const providers = props.possibleAlternativeProviders.filter(
      (_) =>
        _.companyId === provider.providerCompanyId &&
        _.providerDistinction === provider.providerDistinction
    )
    if (providers.length === 1) return providers[0]

    throw new Error(
      `Provider with company ID '${provider.providerCompanyId}|${provider.providerDistinction}' was not found.`
    )
  }

  function getSelectedProviderStringValue(): string {
    let stringValue = state.selectedProvider.name

    if (state.timeCode === TimeCode.RunsInEvenWeeksOnly)
      stringValue += ', ' + Localizer.localize('Runs in even weeks only').toLowerCase()
    else if (state.timeCode === TimeCode.RunsInOddWeeksOnly)
      stringValue += ', ' + Localizer.localize('Runs in odd weeks only').toLowerCase()

    if (state.validFrom && !state.validTo) {
      stringValue += `, ${state.validFrom.toFormat('dd.MM.yyyy')}`
    }

    if (state.validFrom && state.validTo)
      stringValue +=
        `, ${Localizer.localize('From').toLowerCase()} ${state.validFrom.toFormat('dd.MM.yyyy')} ` +
        `${Localizer.localize('To').toLowerCase()} ${state.validTo.toFormat('dd.MM.yyyy')}`

    if (state.fixedCodes.length > 0)
      for (let i = 0; i < state.fixedCodes.length; i++) stringValue += ', ' + state.fixedCodes[i]

    return stringValue
  }

  function onCancel(): void {
    props.onCancel()
  }

  return (
    <div className='inline-editor pt-2 pb-3 px-3'>
      <div className={'mb-2'}>
        <label className='mb-1'>{Localizer.localize('Provider')}</label>
        <UncontrolledDropdown>
          <DropdownToggle
            id={guideContent.connections.editor.providers.dropdown.target}
            caret
            size='sm'
            className={'m-0 button button-mini button-rounded button-light w-100'}
            style={{ whiteSpace: 'normal' }}
          >
            {state.selectedProvider.name?.shorten(65)}
          </DropdownToggle>

          <DropdownMenu>
            {state.availableAlternativeProviders.map((_) => (
              <DropdownItem
                onClick={() =>
                  setState((previousState) => {
                    return { ...previousState, selectedProvider: _ }
                  })
                }
                key={_.companyId + _.providerDistinction}
                className='d-flex align-items-center'
              >
                <span className='small d-flex'>{_.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <div className='mb-2'>
        <label className='mb-1'>{Localizer.localize('Fixed code')}</label>
        <div
          id={guideContent.connections.editor.providers.fixedCodes.target}
          className='m-0 button-group fixed-code-toggles w-100'
        >
          {TTFixedCodesService.connectionAlternativeProviderAllowedFixedCodes.map((_) => (
            <button
              type='button'
              key={_}
              className={`toggle-button button button-rounded button-small ${
                isFixedCodeChecked(_) ? 'text-white' : 'button-light'
              }`}
              onClick={() => editorToggleFixedCode(_)}
            >
              <TTFixedCode mark={_} wrapperClassName={'p-2'} />
            </button>
          ))}
        </div>

        {state.infoNote && (
          <span className={'small text-muted'}>
            <i className={'fas fa-info-circle'} /> {state.infoNote}
          </span>
        )}
      </div>

      <div className='form-group mb-2'>
        <label className='mb-1'>{Localizer.localize('Time code')}</label>
        <div className={'d-flex'}>
          <UncontrolledDropdown className={'flex-grow-1'}>
            <DropdownToggle
              id={guideContent.connections.editor.providers.timeCode.target}
              caret
              size='sm'
              className={'m-0 button button-rounded button-mini button-light w-100'}
              style={{ whiteSpace: 'normal' }}
            >
              {state.timeCode === TimeCode.RunsInEvenWeeksOnly ? (
                Localizer.localize('Runs in even weeks only')
              ) : state.timeCode === TimeCode.RunsInOddWeeksOnly ? (
                Localizer.localize('Runs in odd weeks only')
              ) : (
                <FontAwesomeIcon icon={faMinus} />
              )}
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  setState((previousState) => {
                    return {
                      ...previousState,
                      timeCode: stringToTimeCode(TimeCode.RunsInOddWeeksOnly),
                    }
                  })
                }
                className='d-flex align-items-center'
              >
                <span className='small d-flex'>{Localizer.localize('Runs in odd weeks only')}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setState((previousState) => {
                    return {
                      ...previousState,
                      timeCode: stringToTimeCode(TimeCode.RunsInEvenWeeksOnly),
                    }
                  })
                }
                className='d-flex align-items-center'
              >
                <span className='small d-flex'>
                  {Localizer.localize('Runs in even weeks only')}
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {state.timeCode && (
            <Button
              id={guideContent.connections.editor.providers.clearTimeCode.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              onClick={() => {
                setState((_) => {
                  return { ..._, timeCode: undefined }
                })
              }}
              className={'m-0 ml-1'}
              color={ButtonColor.Danger}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </div>
      </div>

      <div className='d-flex mb-2'>
        <label className='mb-0 mr-2'>{Localizer.localize('Validity')}</label>
        <div id={guideContent.connections.editor.providers.validityCheckbox.target} className='m-0'>
          <input
            id='show-validity-dates-toggle'
            className='switch-toggle switch-flat-mini switch-toggle-flat'
            type='checkbox'
            defaultChecked={state.isValidityDatesVisible}
            onChange={() => toggleShowValidityDates()}
          />
          <label htmlFor='show-validity-dates-toggle' className='mb-0' />
        </div>
      </div>

      {state.isValidityDatesVisible && (
        <div className={'d-flex align-items-center mb-2'}>
          <div className={'mr-1 flex-grow-1'}>
            <DatePickerComponent
              placeholder={`${Localizer.localize('Valid from')}...`}
              value={state.validFrom?.toJSDate()}
              change={(_) =>
                setState((previousState) => {
                  return {
                    ...previousState,
                    validFrom: _?.value ? DateTime.fromMillis(_.value.getTime()) : undefined,
                  }
                })
              }
            />
          </div>
          <div className={'flex-grow-1'}>
            <DatePickerComponent
              placeholder={`${Localizer.localize('Valid to')}...`}
              value={state.validTo?.toJSDate()}
              locale={'sk'}
              change={(_) =>
                setState((previousState) => {
                  return {
                    ...previousState,
                    validTo: _?.value ? DateTime.fromMillis(_.value.getTime()) : undefined,
                  }
                })
              }
            />
          </div>
        </div>
      )}

      {state.validationErrors?.length === 0 ? undefined : (
        <div className={'mb-2'}>
          <ul className={'text-danger small mb-0 pl-3'}>
            {state.validationErrors?.map((_, index) => (
              <li key={index}>{_}</li>
            ))}
          </ul>
        </div>
      )}

      <div className='d-flex justify-content-end'>
        <Button
          id={guideContent.connections.editor.providers.confirmChanges.target}
          size={ButtonSize.ExtraSmall}
          shape={ButtonShape.Rounded}
          onClick={onSave}
          className={'m-0 mr-1'}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
        <Button
          id={guideContent.connections.editor.providers.cancelChanges.target}
          size={ButtonSize.ExtraSmall}
          shape={ButtonShape.Rounded}
          onClick={onCancel}
          color={ButtonColor.Danger}
          className={'m-0'}
        >
          <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
          {Localizer.localize('Cancel')}
        </Button>
      </div>
    </div>
  )
}