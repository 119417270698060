import useAxios from "axios-hooks";
import {useDataLoadingState} from "@inprop/tt-ui-elements";
import {UrlProvider} from "./UrlProvider";

const useJdfStationApi = (jdfDataId: string, stationId: string, exchangeToCrwsStationId?: string) => {
  const getEndpointUrl = (): string => {
    if (!jdfDataId) {
      throw new Error(`When using JDF stations, JDF data id must be set in configuration.`);
    }

    if (!stationId) {
      throw new Error('When requesting JDF station details, ID must not be null.');
    }

    return UrlProvider.Api.JdfData.Stations.getUrl(jdfDataId, stationId, exchangeToCrwsStationId);
  };

  const [{loading: isUpdating, error: updateError}, update] = useAxios(
    {method: 'PUT'}, {manual: true}
  );
  const updateState = useDataLoadingState(isUpdating, updateError);
  
  const executeUpdate = () => {
    return update({url: getEndpointUrl()})
  }

  return {
    executeUpdate,
    updateState
  };
};

export default useJdfStationApi;
