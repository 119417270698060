export const connectionsGuide = {
  toolbar: {
    showLine: {
      target: 'jdf-connections-toolbar-show-line',
      text: 'Zobrazenie vývesky linky.',
    },
    previousConnection: {
      target: 'jdf-connections-toolbar-previous',
      text: 'Zobrazenie predchádzajúceho spoja linky.',
    },
    nextConnection: {
      target: 'jdf-connections-toolbar-next',
      text: 'Zobrazenie nasledujúceho spoja linky.',
    },
    route: {
      target: 'jdf-connections-toolbar-route',
      text: 'Zobrazenie trasy spoja linky.',
    },
    calendar: {
      target: 'jdf-connections-toolbar-calendar',
      text: 'Zobrazenie premávania spoja linky.',
    },
    notes: {
      target: 'jdf-connections-toolbar-notes',
      text: 'Zobrazenie poznámok spoja linky.',
    },
    providers: {
      target: 'jdf-connections-toolbar-providers',
      text: 'Zobrazenie dopravcov spoja linky.',
    },
    save: {
      target: 'jdf-connections-toolbar-save',
      text: 'Uloženie zmien vykonaných nad spojom linky.',
    },
    delete: {
      target: 'jdf-connections-toolbar-delete',
      text: 'Odstránenie spoja linky.',
    },
  },
  editor: {
    route: {
      platformInput: {
        target: 'jdf-connection-edit-route-platform-input',
        text: 'Priamou editáciou  sa zadá nástupište pre spoj v príslušnej zastávke.',
      },
      kmInput: {
        target: 'jdf-connection-edit-route-km-input',
        text: 'Priamou editáciou  sa zadá tarifná vzdialenosť v kilometroch od začiatku spoja. V trase spoja nemôže existovať zastávka so zadaným časom príchodu alebo odchodu bez zadanej tarifnej vzdialenosti.',
      },
      arrivalInput: {
        target: 'jdf-connectoin-edit-route-arrival-input',
        text: 'Priamou editáciou sa zadá čas príchodu do zastávky spoja. Tipy na zadanie času sa zobrazujú na stránke vpravo.',
      },
      departureInput: {
        target: 'jdf-connection-edit-route-departure-input',
        text: 'Priamou editáciou sa zadá čas odchodu zo zastávky spoja. Tipy na zadanie času sa zobrazujú na stránke vpravo.',
      },
      stopSettings: {
        target: 'jdf-connection-edit-route-stop-settings',
        text: 'Ďalšie nastavenia zastavenia spoja. Ukázaním kurzorom na jednotlivé ikony sa zobrazí ich význam.',
      },
    },
    calendar: {
      toggles: {
        daily: {
          target: 'jdf-connection-edit-calendar-toggles-daily',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky dni.',
        },
        one: {
          target: 'jdf-connection-edit-calendar-toggles-1',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky pondelky.',
        },
        two: {
          target: 'jdf-connection-edit-calendar-toggles-2',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky utorky.',
        },
        three: {
          target: 'jdf-connection-edit-calendar-toggles-3',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky stredy.',
        },
        four: {
          target: 'jdf-connection-edit-calendar-toggles-4',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky štvrtky.',
        },
        five: {
          target: 'jdf-connection-edit-calendar-toggles-5',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky piatky.',
        },
        six: {
          target: 'jdf-connection-edit-calendar-toggles-6',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky soboty.',
        },
        seven: {
          target: 'jdf-connection-edit-calendar-toggles-7',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky nedele.',
        },
        x: {
          target: 'jdf-connection-edit-calendar-toggles-x',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky pracovné dni.',
        },
        cross: {
          target: 'jdf-connection-edit-calendar-toggles-cross',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky nedele a sviatky.',
        },
        monthRow: {
          target: 'jdf-connection-edit-calendar-toggles-month-row',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky dni v príslušnom riadku.',
        },
        dayColumn: {
          target: 'jdf-connection-edit-calendar-toggles-day-column',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí všetky dni v príslušnom stĺpci.',
        },
        dayCell: {
          target: 'jdf-connection-edit-calendar-toggles-day-cell',
          text: 'Kliknutie ľavého tlačidla myši označí/odznačí príslušný deň.',
        },
      },
    },
    notes: {
      fixedCodes: {
        target: 'jdf-connection-edit-notes-fixed-code',
        text: 'Zaškrtnutie/odškrtnutie pridá/odoberie poznámku pre spoj linky.',
      },
      textNotes: {
        edit: {
          target: 'jdf-connection-edit-text-notes-edit',
          text: 'Úprava textovej poznámky spoja linky.',
        },
        delete: {
          target: 'jdf-connection-edit-text-notes-delete',
          text: 'Odstránenie textovej poznámky spoja linky.',
        },
        add: {
          target: 'jdf-connection-edit-text-notes-add',
          text: 'Pridanie novej textovej poznámky pre spoj linky.',
        },
        typeDropdown: {
          target: 'jdf-connection-edit-text-notes-type-dropdown',
          text: 'Výberom zo zoznamu sa určí typ textovej poznámky pre spoj linky.',
        },
        confirmChanges: {
          target: 'jdf-connection-edit-text-notes-confirm-changes',
          text: 'Pridanie novej textovej poznámky resp. akceptovanie zmeny poznámky. Aby sa zmena zapísala do dát, musí sa následne použiť oranžovo podfarbená voľba ULOŽIŤ na uloženie zmien vykonaných nad spojom linky.',
        },
        cancelChanges: {
          target: 'jdf-connection-edit-text-notes-cancel-changes',
          text: 'Ukončenie nástroja na pridanie/zmenu textovej poznámky bez akceptovania zmien v textových poznámkach pre spoj linky.',
        },
      },
    },
    providers: {
      edit: {
        target: 'jdf-connection-edit-provider-edit',
        text: 'Úprava alternatívneho dopravcu spoja linky.',
      },
      delete: {
        target: 'jdf-connection-edit-provider-delete',
        text: 'Odstránenie alternatívneho dopravcu spoja linky.',
      },
      add: {
        target: 'jdf-connection-edit-provider-add',
        text: 'Pridanie nového alternatívneho dopravcu pre spoj linky.',
      },
      manage: {
        target: 'jdf-connection-edit-provider-manage',
        text: 'Zobrazenie stránky DOPRAVCOVIA so zoznamom dopravcov.'
      },
      dropdown: {
        target: 'jdf-connection-edit-provider-dropdown',
        text: 'Výberom zo zoznamu dopravcov sa zmení alternatívny dopravca linky. Zoznam neobsahuje hlavného dopravcu linku.',
      },
      fixedCodes: {
        target: 'jdf-connection-edit-provider-fixed-codes',
        text: 'Označenie/odznačenie pevného kódu určuje dátumovú obsluhu spoja alternatívnym dopravcom.',
      },
      timeCode: {
        target: 'jdf-connection-edit-provider-tim-code',
        text:
          'Výberom zo zoznamu sa spresňuje dátumová obsluha spoja alternatívnym dopravcom v párnych resp.' +
          ' nepárnych týždňoch.',
      },
      clearTimeCode: {
        target: 'jdf-connection-edit-provider-clear-time-code',
        text: 'Zrušenie časového kódu premávania v párnych resp. nepárnych týždňoch pre alternatívneho dopravcu.',
      },
      validityCheckbox: {
        target: 'jdf-connection-edit-provider-validity-checkbox',
        text: 'Zaškrtnutie umožní obmedziť platnosť obsluhy spoja alternatívnym dopravcom zadaním začiatku a konca platnosti obsluhy.',
      },
      confirmChanges: {
        target: 'jdf-connection-edit-provider-confirm-changes',
        text: 'Pridanie nového alternatívneho dopravcu resp. akceptovanie zmeny alternatívneho dopravcu. Aby sa zmena zapísala do dát, musí sa následne použiť oranžovo podfarbená voľba ULOŽIŤ na uloženie zmien vykonaných nad spojom linky.',
      },
      cancelChanges: {
        target: 'jdf-connection-edit-provider-cancel-changes',
        text: 'Ukončenie nástroja na pridanie/zmenu alternatívneho dopravcu bez akceptovania zmien v alternatívnych dopravcoch pre spoj linky.',
      },
    },
  },
  add: {
    input: {
      target: 'jdf-connections-add-input',
      text: 'Pole na zadanie čísla nového spoja.'
    },
    confirm: {
      target: 'jdf-connection-add-confirm',
      text: 'Pridanie nového spoja.'
    },
    cancel: {
      target: 'jdf-connection-add-cancel',
      text: 'Zrušenie pridania nového spoja.'
    }
  }
}
