import React, { createContext, FC, useEffect, useState } from 'react'

export enum LineEditor {
  Route = 'route',
  Provider = 'provider',
  Notes = 'notes',
}

export type LineEditorSaveFn = () => Promise<void>

export interface JdfLineEditorContextState {
  hasUnsavedChanges: boolean
  currentEditor: LineEditor
  saveFn: LineEditorSaveFn
}

export interface JdfLineEditorContextActions {
  setHasUnsavedChanges: (newValue: boolean) => void
  setCurrentEditor: (editor: LineEditor) => void
  setSaveFn: (newFn: LineEditorSaveFn) => void
}

const defaultState: JdfLineEditorContextState = {
  hasUnsavedChanges: false,
  currentEditor: LineEditor.Route,
  saveFn: () => Promise.resolve(),
}

export const JdfLineEditorContext = createContext<{
  state: JdfLineEditorContextState
  dispatch: JdfLineEditorContextActions
}>({
  state: defaultState,
  dispatch: {
    setHasUnsavedChanges: () => {},
    setCurrentEditor: () => {},
    setSaveFn: () => {},
  },
})

const JdfLineEditorContextProvider: FC = (props) => {
  const [state, setState] = useState<JdfLineEditorContextState>(defaultState)

  const setHasUnsavedChanges = (newValue: boolean) => {
    setState((_) => ({ ..._, hasUnsavedChanges: newValue }))
  }

  const setCurrentEditor = (newValue: LineEditor) => {
    setState((_) => ({ ..._, currentEditor: newValue }))
  }

  const setSaveFn = (newValue: LineEditorSaveFn) => {
    setState((_) => ({ ..._, saveFn: newValue }))
  }

  useEffect(() => {
    setState((_) => ({ ..._, hasUnsavedChanges: false }))
  }, [state.currentEditor])

  return (
    <JdfLineEditorContext.Provider
      value={{ state, dispatch: { setHasUnsavedChanges, setCurrentEditor, setSaveFn } }}
    >
      {props.children}
    </JdfLineEditorContext.Provider>
  )
}

export default JdfLineEditorContextProvider
