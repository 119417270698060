import React, { FunctionComponent } from 'react'
import { Localizer } from '../../../../utils/localizer'

export const ConnectionRouteEditorHints: FunctionComponent = () => (
  <>
    <h5 className='mb-2'>{Localizer.localize('Hints for using')}</h5>

    <ul style={{ fontSize: '0.9em' }}>
      <li>
        {Localizer.localize('for selecting next time of arrival or departure, press') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Tab')}</span>
        {' ' + Localizer.localize('or') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Enter')}</span>
      </li>

      <li>
        {Localizer.localize('you can set new time, e.g. 06:05, in multiple formats') + ' '}
        <span className={'badge badge-light'}>605</span>,{' '}
        <span className={'badge badge-light'}>0605</span>
        {' ' + Localizer.localize('or') + ' '}
        <span className={'badge badge-light'}>06:05</span>
      </li>

      <li>
        {Localizer.localize('you can increase and decrease time by pressing') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Key up')}</span>
        {' ' + Localizer.localize('and') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Key down')}</span>
      </li>

      <li>
        {Localizer.localize(
          'you can increase and decrease time with mouse wheel, when in input with mouse over it and holding'
        )}{' '}
        <span className={'badge badge-light'}>Shift</span>
      </li>

      <li>
        {Localizer.localize(
          'you can increase and decrease time of the current input and all its followers ' +
            'by pressing'
        ) + ' '}
        <span className={'badge badge-light'}>Ctrl + {Localizer.localize('Key up')}</span>
        {' ' + Localizer.localize('and') + ' '}
        <span className={'badge badge-light'}>Ctrl + {Localizer.localize('Key down')}</span>
      </li>

      <li>
        {Localizer.localize('to add new time in empty input, you can press') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Key up')}</span>
        {' ' + Localizer.localize('or') + ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Key down')}</span>
        {' ' +
          Localizer.localize('and first following (or first previous) time +1 minute (or -1 when') +
          ' '}
        <span className={'badge badge-light'}>{Localizer.localize('Key down')}</span>
        {' ' + Localizer.localize('was pressed) will be used')}
      </li>

      <li>
        {Localizer.localize('you can set that connection goes through concrete stop by inserting')}
        <span className={'badge badge-light'}>|</span>
      </li>

      <li>
        {Localizer.localize('you can set that connection bypasses concrete stop by inserting')}
        <span className={'badge badge-light'}>{'<'}</span>
      </li>
    </ul>
  </>
)