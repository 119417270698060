import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LineNumberExtended, Station } from '../../../models/LineModels'
import { Localizer } from '../../../utils/localizer'
import { List } from 'immutable'
import {
  ConnectionStopFixedCodesEditor,
  FixedCodeStatus,
} from '../connection/stop/ConnectionStopFixedCodesEditor'
import { TTFixedCodesService } from '../../../utils/ttFixedCodesService'
import { Logger } from '../../../utils/logger'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import {
  AutocompleteStation as SelectableLineStop,
  StationSelectAutocomplete,
} from '../station/StationSelectAutocomplete'
import {
  AppGuideContext,
  Button as InpropButton,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Preloader,
} from '@inprop/tt-ui-elements'
import { LoadingFailed, LoadingState } from '../../../to-refactor/LoadingHelpers'
import StationName from '../station/StationName'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { JdfLineEditorContext, LineEditorSaveFn } from '../../../contexts/JdfLineEditorContext'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import cx from 'classnames'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'
import { Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  setSaveFn: (saveFn: LineEditorSaveFn) => void
}

interface State {
  lineStops: List<LineStop>
  isNewLineStopSelectVisible: boolean
  newLineStop?: SelectableLineStop
}

interface LineStopApi {
  stationId: string
  line: LineNumberExtended
  tariffNumber: number
  stopNumber: string
  stopName: string
  isStationUnknown: boolean
  averageTime: number
  fixedCodes: string[]
}

interface LineStopDeletableApiModel {
  isDeletable: boolean
  deleteDescription: string
}

export interface LineStop {
  line: LineNumberExtended
  stationId: string
  tariffNumber: number
  stopNumber?: string
  stopName: string
  isStationUnknown: boolean
  averageTime?: number
  fixedCodes: List<string>
}

export function LineRouteEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfLineEditorContext)

  const [state, setState] = useState<State>({
    lineStops: List(),
    isNewLineStopSelectVisible: false,
    newLineStop: undefined,
  })
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading)
  const [deletingLingStop, setIsDeletingLineStop] = useState<LineStop>()

  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)
  useAppGuide([guideContent.lines.editor.route.addStationButton], !state.isNewLineStopSelectVisible)
  useAppGuide(
    [
      guideContent.lines.editor.route.addStationInput,
      guideContent.lines.editor.route.addStationCancel,
    ],
    state.isNewLineStopSelectVisible
  )
  useAppGuide(
    [guideContent.lines.editor.route.addStationConfirm],
    state.isNewLineStopSelectVisible && !!state.newLineStop
  )
  useAppGuide([guideContent.lines.editor.route.deleteStation], isGuideVisible, 'append')

  useEffect(() => {
    loadLineStops()
  }, [])

  useEffect(() => {
    if (props.setSaveFn) props.setSaveFn(saveLineStops)

    if (deletingLingStop) {
      refreshTariffNumbers()
      setIsDeletingLineStop(undefined)
    }
  }, [state.lineStops])

  function loadLineStops(): void {
    setLoadingState(LoadingState.Loading)

    axios
      .get<LineStopApi[]>(
        UrlProvider.Api.Components.Lines.RouteEditor.getUrl(props.dataId, props.lineNumber)
      )
      .then((result) => {
        setState((previousState) => {
          return {
            ...previousState,
            lineStops: List(
              result.data.map((resultRecord) => {
                return {
                  ...resultRecord,

                  fixedCodes: List(resultRecord.fixedCodes),
                }
              })
            ),
          }
        })

        setLoadingState(LoadingState.LoadingSucceeded)
      })
      .catch((error) => {
        setLoadingState(LoadingState.LoadingFailed)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line route could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleError(error)
      })
  }

  function getFixedCodes(lineStop: LineStop): FixedCodeStatus[] {
    return TTFixedCodesService.lineStopAllowedFixedCodes.map((_) => {
      const maxFixedCodesCount = 3

      const isSelected = lineStop.fixedCodes.includes(_)
      const isDisabled = lineStop.fixedCodes.size === maxFixedCodesCount && !isSelected

      return {
        fixedCode: _,
        isSelected: isSelected,
        isDisabled: isDisabled,
        whyDisabled: isDisabled
          ? Localizer.localize('Line stop can have max 3 fixed codes')
          : undefined,
      }
    })
  }

  function addSelectedLineStop(): void {
    if (!state.newLineStop) {
      return
    }

    editorDispatch.setHasUnsavedChanges(true)

    const newLineStop: LineStop = {
      stationId: state.newLineStop.crwsId,
      line: props.lineNumber,
      stopName: state.newLineStop.name,
      stopNumber: undefined,
      isStationUnknown: false,
      tariffNumber: state.lineStops.size + 1,
      fixedCodes: List(),
      averageTime: undefined,
    }

    setState((_) => {
      return {
        ..._,
        lineStops: _.lineStops.push(newLineStop),
        newLineStop: undefined,
        isNewLineStopSelectVisible: false,
      }
    })
  }

  function refreshTariffNumbers(): void {
    let newLineStops = List<LineStop>([])
    for (let i = 0; i < state.lineStops.size; i++) {
      const lineStop = state.lineStops.get(i)

      if (lineStop) {
        newLineStops = newLineStops.push({ ...lineStop, tariffNumber: i + 1 })
      }
    }

    setState((_) => {
      return { ..._, lineStops: newLineStops }
    })
  }

  function deleteLineStop(index: number): void {
    const lineStop = state.lineStops.get(index)
    if (!lineStop) {
      return
    }

    setIsDeletingLineStop(lineStop)

    axios
      .get<LineStopDeletableApiModel>(
        UrlProvider.Api.Components.Lines.RouteEditor.canDeleteLineStopUrl(
          props.dataId,
          props.lineNumber,
          lineStop.tariffNumber
        )
      )
      .then((_) => {
        if (_.data.isDeletable) {
          setState((_) => {
            return { ..._, lineStops: _.lineStops.remove(index) }
          })
        } else {
          notificationsDispatch({
            type: ADD_NOTIFICATION,
            value: {
              title: Localizer.localize('Line stop cannot be deleted'),
              body: _.data.deleteDescription,
              type: NotificationType.Error,
            },
          })

          setIsDeletingLineStop(undefined)
        }
      })
      .catch((error) => {
        handleError(error)
        setIsDeletingLineStop(undefined)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line stop cannot be deleted'),
            type: NotificationType.Error,
          },
        })
      })
  }

  const saveLineStops = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Lines.RouteEditor.getUrl(props.dataId, props.lineNumber),
        state.lineStops
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line route was saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        handleError(error)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving line route'),
            type: NotificationType.Error,
          },
        })
      })

  function handleError(error: any): void {
    Logger.logError(
      `Error while loading data in ${LineRouteEditor.name}. Message: ${error.toString()}`
    )
  }

  const onFixedCodeChange = useCallback(
    (fixedCode: string, action: 'set' | 'unset', lineStopIndex: number): void => {
      editorDispatch.setHasUnsavedChanges(true)

      const existingLineStop = state.lineStops.get(lineStopIndex)
      if (!existingLineStop) {
        return
      }

      const newLineStop = { ...existingLineStop }

      if (action === 'set') {
        if (!newLineStop.fixedCodes?.includes(fixedCode)) {
          newLineStop.fixedCodes = TTFixedCodesService.removeConflictingFixedCodes(
            newLineStop.fixedCodes ?? List(),
            fixedCode
          )

          newLineStop.fixedCodes = newLineStop.fixedCodes.push(fixedCode)
        }
      } else {
        if (newLineStop.fixedCodes?.includes(fixedCode)) {
          const fixedCodeIndex = newLineStop.fixedCodes.indexOf(fixedCode)
          newLineStop.fixedCodes = newLineStop.fixedCodes.remove(fixedCodeIndex)
        }
      }

      setState((_) => {
        return {
          ..._,
          lineStops: _.lineStops.set(lineStopIndex, newLineStop),
        }
      })
    },
    [state]
  )

  return (
    <>
      {loadingState === LoadingState.Loading ? (
        <div className={'row'}>
          <div className={'col-12 text-center py-5'}>
            <Preloader overlay={false} />
          </div>
        </div>
      ) : loadingState === LoadingState.LoadingFailed ? (
        <LoadingFailed tryAgainFn={loadLineStops} />
      ) : (
        <div className={'row'}>
          <div className={'col-md-7 col-lg-6'}>
            <table>
              <thead>
                <tr>
                  <th>{Localizer.localize('Tar. nr.')}</th>
                  <th>{Localizer.localize('Station')}</th>
                  <th>{Localizer.localize('Fixed code')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {state.lineStops.map((_, index) => (
                  <tr className={'display-child-on-hover'} key={index}>
                    <td>{_.tariffNumber}</td>
                    <td>
                      <StationName
                        name={_.stopName}
                        isStationUnknown={_.isStationUnknown}
                        jdfDataId={props.dataId}
                        stationId={_.stationId}
                        onStationExchangeDone={() => loadLineStops()}
                        withGuide={index === 0}
                      />
                    </td>
                    <td>
                      <ConnectionStopFixedCodesEditor
                        fixedCodes={getFixedCodes(_)}
                        onChangeFunction={(fixedCode: string, action: 'set' | 'unset') =>
                          onFixedCodeChange(fixedCode, action, index)
                        }
                      />
                    </td>
                    <td>
                      <div
                        className={cx({
                          'display-on-parent-hover': !(index === 0 && isGuideVisible),
                        })}
                      >
                        <Tooltip title={Localizer.localize('Delete line stop')}>
                          <LoadingButton
                            id={guideContent.lines.editor.route.deleteStation.target}
                            onClick={() => deleteLineStop(index)}
                            className={'m-0'}
                            disabled={!!deletingLingStop}
                            loading={deletingLingStop?.tariffNumber === _.tariffNumber}
                            size={'small'}
                            color={'error'}
                            variant={'contained'}
                          >
                            <FontAwesomeIcon icon={faTrash} className={'m-0'} />
                          </LoadingButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={'col-md-5 col-lg-6'}>
            {!state.isNewLineStopSelectVisible ? (
              <InpropButton
                id={guideContent.lines.editor.route.addStationButton.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                onClick={() =>
                  setState((_) => {
                    return { ..._, isNewLineStopSelectVisible: true }
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                {Localizer.localize('Add new line stop')}
              </InpropButton>
            ) : (
              <div className={'inline-editor d-flex flex-column p-3 mb-2'}>
                <StationSelectAutocomplete
                  id={guideContent.lines.editor.route.addStationInput.target}
                  className={'mb-2'}
                  onChange={(newLineStop) =>
                    setState((_) => {
                      return { ..._, newLineStop: newLineStop }
                    })
                  }
                  onEscPressed={() =>
                    setState((_) => {
                      return { ..._, isNewLineStopSelectVisible: false }
                    })
                  }
                />
                <div className={'d-flex flex-wrap justify-content-end align-items-baseline'}>
                  {state.newLineStop && (
                    <InpropButton
                      id={guideContent.lines.editor.route.addStationConfirm.target}
                      size={ButtonSize.ExtraSmall}
                      shape={ButtonShape.Rounded}
                      className={'m-0'}
                      onClick={addSelectedLineStop}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </InpropButton>
                  )}
                  <InpropButton
                    id={guideContent.lines.editor.route.addStationCancel.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Danger}
                    className={'m-0 mt-1 ml-1'}
                    onClick={() =>
                      setState((_) => {
                        return { ..._, isNewLineStopSelectVisible: false, newLineStop: undefined }
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                    {Localizer.localize('Cancel')}
                  </InpropButton>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}