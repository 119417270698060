/* Action constants */
import { JdfState } from './JdfContext'
import {JdfKonExitCode} from "../models/protocol";

export const SET_LOADED_JDF_DATA_ID = 'jdf/set-loaded-jdf-data-id'
export const SET_JDF_KON_EXIT_CODE = 'jdf/set-jdf-kon-exit-code'

/* Action interfaces */
export interface SetLoadedJdfDataIdAction {
  type: typeof SET_LOADED_JDF_DATA_ID
  value: string | undefined
}
export interface SetJdfKonExitCodeAction {
  type: typeof SET_JDF_KON_EXIT_CODE
  value: JdfKonExitCode | undefined
}

/* Action functions */
const setLoadedJdfDataId = (jdfDataId: string | undefined, state: JdfState): JdfState => {
  return { ...state, jdfDataId: jdfDataId }
}
const setJdfKonExitCode = (jdfKonExitCode: JdfKonExitCode | undefined, state: JdfState): JdfState => {
  return { ...state, jdfKonExitCode: jdfKonExitCode }
}

export type JdfActionType = SetLoadedJdfDataIdAction | SetJdfKonExitCodeAction

export const JdfReducer = (state: JdfState, action: JdfActionType): JdfState => {
  switch (action.type) {
    case SET_LOADED_JDF_DATA_ID:
      return setLoadedJdfDataId(action.value, state)
    case SET_JDF_KON_EXIT_CODE:
      return setJdfKonExitCode(action.value, state)
    default:
      return state
  }
}
