import React, { createRef, useState } from 'react'
import { Localizer } from '../../../utils/localizer'
import { ConnectionType } from './LineViewer'
import { Button, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons'
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons'
import Tooltip from '../../general/Tooltip'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  activeConnectionType: ConnectionType
  showConnectionsWithType: (connectionType: ConnectionType) => void
}

export function LineViewerConnectionFilters(props: Props): JSX.Element {
  const allConnectionsButtonRef = createRef<HTMLDivElement>()
  const oddConnectionsButtonRef = createRef<HTMLDivElement>()
  const evenConnectionsButtonRef = createRef<HTMLDivElement>()
  const [isAllConnectionsButtonTooltipVisible, setIsAllConnectionsButtonTooltipVisible] =
    useState<boolean>(false)
  const [isOddConnectionsButtonTooltipVisible, setIsOddConnectionsButtonTooltipVisible] =
    useState<boolean>(false)
  const [isEvenConnectionsButtonTooltipVisible, setIsEvenConnectionsButtonTooltipVisible] =
    useState<boolean>(false)

  useAppGuide([guideContent.lines.toolbar.connectionFilter])

  return (
    <div
      id={guideContent.lines.toolbar.connectionFilter.target}
      className='button-group mx-0 mr-2 my-1'
    >
      <Button
        id={'button-filter-all-connections'}
        shape={ButtonShape.Circle}
        size={ButtonSize.ExtraSmall}
        className={'m-0 pl-2 pr-1'}
        isActive={props.activeConnectionType === ConnectionType.All}
        onClick={() => props.showConnectionsWithType(ConnectionType.All)}
      >
        <div
          ref={allConnectionsButtonRef}
          onMouseEnter={() => setIsAllConnectionsButtonTooltipVisible(true)}
          onMouseLeave={() => setIsAllConnectionsButtonTooltipVisible(false)}
        >
          <FontAwesomeIcon icon={faCircleSolid} className={'mr-1'} />
          <FontAwesomeIcon icon={faCircleSolid} className={'m-0'} />
        </div>
      </Button>
      <Tooltip
        tooltipTargetRef={allConnectionsButtonRef}
        isVisible={isAllConnectionsButtonTooltipVisible}
      >
        {Localizer.localize('All connections')}
      </Tooltip>

      <Button
        id={'button-filter-even-connections'}
        shape={ButtonShape.Circle}
        size={ButtonSize.ExtraSmall}
        className={'m-0 px-2'}
        isActive={props.activeConnectionType === ConnectionType.Even}
        onClick={() => props.showConnectionsWithType(ConnectionType.Even)}
      >
        <div
          ref={evenConnectionsButtonRef}
          onMouseEnter={() => setIsEvenConnectionsButtonTooltipVisible(true)}
          onMouseLeave={() => setIsEvenConnectionsButtonTooltipVisible(false)}
        >
          <FontAwesomeIcon icon={faCircleRegular} className={'mr-1'} />
          <FontAwesomeIcon icon={faCircleSolid} className={'m-0'} />
        </div>
      </Button>
      <Tooltip
        tooltipTargetRef={evenConnectionsButtonRef}
        isVisible={isEvenConnectionsButtonTooltipVisible}
      >
        {Localizer.localize('Even connections')}
      </Tooltip>

      <Button
        id={'button-filter-odd-connections'}
        shape={ButtonShape.Circle}
        size={ButtonSize.ExtraSmall}
        className={'m-0 pl-1 pr-2'}
        isActive={props.activeConnectionType === ConnectionType.Odd}
        onClick={() => props.showConnectionsWithType(ConnectionType.Odd)}
      >
        <div
          ref={oddConnectionsButtonRef}
          onMouseEnter={() => setIsOddConnectionsButtonTooltipVisible(true)}
          onMouseLeave={() => setIsOddConnectionsButtonTooltipVisible(false)}
        >
          <FontAwesomeIcon icon={faCircleSolid} className={'mr-1'} />
          <FontAwesomeIcon icon={faCircleRegular} className={'m-0'} />
        </div>
      </Button>
      <Tooltip
        tooltipTargetRef={oddConnectionsButtonRef}
        isVisible={isOddConnectionsButtonTooltipVisible}
      >
        {Localizer.localize('Odd connections')}
      </Tooltip>
    </div>
  )
}