import React, { createRef, useContext, useEffect, useState } from 'react'
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns'
import { Localizer } from '../../../utils/localizer'
import { List } from 'immutable'
import axios from 'axios'
import { Logger } from '../../../utils/logger'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Provider } from './ProvidersViewer'
import { LoadingState } from '../../../to-refactor/LoadingHelpers'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'

interface Props {
  onChange?: (newLineStop: Provider | undefined) => void
  className?: string
  id?: string
}

export function ProviderSelectAutocomplete(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const [providerList, setProviderList] = useState<List<Provider>>(List([]))
  const [selectedProvider, setSelectedProviderPartial] = useState<Provider>()
  const [loadingProvidersState, setLoadingProvidersState] = useState<LoadingState>(
    LoadingState.Loading
  )

  const autoCompleteRef = createRef<AutoCompleteComponent>()
  const fields: object = { value: 'name' } //this "name" is property from Provider interface

  useEffect(() => {
    loadAllProviders()
  }, [])

  function setSelectedProvider(provider: Provider | undefined): void {
    setSelectedProviderPartial(provider)

    if (props.onChange) props.onChange(provider)
  }

  function loadAllProviders(): void {
    setLoadingProvidersState(LoadingState.Loading)

    axios
      .get<Provider[]>(UrlProvider.Api.Components.Providers.SelectAutocomplete.getUrl())
      .then((_) => {
        setLoadingProvidersState(LoadingState.LoadingSucceeded)
        setProviderList(List(_.data))
      })
      .catch((error) => {
        setLoadingProvidersState(LoadingState.LoadingFailed)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Providers list could not be loaded'),
            type: NotificationType.Error,
          },
        })

        handleError(error)
      })
  }

  function handleError(error: any): void {
    Logger.logError(error)
  }

  return (
    <div id={props.id} className={props.className ?? ''}>
      <AutoCompleteComponent
        dataSource={providerList.toArray().map((_) => {
          return { ..._ }
        })}
        placeholder={`${Localizer.localize('Start typing and select item from the list')}...`}
        ref={autoCompleteRef}
        fields={fields}
        change={(args) => {
          const provider = args?.itemData as Provider

          if (!provider || !provider.name) {
            setSelectedProvider(undefined)
            return
          }

          if (provider.name && provider.companyId) {
            setSelectedProvider(provider)
            return
          }

          if (autoCompleteRef?.current) {
            autoCompleteRef.current.value = selectedProvider?.name ?? ''
          }
        }}
        filtering={(args) => {
          if (!args?.text) setSelectedProvider(undefined)
        }}
        ignoreAccent={true}
        noRecordsTemplate={
          loadingProvidersState === LoadingState.Loading
            ? Localizer.localize('Loading') + '...'
            : Localizer.localize('No providers')
        }
      />
    </div>
  )
}
