import React, { FC, useContext, useState } from 'react'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../utils/urls'
import registerSvg from '../../assets/images/register_undraw_sign_up.svg'
import registrationSuccessfulSvg from '../../assets/images/registration-successful_undraw_message_sent.svg'
import { Localizer } from '../../utils/localizer'
import { useForm } from 'react-hook-form'
import TextInput from '../general/inputs/TextInput'
import {
  cpEditEmailAddress,
  emailMaxLength,
  emailPattern, escapedSpecialCharacters,
  passwordMaxLength,
  passwordMinLength,
  passwordPattern, specialCharacters,
  userNameMaxLength,
  userNameMinLength,
} from '../../utils/constants'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { RegisterViewModel } from '../../models/AuthViewModels'
import LoadingButton from '@mui/lab/LoadingButton'
import { errorCodes } from '../../data/errorCodes'
import { Alert, AlertTitle } from '@mui/material'

interface RegisterFormData {
  emailAddress: string
  userName: string
  password: string
  repeatedPassword: string
}

const RegisterForm: FC = () => {
  const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm<RegisterFormData>()
  const [, registerUserAxios] =
    useAxios<number>({ method: 'POST' }, { manual: true })
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const onSubmit = async (data: RegisterFormData): Promise<void> => {
    try {
      const registerViewModel: RegisterViewModel = {
        username: data.userName,
        emailAddress: data.emailAddress,
        password: data.password,
      }

      await registerUserAxios({
        url: apiUrls.auth.register,
        data: registerViewModel,
      })

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Success,
          title: Localizer.localize('Registration was successful'),
        },
      })

      setRegistrationSuccessful(true)

    } catch (error) {
      if (error.response && error.response.data?.code === errorCodes.emailAddressNotAllowedToRegister) {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            type: NotificationType.Warning,
            title: Localizer.localize('Registration failed'),
            body: Localizer.localize('Provided email address is not allowed to register!'),
            autoDismissAfterSeconds: 10,
          },
        })
        
        return
      }

      // TODO: handle 'Given address is already registered' error
      
      // others errors - 500 and other 400
      console.error(error)
      
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Registration failed'),
          body: Localizer.localize('Please try it again. If problem persists, contact us via email.'),
        },
      })
    }
  }

  if (registrationSuccessful) {
    return (
      <div className={'row py-4'}>
        <div className={'col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center'}>
          <img src={registrationSuccessfulSvg}
               alt={Localizer.localize('Registration was successful')}
               className={'w-100'}
          />
        </div>
        <div className={'col-12 col-md-6 col-xl-4 d-flex align-items-center'}>
          <Card className={'w-100 mt-5'}>
            <Card.Header className={'text-center'}>
              <h5 className={'my-0'}>{Localizer.localize('Registration was successful')}</h5>
            </Card.Header>
            <Card.Body className={'text-center'}>
              <div>
                {Localizer.localize('Confirmation email send, please check your inbox')}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={'row py-4'}>
        <div className={'col-12 col-xl-10 offset-xl-1'}>
          <Alert severity={'info'}>
            <AlertTitle>
              {Localizer.localize('Registration is allowed only for invited users.')}
            </AlertTitle>
            <p className={'mb-0'}>
              {Localizer.localize('If you would like to use our tool, please email us at ')}
              <a href={`mailto:${cpEditEmailAddress}`}>
                <strong>{cpEditEmailAddress}</strong>
              </a>
            </p>
          </Alert>
        </div>
      </div>
      
      <div className={'row pb-4'}>
        <div className={'col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center'}>
          <img src={registerSvg}
               alt={Localizer.localize('Register')}
               className={'w-100'}
          />
        </div>
        <div className={'col-12 col-md-6 col-xl-4 d-flex align-items-center'}>
          <Card className={'w-100'}>
            <Card.Header>
              <h5 className={'my-0'}>{Localizer.localize('Register')}</h5>
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit(onSubmit)}
                    className={'mb-0'}>
                <div id={'registerFormInputs'}
                     className={'mb-4'}>
                  <TextInput
                    id={'emailAddressInput'}
                    name={'emailAddress'}
                    type={'text'}
                    label={Localizer.localize('Email address')}
                    ref={register({
                      required: Localizer.localize('Please, enter your email address'),
                      pattern: {
                        value: emailPattern,
                        message: Localizer.localize('Please, enter valid email address'),
                      },
                      maxLength: {
                        value: emailMaxLength,
                        message: Localizer.localize('Entered email address is too long'),
                      },
                    })}
                    error={errors.emailAddress?.message}
                  />
                  <TextInput
                    id={'userNameInput'}
                    name={'userName'}
                    type={'text'}
                    label={Localizer.localize('Username')}
                    ref={register({
                      required: Localizer.localize('Please, enter your username'),
                      // TODO: user name cannot contain '@' character
                      maxLength: {
                        value: userNameMaxLength,
                        message: Localizer.localize('Entered username is too long'),
                      },
                      minLength: {
                        value: userNameMinLength,
                        message: Localizer.localize('Entered username is too short'),
                      },
                    })}
                    error={errors.userName?.message}
                  />
                  <TextInput
                    id={'passwordInput'}
                    name={'password'}
                    type={'password'}
                    label={Localizer.localize('Password')}
                    ref={register({
                      required: Localizer.localize('Please, enter your password'),
                      maxLength: {
                        value: passwordMaxLength,
                        message: Localizer.localize('Entered password is too long'),
                      },
                      minLength: {
                        value: passwordMinLength,
                        message: Localizer.localize('Entered password is too short'),
                      },
                      pattern: {
                        value: passwordPattern,
                        message: Localizer.localizeR('Password must contain at least one lower case character, one upper case character, one number and one special character: {0}', escapedSpecialCharacters),
                      },
                    })}
                    error={errors.password?.message}
                  />
                  <TextInput
                    id={'repeatedPasswordInput'}
                    name={'repeatedPassword'}
                    type={'password'}
                    label={Localizer.localize('Repeat password')}
                    ref={register({
                      required: Localizer.localize('Please, repeat your password'),
                      validate: value =>
                        value === watch('password')
                        || Localizer.localize('Entered passwords do not match'),
                    })}
                    error={errors.repeatedPassword?.message}
                  />
                  {/*TODO: Add 'Agree with terms and conditions' checkbox? */}
                </div>
                <div className={'d-flex justify-content-between align-items-center'}>
                  <LoadingButton
                    loading={isSubmitting}
                    type={'submit'}
                    variant={'contained'}
                  >
                    {Localizer.localize('Register')}
                  </LoadingButton>
                </div>
              </form>
            </Card.Body>
            <Card.Footer className={'text-center'}>
              <Link to={appUrls.login}
                    className={'text-sm'}>
                {Localizer.localize('Already have account? Log in instead')}
              </Link>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </>
  )
}

export default RegisterForm
