import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, ButtonColor, ButtonShape, ButtonSize, JdfLineNumber } from '@inprop/tt-ui-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { Localizer } from '../../../utils/localizer'
import { Popover, PopoverBody } from 'reactstrap'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import cx from 'classnames'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { Line } from '../../../models/LineModels'
import useJdfConnectionDelete from '../../../hooks/data/jdf/connection/useJdfConnectionDelete'
import { JdfContext } from '../../../contexts/JdfContext'
import unwrap from '../../../utils/unwrap'
import { appUrls } from '../../../utils/urls'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogActions, DialogTitle, Tooltip } from '@mui/material'
import { Editor, JdfConnectionEditorContext } from '../../../contexts/JdfConnectionEditorContext'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

export interface Props {
  line: Line
  connectionId: string
}

const JdfConnectionEditorToolbar: FC<Props> = ({ line, connectionId }) => {
  const {
    state: { jdfDataId },
  } = useContext(JdfContext)
  const { state: editorState, dispatch: editorDispatch } = useContext(JdfConnectionEditorContext)
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  const history = useHistory()

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [actionToConfirm, setActionToConfirm] = useState<(() => void) | undefined>(undefined)

  useAppGuide([
    guideContent.connections.toolbar.showLine,
    guideContent.connections.toolbar.previousConnection,
    guideContent.connections.toolbar.nextConnection,
    guideContent.connections.toolbar.route,
    guideContent.connections.toolbar.calendar,
    guideContent.connections.toolbar.notes,
    guideContent.connections.toolbar.providers,
    guideContent.connections.toolbar.save,
    guideContent.connections.toolbar.delete
  ])

  const redirectToLine = useCallback(() => {
    history.push(
      appUrls.jdf.lineDetails.withParams({
        jdfDataId: unwrap(jdfDataId),
        lineNumber: line.lineNumber.lineNumber,
        lineNumberExtension: line.lineNumber.lineNumberExtension,
      })
    )
  }, [jdfDataId, line.lineNumber.lineNumber, line.lineNumber.lineNumberExtension])

  const { deleteConnection, isDeleting, deleteFailed } = useJdfConnectionDelete(
    unwrap(jdfDataId),
    line.lineNumber,
    connectionId,
    () => {
      redirectToLine()
    }
  )

  const [isConnectionDeletePopoverOpen, setIsConnectionDeletePopoverOpen] = useState(false)
  const [isDeleteConnectionButtonEnabled, setIsDeleteConnectionButtonEnabled] =
    useState<boolean>(true)

  const doIfChangesSaved = (action: () => void): void => {
    if (!editorState.hasUnsavedChanges) {
      action()

      return
    }

    setActionToConfirm(() => action)
  }

  useEffect(() => {
    editorDispatch.setHasUnsavedChanges(false)
  }, [line, connectionId])

  const toggleIsConnectionDeletePopoverOpen = () =>
    setIsConnectionDeletePopoverOpen(!isConnectionDeletePopoverOpen)

  const redirectToConnectionEdit = (id: string) => {
    history.push(
      appUrls.jdf.connectionEdit.withParams({
        jdfDataId: unwrap(jdfDataId),
        lineNumber: line.lineNumber.lineNumber,
        lineNumberExtension: line.lineNumber.lineNumberExtension,
        connectionId: id,
      })
    )
  }

  const indexOfCurrentEditingConnection = useMemo((): number | undefined => {
    const filteredConnections = line?.connections.filter(
      (_) => _.connectionNumber.toString() === connectionId
    )
    if (filteredConnections?.length !== 1) {
      return undefined
    }

    const indexOfConnection = line.connections.indexOf(filteredConnections[0])
    if (!indexOfConnection && indexOfConnection !== 0) {
      return undefined
    }

    return indexOfConnection
  }, [line, connectionId])

  const changeToPreviousConnection = useMemo((): (() => void) | undefined => {
    if (!indexOfCurrentEditingConnection && indexOfCurrentEditingConnection !== 0) {
      return undefined
    }

    const previousConnectionIndex = indexOfCurrentEditingConnection - 1
    if (previousConnectionIndex < 0) {
      return undefined
    }

    return () =>
      redirectToConnectionEdit(
        line.connections[previousConnectionIndex].connectionNumber.toString()
      )
  }, [line, indexOfCurrentEditingConnection])

  const changeToNextConnection = useMemo((): (() => void) | undefined => {
    if (!indexOfCurrentEditingConnection && indexOfCurrentEditingConnection !== 0) {
      return undefined
    }

    const nextConnectionIndex = indexOfCurrentEditingConnection + 1
    if (line?.connections === undefined || nextConnectionIndex >= line?.connections?.length) {
      return undefined
    }

    return () =>
      redirectToConnectionEdit(line.connections[nextConnectionIndex].connectionNumber.toString())
  }, [line, indexOfCurrentEditingConnection])

  const save = async (): Promise<boolean> => {
    let saveSuccess = true

    setIsSaving(true)
    try {
      await editorState.saveFn()
      editorDispatch.setHasUnsavedChanges(false)
    } catch {
      saveSuccess = false
    }
    setIsSaving(false)

    return saveSuccess
  }

  const handleDialogClose = () => setActionToConfirm(undefined)

  const saveAndContinue = async () => {
    const saveSuccess = await save()

    if (saveSuccess) {
      confirmAndCloseDialog()
    }
  }

  const confirmAndCloseDialog = () => {
    if (actionToConfirm) {
      actionToConfirm()
      setActionToConfirm(undefined)
    }
  }

  useEffect(() => {
    if (editorState.hasUnsavedChanges) {
      navigationDispatch.setOnNavigateElsewhere((confirmRedirect: () => void) =>
        doIfChangesSaved(confirmRedirect)
      )
    } else {
      navigationDispatch.setOnNavigateElsewhere(undefined)
    }
  }, [editorState.hasUnsavedChanges])

  return (
    <>
      <Dialog onClose={handleDialogClose} open={actionToConfirm !== undefined}>
        <DialogTitle>
          {Localizer.localize('Do you really want to leave without saving your changes?')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={saveAndContinue} size={ButtonSize.ExtraSmall} isActing={isSaving}>
            {Localizer.localize('Save and continue')}
          </Button>
          <Button
            color={ButtonColor.Danger}
            size={ButtonSize.ExtraSmall}
            onClick={confirmAndCloseDialog}
          >
            {Localizer.localize('Leave without saving')}
          </Button>
        </DialogActions>
      </Dialog>

      <div className='d-flex align-items-center flex-wrap'>
        <Button
          id={guideContent.connections.toolbar.showLine.target}
          size={ButtonSize.ExtraSmall}
          color={ButtonColor.Light}
          className={'m-0 mr-4'}
          onClick={() => doIfChangesSaved(redirectToLine)}
        >
          <FontAwesomeIcon icon={faChevronLeft} className={'mr-2'} />
          {Localizer.localize('Show line')}
        </Button>

        <div className={'d-flex align-items-center justify-content-center m-1'}>
          <h4 className='mb-0 d-flex align-items-start'>
            {`${Localizer.localize('Edit connection')} ${connectionId} ` +
              `${Localizer.localize('of line')} `}
            <JdfLineNumber lineId={line.lineNumber} className={'ml-1'} />
          </h4>
        </div>

        <div className={'button-group'}>
          <Tooltip title={Localizer.localize('Previous connection')}>
            <Button
              id={guideContent.connections.toolbar.previousConnection.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              color={ButtonColor.TransparentDark}
              onClick={() => doIfChangesSaved(changeToPreviousConnection ?? (() => {}))}
              disabled={!changeToPreviousConnection}
              className={'mr-0'}
            >
              <FontAwesomeIcon icon={faChevronLeft} className={'m-0'} />
            </Button>
          </Tooltip>

          <Tooltip title={Localizer.localize('Next connection')}>
            <Button
              id={guideContent.connections.toolbar.nextConnection.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              color={ButtonColor.TransparentDark}
              onClick={() => doIfChangesSaved(changeToNextConnection ?? (() => {}))}
              disabled={!changeToNextConnection}
            >
              <FontAwesomeIcon icon={faChevronRight} className={'m-0'} />
            </Button>
          </Tooltip>
        </div>

        <div className={'d-flex align-items-center justify-content-end flex-grow-1 flex-wrap my-1'}>
          <div className={'button-group d-flex flex-wrap justify-content-center'}>
            <Button
              id={guideContent.connections.toolbar.route.target}
              color={
                editorState.currentEditor === Editor.RouteEditor
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() =>
                doIfChangesSaved(() => editorDispatch.setCurrentEditor(Editor.RouteEditor))
              }
              className={'mr-0'}
            >
              {Localizer.localize('Route')}
            </Button>
            <Button
              id={guideContent.connections.toolbar.calendar.target}
              color={
                editorState.currentEditor === Editor.CalendarEditor
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() =>
                doIfChangesSaved(() => editorDispatch.setCurrentEditor(Editor.CalendarEditor))
              }
              className={'mr-0'}
            >
              {Localizer.localize('Calendar')}
            </Button>
            <Button
              id={guideContent.connections.toolbar.notes.target}
              color={
                editorState.currentEditor === Editor.NotesEditor
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() =>
                doIfChangesSaved(() => editorDispatch.setCurrentEditor(Editor.NotesEditor))
              }
              className={'mr-0'}
            >
              {Localizer.localize('Notes')}
            </Button>
            <Button
              id={guideContent.connections.toolbar.providers.target}
              color={
                editorState.currentEditor === Editor.ProvidersEditor
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() =>
                doIfChangesSaved(() => editorDispatch.setCurrentEditor(Editor.ProvidersEditor))
              }
            >
              {Localizer.localize('Providers')}
            </Button>
          </div>

          <div className={'d-flex justify-content-center align-items-center'}>
            <Button
              id={guideContent.connections.toolbar.save.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              color={editorState.hasUnsavedChanges ? ButtonColor.Amber : ButtonColor.Light}
              onClick={save}
              className={'mr-2'}
              customTextWhileActing={`${Localizer.localize('Saving')} ...`}
              disabled={isSaving}
            >
              <FontAwesomeIcon
                icon={isSaving ? faSpinner : faCheck}
                className={cx('mr-2', { 'fa-spin': isSaving })}
              />
              {Localizer.localize('Save')}
            </Button>
          </div>

          <Button
            id={guideContent.connections.toolbar.delete.target}
            className={'m-0 mr-0'}
            color={ButtonColor.Light}
            shape={ButtonShape.Rounded}
            size={ButtonSize.ExtraSmall}
            onClick={() => setIsConnectionDeletePopoverOpen(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Popover
            placement='bottom'
            isOpen={isConnectionDeletePopoverOpen}
            target={guideContent.connections.toolbar.delete.target}
            toggle={toggleIsConnectionDeletePopoverOpen}
          >
            <PopoverBody className={'p-3'}>
              <p className={'mb-3'}>
                {Localizer.localize('All related records will be deleted with the connection.')}{' '}
                {Localizer.localize('Do you really want to delete this connection?')}
              </p>
              <div className={'d-flex justify-content-end'}>
                <Button
                  size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Rounded}
                  color={ButtonColor.Danger}
                  onClick={deleteConnection}
                  className={'m-0 mr-2'}
                  disabled={!isDeleteConnectionButtonEnabled}
                >
                  <FontAwesomeIcon icon={faTrash} className={'mr-1'} />
                  {Localizer.localize('Delete')}
                </Button>
                <Button
                  size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Rounded}
                  color={ButtonColor.Light}
                  onClick={toggleIsConnectionDeletePopoverOpen}
                  className={'m-0'}
                >
                  <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                  {Localizer.localize('Cancel')}
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        </div>
      </div>
    </>
  )
}

export default JdfConnectionEditorToolbar
