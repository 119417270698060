import React, { FC, useContext, useEffect, useMemo } from 'react'
import { LineRouteEditor } from './LineRouteEditor'
import { LineProviderEditor } from './LineProviderEditor'
import { LineNotesEditor } from './LineNotesEditor'
import { JdfContext } from '../../../contexts/JdfContext'
import unwrap from '../../../utils/unwrap'
import { Line } from '../../../models/LineModels'
import {
  JdfLineEditorContext,
  LineEditor as LineEditorEnum,
} from '../../../contexts/JdfLineEditorContext'
import { useParams } from 'react-router-dom'
import { JdfLineEditPageRouteParams } from '../../pages/JdfLineEditPage'

export interface Props {
  line: Line
}

const LineEditor: FC<Props> = ({ line }) => {
  const params = useParams<JdfLineEditPageRouteParams>()

  const { state: jdfState } = useContext(JdfContext)
  const { state: editorState, dispatch: editorDispatch } = useContext(JdfLineEditorContext)

  const jdfDataId = useMemo(() => unwrap(jdfState.jdfDataId), [jdfState.jdfDataId])

  useEffect(() => {
    editorDispatch.setCurrentEditor((params.whatToEdit as LineEditorEnum) ?? LineEditorEnum.Route)
  }, [params.whatToEdit])

  switch (editorState.currentEditor) {
    case LineEditorEnum.Route:
      return (
        <LineRouteEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          setSaveFn={editorDispatch.setSaveFn}
        />
      )
    case LineEditorEnum.Provider:
      return (
        <LineProviderEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          setSaveFn={editorDispatch.setSaveFn}
        />
      )
    case LineEditorEnum.Notes:
      return (
        <LineNotesEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          setSaveFn={editorDispatch.setSaveFn}
        />
      )
  }
}

export default LineEditor
