declare global {
  interface String {
    shorten: (maxLength: number) => String
    isNullOrEmpty: () => boolean
  }
}

/**
 * Shorten string to contain maximally "maxLength" characters. Add ... to the end of the string.
 * @param maxLength Maximal length of shortened string
 */
String.prototype.shorten = function (maxLength) {
  if (maxLength < 3)
    maxLength = 3;
  if (this.length > maxLength)
    return `${this.substr(0, maxLength - 3)}...`;
  return this;
};

String.prototype.isNullOrEmpty = function() {
  return this === null || this === undefined || this.length === 0;
};

export {}
