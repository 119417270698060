import { useQuery } from '@tanstack/react-query'
import { queryKeys } from './queryKeys'
import { getAllRegistrationAllowedEmails, getAllUsers } from './apiFunctions'

export const useAllUsers = () =>
  useQuery({
    queryKey: queryKeys.users,
    queryFn: getAllUsers,
  })

export const useAllRegistrationAllowedEmails = () =>
  useQuery({
    queryKey: queryKeys.registrationAllowedEmails,
    queryFn: getAllRegistrationAllowedEmails,
  })
