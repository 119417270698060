import React, { FC } from 'react'
import LinesList from '../jdf/line/LinesList'
import { Localizer } from '../../utils/localizer'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { navigationWithJdf } from '../guide/content/guideContent'

const JdfLinesPage: FC = () => {
  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  return (
    <>
      <div className={'row py-2'}>
        <div className={'col'}>
          <div className={'h2'}>{Localizer.localize('Lines list')}</div>
        </div>
      </div>

      <LinesList />
    </>
  )
}

export default JdfLinesPage
