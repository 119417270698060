/* Action constants */
import { Notification, NotificationsState, NotificationType } from './NotificationsContext'
import generateID from '../utils/randomGenerator'

export const ADD_NOTIFICATION = 'notifications/add'
export const REMOVE_NOTIFICATION = 'notifications/remove'

export const DEFAULT_AUTO_DISMISS_AFTER_SECONDS = 5;

/* Action interfaces */
export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION
  value: Omit<Notification, 'id'>
}

export interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION
  value: string
}

/* Action functions */
const addNotification = (
  newNotification: Omit<Notification, 'id'>,
  state: NotificationsState
): NotificationsState => {
  const newNotificationId = generateID()

  if (
    newNotification.type === NotificationType.Success &&
    !newNotification.disableAutoDismiss &&
    newNotification.autoDismissAfterSeconds === undefined
  ) {
    newNotification.autoDismissAfterSeconds = DEFAULT_AUTO_DISMISS_AFTER_SECONDS
  }

  return {
    notifications: state.notifications.concat({
      ...newNotification,
      id: newNotificationId,
    }),
  }
}
const removeNotification = (id: string, state: NotificationsState): NotificationsState => {
  return { notifications: state.notifications.filter((_) => _.id !== id) }
}

export type NotificationActionType = AddNotificationAction | RemoveNotificationAction

export const NotificationReducer = (
  state: NotificationsState,
  action: NotificationActionType
): NotificationsState => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return addNotification(action.value, state)
    case REMOVE_NOTIFICATION:
      return removeNotification(action.value, state)
    default:
      return state
  }
}
