import React, { FC, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useAuthContext } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { appUrls } from '../../utils/urls'
import { Card } from 'react-bootstrap'
import { Localizer } from '../../utils/localizer'
import TextInput from '../general/inputs/TextInput'
import CheckboxInput from '../general/inputs/CheckboxInput'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import loginSvg from '../../assets/images/login.svg'
import LoadingButton from '@mui/lab/LoadingButton'
import { badRequestStatusCode } from '../../utils/constants'

interface LoginForm {
  emailAddressOrUserName: string
  password: string
  hasCheckedRememberMe: boolean
}

const LoginForm: FC = () => {
  const { register, handleSubmit, errors, watch, setValue, formState: { isSubmitting } } = useForm<LoginForm>()
  const { signInAsync } = useAuthContext()
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const history = useHistory()

  const onSubmitAsync = async (data: LoginForm) => {
    try {
      await signInAsync(data.emailAddressOrUserName, data.password, data.hasCheckedRememberMe)

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Success,
          title: Localizer.localize('You were logged in'),
        },
      })
      history.push(appUrls.home)

    } catch (error) {
      // TODO: handle 'Wrong credentials' and 'Email address is not yet confirmed' errors separately
      if (error.response && error.response.status === badRequestStatusCode) { // TODO: what about others errors - 500, etc...?
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            type: NotificationType.Warning,
            title: Localizer.localize("Login was not successful"),
            body: Localizer.localize(
              'Probably you entered wrong credentials or you have not confirmed your email address yet'
            ),
            autoDismissAfterSeconds: 10
          },
        })
      }
    }
  }

  return (
    <>
      <div className='col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center'>
        <img src={loginSvg} alt={Localizer.localize('Log in')} className='w-100' />
      </div>
      <div className='col-12 col-md-6 col-xl-4 d-flex align-items-center'>
        <Card className='w-100'>
          <Card.Header>
            <h5 className='my-0'>{Localizer.localize('Log in')}</h5>
          </Card.Header>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmitAsync)} className={'mb-0'}>
              <div className='mb-4'>
                <TextInput
                  ref={register({
                    required: Localizer.localize('Please, enter your email address or username'),
                    // pattern: { // TODO: make this check conditional - only if entered text contains '@' character
                    //   value: emailPattern,
                    //   message: Localizer.localize('Please, enter valid email'),
                    // },
                  })}
                  name='emailAddressOrUserName'
                  type='text'
                  id='emailAddressOrUserName'
                  label={Localizer.localize('Email address or username')}
                  error={errors.emailAddressOrUserName?.message}
                />
                <TextInput
                  ref={register({
                    required: Localizer.localize('Please, enter your password'),
                  })}
                  name='password'
                  id='password'
                  label={Localizer.localize('Password')}
                  type='password'
                  error={errors.password?.message}
                />
                {/*<CheckboxInput // TODO: add when implementing refresh token
                  id='rememberMe'
                  label={Localizer.localize('Remember me')}
                  ref={register}
                  name={'hasCheckedRememberMe'}
                  checked={watch().hasCheckedRememberMe}
                  onChange={(_) => setValue('hasCheckedRememberMe', _)}
                />*/}
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <LoadingButton
                  loading={isSubmitting}
                  type={'submit'}
                  variant={'contained'}
                >
                  {Localizer.localize('Log in')}
                </LoadingButton>

                <Link to={appUrls.forgotPassword}>
                  {Localizer.localize('Forgot password?')}
                </Link>

              </div>
            </form>
          </Card.Body>
          <Card.Footer className='text-center'>
            <Link to={appUrls.register} className='text-sm'>
              {Localizer.localize('Do not have account yet? Register now')}
            </Link>
          </Card.Footer>
        </Card>
      </div>
    </>
  )
}

export default LoginForm