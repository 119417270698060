import {
  RegistrationAllowedEmail,
  RegistrationAllowedEmailDto,
  UserAuth,
  UserAuthDto,
  UserExcerpt,
  UserExcerptDto,
} from './AuthViewModels'
import { DateTime } from 'luxon'
import { parseUTCDateTimeToLocal } from '../utils/dateTimeUtils'

export const dtoToModel = {
  userAuth: (dto: UserAuthDto): UserAuth => ({
    ...dto,
    loginExpires: parseUTCDateTimeToLocal(dto.loginExpires),
  }),
  userExcerpt: (dto: UserExcerptDto): UserExcerpt => ({
    ...dto,
    registeredAt: parseUTCDateTimeToLocal(dto.registeredAt),
    verifiedAt: dto.verifiedAt ? parseUTCDateTimeToLocal(dto.verifiedAt) : null,
  }),
  registrationAllowedEmail: (dto: RegistrationAllowedEmailDto): RegistrationAllowedEmail => ({
    ...dto,
    invitationDateTime: parseUTCDateTimeToLocal(dto.invitationDateTime),
    addedByUser: dtoToModel.userExcerpt(dto.addedByUser),
  }),
}
