import React, { FC, useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import axios from 'axios'
import { ListLineApi } from './line/LinesList'
import { apiUrls } from '../../utils/urls'
import { JdfContext } from '../../contexts/JdfContext'
import { parseISO } from 'date-fns'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import { LineNumberExtended } from '../../models/LineModels'
import { Localizer } from '../../utils/localizer'
import { Button, ButtonColor, ButtonSize, JdfLineNumber } from '@inprop/tt-ui-elements'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { ExportJdfCreateDto } from '../../models/jdf/export'

interface Props {
  onClose: () => void
}

const JdfSaveSettings: FC<Props> = (props) => {
  const { palette } = useTheme()

  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const [{ data: rawLinesData, loading: isLoadingLines, error: loadingLinesError }, fetchLines] =
    useAxios<ListLineApi<string>[]>({})
  const [{}, exportJdfData] = useAxios<string>(
    { url: apiUrls.jdf.exportData(jdfState.jdfDataId ?? ''), method: 'POST' },
    { manual: true }
  )

  const [lines, setLines] = useState<ListLineApi<Date>[]>([])

  const [linesSelected, setLinesSelected] = useState<{ [index: string]: boolean | undefined }>({})
  const [checkedStatus, setCheckedStatus] = useState<'none' | 'some' | 'all'>('all')

  useEffect(() => {
    if (!jdfState.jdfDataId) {
      return
    }

    fetchLines({ url: apiUrls.jdf.lines.list(jdfState.jdfDataId) })
  }, [jdfState.jdfDataId])

  useEffect(() => {
    if (!rawLinesData) {
      setLines([])

      return
    }

    setLines(
      rawLinesData.map((_) => {
        return {
          ..._,
          lineValidFrom: parseISO(_.lineValidFrom),
          lineValidTo: parseISO(_.lineValidTo),
        }
      })
    )
  }, [rawLinesData])

  const setAllLinesSelected = (selected: boolean) => {
    const newSelectedLines: { [index: string]: boolean } = {}

    for (let i = 0; i < lines.length; i++) {
      newSelectedLines[getLineIndex(lines[i].lineNumber)] = selected
    }

    setLinesSelected(newSelectedLines)
  }

  useEffect(() => {
    setAllLinesSelected(true)
    setCheckedStatus('all')
  }, [lines])

  useEffect(() => {
    let wasFalse = false
    let wasTrue = false

    for (const key in linesSelected) {
      if (linesSelected[key]) {
        wasTrue = true
      } else {
        wasFalse = true
      }
    }

    if (wasFalse && wasTrue) {
      setCheckedStatus('some')
    } else if (wasFalse) {
      setCheckedStatus('none')
    } else {
      setCheckedStatus('all')
    }
  }, [linesSelected])

  const getLineIndex = (lineNumber: LineNumberExtended) =>
    `${lineNumber.lineNumber}${lineNumber.lineNumberExtension}`

  const onClick = (lineNumber: LineNumberExtended) => {
    setLinesSelected((_) => {
      const lineIndex = getLineIndex(lineNumber)

      if (!_[lineIndex]) {
        return { ..._, [lineIndex]: true }
      }

      return { ..._, [lineIndex]: false }
    })
  }

  const onBulkToggleClick = () => {
    if (checkedStatus === 'all' || checkedStatus === 'some') {
      setAllLinesSelected(false)
    } else {
      setAllLinesSelected(true)
    }
  }

  const save = async () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data must be set when exporting data')
    }

    const linesToIncludeInExport: LineNumberExtended[] = []

    for (const key in lines) {
      if (linesSelected[getLineIndex(lines[key].lineNumber)]) {
        linesToIncludeInExport.push(lines[key].lineNumber)
      }
    }

    const requestBody: ExportJdfCreateDto = {
      linesToInclude: linesToIncludeInExport,
    }

    const exportedJdfDataId = (
      await exportJdfData({
        data: requestBody,
      })
    ).data

    const link = document.createElement('a')
    link.href = axios.getUri({
      url: apiUrls.jdf.exportData(jdfState.jdfDataId ?? '', exportedJdfDataId),
    })
    link.click()

    props.onClose()
  }

  useEffect(() => {
    if (loadingLinesError) {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Lines could not be loaded'),
          body: Localizer.localize('Please, try again'),
        },
      })
    }
  }, [loadingLinesError])

  return (
    <Grid container>
      {lines.length !== 0 && (
        <Grid
          item
          xs={12}
          sx={{ background: palette.grey['100'], px: 2, py: 1, mb: 2, borderRadius: 1 }}
        >
          <FormControlLabel
            label={<Typography variant={'subtitle2'}>{Localizer.localize('All lines')}</Typography>}
            control={
              <Checkbox
                checked={checkedStatus === 'all'}
                indeterminate={checkedStatus === 'some'}
                onChange={onBulkToggleClick}
              />
            }
            sx={{ mb: 0 }}
          />
        </Grid>
      )}

      {isLoadingLines &&
        Array.from({ length: 24 }).map((_, index) => (
          <Grid item xs={6} md={3} lg={2} key={index} sx={{ pl: 2 }}>
            <Skeleton variant='text' sx={{ width: 1, my: 1 }} />
          </Grid>
        ))}

      {!isLoadingLines && !loadingLinesError && lines.length === 0 && (
        <Alert severity='info' sx={{ width: '100%', mb: 2 }}>
          {Localizer.localize(
            'Your JDF data does not contain any lines and therefore cannot be exported'
          )}
        </Alert>
      )}

      {!isLoadingLines &&
        lines.map((_) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            key={`${_.lineNumber.lineNumber}${_.lineNumber.lineNumberExtension}`}
            sx={{ pl: 2 }}
          >
            <FormControlLabel
              label={
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    fontWeight: !!linesSelected[getLineIndex(_.lineNumber)] ? 'bold' : 'normal',
                  }}
                >
                  <JdfLineNumber lineId={_.lineNumber} />
                </Typography>
              }
              control={
                <Checkbox
                  checked={!!linesSelected[getLineIndex(_.lineNumber)]}
                  onChange={() => onClick(_.lineNumber)}
                />
              }
            />
          </Grid>
        ))}

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
        {!isLoadingLines && lines.length !== 0 && (
          <Button
            size={ButtonSize.Small}
            onClick={save}
            className={'mr-1'}
            disabled={lines.length === 0}
          >
            {Localizer.localize('Save')}
          </Button>
        )}
        <Button size={ButtonSize.Small} color={ButtonColor.Light} onClick={props.onClose}>
          {Localizer.localize('Close')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default JdfSaveSettings
