import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../utils/urls'
import emailAddressConfirmationSuccessfulSvg
  from '../../assets/images/email-address-confirmation-successful_undraw_accept_request.svg'
import emailAddressConfirmationErrorSvg from '../../assets/images/email-address-confirmation-error_undraw_cancel.svg'
import { Localizer } from '../../utils/localizer'
import { Card } from 'react-bootstrap'
import { Alert, Button } from '@mui/material'
import { Preloader } from '@inprop/tt-ui-elements'

const EmailAddressConfirmationPage: FC = () => {
  const search = useLocation().search
  const history = useHistory()

  const [, confirmAccountAxios] = useAxios({ url: apiUrls.auth.confirmEmailAddress }, { manual: true })
  const [emailAddressConfirmationState, setEmailAddressConfirmationState] = useState<'confirming' | 'successful' | 'error'>('confirming')

  const runConfirmation = useCallback(
    async (emailAddress: string, code: string) => {
      try {
        const queryParams = { emailAddress, code }

        await confirmAccountAxios({ params: queryParams })
        setEmailAddressConfirmationState('successful')
      } catch (e) {
        setEmailAddressConfirmationState('error')
      }
    },
    [confirmAccountAxios],
  )

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search)
    const emailAddress = urlSearchParams.get('emailAddress') ?? ''
    const code = urlSearchParams.get('code') ?? ''

    runConfirmation(emailAddress, code)
  }, [search, runConfirmation])

  const goToLogin = () => {
    history.push(appUrls.login)
  }
  
  if (emailAddressConfirmationState === 'confirming') {
    return <>
      <div className={'row py-4 mt-5'}>
        <div className={'col-12 col-md-8 col-xl-6 offset-md-2 offset-xl-2 d-flex align-items-center'}>
          {/*TODO: improve loading animation */}
          <h3 className={'mb-1 mx-3'}>{Localizer.localize('Email address confirmation in progress...')}</h3>
          <Preloader overlay={false} small={false} centered={true} />
        </div>
      </div>
    </>
  }

  return (
    <>
      <div className={'row py-4'}>
        <div className={'col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center'}>
          {emailAddressConfirmationState === 'successful' &&
            <img src={emailAddressConfirmationSuccessfulSvg}
                 className={'w-100'}
                 alt={Localizer.localize('Email address confirmation was successful')}
            />
          }

          {emailAddressConfirmationState === 'error' &&
            <img src={emailAddressConfirmationErrorSvg}
                 className={'w-100'}
                 alt={Localizer.localize('Email address confirmation failed')}
            />
          }
        </div>

        <div className={'col-12 col-md-6 col-xl-4 d-flex align-items-center'}>
          <Card className={'w-100'}>
            <Card.Header>
              <h5 className={'my-0'}>{Localizer.localize('Email address confirmation')}</h5>
            </Card.Header>
            <Card.Body>
              {emailAddressConfirmationState === 'successful' &&
                <>
                  <Alert severity={'success'}
                         sx={{ mb: 3 }}>
                    {Localizer.localize('Email address confirmation was successful. You can now log in.')}
                  </Alert>
  
                  <Button variant='contained' onClick={goToLogin}>
                    {Localizer.localize('Login')}
                  </Button>
                </>
              }

              {emailAddressConfirmationState === 'error' &&
                <Alert severity={'error'}
                       sx={{ mb: 3 }}>
                  {Localizer.localize('Email address confirmation failed, please try it again. If problem persists, contact us via email.')}
                </Alert>
              }
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default EmailAddressConfirmationPage
