import React, { FC } from 'react'
import PasswordResetForm from '../auth/PasswordResetForm'

const PasswordResetPage: FC = () => {
  return (
    <div className={'row py-4'}>
      <PasswordResetForm />
    </div>
  )
}

export default PasswordResetPage
