import { Localizer } from './localizer'
import { List } from 'immutable'
import { LineNumberExtended, Station, TTChars } from '../models/LineModels'
import { ConnectionStopExtended } from '../components/jdf/connection/route/ConnectionRouteEditor'
import { JdfConnectionStop } from '../models/jdf/connectionStop'

function isSpeedValid(kilometers: number, minutes: number): boolean {
  const minDistance = 10
  const minTime = 60
  const minSpeed = 10
  const maxSpeed = 300

  if (minutes === 0) return kilometers <= 1

  if (kilometers < minDistance) return minutes < minTime

  const speed = Math.round((60 * kilometers) / minutes)

  return minSpeed < speed && speed < maxSpeed
}

export enum ConnectionRouteValidationErrorType {
  EmptyRoute = 'empty-route',
  NoDepartureTimeAtFirstStop = 'no-departure-time-at-first-stop',
  FirstKmIsNotZero = 'first-km-is-not-zero',
  NoArrivalTimeAtLastStop = 'no-arrival-time-at-last-stop',
  InvalidArrivalTime = 'invalid-arrival-time',
  InvalidDepartureTime = 'invalid-departure-time',
  EmptyKm = 'empty-km',
  KmSequenceIsNotNonDecreasing = 'km-sequence-is-not-non-decreasing',
  UnrealConnectionSpeed = 'unreal-connection-speed',
  DepartureTimeBeforeArrivalTime = 'departure-time-before-arrival-time',
}

export interface ConnectionRouteValidationError {
  type: ConnectionRouteValidationErrorType
  text: string
}

/**
 * Validate connection stops data from user. Return result and array of errors.
 */
export const validateConnectionStops = (
  stops: List<JdfConnectionStop>
): {
  valid: boolean
  errors: ConnectionRouteValidationError[]
} => {
  const errors: ConnectionRouteValidationError[] = []

  if (stops.size < 2) {
    errors.push({
      type: ConnectionRouteValidationErrorType.EmptyRoute,
      text: Localizer.localize(`Empty route.`),
    })
    return { valid: false, errors: errors }
  }

  let previousKm = 0
  let previousTime = undefined
  for (let i = 0; i < stops.size; i++) {
    const currentStop = stops.get(i)
    if (!currentStop) {
      throw new Error(`Could not get stop by index ${i} from stops for validation.`)
    }

    const errorMessageStart = `${Localizer.localizeR(
      'Error on connection stop with tariff number {0}',
      currentStop.tariffNumber
    )}:`

    if (i === 0) {
      if (!currentStop.departure?.isTime())
        errors.push({
          type: ConnectionRouteValidationErrorType.NoDepartureTimeAtFirstStop,
          text: `${errorMessageStart} ${Localizer.localize(
            `No departure time at first connection stop.`
          )}`,
        })

      if (currentStop.km !== '0')
        errors.push({
          type: ConnectionRouteValidationErrorType.FirstKmIsNotZero,
          text: `${errorMessageStart} ${Localizer.localize(`First kilometers data must be '0'.`)}`,
        })
    } else if (i === stops.size - 1) {
      if (!currentStop.arrival?.isTime())
        errors.push({
          type: ConnectionRouteValidationErrorType.NoArrivalTimeAtLastStop,
          text: `${errorMessageStart} ${Localizer.localize(
            `No arrival time at last connection stop.`
          )}`,
        })
    }

    // if both arrival and departure is empty or timeish char
    if (
      (!currentStop.arrival || currentStop.arrival.isTimeishChar()) &&
      (!currentStop.departure || currentStop.departure.isTimeishChar())
    )
      continue

    let continueValidation = true
    if (
      currentStop.arrival &&
      !currentStop.arrival.isTimeishChar() &&
      !currentStop.arrival.isTime()
    ) {
      errors.push({
        type: ConnectionRouteValidationErrorType.InvalidArrivalTime,
        text: `${errorMessageStart} ${Localizer.localize(`Invalid arrival time.`)}`,
      })
      continueValidation = false
    }

    if (
      currentStop.departure &&
      !currentStop.departure.isTimeishChar() &&
      !currentStop.departure.isTime()
    ) {
      errors.push({
        type: ConnectionRouteValidationErrorType.InvalidDepartureTime,
        text: `${errorMessageStart} ${Localizer.localize(`Invalid departure time.`)}`,
      })
      continueValidation = false
    }

    if (!continueValidation) continue

    let currentTime =
      currentStop.arrival && currentStop.arrival.isTime()
        ? currentStop.arrival.getDateFromTimeString()
        : currentStop.departure?.getDateFromTimeString()
    if (!currentTime) {
      throw new Error(
        `Current time of stop with tariff no. '${currentStop.tariffNumber}' is empty.`
      )
    }
    const currentKm = currentStop.km ? parseInt(currentStop.km) : 0

    if (!currentStop.km)
      errors.push({
        type: ConnectionRouteValidationErrorType.EmptyKm,
        text: `${errorMessageStart} ${Localizer.localize('Empty kilometers.')}`,
      })

    if (currentKm < previousKm)
      errors.push({
        type: ConnectionRouteValidationErrorType.KmSequenceIsNotNonDecreasing,
        text: `${errorMessageStart} ${Localizer.localize(`Km sequence must be non-decreasing.`)}`,
      })

    if (previousTime && currentTime && previousTime > currentTime) {
      currentTime = currentTime.addDays(1)
    }
    if (previousTime) {
      const stopsDistance = currentKm - previousKm
      const timeDifference = currentTime.getTime() - previousTime.getTime()
      const stopsTimeDistanceInMinutes = Math.round(timeDifference / 1000 / 60)

      if (!isSpeedValid(stopsDistance, stopsTimeDistanceInMinutes)) {
        const speed =
          stopsTimeDistanceInMinutes !== 0 ? (60 * stopsDistance) / stopsTimeDistanceInMinutes : 0
        errors.push({
          type: ConnectionRouteValidationErrorType.UnrealConnectionSpeed,
          text: `${errorMessageStart} ${Localizer.localizeR(
            'Unreal connection speed ({0} km/h).',
            speed.toFixed(1)
          )}`,
        })
      }

      if (
        currentStop.arrival &&
        currentStop.arrival.isTime() &&
        currentStop.departure &&
        currentStop.departure.isTime() &&
        currentStop.arrival.getDateFromTimeString() > currentStop.departure.getDateFromTimeString()
      ) {
        errors.push({
          type: ConnectionRouteValidationErrorType.DepartureTimeBeforeArrivalTime,
          text: `${errorMessageStart} ${Localizer.localize(
            'Departure time is before arrival time.'
          )}`,
        })
      }
    }

    previousKm = currentKm
    previousTime = currentTime
  }

  return {
    valid: errors.length === 0,
    errors: errors,
  }
}

/**
 * Create default connection stop with station identifiers data
 */
export const createDefaultConnectionStopForStation = (
  tariffNumber: string,
  stationId: string,
  lineNumber: LineNumberExtended,
  connectionNumber: string
): ConnectionStopExtended => {
  return {
    tariffNumber: tariffNumber,
    stationNumber: stationId,
    lineNumber: lineNumber,
    connection: connectionNumber,
    fixedCodes: [],
  }
}

const sortByNumberIncreasing = (a: number, b: number) => {
  if (a < b) return -1
  if (a === b) return 0
  return 1
}
const sortByNumberDecreasing = (a: number, b: number) => {
  if (a < b) return 1
  if (a === b) return 0
  return -1
}

export const getSortingFnByConnectionNumber = (
  connectionNumber: string
): ((a: number, b: number) => number) => {
  return parseInt(connectionNumber) % 2 === 0 ? sortByNumberDecreasing : sortByNumberIncreasing
}

/**
 * Look for first and last row filled with data, return them and all rows between them
 */
export const prepareConnectionStopsForValidation = (
  connectionNumber: string,
  connectionStops: List<ConnectionStopExtended>
): List<JdfConnectionStop> => {
  const sortedStops = connectionStops.sort((a, b) =>
    getSortingFnByConnectionNumber(connectionNumber)(
      parseInt(a.tariffNumber),
      parseInt(b.tariffNumber)
    )
  )

  let firstIndex = -1
  let lastIndex = -1
  for (let i = 0; i < sortedStops.size; i++) {
    const stop = sortedStops.get(i)
    if (!stop) {
      throw new Error(`Could not find stop with index ${i} among sorted stops.`)
    }

    if (firstIndex < 0)
      if (stop.km || stop.arrival || stop.departure) firstIndex = i
      else continue

    if (stop.km || stop.arrival || stop.departure) lastIndex = i
  }

  let connectionStopsToValidate = List<JdfConnectionStop>([])
  if (firstIndex === -1) return connectionStops

  for (let i = firstIndex; i <= lastIndex; i++) {
    const removedCustomFields = {
      ...sortedStops.get(i),
      arrivalTime: undefined,
      departureTime: undefined,
    } as JdfConnectionStop
    connectionStopsToValidate = connectionStopsToValidate.push(removedCustomFields)
  }

  return connectionStopsToValidate
}

/**
 * Get index of related connection stop by station.
 * @param station
 * @param connectionStops
 */
export const getConnectionStopIndexByStation = (
  station: Station,
  connectionStops: List<ConnectionStopExtended>
): number | undefined => {
  const connectionStopIndex = connectionStops.findIndex(
    (_) => _.tariffNumber === station.tariffNumber.toString()
  )
  if (connectionStopIndex === -1) {
    return undefined
  }

  return connectionStopIndex
}

export const getConnectionStopByStation = (
  station: Station,
  connectionStops: List<ConnectionStopExtended>
): ConnectionStopExtended | undefined => {
  const connectionStopIndex = getConnectionStopIndexByStation(station, connectionStops)
  if (!connectionStopIndex) {
    return undefined
  }

  const connectionStop = connectionStops.get(connectionStopIndex)
  if (!connectionStop) {
    throw new Error(`No connection stop found by index ${connectionStopIndex} in connection stops.`)
  }

  return connectionStop
}

export const getConnectionStopsEmptyRanges = (
  stations: List<Station>,
  connectionStops: List<ConnectionStopExtended>
): { startIndex: number; endIndex: number }[] => {
  const minimalRangeSize = 2
  const emptyRanges: { startIndex: number; endIndex: number }[] = []
  let currentRangeStartIndex = -1
  let currentRangeEndIndex = -1

  const allConnectionStops = stations.map((_) => getConnectionStopByStation(_, connectionStops))

  for (let i = 0; i < allConnectionStops.size; i++) {
    const currentStop = allConnectionStops.get(i)

    if (
      !currentStop?.arrivalTime &&
      !currentStop?.departureTime &&
      currentStop?.arrival !== TTChars.ConnectionGoThroughStop &&
      currentStop?.departure !== TTChars.ConnectionGoThroughStop
    ) {
      if (currentRangeStartIndex === -1) {
        currentRangeStartIndex = i
      }

      currentRangeEndIndex = i
    } else {
      if (
        currentRangeStartIndex !== -1 &&
        currentRangeEndIndex !== -1 &&
        currentRangeEndIndex - currentRangeStartIndex + 1 >= minimalRangeSize
      ) {
        emptyRanges.push({ startIndex: currentRangeStartIndex, endIndex: currentRangeEndIndex })
      }

      currentRangeStartIndex = -1
      currentRangeEndIndex = -1
    }
  }

  if (currentRangeStartIndex !== -1 && currentRangeEndIndex !== -1) {
    emptyRanges.push({ startIndex: currentRangeStartIndex, endIndex: currentRangeEndIndex })
  }

  return emptyRanges
}