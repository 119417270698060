import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useState,
  WheelEvent,
} from 'react'
import { List } from 'immutable'
import { ScrollService } from '../../utils/scrollService'

interface Props {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: MouseEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onEscPressed?: () => void
  onTabPressed?: () => void
  onEnterPressed?: () => void
  onArrowUp?: () => void
  onArrowDown?: () => void
  onCtrlArrowUp?: () => void
  onCtrlArrowDown?: () => void
  defaultValue?: string
  value?: string
  className?: string
  id?: string
  size?: InputSize
  placeholder?: string

  actions?: List<InputAction>
}

export interface InputAction {
  helperKeys?: List<HelperKey>
  mainTrigger: KeyTrigger | WheelTrigger | string
  action: () => void
}

export enum InputSize {
  Normal = 1,
  Small = 2,
}

export enum KeyCodes {
  Tab = 'Tab',
  Enter = 'Enter',
  Shift = 'Shift',
  Ctrl = 'Control',
  Alt = 'Alt',
  Esc = 'Escape',
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
}

export type HelperKey = KeyCodes.Shift | KeyCodes.Ctrl | KeyCodes.Alt
export type KeyTrigger =
  | KeyCodes.Tab
  | KeyCodes.Enter
  | KeyCodes.Esc
  | KeyCodes.ArrowLeft
  | KeyCodes.ArrowUp
  | KeyCodes.ArrowRight
  | KeyCodes.ArrowDown

export enum WheelTrigger {
  Up = 'Up',
  Down = 'Down',
}

export const InputWithKeyActions = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [keysDown, setKeysDown] = useState<List<KeyCodes>>(List([]))

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    handleKeyActions(event)

    if (event.key === KeyCodes.Tab && props.onTabPressed) {
      props.onTabPressed()
      event.preventDefault()
    } else if (event.key === KeyCodes.Enter && props.onEnterPressed) {
      props.onEnterPressed()
      event.preventDefault()
    } else if (event.key === KeyCodes.Ctrl) {
      setKeysDown((_) => _.push(KeyCodes.Ctrl))
      event.preventDefault()
    } else if (event.key === KeyCodes.Shift) {
      setKeysDown((_) => _.push(KeyCodes.Shift))
      event.preventDefault()
    } else if (event.key === KeyCodes.Alt) {
      setKeysDown((_) => _.push(KeyCodes.Alt))
      event.preventDefault()
    } else if (event.key === KeyCodes.Esc && props.onEscPressed) {
      props.onEscPressed()
      event.preventDefault()
    } else if (event.key === KeyCodes.ArrowUp) {
      if (keysDown.contains(KeyCodes.Ctrl)) {
        if (props.onCtrlArrowUp) props.onCtrlArrowUp()
      } else {
        if (props.onArrowUp) props.onArrowUp()
      }
      event.preventDefault()
    } else if (event.key === KeyCodes.ArrowDown) {
      if (keysDown.contains(KeyCodes.Ctrl)) {
        if (props.onCtrlArrowDown) props.onCtrlArrowDown()
      } else {
        if (props.onArrowDown) props.onArrowDown()
      }
      event.preventDefault()
    }
  }

  function onKeyUp(event: KeyboardEvent<HTMLInputElement>): void {
    const eventKey = event.key
    setKeysDown((_) => _.filter((__) => __ !== eventKey))
  }

  function areActionHelperKeysPressed(action: InputAction) {
    let shouldRunAction = true

    for (const helperKey of action.helperKeys?.toArray() ?? []) {
      if (!keysDown.contains(helperKey)) {
        shouldRunAction = false

        break
      }
    }

    return shouldRunAction
  }

  function handleKeyActions(event: KeyboardEvent<HTMLInputElement>): void {
    if (!props.actions) {
      return
    }

    const actions = props.actions.filter((_) => !(_.mainTrigger in WheelTrigger))

    for (const action of actions.toArray() ?? []) {
      if (event.key !== action.mainTrigger) {
        continue
      }

      if (areActionHelperKeysPressed(action)) {
        action.action()
      }
    }
  }

  function onChange(event: ChangeEvent<HTMLInputElement>): void {
    if (props.onChange) props.onChange(event)
  }

  function onClick(event: MouseEvent<HTMLInputElement>): void {
    if (props.onClick) props.onClick(event)
  }

  function onFocus(event: FocusEvent<HTMLInputElement>): void {
    if (props.onFocus) props.onFocus(event)
  }

  function onBlur(event: FocusEvent<HTMLInputElement>): void {
    if (props.onBlur) props.onBlur(event)
  }

  function onWheel(event: WheelEvent<HTMLInputElement>): void {
    const actions = props.actions?.filter((_) => _.mainTrigger in WheelTrigger)
    const eventTrigger = event.deltaY > 0 ? WheelTrigger.Down : WheelTrigger.Up

    for (const action of actions?.toArray() ?? []) {
      if (action.mainTrigger !== eventTrigger) {
        continue
      }

      if (areActionHelperKeysPressed(action)) {
        ScrollService.disableThisOneScrolling()
        const count = Math.abs(event.deltaY)

        for (let i = 0; i < count / 10; i++) {
          action.action()
        }
      }
    }
  }

  function getCss(): string {
    let css = `form-control`

    switch (props.size) {
      case InputSize.Small:
        css += ' form-control-sm'
        break
    }

    if (props.className) css += ' ' + props.className

    return css
  }

  return (
    <input
      ref={ref}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onWheel={onWheel}
      defaultValue={props.defaultValue}
      value={props.value}
      className={getCss()}
      id={props.id}
      placeholder={props.placeholder}
    />
  )
})

InputWithKeyActions.displayName = 'InputWithKeyActions'
