import React, { FC, useContext } from 'react'
import JdfNavigation from './JdfNavigation'
import logo from '../../../assets/logo-optimized.svg'
import { NavLink } from 'react-router-dom'
import { appUrls } from '../../../utils/urls'
import { Localizer } from '../../../utils/localizer'
import { JdfContext } from '../../../contexts/JdfContext'
import { Nav, Navbar } from 'react-bootstrap'
import cx from 'classnames'
import styles from './Navigation.module.scss'
import LoginNavigation from './LoginNavigation'
import SaveJdfButton from './SaveJdfButton'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { navigationGuide } from '../../guide/content/navigation'
import AppGuideButton from './AppGuideButton'
import { common } from '@mui/material/colors'
import { Badge } from '@mui/material'

const Navigation: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const {
    state: { getNavUrlProps },
  } = useContext(NavigationContext)

  return (
    <Navbar expand='sm' className={styles.navigation}>
      <Navbar.Brand as={NavLink} {...getNavUrlProps(appUrls.home)} className={'mr-3 mx-md-3'}>
        <img
          src={logo}
          id={navigationGuide.logo.target}
          alt='CPEdit'
          className={cx('p-2', styles.logo)}
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse
        id='nav-menu'
        className={'justify-content-sm-end align-self-stretch mx-md-5'}
      >
        <Nav className={'border-right justify-content-end align-self-stretch flex-wrap pr-4 mr-3'}>
          <Nav.Link id={'home'} as={NavLink} {...getNavUrlProps(appUrls.home)} exact>
            {Localizer.localize('Home')}
          </Nav.Link>

          {/*<Nav.Link as={NavLink} to={appUrls.internationalStations}>*/}
          {/*  {Localizer.localize('Internat. stations')}*/}
          {/*</Nav.Link>*/}

          {jdfState.jdfDataId && <JdfNavigation jdfDataId={jdfState.jdfDataId} />}

          <Nav.Link
            id={navigationGuide.map.target}
            as={NavLink}
            {...getNavUrlProps(
              jdfState.jdfDataId
                ? appUrls.jdf.map.withParams({ jdfDataId: jdfState.jdfDataId })
                : appUrls.map
            )}
          >
            <Badge
              badgeContent={'BETA'}
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  right: -3,
                  top: -3,
                  border: `2px solid ${common.white}`,
                  padding: '0 4px',
                  color: common.white,
                  fontWeight: 'bold',
                },
              }}
            >
              {Localizer.localize('Map')}
            </Badge>
          </Nav.Link>
        </Nav>

        {jdfState.jdfDataId && (
          <Nav className={'border-right pr-4 mr-3 align-self-stretch align-items-sm-center'}>
            <SaveJdfButton />
          </Nav>
        )}

        <AppGuideButton />

        <LoginNavigation />
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
