import React, { FC, useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser'
import { Localizer } from '../../../utils/localizer'
import { Dropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../../contexts/AuthContext'
import { apiServerBaseUrl, appUrls } from '../../../utils/urls'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import cx from 'classnames'
import { NavigationContext } from '../../../contexts/NavigationContext'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Role } from '../../../models/AuthViewModels'
import { Stack, Tooltip } from '@mui/material'
import './LoginNavigation.scss'

const LoginNavigation: FC = () => {
  const { isUserLoggedIn, isVerifyingUser, userAuth, signOut } = useAuthContext()
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const {
    state: { getNavUrlProps, onNavigateElsewhere },
  } = useContext(NavigationContext)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const isAdmin = userAuth?.role === Role.Admin

  const handleTooltipClose = () =>
    setTooltipOpen(false)

  const handleTooltipOpen = () => {
    if (!dropdownOpen) {
      setTooltipOpen(true)
    }
  }

  const handleDropdownToggle = (isDropdownOpen: boolean) => {
    setDropdownOpen(isDropdownOpen) // save if dropdown is open to state in both cases

    if (isDropdownOpen) {
      handleTooltipClose() // close tooltip when dropdown opened 
    }
  }
  
  const logout = async () => {
    await signOut()

    notificationsDispatch({
      type: ADD_NOTIFICATION,
      value: {
        type: NotificationType.Success,
        title: Localizer.localize('You were logged out'),
      },
    })
  }

  const onLogoutClick = async () => {
    if (onNavigateElsewhere) {
      onNavigateElsewhere(() => logout())
      return
    }

    await logout()
  }

  const getUserInfoForTooltip = () => {
    if (!userAuth) {
      return ''
    }

    const fullNameOrUserName = (userAuth.firstName && userAuth.lastName)
      ? userAuth.firstName + ' ' + userAuth.lastName
      : userAuth.userName;

    return fullNameOrUserName + ', ' + Localizer.localize(userAuth.role)
  } 
  
  if (isVerifyingUser) {
    return <></>
  }

  return (
    <Dropdown onToggle={handleDropdownToggle}>

      <Tooltip
        title={getUserInfoForTooltip()}
        open={tooltipOpen}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        TransitionProps={{ exit: false }}
      >
        <Dropdown.Toggle
          id='nav-user-dropdown'
          className={cx('button btn-icon button-transparent-dark no-caret', {
            isLoggedIn: isUserLoggedIn,
          })}
        >
          <PersonOutlineIcon className={'mr-2'} style={{ fontSize: '1.4em' }} />

          <span className='d-inline-block d-sm-none'>{Localizer.localize('User')}</span>
        </Dropdown.Toggle>
      </Tooltip>

      <Dropdown.Menu alignRight>
        {!isUserLoggedIn && (
          <Dropdown.Item as={NavLink} {...getNavUrlProps(appUrls.login)}>
            {Localizer.localize('Log in')}
          </Dropdown.Item>
        )}
        
        {isUserLoggedIn && (
          <>
            <Dropdown.Item disabled={true}>
              <Stack >
                <span className={'user-info-primary'}>
                  {(userAuth?.firstName && userAuth?.lastName)
                    ? `${userAuth?.firstName} ${userAuth?.lastName} (${userAuth?.userName})`
                    : userAuth?.userName}
                </span>
                <span className={'user-info-secondary'}>{userAuth?.email}</span>
                <span className={'user-info-secondary'}>
                  {Localizer.localize(userAuth?.role ?? '')}
                </span>
              </Stack>
            </Dropdown.Item>
            
            {isAdmin && (
              <>
                <hr className='m-0' />

                <Dropdown.Item as={NavLink} {...getNavUrlProps(appUrls.admin.manageDate)}>
                  {Localizer.localize('Manage data')}
                </Dropdown.Item>
                <a
                  href={apiServerBaseUrl + '/hangfire'}
                  className={'dropdown-item'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {Localizer.localize('Hangfire')}
                </a>
                <Dropdown.Item as={NavLink} {...getNavUrlProps(appUrls.admin.usersList)}>
                  {Localizer.localize('Users list')}
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} {...getNavUrlProps(appUrls.admin.registrationAllowedEmails)}>
                  {Localizer.localize('Allowed email addresses')}
                </Dropdown.Item>
              </>
            )}

            <hr className='m-0' />

            <Dropdown.Item onClick={onLogoutClick}>{Localizer.localize('Logout')}</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LoginNavigation
