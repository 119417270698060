import React, { useContext, useEffect, useState } from 'react'
import { LineNumberExtended } from '../../../models/LineModels'
import { LoadingFailed } from '../../../to-refactor/LoadingHelpers'
import { LineViewerToolbar } from './LineViewerToolbar'
import { Localizer } from '../../../utils/localizer'
import {
  AppGuideContext,
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  JdfLineNumber,
  Preloader,
} from '@inprop/tt-ui-elements'
import { LineNameEditor } from './LineNameEditor'
import { List } from 'immutable'
import { TTFixedCode } from '../../general/TTFixedCode'
import { TTTimeCode } from '../../general/TTTimeCode'
import TimeTableSheet from './TimeTableSheet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'

import styles from './LineViewer.module.scss'
import { useJdfLineApi } from '../../../hooks/useJdfLineApi'
import { appUrls } from '../../../utils/urls'
import { useHistory } from 'react-router-dom'
import { JdfContext } from '../../../contexts/JdfContext'
import cx from 'classnames'
import { LineEditor } from '../../../contexts/JdfLineEditorContext'
import NegativeMark from '../NegativeMark'
import { guideContent } from '../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
}

interface State {
  activeConnectionType: ConnectionType
  showConnectionEditorForConnectionNumber?: number
  error?: string
}

export enum ConnectionType {
  All,
  Even,
  Odd,
}

export function LineViewer(props: Props): JSX.Element {
  const history = useHistory()
  const { state: jdfState } = useContext(JdfContext)

  const {
    line,
    isLoading,
    error: isLoadingLineError,
    fetchLine,
  } = useJdfLineApi(props.dataId, props.lineNumber)

  useEffect(() => {
    fetchLine()
  }, [props.dataId, props.lineNumber.lineNumber, props.lineNumber.lineNumberExtension])

  const [state, setState] = useState<State>({
    activeConnectionType: ConnectionType.All,
  })
  const [isLineNameEditorVisible, setIsLineNameEditorVisible] = useState<boolean>(false)

  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  useAppGuide(
    [guideContent.lines.renameLine, guideContent.lines.changeProvider],
    !!line && isGuideVisible,
    'append'
  )

  function changeActiveConnectionsType(type: ConnectionType): void {
    setState((_) => {
      return { ..._, activeConnectionType: type }
    })
  }

  const redirectToLineEditProvider = () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data ID must be set when redirecting to line editor')
    }

    history.push(
      appUrls.jdf.lineEdit.withParams({
        jdfDataId: jdfState.jdfDataId,
        lineNumber: props.lineNumber.lineNumber,
        lineNumberExtension: props.lineNumber.lineNumberExtension,
        whatToEdit: LineEditor.Provider,
      })
    )
  }

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <div className={styles.lineViewer}>
      {!isLoading && !line && <LoadingFailed tryAgainFn={fetchLine} />}

      {line && (
        <>
          <LineViewerToolbar
            jdfDataId={props.dataId}
            currentLineNumber={line.lineNumber}
            allLineNumbers={List<LineNumberExtended>(line.allLineNumbers)}
            allConnectionNumbers={List<number>(line.connections.map((_) => _.connectionNumber))}
            activeConnectionType={state.activeConnectionType}
            refreshLine={fetchLine}
            showConnectionsWithType={(type: ConnectionType) => changeActiveConnectionsType(type)}
          />

          <div>
            <div className='row mb-3'>
              <div className='col-12 d-flex'>
                <div className={'flex-grow-1 mr-2'}>
                  {!isLineNameEditorVisible ? (
                    <div className={'d-flex display-child-on-hover'}>
                      <div className='h2 mr-2 d-flex align-items-start'>
                        <JdfLineNumber lineId={line.lineNumber} />
                        &nbsp;{line.lineName}
                      </div>
                      <Button
                        id={guideContent.lines.renameLine.target}
                        color={ButtonColor.Light}
                        shape={ButtonShape.Rounded}
                        size={ButtonSize.ExtraSmall}
                        onClick={() => setIsLineNameEditorVisible(true)}
                        className={cx({ 'display-on-parent-hover': !isGuideVisible })}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} className={'mr-2'} />
                        {Localizer.localize('Rename')}
                      </Button>
                    </div>
                  ) : (
                    <LineNameEditor
                      lineName={line.lineName}
                      dataId={props.dataId}
                      lineNumber={line.lineNumber}
                      onSave={() => {
                        fetchLine()
                        setIsLineNameEditorVisible(false)
                      }}
                      onCancel={() => setIsLineNameEditorVisible(false)}
                    />
                  )}

                  <div className={'d-flex'}>
                    <span className={'d-flex mr-1'}>{Localizer.localize('Line providers')}:</span>
                    <div className={'d-flex flex-column'}>
                      <div
                        className={cx('d-flex align-items-center display-child-on-hover', {
                          'text-danger': line.provider.isUnknown,
                        })}
                      >
                        {line.provider.name}{' '}
                        {line.provider.usedInConnections && `(${line.provider.usedInConnections})`}
                        <Button
                          id={guideContent.lines.changeProvider.target}
                          shape={ButtonShape.Rounded}
                          size={ButtonSize.ExtraExtraSmall}
                          color={ButtonColor.Light}
                          className={cx('my-0', { 'display-on-parent-hover': !isGuideVisible })}
                          onClick={redirectToLineEditProvider}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} className={'mr-2'} />
                          {Localizer.localize('Change')}
                        </Button>
                      </div>

                      {line.alternativeProviders.map((_, index) => (
                        <div
                          key={_.name}
                          className={cx('d-flex align-items-center', {
                            'text-danger': _.isUnknown,
                          })}
                        >
                          {_.name}
                          {_.timeRestrictionNote && `, ${_.timeRestrictionNote}`}{' '}
                          {_.usedInConnections && `(${_.usedInConnections})`}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  {line.validFrom}
                  <br />
                  {line.validTo}
                </div>
              </div>
            </div>

            <TimeTableSheet
              line={line}
              jdfDataId={props.dataId}
              activeConnectionType={state.activeConnectionType}
              openConnectionEditorFn={(connectionNumber: number) =>
                setState((_) => {
                  return { ..._, showConnectionEditorForConnectionNumber: connectionNumber }
                })
              }
              refreshTimeTableSheet={fetchLine}
            />

            <table className='table-borderless table-hover table-sm mb-3'>
              <tbody>
                {line.fixedCodes.map((fixedCode) => (
                  <tr key={fixedCode.mark}>
                    <td className={'text-center'}>
                      <TTFixedCode mark={fixedCode.mark} disableTooltip />
                    </td>
                    <td>{fixedCode.description}</td>
                  </tr>
                ))}
                {line.textTimeCodes.map((textTimeCode) =>
                  textTimeCode.description ? (
                    <tr key={textTimeCode.mark}>
                      <td className={'text-center'}>
                        <TTTimeCode mark={textTimeCode.mark} disableTooltip />
                      </td>
                      <td>{textTimeCode.description}</td>
                    </tr>
                  ) : null
                )}
                {line.negativeMarks.map((negativeMark) =>
                  negativeMark.description ? (
                    <tr key={negativeMark.mark}>
                      <td className={'text-center'}>
                        <NegativeMark {...negativeMark} withTooltip={false} />
                      </td>
                      <td>{negativeMark.description}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>

            {line.textRemarks.length === 0 ? null : (
              <div className={'mb-2'}>
                {line.textRemarks.map((textMark, index) => (
                  <p key={index} className={'mb-0'}>
                    {textMark}
                  </p>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}