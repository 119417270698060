import React, { ChangeEvent, FC, Ref, useContext } from 'react'
import { Station } from '../../../../models/LineModels'
import { HtmlIdProvider } from '../../../../utils/htmlIdProvider'
import { TTFixedCode } from '../../../general/TTFixedCode'
import { ConnectionStopTimeInput } from './ConnectionStopTimeInput'
import { ConnectionStopFixedCodesEditor, FixedCodeStatus } from './ConnectionStopFixedCodesEditor'
import { TTFixedCodesService } from '../../../../utils/ttFixedCodesService'
import { List } from 'immutable'
import { Localizer } from '../../../../utils/localizer'
import './../../../../utils/stringTimeExtensions'
import {
  AfterConnectionStopsChangedFn,
  ConnectionStopExtended,
} from '../route/ConnectionRouteEditor'
import StationName from '../../station/StationName'
import { guideContent } from '../../../guide/content/guideContent'
import cx from 'classnames'
import { AppGuideContext, AppGuideSteps } from '@inprop/tt-ui-elements'

export interface Props {
  jdfDataId: string
  station: Station
  connectionStop: ConnectionStopExtended

  type: 'first' | 'middle' | 'last'
  isWithGuide?: boolean

  arrivalInputRef: Ref<HTMLInputElement>
  departureInputRef: Ref<HTMLInputElement>

  updateConnectionStop: (
    newConnectionStop: ConnectionStopExtended,
    afterConnectionStopUpdated?: AfterConnectionStopsChangedFn
  ) => void
  updateFollowingConnectionStopsTime: (
    currentInputType: 'arrival' | 'departure',
    addMinutes: number,
    currentConnectionStops: List<ConnectionStopExtended>
  ) => void

  selectNextInput: (currentInputType: 'arrival' | 'departure') => void
  getNearestTimeToInput: (currentInputType: 'arrival' | 'departure') => Date

  refreshConnectionRoute: () => void
}

export const ConnectionStopEditorRow: FC<Props> = ({ isWithGuide, ...props }) => {
  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  /**
   * Get fixed codes array from connection stop.
   * @param connectionStop
   */
  function getFixedCodes(connectionStop: ConnectionStopExtended): List<string> {
    return List<string>(connectionStop.fixedCodes ?? [])
  }

  /**
   * Create fixed codes' status array from connection stop.
   * @param connectionStop
   */
  function getFixedCodeStatuses(connectionStop: ConnectionStopExtended): FixedCodeStatus[] {
    const maxFixedCodes = 3

    const fixedCodes = getFixedCodes(connectionStop)

    return TTFixedCodesService.connectionStopAllowedFixedCodes.map((_) => {
      const isSelected = fixedCodes.includes(_)
      const isDisabled = !isSelected && fixedCodes.size >= maxFixedCodes

      return {
        fixedCode: _,
        isSelected: isSelected,
        isDisabled: isDisabled,
        whyDisabled: isDisabled
          ? Localizer.localize('Connection stop can have max 3 fixed codes')
          : undefined,
      }
    })
  }

  /**
   * Process new "timeish" value.
   * It can be "timeish" char (| or <), time (e.g. 09:45), shortcut for time (e.g. 0945, 945),
   * time or shortcut for time with modifier (e.g. 09:45+3, 0945+3, 945+3) or just modifier (e.g. +3, -3)
   * @param currentInputType Type of time input - arrival or departure
   * @param newValue
   */
  function onTimeChange(
    currentInputType: 'arrival' | 'departure',
    newValue: string | undefined
  ): void {
    if (!newValue) {
      updateConnectionStopTime(currentInputType, '', undefined)

      return
    }

    let newStringValue: string = newValue
    let newDateValue: Date | undefined = undefined

    if (newValue.isConvertibleToTime()) {
      if (containsTimeModifier(newValue)) {
        const valueParts = newValue.split(/[+-]/)
        let minutes = parseInt(valueParts[1])
        if (!newValue.includes('+')) {
          minutes = 0 - minutes
        }

        addMinutes(currentInputType, minutes, true)

        return
      } else {
        newStringValue = newValue.addColonToTime()
        newDateValue = newStringValue.getDateFromTimeString()
      }
    }

    updateConnectionStopTime(currentInputType, newStringValue, newDateValue)
  }

  function onKilometersChange(event: ChangeEvent<HTMLInputElement>): void {
    const newKm = event.target.value
    if (props.connectionStop.km === newKm) {
      return
    }

    const newConnectionStop = { ...props.connectionStop }
    newConnectionStop.km = newKm

    props.updateConnectionStop(newConnectionStop)
  }

  const onPlatformChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newPlatform = event.target.value
    if (props.connectionStop.platform === newPlatform) {
      return
    }

    const newConnectionStop = { ...props.connectionStop }
    newConnectionStop.platform = newPlatform

    props.updateConnectionStop(newConnectionStop)
  }

  function onFixedCodeChange(fixedCode: string, action: 'set' | 'unset'): void {
    let newConnectionStop = { ...props.connectionStop }
    let fixedCodes = getFixedCodes(newConnectionStop)

    if (action === 'set') {
      if (!fixedCodes.contains(fixedCode)) {
        fixedCodes = TTFixedCodesService.removeConflictingFixedCodes(fixedCodes, fixedCode)

        fixedCodes = fixedCodes.push(fixedCode)
      }
    } else {
      if (fixedCodes.includes(fixedCode)) {
        const fixedCodeIndex = fixedCodes.indexOf(fixedCode)
        fixedCodes = fixedCodes.remove(fixedCodeIndex)
      }
    }

    newConnectionStop = setFixedCodesToConnectionStop(newConnectionStop, fixedCodes)
    props.updateConnectionStop(newConnectionStop)
  }

  function updateConnectionStopTime(
    which: 'arrival' | 'departure',
    stringValue: string,
    dateValue?: Date,
    afterConnectionStopChanged?: AfterConnectionStopsChangedFn
  ): void {
    const newConnectionStop = { ...props.connectionStop }

    if (which === 'arrival') {
      if (props.connectionStop.arrival === stringValue) {
        return
      }

      newConnectionStop.arrival = stringValue
      newConnectionStop.arrivalTime = dateValue
    } else {
      if (props.connectionStop.departure === stringValue) {
        return
      }

      newConnectionStop.departure = stringValue
      newConnectionStop.departureTime = dateValue
    }

    props.updateConnectionStop(newConnectionStop, afterConnectionStopChanged)
  }

  function addMinutes(
    where: 'arrival' | 'departure',
    minutes: number,
    modifyFollowers = false
  ): void {
    let afterConnectionStopChanged: AfterConnectionStopsChangedFn | undefined = undefined
    if (modifyFollowers) {
      afterConnectionStopChanged = (currentConnectionStops) =>
        props.updateFollowingConnectionStopsTime(where, minutes, currentConnectionStops)
    }

    if (where === 'arrival') {
      addArrivalMinutes(minutes, afterConnectionStopChanged)
    } else {
      addDepartureMinutes(minutes, afterConnectionStopChanged)
    }
  }

  function addArrivalMinutes(
    minutes: number,
    afterConnectionStopChanged?: AfterConnectionStopsChangedFn
  ): void {
    let originalValue = props.connectionStop.arrivalTime
    if (!originalValue) {
      originalValue = props.getNearestTimeToInput('arrival')
    }

    const newArrivalTime = originalValue.addMinutes(minutes)

    updateConnectionStopTime(
      'arrival',
      newArrivalTime.toTimeString(),
      newArrivalTime,
      afterConnectionStopChanged
    )
  }

  function addDepartureMinutes(
    minutes: number,
    afterConnectionStopChanged?: AfterConnectionStopsChangedFn
  ): void {
    let originalValue = props.connectionStop.departureTime
    if (!originalValue) {
      originalValue = props.getNearestTimeToInput('departure')
    }
    const newDepartureTime = originalValue.addMinutes(minutes)

    updateConnectionStopTime(
      'departure',
      newDepartureTime.toTimeString(),
      newDepartureTime,
      afterConnectionStopChanged
    )
  }

  function setFixedCodesToConnectionStop(
    connectionStop: ConnectionStopExtended,
    fixedCodes: List<string>
  ): ConnectionStopExtended {
    if (fixedCodes.size > 3)
      throw new Error('Maximum number of fixed codes for connection stop was exceeded')

    connectionStop.fixedCodes = fixedCodes.toArray()

    return connectionStop
  }

  function containsTimeModifier(value: string): boolean {
    return /^.*([+-][0-9]{0,4})$/.test(value)
  }

  // TODO what about prichodMin and odchodMax?
  return (
    <>
      {isWithGuide && (
        <AppGuideSteps
          steps={[
            guideContent.connections.editor.route.platformInput,
            guideContent.connections.editor.route.kmInput,
            guideContent.connections.editor.route.arrivalInput,
            guideContent.connections.editor.route.departureInput,
          ]}
        />
      )}

      {isWithGuide && isGuideVisible && (
        <AppGuideSteps
          steps={[guideContent.connections.editor.route.stopSettings]}
          mode={'append'}
        />
      )}

      <tr className='display-child-on-hover'>
        <td className='text-center'>{props.station.tariffNumber}</td>

        <td className='d-flex align-content-center justify-content-between text-nowrap'>
          <StationName
            name={props.station.name}
            isStationUnknown={props.station.isUnknown}
            jdfDataId={props.jdfDataId}
            stationId={props.station.id}
            onStationExchangeDone={() => props.refreshConnectionRoute()}
            allowStationExchange={false}
            withGuide={isWithGuide}
          />

          <div className='d-flex'>
            {getFixedCodes(props.connectionStop).size > 0 ? (
              <div
                className='d-flex align-items-center badge badge-dark ml-1 pl-1 pr-0'
                style={{ fontSize: '0.9em' }}
              >
                {getFixedCodes(props.connectionStop).map((_, index) => {
                  const fixedCodeWrapperId = HtmlIdProvider.getNewId('fixed-code')
                  return (
                    <span className='d-inline-flex mr-1' id={fixedCodeWrapperId} key={index}>
                      <TTFixedCode
                        mark={_}
                        tooltipTarget={fixedCodeWrapperId}
                        relatedTo={'connectionStop'}
                      />
                    </span>
                  )
                })}
              </div>
            ) : null}
          </div>
        </td>

        <td>
          <input
            id={isWithGuide ? guideContent.connections.editor.route.platformInput.target : ''}
            type='text'
            className='form-control form-control-xs narrow-input text-center'
            value={props.connectionStop.platform ?? ''}
            onChange={onPlatformChange}
          />
        </td>

        <td>
          <input
            id={isWithGuide ? guideContent.connections.editor.route.kmInput.target : ''}
            type='text'
            className='form-control form-control-xs narrow-input text-center'
            value={props.connectionStop.km ?? ''}
            onChange={onKilometersChange}
          />
        </td>

        <td>
          {props.type !== 'first' && (
            <ConnectionStopTimeInput
              id={isWithGuide ? guideContent.connections.editor.route.arrivalInput.target : ''}
              originalValue={props.connectionStop.arrival}
              processNewValue={(newValue) => onTimeChange('arrival', newValue)}
              onTabPressed={() => props.selectNextInput('arrival')}
              onEnterPressed={() => props.selectNextInput('arrival')}
              addMinutes={(minutes) => addMinutes('arrival', minutes)}
              addMinutesWithFollowers={(minutes) => addMinutes('arrival', minutes, true)}
              disableButtons={props.type === 'last'}
              ref={props.arrivalInputRef}
            />
          )}
        </td>

        <td>
          {props.type !== 'last' && (
            <ConnectionStopTimeInput
              id={isWithGuide ? guideContent.connections.editor.route.departureInput.target : ''}
              originalValue={props.connectionStop.departure}
              processNewValue={(newValue) => onTimeChange('departure', newValue)}
              onTabPressed={() => props.selectNextInput('departure')}
              onEnterPressed={() => props.selectNextInput('departure')}
              addMinutes={(minutes) => addMinutes('departure', minutes)}
              addMinutesWithFollowers={(minutes) => addMinutes('departure', minutes, true)}
              disableButtons={props.type === 'first'}
              ref={props.departureInputRef}
            />
          )}
        </td>

        <td id={isWithGuide ? guideContent.connections.editor.route.stopSettings.target : ''}>
          <ConnectionStopFixedCodesEditor
            class={cx({ 'display-on-parent-hover': !(isWithGuide && isGuideVisible) })}
            fixedCodes={getFixedCodeStatuses(props.connectionStop)}
            onChangeFunction={onFixedCodeChange}
          />
        </td>
      </tr>
    </>
  )
}

ConnectionStopEditorRow.defaultProps = {
  isWithGuide: false,
}
