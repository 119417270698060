import axios from 'axios'
import {
  RegistrationAllowedEmailDto,
  RegistrationAllowedEmailViewModel,
  UserExcerptDto,
} from '../models/AuthViewModels'
import { apiUrls } from '../utils/urls'
import { dtoToModel } from '../models/dtoToModel'

export const getAllUsers = () =>
  axios.get<UserExcerptDto[]>(apiUrls.users.getAll)
    .then(response => response.data.map(dtoToModel.userExcerpt))

export const getAllRegistrationAllowedEmails = () =>
  axios.get<RegistrationAllowedEmailDto[]>(apiUrls.registrationAllowedEmails.getAll)
    .then(response => response.data.map(dtoToModel.registrationAllowedEmail))

export const createRegistrationAllowedEmail = (registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel) =>
  axios.post<number>(apiUrls.registrationAllowedEmails.base, registrationAllowedEmailViewModel);

export const updateRegistrationAllowedEmail =
  (registrationAllowedEmailId: number, registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel) =>
    axios.patch<void>(apiUrls.registrationAllowedEmails.updateAndDelete(registrationAllowedEmailId), registrationAllowedEmailViewModel)

export const deleteRegistrationAllowedEmail = (registrationAllowedEmailId: number) =>
  axios.delete<void>(apiUrls.registrationAllowedEmails.updateAndDelete(registrationAllowedEmailId))
