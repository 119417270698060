import * as React from 'react'
import { ChangeEvent, RefObject, useEffect, useState } from 'react'
import { TTChars } from '../../../../models/LineModels'
import { TTFixedCodesService } from '../../../../utils/ttFixedCodesService'
import {
  HelperKey,
  InputAction,
  InputWithKeyActions,
  KeyCodes,
  WheelTrigger,
} from '../../../general/InputWithKeyActions'
import { TTFixedCode } from '../../../general/TTFixedCode'
import '../../../../utils/stringTimeExtensions'
import { List } from 'immutable'

export interface Props {
  originalValue?: string
  processNewValue: (newValue: string | undefined) => void
  onTabPressed?: () => void
  onEnterPressed?: () => void
  onFocus?: () => void
  onBlur?: () => void
  disableButtons?: boolean
  id?: string
  class?: string[]
  ref: RefObject<HTMLInputElement>
  setRef?: (ref: HTMLInputElement) => void
  addMinutes: (minutes: number) => void
  addMinutesWithFollowers: (minutes: number) => void
}

export const ConnectionStopTimeInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const [value, setValue] = useState<string | undefined>(props.originalValue)
    const [focused, setFocused] = useState<boolean>(false)

    useEffect(() => {
      setValue(props.originalValue)
    }, [props.originalValue])

    function prepareDisplayValue(value: string | undefined): string | undefined {
      if (focused) return value

      if (value === TTChars.ConnectionBypassStop || value === TTChars.ConnectionGoThroughStop)
        return ''

      return value
    }

    function onChange(event: ChangeEvent<HTMLInputElement>): void {
      const newValue = event.target.value
      const pattern = /^[|<0-9:+-]*$/
      if (pattern.test(newValue)) {
        setValue(newValue)
      }
    }

    function getInputActions(): List<InputAction> {
      // TODO is there some better way than retyping to HelperKey[]?
      return List([
        { mainTrigger: KeyCodes.ArrowUp, action: () => props.addMinutes(1) },
        { mainTrigger: KeyCodes.ArrowDown, action: () => props.addMinutes(-1) },
        {
          mainTrigger: KeyCodes.ArrowUp,
          helperKeys: List([KeyCodes.Ctrl] as HelperKey[]),
          action: () => props.addMinutesWithFollowers(1),
        },
        {
          mainTrigger: KeyCodes.ArrowDown,
          helperKeys: List([KeyCodes.Ctrl] as HelperKey[]),
          action: () => props.addMinutesWithFollowers(-1),
        },
        {
          mainTrigger: WheelTrigger.Up,
          helperKeys: List([KeyCodes.Shift] as HelperKey[]),
          action: () => props.addMinutes(1),
        },
        {
          mainTrigger: WheelTrigger.Down,
          helperKeys: List([KeyCodes.Shift] as HelperKey[]),
          action: () => props.addMinutes(-1),
        },
      ])
    }

    const onFocus = () => {
      setFocused(true)

      if (props.onFocus) props.onFocus()
    }

    const onBlur = () => {
      setFocused(false)
      props.processNewValue(value)

      if (props.onBlur) props.onBlur()
    }

    return (
      <div id={props.id} className='input-group input-group-xs' style={{ minWidth: '5em' }}>
        <InputWithKeyActions
          className={`form-control middle-narrow-input text-center ${props.class}`}
          value={prepareDisplayValue(value) ?? ''}
          ref={ref}
          onChange={onChange}
          onClick={(_) => _.currentTarget.select()}
          onFocus={onFocus}
          onBlur={onBlur}
          onTabPressed={props.onTabPressed}
          onEnterPressed={props.onEnterPressed}
          actions={getInputActions()}
        />
        <div className='input-group-append'>
          <button
            type='button'
            className={
              'btn btn-outline-secondary' +
              (props.originalValue === TTChars.ConnectionGoThroughStop ? ' active' : '')
            }
            title={TTFixedCodesService.getDescription(
              TTChars.ConnectionGoThroughStop,
              'connectionStop'
            )}
            onClick={() => {
              if (value === TTChars.ConnectionGoThroughStop) props.processNewValue('')
              else props.processNewValue(TTChars.ConnectionGoThroughStop)
            }}
            disabled={props.disableButtons}
          >
            <TTFixedCode mark={TTChars.ConnectionGoThroughStop} disableTooltip />
          </button>
          <button
            type='button'
            className={
              'btn btn-outline-secondary' +
              (props.originalValue === TTChars.ConnectionBypassStop ? ' active' : '')
            }
            title={TTFixedCodesService.getDescription(
              TTChars.ConnectionBypassStop,
              'connectionStop'
            )}
            onClick={() => {
              if (value === TTChars.ConnectionBypassStop) props.processNewValue('')
              else props.processNewValue(TTChars.ConnectionBypassStop)
            }}
            disabled={props.disableButtons}
          >
            <TTFixedCode mark={TTChars.ConnectionBypassStop} disableTooltip />
          </button>
        </div>
      </div>
    )
  }
)

ConnectionStopTimeInput.displayName = 'ConnectionStopTimeInput'
