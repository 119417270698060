import { TTChars } from '../models/LineModels'
import './dateExtensions'

export {}

declare global {
  interface String {
    isTime(): boolean

    isTimeishChar(): boolean

    isConvertibleToTime(): boolean

    addColonToTime(): string

    getDateFromTimeString(): Date

    changeTime(minutes: number): string
  }
}

/**
 * Check if string is a valid time
 */
String.prototype.isTime = function (): boolean {
  const pattern = /^([01][0-9]|2[0-3]):([0-5][0-9])$/
  return pattern.test(this.toString())
}

/**
 * Check if string is a valid time char
 */
String.prototype.isTimeishChar = function (): boolean {
  return this === TTChars.ConnectionGoThroughStop || this === TTChars.ConnectionBypassStop
}

/**
 * Add colon to time.
 * E.g.: 2045 => 20:45
 *       204  => 02:04
 */
String.prototype.addColonToTime = function (): string {
  if (this.includes(':')) return this.toString()

  if (this.length === 4) return `${this.substr(0, 2)}:${this.substr(2, 2)}`

  if (this.length === 3) return `0${this.substr(0, 1)}:${this.substr(1, 2)}`

  return this.toString()
}

String.prototype.getDateFromTimeString = function (): Date {
  if (!this.isTime()) throw new Error(`String '${this}' is not valid time`)

  const splittedTime = this.split(':')
  return new Date(2020, 0, 1, parseInt(splittedTime[0]), parseInt(splittedTime[1]))
}

String.prototype.isConvertibleToTime = function (): boolean {
  return /^([0-9]{3,4}|[0-9]{2}:[0-9]{2})?([+-][0-9]{0,4})?$/.test(this.toString())
}
