import React, { FC, useContext, useEffect, useMemo } from 'react'
import { TTMapMainContextProvider } from '@inprop/tt-maps'
import TTMap from '../general/TTMap'
import { apiUrls } from '../../utils/urls'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { Preloader, useAppGuide } from '@inprop/tt-ui-elements'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'
import { ConfigurationState } from '@inprop/tt-maps/dist/Contexts/ConfigurationContext'
import { JdfContext } from '../../contexts/JdfContext'
import {
  SET_LAYOUT_HEIGHT,
  SET_LAYOUT_IS_WITH_FOOTER,
  SET_LAYOUT_WIDTH,
} from '../../contexts/LayoutReducer'
import { LayoutContext, LayoutHeight, LayoutWidth } from '../../contexts/LayoutContext'
import { useLocation } from 'react-router-dom'
import { JdfRouteParams } from '../layout/navigation/routes/JdfRouteWrapper'
import { navigationNoJdf, navigationWithJdf } from '../guide/content/guideContent'

export type MapPageRouteParams =
  | (JdfRouteParams & { sId?: never; sIdType?: never })
  | (JdfRouteParams & {
      /**
       * selected station ID
       */
      sId: string

      /**
       * selected station ID type
       * TODO add 'cis' type
       */
      sIdType: 'jdf'
    })

const MapPage: FC = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: layoutDispatch } = useContext(LayoutContext)

  useAppGuide(
    jdfState.jdfDataId ? navigationWithJdf : navigationNoJdf,
    undefined,
    undefined,
    'navigation'
  )

  const querySearch = useLocation().search
  const getSelectedStationIdFromQuery = () => {
    return new URLSearchParams(querySearch).get('sId') ?? undefined
  }
  const getSelectedStationIdTypeFromQuery = () => {
    return new URLSearchParams(querySearch).get('sIdType') ?? undefined
  }

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Full })
    layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.Full })
    layoutDispatch({ type: SET_LAYOUT_IS_WITH_FOOTER, value: false })

    return () => {
      layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Fixed })
      layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.ByContent })
      layoutDispatch({ type: SET_LAYOUT_IS_WITH_FOOTER, value: true })
    }
  }, [layoutDispatch])

  const ttMapConfiguration: ConfigurationState = {
    preloader: <Preloader overlay={false} centered />,
    onError: (title: string, text?: string) =>
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          disableAutoDismiss: true,
          title: title,
          body: text,
        },
      }),
    onJdfStationsPositionNotFound: (stations) =>
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Location of some station was not found'),
          disableAutoDismiss: true,
          body: (
            <ul className={'ml-2 mb-0'}>
              {stations.map((_, index) => (
                <li key={index}>{_.name}</li>
              ))}
            </ul>
          ),
        },
      }),
    onSelectedStationPositionNotFound: (stationName: string) =>
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Location not found'),
          disableAutoDismiss: true,
          body: `${Localizer.localize('Could not find location of')} ${stationName}`,
        },
      }),
  }

  const configurationState = useMemo<ConfigurationState>(
    () => {
      return {
        ...ttMapConfiguration,
        jdfDataId: jdfState.jdfDataId,
        selectedJdfStationId:
          getSelectedStationIdTypeFromQuery() === 'jdf' ? getSelectedStationIdFromQuery() : '',
      }
    },
    []
  )
 
  return (
    <div className={'my-0 w-100 h-100'}>
      <TTMapMainContextProvider
        apiEndpointsState={apiUrls.map}
        configurationState={configurationState}
      >
        <TTMap />
      </TTMapMainContextProvider>
    </div>
  )
}

export default MapPage