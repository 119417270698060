import {LineNumberExtended} from '../models/LineModels'
import {apiServerBaseUrl} from '../utils/urls'

export class UrlProvider {
  static Page = class {
    static Jdf = class {
      public static Lines(jdfDataId: string): string {
        return `/jdf/${jdfDataId}/lines`
      }

      public static Line(jdfDataId: string, lineNumber: LineNumberExtended): string {
        let url = `/jdf/${jdfDataId}/lines/${lineNumber.lineNumber}`
        if (lineNumber.lineNumberExtension) url += '/' + lineNumber.lineNumberExtension

        return url
      }

      public static Providers(jdfDataId: string): string {
        return `/jdf/${jdfDataId}/providers`
      }

      public static Map(jdfDataId: string, selectedStationName?: string): string {
        let url = `/jdf/${jdfDataId}/map`
        if (selectedStationName) {
          url += `?s=${selectedStationName}`
        }

        return url
      }

      public static Log(jdfDataId: string): string {
        return `/jdf/${jdfDataId}/log`
      }
    }
  }
  static Api = class {
    static Components = class {
      static Lines = class {
        static Viewer = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Lines.getUrl(dataId, lineNumber)
            url += '/viewer'

            return url
          }
        }
        static NotesEditor = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Lines.getUrl(dataId, lineNumber)
            url += '/notes-editor'

            return url
          }
        }
        static RouteEditor = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Lines.getUrl(dataId, lineNumber)
            url += '/route-editor'

            return url
          }

          public static canDeleteLineStopUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            tariffNumber: number
          ): string {
            let url = this.getUrl(dataId, lineNumber)
            url += `/can-delete-line-stop/${tariffNumber}`

            return url
          }
        }
        static NameEditor = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Lines.getUrl(dataId, lineNumber)
            url += '/name-editor'

            return url
          }
        }
        static ProviderEditor = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Lines.getUrl(dataId, lineNumber)
            url += '/provider-editor'

            return url
          }
        }

        private static getUrl = (dataId: string, lineNumber: LineNumberExtended): string => {
          let url = `${UrlProvider.ApiComponentsBaseUrl}/jdf/${dataId}/lines/${lineNumber.lineNumber}`
          if (lineNumber.lineNumberExtension) {
            url += `/${lineNumber.lineNumberExtension}`
          }

          return url
        }
      }

      static Connections = class {
        static RouteEditor = class {
          public static getUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            connectionNumber: string
          ): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(
              dataId,
              lineNumber,
              connectionNumber
            )
            url += '/route-editor'

            return url
          }
        }
        static NewEditor = class {
          public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(dataId, lineNumber)
            url += '/new-editor'

            return url
          }
        }
        static Calendar = class {
          static urlBase = `/api/components/connections/calendar`
          static Note = class {
            public static getUrl(dataId: string, lineNumber: LineNumberExtended): string {
              let url = `${UrlProvider.Api.Components.Connections.getUrl(dataId, lineNumber, '0')}`
              url += '/calendar/note'

              return url
            }
          }

          public static getUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            connectionNumber: string
          ): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(
              dataId,
              lineNumber,
              connectionNumber
            )
            url += '/calendar'

            return url
          }
        }
        static FixedCodesEditor = class {
          public static getUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            connectionNumber: string
          ): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(
              dataId,
              lineNumber,
              connectionNumber
            )
            url += '/fixed-codes-editor'

            return url
          }
        }
        static TextNotesEditor = class {
          public static getUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            connectionNumber: string
          ): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(
              dataId,
              lineNumber,
              connectionNumber
            )
            url += '/text-notes-editor'

            return url
          }
        }
        static ProvidersEditor = class {
          public static getUrl(
            dataId: string,
            lineNumber: LineNumberExtended,
            connectionNumber: string
          ): string {
            let url = UrlProvider.Api.Components.Connections.getUrl(
              dataId,
              lineNumber,
              connectionNumber
            )
            url += '/providers-editor'

            return url
          }
        }

        private static getUrl = (
          dataId: string,
          lineNumber: LineNumberExtended,
          connectionNumber?: string
        ): string => {
          let url = `${UrlProvider.ApiComponentsBaseUrl}/jdf/${dataId}/lines/${lineNumber.lineNumber}`
          if (lineNumber.lineNumberExtension) {
            url += `/${lineNumber.lineNumberExtension}`
          }
          url += `/connections`
          if (connectionNumber) {
            url += `/${connectionNumber}`
          }

          return url
        }
      }

      static Providers = class {
        static Viewer = class {
          public static getUrl(dataId: string): string {
            let url = UrlProvider.Api.Components.Providers.getUrl(dataId)
            url += '/viewer'

            return url
          }
        }
        static Editor = class {
          public static getUrl(
            dataId: string,
            providerCompanyId?: string,
            providerDistinction?: string
          ): string {
            let url = UrlProvider.Api.Components.Providers.getUrl(
              dataId,
              providerCompanyId,
              providerDistinction
            )
            url += '/editor'

            return url
          }
        }
        static SelectAutocomplete = class {
          public static getUrl(): string {
            return `${UrlProvider.ApiComponentsBaseUrl}/providers/select`
          }
        }

        private static getUrl = (
          dataId: string,
          providerCompanyId?: string,
          providerDistinction?: string
        ): string => {
          let url = `${UrlProvider.ApiComponentsBaseUrl}/jdf/${dataId}/providers`
          if (providerCompanyId) url += '/' + providerCompanyId
          if (providerDistinction) url += '/' + providerDistinction

          return url
        }
      }
    }

    public static JdfData = class {
      static Lines = class {
        public static getUrl = (dataId: string, lineNumber: LineNumberExtended): string => {
          let url = `${UrlProvider.ApiBaseUrl}/jdf/${dataId}/lines/${lineNumber.lineNumber}`
          if (lineNumber.lineNumberExtension) {
            url += `/${lineNumber.lineNumberExtension}`
          }

          return url
        }
      }

      static Connections = class {
        public static getUrl = (
          dataId: string,
          lineNumber: LineNumberExtended,
          connectionNumber?: string
        ): string => {
          let url = `${UrlProvider.ApiBaseUrl}/jdf/${dataId}/lines/${lineNumber.lineNumber}`
          if (lineNumber.lineNumberExtension) {
            url += `/${lineNumber.lineNumberExtension}`
          }
          url += `/connections`
          if (connectionNumber) {
            url += `/${connectionNumber}`
          }

          return url
        }
      }

      static Providers = class {
        public static getUrl(
          jdfDataId: string,
          companyId?: string,
          providerDistinction?: string
        ): string {
          let url = `${UrlProvider.ApiBaseUrl}/jdf/${jdfDataId}/providers`
          if (companyId) url += '/' + companyId
          if (providerDistinction) url += '/' + providerDistinction

          return url
        }
      }

      static Status = class {
        public static getUrl(dataId: string): string {
          return `${UrlProvider.ApiBaseUrl}/jdf/${dataId}/status`
        }
      }

      static Stations = class {
        public static getUrl(
          jdfDataId: string,
          stationId?: string,
          exchangeToCrwsStationId?: string
        ): string {
          let url = `${UrlProvider.ApiBaseUrl}/jdf/${jdfDataId}/stations`
          if (stationId) url += `/${stationId}`
          if (exchangeToCrwsStationId) url += `?exchangeToCrwsStationId=${exchangeToCrwsStationId}`

          return url
        }
      }
    }

    public static Crws = class {
      static Stations = class {
        public static getUrl(mask: string): string {
          let url = `${UrlProvider.ApiCrwsBaseUrl}/stations`
          if (mask) {
            url += `?mask=${mask}`
          }

          return url
        }
      }
    }
  }
  static SignalR = class {
    public static hubUrl = '/jobprogress'
  }
  private static ApiBaseUrl = `${apiServerBaseUrl}/api`
  private static ApiComponentsBaseUrl = UrlProvider.ApiBaseUrl + '/components'
  private static ApiCrwsBaseUrl = UrlProvider.ApiBaseUrl + '/crws'
}