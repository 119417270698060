import React, { FC, useContext, useState } from 'react'
import { Localizer } from '../../../utils/localizer'
import { ProviderSelectAutocomplete } from './ProviderSelectAutocomplete'
import { Provider } from './ProvidersViewer'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Logger } from '../../../utils/logger'
import { AppGuideContext, JdfLineNumberButton, useAppGuide } from '@inprop/tt-ui-elements'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { LineNumberExtended } from '../../../models/LineModels'
import { useHistory } from 'react-router-dom'
import { appUrls } from '../../../utils/urls'
import { JdfContext } from '../../../contexts/JdfContext'
import cx from 'classnames'
import { Button, ButtonGroup, Popover, Tooltip } from '@mui/material'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  jdfDataId: string
  provider: Provider
  className?: string
  onSave?: () => void
  withGuide?: boolean
}

interface State {
  isEditorVisible: boolean
  selectedProviderInEditor?: Provider
}

const ProviderEditor: FC<Props> = (props: Props) => {
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)
  const history = useHistory()

  const [state, setState] = useState<State>({
    isEditorVisible: false,
    selectedProviderInEditor: undefined,
  })

  useAppGuide(
    [guideContent.providers.edit.input, guideContent.providers.edit.cancel],
    state.isEditorVisible
  )
  useAppGuide(
    [guideContent.providers.edit.confirm],
    state.isEditorVisible && !!state.selectedProviderInEditor
  )
  useAppGuide(
    [guideContent.providers.edit.button, guideContent.providers.edit.delete],
    props.withGuide && isGuideVisible,
    'append'
  )

  const [isDeleteProviderButtonEnabled, setIsDeleteProviderButtonEnabled] = useState<boolean>(true)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const navigateToLineDetails = (lineNumber: LineNumberExtended) => {
    if (!jdfState.jdfDataId) {
      throw new Error('When redirecting to JDF line details, JDF data ID must be set.')
    }

    history.push(
      appUrls.jdf.lineDetails.withParams({
        jdfDataId: jdfState.jdfDataId,
        lineNumber: lineNumber.lineNumber,
        lineNumberExtension: lineNumber.lineNumberExtension,
      })
    )
  }

  function onSave(): void {
    if (props.onSave) props.onSave()
  }

  function changeProvider(): void {
    axios
      .put(
        UrlProvider.Api.Components.Providers.Editor.getUrl(
          props.jdfDataId,
          props.provider.companyId,
          props.provider.providerDistinction
        ),
        state.selectedProviderInEditor
      )
      .then(() => {
        setState((_) => {
          return { ..._, isEditorVisible: false, selectedProviderInEditor: undefined }
        })

        onSave()

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Provider was changed'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        handleError(error)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while changing provider'),
            type: NotificationType.Error,
          },
        })
      })
  }

  function deleteProvider(): void {
    setIsDeleteProviderButtonEnabled(false)

    axios
      .delete(
        UrlProvider.Api.JdfData.Providers.getUrl(
          props.jdfDataId,
          props.provider.companyId,
          props.provider.providerDistinction
        )
      )
      .then(() => {
        onSave()

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Provider was deleted'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        handleError(error)
        setIsDeleteProviderButtonEnabled(true)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while deleting provider'),
            type: NotificationType.Error,
          },
        })
      })
  }

  function handleError(error: any): void {
    Logger.logError(`Error in ${ProviderEditor.name}. Message: ${error.toString()}`)
  }

  return (
    <div className={cx(props.className, 'display-child-on-hover')}>
      <h4
        className={cx('mb-1 d-flex align-items-center', {
          'text-danger': props.provider.isUnknown,
        })}
      >
        {props.provider.name}

        <ButtonGroup
          variant={'contained'}
          className={cx('ml-2', {
            'display-on-parent-hover':
              anchorEl === null && !state.isEditorVisible && !(props.withGuide && isGuideVisible),
          })}
          color={'secondary'}
        >
          <Tooltip title={Localizer.localize('Change')}>
            <Button
              id={props.withGuide ? guideContent.providers.edit.button.target : ''}
              onClick={() =>
                setState((_) => {
                  return { ..._, isEditorVisible: true }
                })
              }
              className={'m-0 py-2'}
              disabled={state.isEditorVisible}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </Tooltip>

          <Tooltip title={Localizer.localize('Delete')}>
            <Button
              id={props.withGuide ? guideContent.providers.edit.delete.target : ''}
              onClick={(_) => setAnchorEl(_.currentTarget)}
              className={'m-0 py-2'}
              disabled={state.isEditorVisible}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </h4>

      <Popover open={anchorEl !== null} anchorEl={anchorEl}>
        <div className={'p-3'}>
          <p className={'mb-3'}>
            {Localizer.localize('All related records will be deleted with the provider.')}{' '}
            {Localizer.localize('Do you really want to delete this provider?')}
          </p>
          <div className={'d-flex justify-content-end'}>
            <Button
              onClick={deleteProvider}
              className={'m-0 mr-2'}
              color={'error'}
              variant={'contained'}
              disabled={!isDeleteProviderButtonEnabled}
            >
              <FontAwesomeIcon icon={faTrash} className={'mr-2'} />
              {Localizer.localize('Delete')}
            </Button>
            <Button
              onClick={() => setAnchorEl(null)}
              color={'secondary'}
              variant={'contained'}
              className={'m-0'}
            >
              <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
              {Localizer.localize('Cancel')}
            </Button>
          </div>
        </div>
      </Popover>

      {state.isEditorVisible && (
        <div className={'inline-editor p-3 mb-1'}>
          <div className={'d-flex align-items-center mb-2'}>
            <span className={'mr-2'}>{Localizer.localize('Change provider to')}: </span>
            <ProviderSelectAutocomplete
              id={guideContent.providers.edit.input.target}
              className={'flex-grow-1'}
              onChange={(_) =>
                setState((previousState) => {
                  return {
                    ...previousState,
                    selectedProviderInEditor: _,
                  }
                })
              }
            />
          </div>
          <div className={'d-flex justify-content-end'}>
            {state.selectedProviderInEditor && (
              <Button
                id={guideContent.providers.edit.confirm.target}
                onClick={changeProvider}
                className={'m-0 mr-2 px-3'}
                variant={'contained'}
              >
                <FontAwesomeIcon icon={faCheck} className={'mr-2'} />
                {Localizer.localize('Change provider')}
              </Button>
            )}

            <Button
              id={guideContent.providers.edit.cancel.target}
              onClick={() =>
                setState((_) => {
                  return { ..._, isEditorVisible: false, selectedProviderInEditor: undefined }
                })
              }
              color={'error'}
              variant={'contained'}
              className={'m-0 px-3'}
            >
              <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
              {Localizer.localize('Cancel')}
            </Button>
          </div>
        </div>
      )}

      <div className={'d-flex flex-wrap'}>
        {props.provider.usedInLines.map((lineNumber, index) => (
          <JdfLineNumberButton
            key={index}
            lineId={lineNumber}
            onClick={() => navigateToLineDetails(lineNumber)}
          />
        ))}
      </div>
    </div>
  )
}

ProviderEditor.defaultProps = {
  withGuide: false,
}

export default ProviderEditor
