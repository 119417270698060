import React, {FunctionComponent, useState} from 'react';
import {AutocompleteStation, StationSelectAutocomplete} from "./StationSelectAutocomplete";
import {Button, ButtonColor, ButtonShape, ButtonSize} from "@inprop/tt-ui-elements";
import { Localizer } from '../../../utils/localizer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";

export interface Props {
  onConfirm: (station: AutocompleteStation) => void
  onCancel: () => void
  onChange?: (station: AutocompleteStation) => void
}

const SelectStationForm: FunctionComponent<Props> = (props) => {
  const [selectedStation, setSelectedStation] = useState<AutocompleteStation | undefined>()

  const onStationChange = (station: AutocompleteStation) => {
    setSelectedStation(station)

    if (props.onChange) {
      props.onChange(station)
    }
  }

  return (
    <div className={'d-flex flex-column py-1'}>
      <StationSelectAutocomplete onChange={onStationChange} onEscPressed={() => props.onCancel()}/>

      <div className={'d-flex flex-wrap justify-content-end align-items-baseline'}>
        {!selectedStation ? null :
          <Button size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Rounded}
                  className={'m-0'}
                  onClick={() => props.onConfirm(selectedStation)}
          >
            <FontAwesomeIcon icon={faCheck} className={'mr-2'} />
            {Localizer.localize('Choose')}
          </Button>
        }
        <Button size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Danger}
                className={'m-0 mt-1 ml-1'}
                onClick={() => props.onCancel()}
                >
          <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
          {Localizer.localize('Cancel')}
        </Button>
      </div>
    </div>
  );
}

export default SelectStationForm;