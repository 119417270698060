import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import {
  ConnectionFixedCodesEditor,
  ConnectionFixedCodesEditorRef,
} from '../ConnectionFixedCodesEditor'
import { LineNumberExtended } from '../../../../models/LineModels'
import {
  ConnectionTextNotesEditor,
  ConnectionTextNotesEditorRef,
} from './ConnectionTextNotesEditor'
import { JdfConnectionEditorContext } from '../../../../contexts/JdfConnectionEditorContext'

export interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
}

enum DataState {
  NotChanged = 1,
  Changed = 2,
}

export interface State {
  fixedCodes: DataState
  textNotes: DataState
}

export function ConnectionNotesEditor(props: Props): JSX.Element {
  const { dispatch: editorDispatch } = useContext(JdfConnectionEditorContext)

  const [dataState, setDataState] = useState<State>({
    fixedCodes: DataState.NotChanged,
    textNotes: DataState.NotChanged,
  })
  const [fixedCodesEditorRef, setFixedCodesEditorRef] = useState<ConnectionFixedCodesEditorRef>({
    saveData: undefined,
  })
  const [textNotesEditorRef, setTextNotesEditorRef] = useState<ConnectionTextNotesEditorRef>({
    saveData: undefined,
  })

  useEffect(() => {
    editorDispatch.setSaveFn(saveNotes)
  }, [fixedCodesEditorRef, textNotesEditorRef, dataState])

  useEffect(() => {
    editorDispatch.setHasUnsavedChanges(
      dataState.fixedCodes === DataState.Changed || dataState.textNotes === DataState.Changed
    )
  }, [dataState.fixedCodes, dataState.textNotes])

  const saveNotes = async (): Promise<void> => {
    if (!fixedCodesEditorRef.saveData || !textNotesEditorRef.saveData) {
      throw new Error('Save function of fixed codes editor or text notes editor is empty.')
    }

    let someSavingFailed = false

    try {
      await fixedCodesEditorRef.saveData()

      setDataState((_) => ({ ..._, fixedCodes: DataState.NotChanged }))
    } catch (error) {
      console.log(error)
      someSavingFailed = true

      setDataState((_) => ({ ..._, fixedCodes: DataState.Changed }))
    }

    try {
      await textNotesEditorRef.saveData()

      setDataState((_) => ({ ..._, textNotes: DataState.NotChanged }))
    } catch (error) {
      console.log(error)
      someSavingFailed = true

      setDataState((_) => ({ ..._, textNotes: DataState.Changed }))
    }

    return someSavingFailed ? Promise.reject() : Promise.resolve()
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <ConnectionFixedCodesEditor
            setRef={(_) => setFixedCodesEditorRef(_)}
            dataId={props.dataId}
            lineNumber={props.lineNumber}
            connectionNumber={props.connectionNumber}
            onDataChanged={() => setDataState((_) => ({ ..._, fixedCodes: DataState.Changed }))}
            showSaveButton={false}
          />
        </div>
        <div className='col-md-6'>
          <ConnectionTextNotesEditor
            setRef={(_) => setTextNotesEditorRef(_)}
            dataId={props.dataId}
            lineNumber={props.lineNumber}
            connectionNumber={props.connectionNumber}
            onDataChanged={() => setDataState((_) => ({ ..._, textNotes: DataState.Changed }))}
            showSaveButton={false}
          />
        </div>
      </div>
    </div>
  )
}