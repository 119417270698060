import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { JdfLineNumberButton, Preloader } from '@inprop/tt-ui-elements'
import { Localizer } from '../../../utils/localizer'
import { LineNumberExtended } from '../../../models/LineModels'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../../utils/urls'
import { JdfContext } from '../../../contexts/JdfContext'
import { format as formatDate, parseISO } from 'date-fns'
import { LoadingFailed } from '../../../to-refactor/LoadingHelpers'
import { Link, useHistory } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

export interface ListLineApi<T extends string | Date> {
  lineNumber: LineNumberExtended
  name: string
  unknownStationsCount: number
  unknownProvidersCount: number
  lineValidFrom: T
  lineValidTo: T
  lineType: string
  lineTypeDescription: string
}

const LinesList: FC = () => {
  const history = useHistory()
  const { state: jdfState } = useContext(JdfContext)
  const [{ data: rawLinesData, loading, error }, fetchLines] = useAxios<ListLineApi<string>[]>({})

  const [lines, setLines] = useState<ListLineApi<Date>[]>([])
  const unknownStationGuideIndex = useMemo(
    () => lines.findIndex((_) => _.unknownStationsCount > 0),
    [lines]
  )
  const unknownProviderGuideIndex = useMemo(
    () => lines.findIndex((_) => _.unknownProvidersCount > 0),
    [lines]
  )

  useAppGuide(
    [
      guideContent.lines.lineNumber,
      guideContent.lines.name,
      guideContent.lines.validity,
      guideContent.lines.lineType,
    ],
    lines.length > 0
  )
  useAppGuide([guideContent.lines.unknownStations], unknownStationGuideIndex !== -1)
  useAppGuide([guideContent.lines.unknownProviders], unknownProviderGuideIndex !== -1)

  useEffect(() => {
    if (!jdfState.jdfDataId) {
      return
    }

    fetchLines({ url: apiUrls.jdf.lines.list(jdfState.jdfDataId) })
  }, [jdfState.jdfDataId])

  useEffect(() => {
    if (!rawLinesData) {
      setLines([])

      return
    }

    setLines(
      rawLinesData.map((_) => {
        return {
          ..._,
          lineValidFrom: parseISO(_.lineValidFrom),
          lineValidTo: parseISO(_.lineValidTo),
        }
      })
    )
  }, [rawLinesData])

  const getLineDetailsUrl = (lineNumber: LineNumberExtended): string => {
    if (!jdfState.jdfDataId) {
      throw new Error('When redirecting to JDF line details page, JDF data ID must be set.')
    }

    return appUrls.jdf.lineDetails.withParams({
      jdfDataId: jdfState.jdfDataId,
      lineNumber: lineNumber.lineNumber,
      lineNumberExtension: lineNumber.lineNumberExtension,
    })
  }

  const redirectToLine = (lineNumber: LineNumberExtended) => {
    history.push(getLineDetailsUrl(lineNumber))
  }

  if (error) {
    return <LoadingFailed />
  }

  if (loading) {
    return (
      <div className={'w-100 text-center py-4'}>
        <Preloader overlay={false} />
      </div>
    )
  }

  return (
    <div className='row'>
      {lines.length === 0 && !loading && (
        <div className={'col pb-3'}>
          <span>{Localizer.localize('Does not contain any lines.')}</span>
        </div>
      )}

      {lines.map((_, index) => (
        <div
          key={`${_.lineNumber.lineNumber}${_.lineNumber.lineNumberExtension}`}
          className='col-md-6 d-flex flex-row align-items-center pb-1 bg-hover-darken'
        >
          <div className='d-flex' id={index === 0 ? guideContent.lines.lineNumber.target : ''}>
            <JdfLineNumberButton
              lineId={_.lineNumber}
              onClick={() => redirectToLine(_.lineNumber)}
            />
          </div>

          <div className='d-flex flex-column flex-grow-1'>
            <h4 className='mb-0'>
              <Link
                to={getLineDetailsUrl(_.lineNumber)}
                id={index === 0 ? guideContent.lines.name.target : ''}
                className='uppercase-link link-black-green'
              >
                {_.name}
              </Link>
            </h4>

            <div className={'d-flex flex-row'}>
              {_.unknownStationsCount > 0 && (
                <span
                  className='small mr-2'
                  id={
                    index === unknownStationGuideIndex
                      ? guideContent.lines.unknownStations.target
                      : ''
                  }
                >
                  {Localizer.localize('Unknown stops')}: {_.unknownStationsCount}
                </span>
              )}

              {_.unknownProvidersCount > 0 && (
                <span
                  className='small'
                  id={
                    index === unknownProviderGuideIndex
                      ? guideContent.lines.unknownProviders.target
                      : ''
                  }
                >
                  {Localizer.localize('Unknown providers')}: {_.unknownProvidersCount}
                </span>
              )}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <Tooltip title={Localizer.localize('Line validity')}>
              <span
                className='d-flex badge badge-light'
                id={index === 0 ? guideContent.lines.validity.target : ''}
              >
                {formatDate(_.lineValidFrom, 'dd. MM. yyyy')} -{' '}
                {formatDate(_.lineValidTo, 'dd. MM. yyyy')}
              </span>
            </Tooltip>

            <div>
              <Tooltip title={_.lineTypeDescription}>
                <span
                  className='badge badge-light'
                  id={index === 0 ? guideContent.lines.lineType.target : ''}
                >
                  {_.lineType}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LinesList
