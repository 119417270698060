import React from 'react'
import cx from 'classnames'
import styles from './CheckboxInput.module.scss'

type InputElementAttributes = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface CheckboxInputBaseProps extends Omit<InputElementAttributes, 'onChange' | 'ref'> {
  error?: string
  onChange?: (value: boolean) => void
  disabled?: boolean
}

interface CheckboxInputPropsWithLabel extends CheckboxInputBaseProps {
  id: string
  label: string
}

interface CheckboxInputPropsWithoutLabel extends CheckboxInputBaseProps {
  id?: string
  label?: null
}

type CheckboxInputProps = CheckboxInputPropsWithLabel | CheckboxInputPropsWithoutLabel

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ error, onChange, disabled, label, id, checked, value, ...nativeCheckboxProps }, ref) => {
    const onButtonClick = () => {
      if (onChange) {
        onChange(!checked)
      }
    }

    return (
      <div className={'d-flex flex-row align-items-center'}>
        <div className={cx(styles.checkbox, { [styles.checked]: checked })} onClick={onButtonClick}>
          <button className={styles.circle} id={id} />
        </div>

        <div>
          {label && (
            <label htmlFor={id} className={'ml-2 mb-1'}>
              {label}
            </label>
          )}

          {error && <div className='text-danger text-sm'>{error}</div>}
        </div>

        <input
          hidden
          {...nativeCheckboxProps}
          ref={ref}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.checked)
            }
          }}
          id={id}
          disabled={disabled}
          type='checkbox'
        />
      </div>
    )
  }
)

CheckboxInput.displayName = 'CheckboxInput'

export default CheckboxInput
