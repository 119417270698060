import React, { FC, useCallback } from 'react'
import ConfirmDialog from '../general/ConfirmDialog'
import { Localizer } from '../../utils/localizer'
import { Typography } from '@mui/material'
import { useDeleteRegistrationAllowedEmail } from '../../data/mutations'
import { RegistrationAllowedEmail } from '../../models/AuthViewModels'

interface Props {
  open: boolean
  handleClose: () => void
  deleteSelectedRegistrationAllowedEmail: RegistrationAllowedEmail | undefined
}

const RegistrationAllowedEmailDeleteConfirmDialog: FC<Props> = (props) => {

  const {
    mutateAsync: deleteRegistrationAllowedEmail,
    isLoading: isDeleting,
  } = useDeleteRegistrationAllowedEmail()

  const handleDeleteConfirm = useCallback(async () => {
    if (props.deleteSelectedRegistrationAllowedEmail) {
      await deleteRegistrationAllowedEmail(props.deleteSelectedRegistrationAllowedEmail)
      props.handleClose()
    } else {
      console.error('deleteSelectedRegistrationAllowedEmail is undefined!')
    }
  }, [deleteRegistrationAllowedEmail, props.deleteSelectedRegistrationAllowedEmail])

  return (
    <ConfirmDialog
      open={props.open}
      title={Localizer.localize('Delete')}
      content={
        <Typography>
          {Localizer.localize('Are you sure to delete invitation for email address')}{' '}
          <span style={{ fontWeight: 'bold' }}>
            {props.deleteSelectedRegistrationAllowedEmail?.emailAddress}
          </span>
          {'?'}
        </Typography>
      }
      onConfirm={handleDeleteConfirm}
      onClose={props.handleClose}
      isLoading={isDeleting}
    />
  )
}

export default RegistrationAllowedEmailDeleteConfirmDialog
