import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import '@syncfusion/ej2-base/styles/bootstrap4.css'
import '@syncfusion/ej2-buttons/styles/bootstrap4.css'
import '@syncfusion/ej2-react-inputs/styles/bootstrap4.css'
import '@syncfusion/ej2-react-dropdowns/styles/bootstrap4.css'
import '@syncfusion/ej2-react-calendars/styles/bootstrap4.css'

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base'
import skCulture from '@syncfusion/ej2-locale/src/sk.json'
import skCaGregorian from 'cldr-data/main/sk/ca-gregorian.json'
import skNumbers from 'cldr-data/main/sk/numbers.json'
import skTimeZoneNames from 'cldr-data/main/sk/timeZoneNames.json'
import skNumberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import skWeekData from 'cldr-data/supplemental/weekData.json'
import { configureAxios } from './config/axios'

configureAxios()

loadCldr(skNumberingSystems, skCaGregorian, skNumbers, skTimeZoneNames, skWeekData)
L10n.load(skCulture)
setCulture('sk')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
