import skTranslations from '../translations/sk/translations.json'

type Dictionary = { [key: string]: string };

/**
 * Minimal static service for localizing application.
 * If this is not satisfactory, take a look at this complex framework https://www.i18next.com/
 */
export class Localizer {
  private static translations: Dictionary = skTranslations;

  public static localize(text: string): string {    
    if (Localizer.translations === undefined) {
      return text;
    }

    return Localizer.translations[text] ?? text;
  }

  public static localizeR(text: string, ...replacements: string[]): string {
    text = Localizer.localize(text);

    if (replacements.length > 0) {
      for (const key in replacements) {
        text = text.replace(new RegExp("\\{" + key + "\\}", "gi"), replacements[key]);
      }
    }

    return text;
  }

  public static localizePlural(text: string, count: number): string {
    if (Localizer.translations === undefined) {
      return text;
    }

    for (let i = count; i >= 0; i--) {
      const possibleTranslation = Localizer.translations[`${text}_${i}`];

      if (possibleTranslation) {
        return possibleTranslation;
      }
    }

    return Localizer.translations[text] ?? text;
  }
}