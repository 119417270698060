import { Line, LineNumberExtended } from '../models/LineModels'
import { ADD_NOTIFICATION } from '../contexts/NotificationsReducer'
import { Localizer } from '../utils/localizer'
import { NotificationsContext, NotificationType } from '../contexts/NotificationsContext'
import useAxios from 'axios-hooks'
import { useContext, useEffect } from 'react'
import { UrlProvider } from '../to-refactor/UrlProvider'

export const useJdfLineApi = (jdfDataId: string, lineNumber: LineNumberExtended) => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const [{ data, loading, error }, fetchLine] = useAxios<Line>(
    { url: UrlProvider.Api.Components.Lines.Viewer.getUrl(jdfDataId, lineNumber) },
    { manual: true }
  )

  useEffect(() => {
    if (error) {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Line could not be loaded'),
          type: NotificationType.Error,
          disableAutoDismiss: true,
        },
      })
    }
  }, [error])

  return {
    line: data,
    isLoading: loading,
    error,
    fetchLine,
  }
}