import React, { FC } from 'react'
import RegisterForm from '../auth/RegisterForm'

const RegisterPage: FC = () => {
  return (
    <RegisterForm />
  )
}

export default RegisterPage
