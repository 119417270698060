import { UrlProvider } from '../../../../to-refactor/UrlProvider'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import { Localizer } from '../../../../utils/localizer'
import {NotificationsContext, NotificationType} from '../../../../contexts/NotificationsContext'
import { LineNumberExtended } from '../../../../models/LineModels'
import useAxios from 'axios-hooks'
import {useContext} from "react";

const useJdfLineDelete = (
  jdfDataId: string,
  lineNumber: LineNumberExtended,
  onDelete: () => void
) => {
  const {dispatch: notificationsDispatch} = useContext(NotificationsContext)

  const [{ loading, error }, deleteLineAxios] = useAxios(
    { method: 'DELETE' },
    { manual: true }
  )

  const deleteLine = async () => {
    try {
      await deleteLineAxios({
        url: UrlProvider.Api.JdfData.Lines.getUrl(jdfDataId, lineNumber),
      })
      
      onDelete()
      
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Line was deleted'),
          type: NotificationType.Success,
        },
      })
    } catch {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while deleting line'),
          type: NotificationType.Error,
        },
      })
    }
  }

  return {
    deleteLine,
    isDeleting: loading,
    deleteFailed: !!error
  }
}

export default useJdfLineDelete
