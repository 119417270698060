import React, { createRef, MouseEvent, useEffect, useState } from 'react'
import { Localizer } from '../../../utils/localizer'
import { List } from 'immutable'
import axios from 'axios'
import { Logger } from '../../../utils/logger'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Station } from '../../../models/Crws'
import { InputWithKeyActions } from '../../general/InputWithKeyActions'
import { Preloader } from '../../../to-refactor/LoadingHelpers'

interface Props {
  onChange?: (newStation: AutocompleteStation) => void
  onEscPressed?: () => void
  className?: string
  id?: string
}

export interface AutocompleteStation {
  crwsId: string
  stationNumber?: number
  name: string
}

export function StationSelectAutocomplete(props: Props): JSX.Element {
  const [selectedStation, setSelectedStationPartial] = useState<Station>()

  const inputRef = createRef<HTMLInputElement>()
  const [autocompleteItems, setAutocompleteItems] = useState<List<Station>>(List([]))
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState<boolean>(false)
  const [selectedItemInAutocomplete, setSelectedItemInAutocomplete] = useState<number>(0)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [loadStationsTimeout, setLoadStationsTimeout] = useState<NodeJS.Timeout>()
  const apiRequestTypingDelay = 500

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }, [])

  function selectStationByEnterPressed(): void {
    selectStation(selectedItemInAutocomplete)
  }

  function selectStationByClick(event: MouseEvent<HTMLDivElement>, stationIndex: number): void {
    selectStation(stationIndex)

    event.preventDefault()
  }

  function selectStation(stationIndex: number): void {
    const newStation = autocompleteItems.get(stationIndex)

    if (!newStation) {
      return
    }

    setSelectedStationPartial(newStation)
    setIsAutocompleteOpen(false)

    if (inputRef.current) {
      inputRef.current.value = newStation.name
      inputRef.current.blur()
    }

    if (props.onChange)
      props.onChange({
        crwsId: newStation.id,
        name: newStation.name,
        stationNumber: undefined,
      })
  }

  function loadStations(mask: string): void {
    if (!mask) {
      return
    }

    setIsLoading(true)
    setIsError(false)

    axios
      .get<Station[]>(UrlProvider.Api.Crws.Stations.getUrl(mask))
      .then((_) => {
        setAutocompleteItems(List(_.data))
        setSelectedItemInAutocomplete(0)
      })
      .catch((error) => handleError(error))
      .finally(() => setIsLoading(false))
  }

  function onInputChange(stationSearchMask: string): void {
    if (loadStationsTimeout) {
      clearTimeout(loadStationsTimeout)
    }

    setLoadStationsTimeout(() => {
      return setTimeout(() => {
        loadStations(stationSearchMask)
      }, apiRequestTypingDelay)
    })
  }

  function selectLowerInAutocomplete(): void {
    setSelectedItemInAutocomplete((_) => {
      return (_ + 1) % autocompleteItems.size
    })
  }

  function selectUpperInAutocomplete(): void {
    setSelectedItemInAutocomplete((_) => {
      return (_ - 1 + autocompleteItems.size) % autocompleteItems.size
    })
  }

  function handleError(error: any): void {
    setIsError(true)
    setAutocompleteItems(List())

    Logger.logError(error)
  }

  function render(): JSX.Element {
    return (
      <div id={props.id} className={props.className ?? ''}>
        <div className={'input-group input-group-sm'}>
          <InputWithKeyActions
            ref={inputRef}
            placeholder={`${Localizer.localize('Start typing and select item from the list')}...`}
            className={'form-control'}
            onChange={(event) => onInputChange(event.currentTarget.value)}
            onArrowDown={selectLowerInAutocomplete}
            onArrowUp={selectUpperInAutocomplete}
            onEnterPressed={selectStationByEnterPressed}
            onEscPressed={props.onEscPressed ?? (() => {})}
            onFocus={() => setIsAutocompleteOpen(true)}
            onBlur={() => setIsAutocompleteOpen(false)}
          />
          {!isAutocompleteOpen ? null : (
            <div className={'autocomplete-items text-left'}>
              {isLoading && (
                <>
                  <Preloader centered small />
                  {autocompleteItems.size === 0 && (
                    <div className={'list-group-item list-group-item-action'}>&nbsp;</div>
                  )}
                </>
              )}

              {autocompleteItems.size === 0 && inputRef.current?.value && (
                <div className={'list-group-item list-group-item-action'}>
                  {Localizer.localize('No items')}
                </div>
              )}

              {isError && (
                <div className={'list-group-item list-group-item-action'}>
                  {Localizer.localize('An error happened somewhere...')}
                </div>
              )}

              {autocompleteItems.map((_, index) => (
                <div
                  key={_.id}
                  className={'list-group-item list-group-item-action'}
                  onMouseDown={(event) => selectStationByClick(event, index)}
                  style={{ cursor: 'pointer' }}
                >
                  {index === selectedItemInAutocomplete ? <b>{_.name}</b> : _.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  return render()
}
