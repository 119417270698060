import React, { FC, useContext, useEffect } from 'react'
import { JdfContext } from '../../contexts/JdfContext'
import { LoadingFailed } from '../../to-refactor/LoadingHelpers'
import { ProvidersViewer } from '../jdf/provider/ProvidersViewer'
import { SET_LAYOUT_WIDTH } from '../../contexts/LayoutReducer'
import { LayoutContext, LayoutWidth } from '../../contexts/LayoutContext'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { navigationWithJdf } from '../guide/content/guideContent'

const ProvidersPage: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: layoutDispatch } = useContext(LayoutContext)
  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Fixed })
  }, [layoutDispatch])

  if (!jdfState.jdfDataId) {
    return <LoadingFailed />
  }

  return (
    <div className={'px-2 pb-3'}>
      <ProvidersViewer jdfDataId={jdfState.jdfDataId} />
    </div>
  )
}

export default ProvidersPage
