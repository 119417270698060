import { LineNumberExtended } from '../models/LineModels'

export class LineService {
  public static getLineNumberFormatted(lineNumber: LineNumberExtended): string {
    return `${lineNumber.lineNumber}${
      !lineNumber.isLineNumberUnique && lineNumber.lineNumberExtension
        ? `(${lineNumber.lineNumberExtension})`
        : ''
    }`
  }

  public static isLineNumberExtensionNecessary(lineNumber: LineNumberExtended): boolean {
    return !lineNumber.isLineNumberUnique && !!lineNumber.lineNumberExtension
  }
}