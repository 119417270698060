import { GridLocaleText } from '@mui/x-data-grid/models/api'
import { Localizer } from './localizer'

export const getMuiGridTranslations = (): Partial<GridLocaleText> => ({
  noResultsOverlayLabel: Localizer.localize('No results found'),
  columnHeaderSortIconLabel: Localizer.localize('Sort'),
  columnMenuSortAsc: Localizer.localize('Sort by ASC'),
  columnMenuSortDesc: Localizer.localize('Sort by DESC'),
  columnMenuUnsort: Localizer.localize('Unsort'),
  columnMenuFilter: Localizer.localize('Filter'),
  columnMenuHideColumn: Localizer.localize('Hide column'),
  columnMenuShowColumns: Localizer.localize('Show columns'),
  filterPanelColumns: Localizer.localize('Columns'),
  filterPanelOperators: Localizer.localize('Operator'),
  filterPanelInputLabel: Localizer.localize('Value'),
  filterPanelInputPlaceholder: Localizer.localize('Filter value'),
  filterPanelDeleteIconLabel: Localizer.localize('Delete'),
  filterOperatorContains: Localizer.localize('Contains').toLowerCase(),
  filterOperatorEquals: Localizer.localize('Equals').toLowerCase(),
  filterOperatorStartsWith: Localizer.localize('Starts with').toLowerCase(),
  filterOperatorEndsWith: Localizer.localize('Ends with').toLowerCase(),
  filterOperatorIs: Localizer.localize('Is').toLowerCase(),
  filterOperatorIsEmpty: Localizer.localize('Is empty').toLowerCase(),
  filterOperatorIsNotEmpty: Localizer.localize('Is not empty').toLowerCase(),
  filterOperatorIsAnyOf: Localizer.localize('Is any of').toLowerCase(),
  filterValueAny: Localizer.localize('Any').toLowerCase(),
  filterValueTrue: Localizer.localize('True').toLowerCase(),
  filterValueFalse: Localizer.localize('False').toLowerCase(),
  columnsPanelShowAllButton: Localizer.localize('Show all'),
  columnsPanelHideAllButton: Localizer.localize('Hide all'),
  columnsPanelTextFieldPlaceholder: Localizer.localize('Column title'),
  columnsPanelTextFieldLabel: Localizer.localize('Find column'),
  MuiTablePagination: {
    labelRowsPerPage: Localizer.localize('Rows per page') + ':',
    labelDisplayedRows: ({ from, to, count}) =>
      `${from} - ${to} ${Localizer.localize('Of').toLowerCase()} ${count}`,
  },
  noRowsLabel: Localizer.localize('No rows'),
  booleanCellTrueLabel: Localizer.localize('Yes'),
  booleanCellFalseLabel: Localizer.localize('No'),
  footerRowSelected: count => `${count} ${Localizer.localizePlural('rows selected', count)}`,
})
