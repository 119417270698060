import React, { createRef, useEffect, useState } from 'react'
import { Localizer } from '../../../../utils/localizer'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { TextNote } from './ConnectionTextNotesEditor'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { InputSize, InputWithKeyActions } from '../../../general/InputWithKeyActions'
import { TimeCodeMark, TTTimeCodesService } from '../../../../utils/ttTimeCodesService'
import { Code } from '../../../../models/LineModels'
import { TTTimeCode } from '../../../general/TTTimeCode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { guideContent } from '../../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'

interface Props {
  note?: TextNote
  onSave: (note: TextNote) => void
  onCancel: () => void
}

interface State {
  note: EditableTextNote
  noteError?: string
  typeError?: string
}

interface EditableTextNote {
  id?: string
  timeCode: Code
  text?: string
}

/**
 * Editor for concrete text note of connection
 * @param props
 */
export function ConnectionTextNoteEditor(props: Props): JSX.Element {
  const [state, setState] = useState<State>({
    note: noteToEditableNote(props.note),
  })
  const inputRef = createRef<HTMLInputElement>()

  useEffect(() => {
    inputRef?.current?.select()
  }, [])

  useAppGuide([
    guideContent.connections.editor.notes.textNotes.typeDropdown,
    guideContent.connections.editor.notes.textNotes.confirmChanges,
    guideContent.connections.editor.notes.textNotes.cancelChanges,
  ])

  function save(): void {
    let noteError: string | undefined = undefined
    let typeError: string | undefined = undefined

    if (!state.note.timeCode.mark) typeError = Localizer.localize('Note time code is not selected.')

    if (!state.note.text) noteError = Localizer.localize('Note text is empty.')

    if (noteError || typeError) {
      setState((_) => {
        return {
          ..._,
          noteError: noteError,
          typeError: typeError,
        }
      })

      return
    }

    const saveNote: TextNote = {
      id: state.note.id,
      mark: state.note.timeCode.mark as TimeCodeMark,
      text: state.note.text,
    }
    props.onSave(saveNote)
  }

  function noteToEditableNote(note: TextNote | undefined): EditableTextNote {
    if (!note) note = { id: undefined, text: undefined, mark: 'p' }

    return {
      ...note,
      timeCode: {
        mark: note.mark,
        description: TTTimeCodesService.getCodeDescription(note.mark, 'connection'),
      },
    }
  }

  /**
   * Change marker of text note that is being edited
   * @param newMark
   */
  function changeTypeOfEditingTextNote(newMark: TimeCodeMark): void {
    setState((_) => {
      return {
        ..._,
        note: {
          ..._.note,
          timeCode: {
            mark: newMark,
            description: TTTimeCodesService.getCodeDescription(newMark, 'connection'),
          },
        },
        typeError: newMark ? '' : Localizer.localize('Note time code is not selected.'),
      }
    })
  }

  function render(): JSX.Element {
    return (
      <div className='inline-editor px-3 py-3'>
        <div className='d-flex align-items-center mb-3'>
          <UncontrolledDropdown>
            <DropdownToggle
              caret
              size='sm'
              id={guideContent.connections.editor.notes.textNotes.typeDropdown.target}
              className={'button button-mini button-rounded button-light m-0 mr-2'}
            >
              {state.note.timeCode.mark ? (
                <TTTimeCode
                  mark={state.note.timeCode.mark}
                  wrapperClassName={'mr-1'}
                  disableTooltip
                  wrapperStyle={{textTransform: 'none'}}
                />
              ) : (
                Localizer.localize('Choose type')
              )}
            </DropdownToggle>

            <DropdownMenu>
              {TTTimeCodesService.getAllTimeCodes('connection').map((_) => (
                <DropdownItem
                  onClick={() => changeTypeOfEditingTextNote(_.mark as TimeCodeMark)}
                  key={_.mark}
                  className='d-flex align-items-center pl-2'
                >
                  <div className='d-flex justify-content-center mr-2' style={{ width: '2em' }}>
                    <TTTimeCode mark={_.mark} disableTooltip />
                  </div>
                  <span className='small d-flex'>{_.description}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <div className='d-flex flex-column'>
            <i className='small'>{state.note.timeCode.description}</i>
            <span className='small text-danger'>{state.typeError}</span>
          </div>
        </div>

        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <InputWithKeyActions
              defaultValue={state.note.text ?? ''}
              ref={inputRef}
              onChange={(event) => {
                const value = event.target.value
                setState((_) => {
                  return {
                    ..._,
                    note: { ..._.note, text: value },
                    noteError: value ? '' : Localizer.localize('Note text is empty.'),
                  }
                })
              }}
              onEnterPressed={save}
              onEscPressed={props.onCancel}
              className={'mr-1'}
              size={InputSize.Small}
              placeholder={`${Localizer.localize('Note text')}...`}
            />
            <Button
              id={guideContent.connections.editor.notes.textNotes.confirmChanges.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              onClick={save}
              className={'m-0 mr-1'}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              id={guideContent.connections.editor.notes.textNotes.cancelChanges.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              color={ButtonColor.Danger}
              onClick={props.onCancel}
              className={'m-0'}
            >
              <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
              {Localizer.localize('Cancel')}
            </Button>
          </div>

          <span className='small text-danger'>{state.noteError}</span>
        </div>
      </div>
    )
  }

  return render()
}