import React, { FC, useContext } from 'react'
import uploadIcon from '../../assets/pages/home/upload.svg'
import { JDFUploader } from '../jdf/JdfUploader'
import { JdfContext } from '../../contexts/JdfContext'
import { Localizer } from '../../utils/localizer'
import { Button, ButtonColor, ButtonSize, useAppGuide } from '@inprop/tt-ui-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { useHistory } from 'react-router-dom'
import { appUrls } from '../../utils/urls'
import { JdfKonExitCode } from '../../models/protocol'
import { faSadTear } from '@fortawesome/free-regular-svg-icons/faSadTear'
import { navigationNoJdf, navigationWithJdf } from '../guide/content/guideContent'

const HomePage: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const history = useHistory()
  useAppGuide(jdfState.jdfDataId ? navigationWithJdf : navigationNoJdf)

  const startEditingJdfData = () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data ID must be set when redirecting to JDF lines page.')
    }

    history.push(appUrls.jdf.lines.withParams({ jdfDataId: jdfState.jdfDataId }))
  }

  const goToProtocol = () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data ID must be set when redirecting to JDF lines page.')
    }

    history.push(appUrls.jdf.protocol.withParams({ jdfDataId: jdfState.jdfDataId }))
  }

  return (
    <>
      <div className='row d-flex align-items-center flex-wrap my-5'>
        <div className='col-12 col-md-6 d-flex justify-content-center order-1 order-md-0'>
          <img src={uploadIcon} alt='Upload' className='w-75 my-2' />
        </div>

        <div className='col-12 col-md-6 d-flex flex-column order-0 order-md-1'>
          {jdfState.jdfDataId &&
            (jdfState.jdfKonExitCode === JdfKonExitCode.Ok ||
              jdfState.jdfKonExitCode === JdfKonExitCode.Warn) && (
              <>
                <div
                  className={'d-flex flex-column mb-4 jumbotron h5 bg-light py-4'}
                  style={{ borderLeft: '4px solid #1abc9c' }}
                >
                  <div className={'d-flex align-items-center mb-4'}>
                    <FontAwesomeIcon icon={faCheck} className={'m-0 mr-4 h2'} />
                    <strong>
                      {Localizer.localize(
                        'You have your JDF data loaded yet. You can start editing them.'
                      )}
                    </strong>
                  </div>
                  <div className={'d-flex justify-content-end'}>
                    <Button className={'m-0'} onClick={startEditingJdfData}>
                      {Localizer.localize('Start editing')}
                    </Button>
                  </div>
                </div>

                <div className={'mx-1 d-flex align-items-center mb-4 flex-nowrap'}>
                  <div className={'border-bottom flex-grow-1'} style={{ height: 0 }} />
                  <div className={'mx-2'}>{Localizer.localize('or load other data')}</div>
                  <div className={'border-bottom flex-grow-1'} style={{ height: 0 }} />
                </div>
              </>
            )}

          {jdfState.jdfDataId &&
            (jdfState.jdfKonExitCode === JdfKonExitCode.Error ||
              jdfState.jdfKonExitCode === JdfKonExitCode.FatalError) && (
              <>
                <div
                  className={'d-flex flex-column mb-4 jumbotron h5 bg-light py-4'}
                  style={{ borderLeft: '4px solid #eb4d4b' }}
                >
                  <div className={'d-flex align-items-center mb-4'}>
                    <FontAwesomeIcon icon={faSadTear} className={'m-0 mr-4 h2'} />
                    <strong>
                      {Localizer.localize('Unfortunately, your data cannot be used.')}
                    </strong>
                  </div>
                  <div className={'d-flex justify-content-end'}>
                    <Button
                      className={'m-0'}
                      onClick={goToProtocol}
                      color={ButtonColor.Danger}
                      size={ButtonSize.Small}
                    >
                      {Localizer.localize('View protocol')}
                    </Button>
                  </div>
                </div>

                <div className={'mx-1 d-flex align-items-center mb-4 flex-nowrap'}>
                  <div className={'border-bottom flex-grow-1'} style={{ height: 0 }} />
                  <div className={'mx-2'}>{Localizer.localize('please, load other data')}</div>
                  <div className={'border-bottom flex-grow-1'} style={{ height: 0 }} />
                </div>
              </>
            )}

          <div className={'d-flex'}>
            <JDFUploader />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage