import { Map } from 'immutable';

export class HtmlIdProvider {
  private static counterMap = Map<number>({});

  /**
   * Generate new unique ID for HTML element.
   * @param idBase Base string for generated ID
   */
  public static getNewId(idBase: string): string {
    if (!this.counterMap.has(idBase)) {
      this.counterMap = this.counterMap.set(idBase, 0);
    }

    const currentIdBaseCount = this.counterMap.get(idBase) ?? 0;
    const newId = `${idBase}-${currentIdBaseCount + 1}`;
    this.counterMap = this.counterMap.set(idBase, currentIdBaseCount + 1);

    return newId;
  }
}