import React, { createRef, useContext, useEffect, useState } from 'react'
import { LineNumberExtended } from '../../../models/LineModels'
import axios from 'axios'
import { Logger } from '../../../utils/logger'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { LoadingFailed, LoadingState } from '../../../to-refactor/LoadingHelpers'
import { Localizer } from '../../../utils/localizer'
import {
  AppGuideContext,
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Preloader,
  useAppGuide,
} from '@inprop/tt-ui-elements'
import { InputSize, InputWithKeyActions } from '../../general/InputWithKeyActions'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import cx from 'classnames'
import { JdfLineEditorContext, LineEditorSaveFn } from '../../../contexts/JdfLineEditorContext'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  setSaveFn: (saveFn: LineEditorSaveFn) => void
}

interface State {
  lineNotes: LineNote[]
  showEditor: boolean
  editingNoteIndex?: number
  editingNoteText?: string
}

interface LineNote {
  text?: string
  noteNumber: string
}

export function LineNotesEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfLineEditorContext)
  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  const [state, setState] = useState<State>({
    lineNotes: [],
    editingNoteIndex: undefined,
    showEditor: false,
  })
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const editorNoteInputRef = createRef<HTMLInputElement>()

  useAppGuide(
    [guideContent.lines.editor.notes.editNote, guideContent.lines.editor.notes.deleteNote],
    state.lineNotes.length > 0 && isGuideVisible,
    'append'
  )
  useAppGuide([guideContent.lines.editor.notes.add], !state.showEditor)
  useAppGuide(
    [guideContent.lines.editor.notes.confirmChange, guideContent.lines.editor.notes.cancelChange],
    state.showEditor
  )

  useEffect(() => {
    loadNotes()
  }, [])

  useEffect(() => {
    if (props.setSaveFn) props.setSaveFn(saveLineNotes)
  }, [state.lineNotes])

  useEffect(() => {
    if (state.showEditor) editorNoteInputRef.current?.select()
  }, [state.showEditor])

  function loadNotes(): void {
    setLoadingState(LoadingState.Loading)

    axios
      .get<LineNote[]>(
        UrlProvider.Api.Components.Lines.NotesEditor.getUrl(props.dataId, props.lineNumber)
      )
      .then((result) => {
        setState((_) => {
          return { ..._, lineNotes: result.data }
        })

        setLoadingState(LoadingState.LoadingSucceeded)
      })
      .catch((error) => {
        setLoadingState(LoadingState.LoadingFailed)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line notes could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleError(error)
      })
  }

  function saveEditingNote(): void {
    editorDispatch.setHasUnsavedChanges(true)

    if (state.editingNoteIndex || state.editingNoteIndex === 0) {
      setState((previousState) => {
        return {
          ...previousState,
          lineNotes: previousState.lineNotes.map((_, index) => {
            if (index === previousState.editingNoteIndex)
              return {
                ...previousState.lineNotes[previousState.editingNoteIndex],
                text: previousState.editingNoteText,
              }
            else return _
          }),
          editingNoteIndex: undefined,
          editingNoteText: undefined,
          showEditor: false,
        }
      })

      return
    }

    setState((_) => {
      const newNotes = _.lineNotes.slice(0, _.lineNotes.length)
      newNotes.push({
        text: _.editingNoteText,
        noteNumber: _.lineNotes.length.toString(),
      })

      return {
        ..._,
        showEditor: false,
        editingNoteIndex: undefined,
        editingNoteText: undefined,
        lineNotes: newNotes,
      }
    })
  }

  function cancelEditing(): void {
    setState((_) => {
      return { ..._, showEditor: false, editingNoteIndex: undefined, editingNoteText: undefined }
    })
  }

  function deleteTextNote(index: number): void {
    editorDispatch.setHasUnsavedChanges(true)

    const newNotes = state.lineNotes.slice(0, state.lineNotes.length)
    newNotes.splice(index, 1)

    setState((_) => {
      return { ..._, lineNotes: newNotes }
    })
  }

  const saveLineNotes = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Lines.NotesEditor.getUrl(props.dataId, props.lineNumber),
        state.lineNotes
      )
      .then((_) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line notes were saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving line notes'),
            type: NotificationType.Error,
          },
        })

        handleError(error)
      })

  function showEditorForNewNote(): void {
    setState((_) => {
      return { ..._, showEditor: true, editingNoteIndex: undefined }
    })
  }

  function showEditorForExistingTextNote(index: number): void {
    setState((_) => {
      return {
        ..._,
        showEditor: true,
        editingNoteIndex: index,
        editingNoteText: _.lineNotes[index].text,
      }
    })
  }

  function handleError(error: any): void {
    Logger.logError(
      `Error while loading notes to ${LineNotesEditor.name}. Message: ${error.toString()}`
    )
  }

  return (
    <div className={'row'}>
      <div className={cx('col-12', { 'text-center py-5': loadingState === LoadingState.Loading })}>
        {loadingState === LoadingState.Loading ? (
          <Preloader overlay={false} />
        ) : loadingState === LoadingState.LoadingFailed ? (
          <LoadingFailed tryAgainFn={loadNotes} />
        ) : (
          <>
            <h4 className='mb-2'>{Localizer.localize('Text notes')}</h4>
            {state.lineNotes.length === 0 ? (
              <p className={'mb-2'}>{Localizer.localize('No notes')}</p>
            ) : (
              <ul className='list-unstyled mb-2'>
                {state.lineNotes.map((_, index) => (
                  <li className='d-flex align-items-center mb-2 display-child-on-hover' key={index}>
                    <span className='mr-2'>{_.text}</span>
                    <div
                      className={cx('button-group', {
                        'display-on-parent-hover': !(index === 0 && isGuideVisible),
                      })}
                    >
                      <Button
                        id={index === 0 ? guideContent.lines.editor.notes.editNote.target : ''}
                        size={ButtonSize.ExtraSmall}
                        shape={ButtonShape.Rounded}
                        color={ButtonColor.Light}
                        className={'m-0'}
                        onClick={() => showEditorForExistingTextNote(index)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                      <Button
                        id={index === 0 ? guideContent.lines.editor.notes.deleteNote.target : ''}
                        size={ButtonSize.ExtraSmall}
                        shape={ButtonShape.Rounded}
                        color={ButtonColor.Light}
                        className={'m-0'}
                        onClick={() => deleteTextNote(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {state.showEditor ? (
              <div className='inline-editor px-3 py-3'>
                <InputWithKeyActions
                  ref={editorNoteInputRef}
                  className={'w-100 mb-2'}
                  size={InputSize.Small}
                  placeholder={`${Localizer.localize('Note text')} ...`}
                  defaultValue={state.editingNoteText}
                  onChange={(event) => {
                    const newValue = event.target.value

                    setState((_) => {
                      return { ..._, editingNoteText: newValue }
                    })
                  }}
                  onEscPressed={cancelEditing}
                  onEnterPressed={saveEditingNote}
                />

                <div className={'w-100 d-flex justify-content-end'}>
                  <Button
                    id={guideContent.lines.editor.notes.confirmChange.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    className={'m-0 mr-1'}
                    onClick={saveEditingNote}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    id={guideContent.lines.editor.notes.cancelChange.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Danger}
                    className={'m-0'}
                    onClick={cancelEditing}
                  >
                    <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                    {Localizer.localize('Cancel')}
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                id={guideContent.lines.editor.notes.add.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                className={'ml-0'}
                onClick={showEditorForNewNote}
              >
                <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                {Localizer.localize('Add new line note')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}