import React, { FC } from 'react'
import { Tooltip } from '@mui/material'
import styles from './NegativeMark.module.scss'

export interface Props {
  description: string
  mark: string
  withTooltip?: boolean
}

const NegativeMark: FC<Props> = (props) => {
  if (!props.withTooltip) {
    return <span className={styles.negativeMark}>{props.mark}</span>
  }

  return (
    <Tooltip title={props.description}>
      <span className={styles.negativeMark}>{props.mark}</span>
    </Tooltip>
  )
}

NegativeMark.defaultProps = {
  withTooltip: true,
}

export default NegativeMark
