import * as React from 'react'
import { useEffect, useState } from 'react'
import { TTFixedCode } from '../../../general/TTFixedCode'
import { HtmlIdProvider } from '../../../../utils/htmlIdProvider'
import { List } from 'immutable'

interface Props {
  fixedCodes: FixedCodeStatus[]
  onChangeFunction: (fixedCode: string, action: 'set' | 'unset') => void
  class?: string
}

export interface FixedCodeStatus {
  fixedCode: string
  isSelected: boolean
  isDisabled: boolean
  whyDisabled?: string
}

export function ConnectionStopFixedCodesEditor(props: Props): JSX.Element {
  const [buttonIds, setButtonIds] = useState<List<string>>(List<string>())

  useEffect(() => {
    let newButtonsIds = List<string>([])
    for (let i = 0; i < props.fixedCodes.length; i++) {
      newButtonsIds = newButtonsIds.push(HtmlIdProvider.getNewId('button-fixed-code'))
    }

    setButtonIds(newButtonsIds)
  }, [])

  return (
    <div className={`btn-group btn-group-xs ${props.class}`}>
      {props.fixedCodes.map((_, index) => (
        <button
          type='button'
          id={buttonIds.get(index)}
          key={_.fixedCode}
          className={`btn btn-outline-secondary ${_.isSelected ? 'active' : ''}`}
          onClick={() => {
            props.onChangeFunction(_.fixedCode, _.isSelected ? 'unset' : 'set')
          }}
          disabled={_.isDisabled}
        >
          <TTFixedCode
            mark={_.fixedCode}
            relatedTo={'connectionStop'}
            tooltipTarget={buttonIds.get(index)}
          />
        </button>
      ))}
    </div>
  )
}