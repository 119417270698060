import React, { FC, useContext, useState } from 'react'
import { Button, ButtonColor, ButtonSize } from '@inprop/tt-ui-elements'
import { faSave } from '@fortawesome/free-regular-svg-icons/faSave'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Localizer } from '../../../utils/localizer'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import JdfSaveSettings from '../../jdf/JdfSaveSettings'
import { NavigationContext } from '../../../contexts/NavigationContext'
import {navigationGuide} from "../../guide/content/navigation";

const SaveJdfButton: FC = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const {
    state: { onNavigateElsewhere },
  } = useContext(NavigationContext)

  const onClick = () => {
    if (onNavigateElsewhere) {
      onNavigateElsewhere(() => setShowDialog(true))

      return
    }

    setShowDialog(true)
  }

  return (
    <>
      <Button
        id={navigationGuide.save.target}
        color={ButtonColor.TransparentDark}
        size={ButtonSize.Small}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faSave} className={'mr-2'} /> {Localizer.localize('Save')}
      </Button>

      <Dialog open={showDialog} maxWidth={'md'} fullWidth onClose={() => setShowDialog(false)}>
        <DialogTitle>{Localizer.localize('Save JDF')}</DialogTitle>
        <DialogContent>
          <JdfSaveSettings onClose={() => setShowDialog(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SaveJdfButton
