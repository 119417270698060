export interface Line {
  lineUrl: string
  unknownLine: boolean
  lineNumber: LineNumberExtended
  allLineNumbers: LineNumberExtended[]
  id: string
  lineName: string
  provider: JdfLineProviderDto
  alternativeProviders: JdfLineAlternativeProviderDto[]
  validFrom: string
  validTo: string
  stations: Station[]
  connections: Connection[]
  tariffKilometersColumns: TariffKilometersColumn[]
  fixedCodes: Code[]
  negativeMarks: Code[]
  textTimeCodes: Code[]
  textRemarks: string[]
}

export interface LineNumberExtended {
  lineNumber: string
  lineNumberExtension: string
  isLineNumberUnique: boolean
}

export interface Connection {
  connectionNumber: number
  fixedCodes: Code[]
  negativeMark: Code
  textTimeCode: Code
  stops: ConnectionStop[]
}

export interface Code {
  mark: string
  description: string
}

export interface ConnectionStop {
  lineStop: LineStop
  arrival: number
  departure: number
  timeString: string
  fixedCodes: Code[]
}

export interface NegativeMark {
  oznacenie: string
  popis: string
}

export interface Station {
  id: string
  tariffNumber: number
  name: string
  fixedCodes: Code[]
  isUnknown: boolean
}

export interface TariffKilometersColumn {
  startingLineStop: LineStop
  kilometers: number[]
}

export interface LineStop {
  tarCislo: number
  nazov: string
  fixedCodes: Code[]
}

export interface JdfLineProviderDto {
  name: string
  isUnknown: boolean
  usedInConnections: string
}

export type JdfLineAlternativeProviderDto = JdfLineProviderDto & {
  timeRestrictionNote: string
}

export enum TTChars {
  ConnectionGoThroughStop = '|',
  ConnectionBypassStop = '<',
}
