export const navigationGuide = {
  logo: {
    target: 'logo',
    text: 'Aplikácia na kontrolu dávok vo formáte JDF, v ktorom sa dodávajú dáta do celoštátneho informačného' +
      ' systému cestovných poriadkov spoločnosti INPROP. Okrem kontroly je umožnená aj zmena cestovných poriadkov a ich uloženie do novej JDF dávky.',
  },
  home: {
    target: 'home',
    text: 'Stránka na načítanie dát.',
  },
  map: {
    target: 'map',
    text: 'Stránka na zobrazenie zastávok na mape.',
  },
  lines: {
    target: 'lines',
    text: 'Stránka na zobrazenie zoznamu liniek.',
  },
  providers: {
    target: 'providers',
    text: 'Stránka na zobrazenie zoznamu dopravcov.',
  },
  protocol: {
    target: 'protocol',
    text: 'Stránka na zobrazenie protokolu načítanej dávky, kde sú uvedené drobné chyby napr. o počte neznámych' +
      ' zastávok, ale aj kritické chyby, ktoré neumožňujú ďalšiu prácu s dávkou.',
  },
  save: {
    target: 'save-jdf',
    text: 'Stránka na uloženie dát.'
  }
}