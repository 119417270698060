import React, { FC, useContext } from 'react'
import cx from 'classnames'
import Navigation from './navigation/Navigation'
import Footer from './Footer'
import NotificationStack from './NotificationStack'
import JdfLoaderFromCookies from '../jdf/JdfLoaderFromCookies'
import { LayoutContext, LayoutHeight, LayoutWidth } from '../../contexts/LayoutContext'

import styles from './Layout.module.scss'
import { AppGuide } from '@inprop/tt-ui-elements'

const Layout: FC = (props) => {
  const { state: layoutContext } = useContext(LayoutContext)

  return (
    <div
      id='wrapper'
      className={cx('clearfix', styles.wrapper, {
        'vh-100 d-flex flex-column': layoutContext.height === LayoutHeight.Full,
      })}
    >
      <Navigation />
      <AppGuide />
      <JdfLoaderFromCookies />

      <section
        id='content'
        className={cx(styles.content, {
          'd-flex flex-grow-1': layoutContext.height === LayoutHeight.Full,
        })}
      >
        <div
          className={cx('content-wrap p-0', {
            'd-flex flex-grow-1 align-self-stretch': layoutContext.height === LayoutHeight.Full,
          })}
        >
          <div
            className={cx('clearfix', {
              'container-fluid p-0': layoutContext.width === LayoutWidth.Full,
              container: layoutContext.width !== LayoutWidth.Full,
              'd-flex flex-grow-1': layoutContext.height === LayoutHeight.Full,
            })}
          >
            {props.children}
          </div>
        </div>
      </section>

      {layoutContext.isWithFooter && <Footer />}

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-4'>
            <NotificationStack />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
