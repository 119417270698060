import React, { Fragment, ReactElement } from 'react'
import { Localizer } from '../../../utils/localizer'
import { Button, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { TTFixedCode } from '../../general/TTFixedCode'
import { HtmlIdProvider } from '../../../utils/htmlIdProvider'
import { Connection, Line, Station, TTChars } from '../../../models/LineModels'
import { ConnectionType } from './LineViewer'
import StationName from '../station/StationName'
import cx from 'classnames'
import { appUrls } from '../../../utils/urls'
import { useHistory } from 'react-router-dom'

import styles from './TimeTableSheet.module.scss'
import NegativeMark from '../NegativeMark'
import { guideContent } from '../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'

interface Props {
  line: Line
  activeConnectionType: ConnectionType
  openConnectionEditorFn: (connectionNumber: number) => void
  jdfDataId: string
  refreshTimeTableSheet: () => void
}

const TimeTableSheet: React.FunctionComponent<Props> = (props: Props) => {
  const history = useHistory()
  useAppGuide([guideContent.lines.connectionButton], props.line.connections.length > 0)

  const redirectToConnectionEditor = (connectionId: string) => {
    history.push(
      appUrls.jdf.connectionEdit.withParams({
        jdfDataId: props.jdfDataId,
        lineNumber: props.line.lineNumber.lineNumber,
        lineNumberExtension: props.line.lineNumber.lineNumberExtension,
        connectionId: connectionId,
      })
    )
  }

  function getPreRenderedTable(): ReactElement[][] {
    let preRenderedTable: ReactElement[][] = []

    preRenderedTable = preparePreRenderedTable(preRenderedTable)
    preRenderedTable = fillHeader(preRenderedTable)
    preRenderedTable = fillTariffKilometers(preRenderedTable)
    preRenderedTable = fillStationNames(preRenderedTable)
    preRenderedTable = fillTariffNumbers(preRenderedTable)

    const fillConnectionsResult = fillConnections(preRenderedTable)
    preRenderedTable = fillConnectionsResult.preRenderedTable

    preRenderedTable = fillEmptyTableCells(
      preRenderedTable,
      fillConnectionsResult.hiddenConnectionsCount
    )

    return preRenderedTable
  }

  function preparePreRenderedTable(preRenderedTable: ReactElement[][]): ReactElement[][] {
    const rowsCount = props.line.stations.length + 3

    for (let i = 0; i < rowsCount; i++) {
      preRenderedTable[i] = []
    }

    return preRenderedTable
  }

  function fillHeader(preRenderedTable: ReactElement[][]): ReactElement[][] {
    preRenderedTable[0][getPreRenderArrayColumnIndex('firstTariffKilometersColumn')] = (
      <th
        key={getPreRenderArrayColumnIndex('firstTariffKilometersColumn')}
        colSpan={props.line.tariffKilometersColumns.length}
      >
        {Localizer.localize('Km')}
      </th>
    )

    preRenderedTable[0][getPreRenderArrayColumnIndex('tariffNumber')] = (
      <th key={getPreRenderArrayColumnIndex('tariffNumber')}>{Localizer.localize('Tar. nr.')}</th>
    )

    preRenderedTable[0][getPreRenderArrayColumnIndex('stationName')] = (
      <th key={getPreRenderArrayColumnIndex('stationName')}>{Localizer.localize('Station')}</th>
    )

    return preRenderedTable
  }

  function fillTariffKilometers(preRenderedTable: ReactElement[][]): ReactElement[][] {
    const firstTariffKilometersColumn = getPreRenderArrayColumnIndex('firstTariffKilometersColumn')

    for (let i = 0; i < props.line.tariffKilometersColumns.length; i++) {
      const currentKilometersColumn = props.line.tariffKilometersColumns[i]
      const currentTableColumn = firstTariffKilometersColumn + i

      for (let j = 0; j < currentKilometersColumn.kilometers.length; j++) {
        const currentTableRow =
          getPreRenderArrayRowIndex('firstStationName') +
          (currentKilometersColumn.startingLineStop.tarCislo - 1) +
          j

        const tariffKilometers =
          currentKilometersColumn.kilometers[j] >= 0 ? (
            currentKilometersColumn.kilometers[j].toString()
          ) : (
            <TTFixedCode
              mark={TTChars.ConnectionBypassStop}
              relatedTo={'connectionStop'}
              wrapperClassName={'mx-1'}
            />
          )

        preRenderedTable[currentTableRow][currentTableColumn] = (
          <td key={currentTableColumn}>{tariffKilometers}</td>
        )
      }
    }

    return preRenderedTable
  }

  function fillStationNames(preRenderedTable: ReactElement[][]): ReactElement[][] {
    const columnIndex = getPreRenderArrayColumnIndex('stationName')

    for (let i = 0; i < props.line.stations.length; i++) {
      const currentRowIndex = getPreRenderArrayRowIndex('firstStationName') + i

      preRenderedTable[currentRowIndex][columnIndex] = (
        <td key={columnIndex} className={'px-1'}>
          <div className={'d-flex align-content-center justify-content-between text-nowrap'}>
            <StationName
              name={props.line.stations[i].name}
              stationId={props.line.stations[i].id}
              isStationUnknown={props.line.stations[i].isUnknown}
              jdfDataId={props.jdfDataId}
              onStationExchangeDone={() => props.refreshTimeTableSheet()}
              singleLineName={true}
              withGuide={i === 0}
            />

            {props.line.stations[i].fixedCodes.length === 0 ? null : (
              <div
                className={'d-flex align-items-center badge badge-dark ml-1 pl-2 pr-0'}
                style={{ margin: '1px' }}
              >
                {getStationFixedCodeElements(props.line.stations[i])}
              </div>
            )}
          </div>
        </td>
      )
    }

    return preRenderedTable
  }

  function fillTariffNumbers(preRenderedTable: ReactElement[][]): ReactElement[][] {
    const columnIndex = getPreRenderArrayColumnIndex('tariffNumber')

    for (let i = 0; i < props.line.stations.length; i++) {
      const currentRowIndex = getPreRenderArrayRowIndex('firstStationName') + i

      preRenderedTable[currentRowIndex][columnIndex] = (
        <td key={getPreRenderArrayColumnIndex('tariffNumber')}>
          {props.line.stations[i].tariffNumber.toString()}
        </td>
      )
    }

    return preRenderedTable
  }

  function fillConnections(preRenderedTable: ReactElement[][]): {
    preRenderedTable: ReactElement[][]
    hiddenConnectionsCount: number
  } {
    let hiddenConnectionsCount = 0
    let wasGuideButton = false

    for (let i = 0; i < props.line.connections.length; i++) {
      const currentConnection = props.line.connections[i]
      const currentConnectionNumber = currentConnection.connectionNumber

      if (!mayShowConnection(currentConnectionNumber)) {
        hiddenConnectionsCount++
        continue
      }

      const columnIndex =
        getPreRenderArrayColumnIndex('firstConnection') + i - hiddenConnectionsCount

      preRenderedTable = fillConnectionNumber(
        preRenderedTable,
        columnIndex,
        currentConnectionNumber,
        !wasGuideButton
      )
      preRenderedTable = fillFixedCodes(preRenderedTable, columnIndex, currentConnection)
      preRenderedTable = fillNegativeMark(preRenderedTable, columnIndex, currentConnection)
      preRenderedTable = fillConnectionStopDetails(preRenderedTable, columnIndex, currentConnection)

      wasGuideButton = true
    }

    return {
      preRenderedTable: preRenderedTable,
      hiddenConnectionsCount: hiddenConnectionsCount,
    }
  }

  function fillConnectionNumber(
    preRenderedTable: ReactElement[][],
    columnIndex: number,
    connectionNumber: number,
    withGuide: boolean
  ): ReactElement[][] {
    preRenderedTable[getPreRenderArrayRowIndex('connectionNumber')][columnIndex] = (
      <th key={columnIndex} colSpan={2}>
        <Button
          id={withGuide ? guideContent.lines.connectionButton.target : ''}
          size={ButtonSize.ExtraSmall}
          shape={ButtonShape.Rounded}
          className={'m-0'}
          onClick={() => redirectToConnectionEditor(connectionNumber.toString())}
        >
          {connectionNumber}
        </Button>
      </th>
    )

    return preRenderedTable
  }

  function fillFixedCodes(
    preRenderedTable: ReactElement[][],
    columnIndex: number,
    connection: Connection
  ): ReactElement[][] {
    preRenderedTable[getPreRenderArrayRowIndex('fixedCodes')][columnIndex] = (
      <td key={columnIndex} colSpan={2} style={{ width: '1px' }}>
        <div className={'d-flex flex-wrap justify-content-center my-1'}>
          {connection.fixedCodes?.map((code) => (
            <TTFixedCode
              mark={code.mark}
              key={code.mark}
              wrapperStyle={{ marginLeft: '0.05em', marginRight: '0.05em', lineHeight: '1.3em' }}
              relatedTo={'connection'}
            />
          ))}
        </div>
      </td>
    )

    return preRenderedTable
  }

  function fillNegativeMark(
    preRenderedTable: ReactElement[][],
    columnIndex: number,
    connection: Connection
  ): ReactElement[][] {
    preRenderedTable[getPreRenderArrayRowIndex('negativeMark')][columnIndex] = (
      <td key={columnIndex} colSpan={2}>
        {connection.negativeMark && <NegativeMark {...connection.negativeMark} />}
      </td>
    )

    return preRenderedTable
  }

  function fillConnectionStopDetails(
    preRenderedTable: ReactElement[][],
    columnIndex: number,
    connection: Connection
  ): ReactElement[][] {
    for (let j = 0; j < connection.stops.length; j++) {
      const currentConnectionStop = connection.stops[j]
      const currentTariffNumber = currentConnectionStop.lineStop.tarCislo
      const rowIndex = getPreRenderArrayRowIndex('firstStationName') + currentTariffNumber - 1

      let time = <span>{currentConnectionStop.timeString}</span>

      if (currentConnectionStop.timeString === TTChars.ConnectionGoThroughStop)
        time = <TTFixedCode mark={TTChars.ConnectionGoThroughStop} relatedTo={'connectionStop'} />
      else if (currentConnectionStop.timeString === TTChars.ConnectionBypassStop)
        time = <TTFixedCode mark={TTChars.ConnectionBypassStop} relatedTo={'connectionStop'} />

      preRenderedTable[rowIndex][columnIndex] = (
        <Fragment key={columnIndex}>
          <td className={'border-right-0'}>{time}</td>

          <td className={'p-0'}>
            {currentConnectionStop.fixedCodes?.length === 0 ? null : (
              <div className={'d-flex flex-nowrap'}>
                {currentConnectionStop.fixedCodes.map((_, index) => {
                  const fixedCodeWrapperId = HtmlIdProvider.getNewId('fixed-code')
                  return (
                    <span className='d-inline-flex mr-1' id={fixedCodeWrapperId} key={index}>
                      <TTFixedCode
                        mark={_.mark}
                        tooltipTarget={fixedCodeWrapperId}
                        relatedTo={'connectionStop'}
                      />
                    </span>
                  )
                })}
              </div>
            )}
          </td>
        </Fragment>
      )
    }

    return preRenderedTable
  }

  function fillEmptyTableCells(
    preRenderedTable: ReactElement[][],
    hiddenConnectionsCount: number
  ): ReactElement[][] {
    const firstConnectionColumnIndex = getPreRenderArrayColumnIndex('firstConnection')
    const connectionsCount = props.line.connections.length - hiddenConnectionsCount

    for (let i = 1; i < preRenderedTable.length; i++) {
      for (let j = 0; j < firstConnectionColumnIndex; j++) {
        preRenderedTable[i][j] = preRenderedTable[i][j] ?? <td key={j} />
      }
    }

    for (let i = 1; i < preRenderedTable.length; i++) {
      for (let j = 0; j < connectionsCount; j++) {
        const columnIndex = j + firstConnectionColumnIndex

        preRenderedTable[i][columnIndex] = preRenderedTable[i][columnIndex] ?? (
          <td key={columnIndex} colSpan={2} />
        )
      }
    }

    return preRenderedTable
  }

  function getStationFixedCodeElements(station: Station): JSX.Element {
    return (
      <>
        {station.fixedCodes.map((_, index) => {
          return (
            <div key={index}>
              <TTFixedCode
                mark={_.mark}
                wrapperClassName={'d-inline-flex mr-2'}
                relatedTo={'connectionStop'}
                customTooltipText={_.description}
                disableTooltip={!_.description}
              />
            </div>
          )
        })}
      </>
    )
  }

  function getPreRenderArrayRowIndex(
    indexFor: 'connectionNumber' | 'fixedCodes' | 'negativeMark' | 'firstStationName'
  ) {
    switch (indexFor) {
      case 'connectionNumber':
        return 0
      case 'fixedCodes':
        return 1
      case 'negativeMark':
        return 2
      case 'firstStationName':
        return 3
    }
  }

  function getPreRenderArrayColumnIndex(
    indexFor: 'firstTariffKilometersColumn' | 'tariffNumber' | 'stationName' | 'firstConnection'
  ): number {
    switch (indexFor) {
      case 'firstTariffKilometersColumn':
        return 0
      case 'tariffNumber':
        return props.line.tariffKilometersColumns.length
      case 'stationName':
        return props.line.tariffKilometersColumns.length + 1
      case 'firstConnection':
        return props.line.tariffKilometersColumns.length + 2
    }
  }

  function mayShowConnection(connectionNumber: number): boolean {
    return getConnectionsFilterFn(props.activeConnectionType)(connectionNumber)
  }

  function getConnectionsFilterFn(showConnectionWithType: ConnectionType): (_: number) => boolean {
    let connectionsFilterFn: (connectionNumber: number) => boolean

    switch (showConnectionWithType) {
      case ConnectionType.All:
        connectionsFilterFn = (connectionNumber) => true
        break
      case ConnectionType.Even:
        connectionsFilterFn = (connectionNumber) => connectionNumber % 2 === 0
        break
      case ConnectionType.Odd:
        connectionsFilterFn = (connectionNumber) => connectionNumber % 2 === 1
        break
    }

    return connectionsFilterFn
  }

  const preRenderedTable = getPreRenderedTable()

  return (
    <table
      className={cx(
        styles.lineTable,
        'table table-sm table-hover table-responsive table-borderless'
      )}
    >
      <thead>
        <tr>{preRenderedTable[0].map((cell) => cell)}</tr>
      </thead>
      <tbody>
        {preRenderedTable.slice(1, preRenderedTable.length).map((row, index) => (
          <tr key={index} className={'display-child-on-hover'}>
            {row.map((cell) => cell)}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TimeTableSheet
