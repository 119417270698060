import React, { useContext, useEffect, useState } from 'react'
import { LineNumberExtended } from '../../../models/LineModels'
import axios from 'axios'
import { Logger } from '../../../utils/logger'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { LoadingFailed, LoadingState } from '../../../to-refactor/LoadingHelpers'
import { Localizer } from '../../../utils/localizer'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { List } from 'immutable'
import { Button, ButtonColor, ButtonShape, ButtonSize, Preloader } from '@inprop/tt-ui-elements'
import '../../../utils/stringExtensions'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { JdfLineEditorContext, LineEditorSaveFn } from '../../../contexts/JdfLineEditorContext'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  setSaveFn: (saveFn: LineEditorSaveFn) => void
}

interface State {
  provider?: Provider
  selectedProviderInEditor?: Provider
  availableProviders: List<Provider>
  isEditorVisible: boolean
}

interface Provider {
  name: string
  companyId: string
  providerDistinction: string
}

interface ProviderApiModel {
  provider: Provider
  availableProviders: Provider[]
}

export function LineProviderEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfLineEditorContext)

  const [state, setState] = useState<State>({
    isEditorVisible: false,
    provider: undefined,
    availableProviders: List([]),
  })
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useAppGuide([guideContent.lines.editor.provider.changeProvider], !state.isEditorVisible)
  useAppGuide(
    [
      guideContent.lines.editor.provider.providerDropdown,
      guideContent.lines.editor.provider.confirmChange,
      guideContent.lines.editor.provider.cancelChange,
    ],
    state.isEditorVisible
  )

  useEffect(() => {
    loadProvider()
  }, [])

  useEffect(() => {
    if (props.setSaveFn) props.setSaveFn(saveProvider)
  }, [state.provider])

  function loadProvider(): void {
    setLoadingState(LoadingState.Loading)

    axios
      .get<ProviderApiModel>(
        UrlProvider.Api.Components.Lines.ProviderEditor.getUrl(props.dataId, props.lineNumber)
      )
      .then((result) => {
        setState((_) => {
          return {
            ..._,
            provider: result.data.provider,
            availableProviders: List(result.data.availableProviders),
          }
        })

        setLoadingState(LoadingState.LoadingSucceeded)
      })
      .catch((error) => {
        setLoadingState(LoadingState.LoadingFailed)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line provider could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleError(error)

        setState((_) => {
          return { ..._, isLoading: false }
        })
      })
  }

  function saveChangedProvider(): void {
    editorDispatch.setHasUnsavedChanges(true)

    setState((_) => {
      return {
        ..._,
        provider: _.selectedProviderInEditor,
        selectedProviderInEditor: undefined,
        isEditorVisible: false,
      }
    })
  }

  function cancelChangingProvider(): void {
    setState((_) => {
      return { ..._, selectedProviderInEditor: undefined, isEditorVisible: false }
    })
  }

  const saveProvider = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Lines.ProviderEditor.getUrl(props.dataId, props.lineNumber),
        state.provider
      )
      .then((_) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Line provider was saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving line provider'),
            type: NotificationType.Error,
          },
        })

        handleError(error)
      })

  function showEditor(): void {
    setState((_) => {
      return { ..._, isEditorVisible: true, selectedProviderInEditor: _.provider }
    })
  }

  function handleError(error: any): void {
    Logger.logError(`Error in ${LineProviderEditor.name}. Message: ${error.toString()}`)
  }

  return (
    <div className={'row'}>
      {loadingState === LoadingState.Loading ? (
        <div className={'col-12 text-center py-5'}>
          <Preloader overlay={false} />
        </div>
      ) : loadingState === LoadingState.LoadingFailed ? (
        <div className={'col-12'}>
          <LoadingFailed tryAgainFn={loadProvider} />
        </div>
      ) : undefined}

      {state.provider && (
        <>
          <div className={'col-md-6'}>
            <h4 className='mb-2'>{Localizer.localize('Main provider')}</h4>
            <p className={'mb-2'}>{state.provider.name}</p>
          </div>
          <div className={'col-md-6'}>
            {!state.isEditorVisible && (
              <Button
                id={guideContent.lines.editor.provider.changeProvider.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                className={'ml-0'}
                onClick={showEditor}
              >
                <FontAwesomeIcon icon={faPencilAlt} className={'mr-2'} />
                {Localizer.localize('Change provider')}
              </Button>
            )}

            {state.isEditorVisible && (
              <div className='inline-editor px-3 py-3'>
                <div
                  id={guideContent.lines.editor.provider.providerDropdown.target}
                  className={'mb-2'}
                >
                  <label className='mb-1'>{Localizer.localize('Provider')}</label>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      size='sm'
                      className={'m-0 button button-mini button-rounded button-light w-100'}
                      style={{ whiteSpace: 'normal' }}
                    >
                      {state.selectedProviderInEditor?.name?.shorten(65)}
                    </DropdownToggle>

                    <DropdownMenu>
                      {state.availableProviders.map((_) => (
                        <DropdownItem
                          onClick={() =>
                            setState((previousState) => {
                              return { ...previousState, selectedProviderInEditor: _ }
                            })
                          }
                          key={_.companyId + _.providerDistinction}
                          className='d-flex align-items-center'
                        >
                          <span className='small d-flex'>{_.name}</span>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div className={'w-100 d-flex justify-content-end'}>
                  <Button
                    id={guideContent.lines.editor.provider.confirmChange.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    className={'m-0 mr-1'}
                    onClick={saveChangedProvider}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    id={guideContent.lines.editor.provider.cancelChange.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Danger}
                    className={'m-0'}
                    onClick={cancelChangingProvider}
                  >
                    <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                    {Localizer.localize('Cancel')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}