import React, { FC, useContext, useEffect } from 'react'
import { Preloader, useAppGuide } from '@inprop/tt-ui-elements'
import { useParams } from 'react-router-dom'
import { JdfLineDetailsPageRouteParams } from './JdfLineDetailsPage'
import { JdfContext } from '../../contexts/JdfContext'
import { useJdfLineApi } from '../../hooks/useJdfLineApi'
import { LoadingFailed } from '../../to-refactor/LoadingHelpers'
import { SET_LAYOUT_WIDTH } from '../../contexts/LayoutReducer'
import { LayoutContext, LayoutWidth } from '../../contexts/LayoutContext'
import cx from 'classnames'
import JdfConnectionEditorToolbar from '../jdf/connection/JdfConnectionEditorToolbar'
import JdfConnectionEditorContextProvider, {
  Editor,
  JdfConnectionEditorContext,
  JdfConnectionEditorContextState,
} from '../../contexts/JdfConnectionEditorContext'
import JdfConnectionEditor from '../jdf/connection/JdfConnectionEditor'
import { navigationWithJdf } from '../guide/content/guideContent'

export type JdfConnectionEditPageParams = JdfLineDetailsPageRouteParams & {
  connectionId: string
}

export const JdfConnectionEditPage: FC = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext)
  const { state: jdfState } = useContext(JdfContext)
  if (!jdfState.jdfDataId) {
    throw new Error('JDF data must be set when showing JDF connection edit page')
  }

  const params = useParams<JdfConnectionEditPageParams>()

  const {
    line,
    isLoading,
    error: loadingError,
    fetchLine,
  } = useJdfLineApi(jdfState.jdfDataId, {
    lineNumber: params.lineNumber,
    lineNumberExtension: params.lineNumberExtension ?? '',
    isLineNumberUnique: false,
  })

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Fixed })
  }, [layoutDispatch])

  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  useEffect(() => {
    fetchLine()
  }, [jdfState.jdfDataId, params.lineNumber, params.lineNumberExtension])

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  if (loadingError || !line) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <LoadingFailed tryAgainFn={fetchLine} />
      </div>
    )
  }

  const getContainerType = (context: JdfConnectionEditorContextState) =>
    context.currentEditor === Editor.CalendarEditor ? 'container-fluid' : 'container'

  return (
    <JdfConnectionEditorContextProvider>
      <div className='row mb-3 border-bottom'>
        <div className='col'>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col'}>
                {line && (
                  <JdfConnectionEditorToolbar line={line} connectionId={params.connectionId} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <JdfConnectionEditorContext.Consumer>
        {(context) => (
          <div className={'d-flex justify-content-center'}>
            <div className={cx(getContainerType(context.state), 'pb-4')}>
              <JdfConnectionEditor line={line} connectionId={params.connectionId} />
            </div>
          </div>
        )}
      </JdfConnectionEditorContext.Consumer>
    </JdfConnectionEditorContextProvider>
  )
}