import React, { FC } from 'react'
import Layout from './components/layout/Layout'
import AppRoutes from './components/layout/navigation/routes/AppRoutes'
import { NotificationsContextProvider } from './contexts/NotificationsContext'
import { BrowserRouter } from 'react-router-dom'
import { JdfContextProvider } from './contexts/JdfContext'
import { CookiesProvider } from 'react-cookie'
import { LayoutContextProvider } from './contexts/LayoutContext'

import './App.scss'
import { AuthContextProvider } from './contexts/AuthContext'
import { ThemeProvider } from '@mui/material'
import { theme } from './config/muiTheme'
import NavigationContextProvider from './contexts/NavigationContext'
import { AppGuideContextProvider } from '@inprop/tt-ui-elements'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { luxonTimeZones, setDefaultLuxonTimeZone } from './utils/dateTimeUtils'

// queryClient should be initialized outside of the App component
const queryClient = new QueryClient()

const App: FC = () => {
  
  // move to onSuccess part of login when loading locale for individual users
  setDefaultLuxonTimeZone(luxonTimeZones.sk)
  
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <NotificationsContextProvider>
          <JdfContextProvider>
            <BrowserRouter>
              <NavigationContextProvider>
                <ThemeProvider theme={theme}>
                  <AuthContextProvider>
                    <LayoutContextProvider>
                      <AppGuideContextProvider>
                        <Layout>
                          <AppRoutes />
                        </Layout>
                      </AppGuideContextProvider>
                    </LayoutContextProvider>
                  </AuthContextProvider>
                </ThemeProvider>
              </NavigationContextProvider>
            </BrowserRouter>
          </JdfContextProvider>
        </NotificationsContextProvider>
      </CookiesProvider>
    </QueryClientProvider>
  )
}

export default App
