import useLocalStorage, { localStorageKeys } from './useLocalStorage'
import { useEffectOnce } from './useEffectOnce'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useEffect } from 'react'

export interface IJdfUploadSettingsV1 {
  removeStands: boolean
  optimizeRoutes: boolean
  syncStations: boolean
  syncProviders: boolean
}

export interface IJdfUploadSettingsV2 {
  removeStands: boolean
  optimizeRoutes: boolean
  syncStations: boolean
  syncProviders: boolean
  fixPhoneConnections: boolean
  // warning!: this property correspond to toggle name, but it has opposite value as param used on BE
  operationAccordingToSpojDatFile: boolean  
}

export type CurrentJdfUploadSettings = IJdfUploadSettingsV2

export const defaultJdfUploadSettingsV2: IJdfUploadSettingsV2 = {
  removeStands: true,
  optimizeRoutes: true,
  syncStations: true,
  syncProviders: true,
  fixPhoneConnections: false,
  operationAccordingToSpojDatFile: false
}

export const useJdfUploaderLocalStorage = (): [
  CurrentJdfUploadSettings,
  Dispatch<SetStateAction<CurrentJdfUploadSettings>>
] => {
  const [settingsV1] = useLocalStorage<IJdfUploadSettingsV1 | undefined>(
    localStorageKeys.jdfUploadSettings.v1,
    undefined
  )

  const [settingsV2, setSettingsV2] = useLocalStorage<IJdfUploadSettingsV2 | undefined>(
    localStorageKeys.jdfUploadSettings.v2,
    undefined
  )

  const getV2FromV1 = (settingsV1: IJdfUploadSettingsV1): IJdfUploadSettingsV2 => ({
    ...defaultJdfUploadSettingsV2,
    ...settingsV1,
  })

  useEffectOnce(() => {
    if (!settingsV2 && settingsV1) {
      setSettingsV2(getV2FromV1(settingsV1))
    }
  })

  const settings = settingsV2 ?? defaultJdfUploadSettingsV2
  const setSettings = (newSettings: SetStateAction<CurrentJdfUploadSettings>) => {
    const newSettingsValue = newSettings instanceof Function ? newSettings(settings) : newSettings

    setSettingsV2(newSettingsValue)
  }

  return [settings, setSettings]
}
