import React, { FC, useContext, useEffect } from 'react'
import { LayoutContext, LayoutHeight } from '../../../contexts/LayoutContext'
import { SET_LAYOUT_HEIGHT } from '../../../contexts/LayoutReducer'
import RegistrationAllowedEmailsList from '../../auth/RegistrationAllowedEmailsList'

const RegistrationAllowedEmailsPage: FC = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext)

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.ByContent })
  }, [layoutDispatch])

  return (
    <RegistrationAllowedEmailsList />
  )
}

export default RegistrationAllowedEmailsPage
