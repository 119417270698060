import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../utils/urls'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'
import { AppGuideContext, Button, ButtonColor, ButtonSize } from '@inprop/tt-ui-elements'
import { JdfContext } from '../../contexts/JdfContext'
import { SET_LOADED_JDF_DATA_ID } from '../../contexts/JdfReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { jdfLoadGuide } from '../guide/content/jdfLoad'
import { createGuideStep } from '../guide/content/guideContent'

export const JDF_DATA_ID_COOKIE_KEY = 'jdf_data_id'

const JdfLoaderFromCookies: FC = () => {
  const [cookies] = useCookies([JDF_DATA_ID_COOKIE_KEY])
  const [{}, fetch] = useAxios({ method: 'head' }, { manual: true })
  const [previouslyLoadedJdfDataId] = useState<string | undefined>(cookies[JDF_DATA_ID_COOKIE_KEY])

  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { state: jdfState, dispatch: jdfDispatch } = useContext(JdfContext)
  const { dispatch: appGuideDispatch } = useContext(AppGuideContext)

  const history = useHistory()
  const location = useLocation()

  const showNotificationJdfDataFound = (jdfDataId: string) => {
    notificationsDispatch({
      type: ADD_NOTIFICATION,
      value: {
        type: NotificationType.Success,
        title: Localizer.localize('Previously loaded JDF data found'),
        htmlId: jdfLoadGuide.previouslyLoadedData.target,
        autoDismissAfterSeconds: 25,
        onClose: () =>
          appGuideDispatch.removeSteps([jdfLoadGuide.previouslyLoadedData.target], 'editor'),
        body: function renderBody(closeFn) {
          return (
            <div className={'d-flex flex-column'}>
              <span>
                {Localizer.localize(
                  'We found data, that you edited some time ago. Do you want to continue editing it?'
                )}
              </span>
              <div className={'d-flex justify-content-end align-items-center'}>
                <Button
                  size={ButtonSize.ExtraSmall}
                  className={'mr-1'}
                  onClick={() => {
                    closeFn()
                    loadFoundJdfData(jdfDataId)
                  }}
                >
                  {Localizer.localize('Yes')}
                </Button>
                <Button size={ButtonSize.ExtraSmall} color={ButtonColor.Light} onClick={closeFn}>
                  {Localizer.localize('No')}
                </Button>
              </div>
            </div>
          )
        },
      },
    })
  }

  useEffect(() => {
    if (!previouslyLoadedJdfDataId) {
      return
    }

    if (jdfState.jdfDataId) {
      return
    }

    if (location.pathname.startsWith('/jdf')) {
      return
    }

    fetch({ url: apiUrls.jdf.protocol(previouslyLoadedJdfDataId) })
      .then(() => {
        showNotificationJdfDataFound(previouslyLoadedJdfDataId)

        appGuideDispatch.appendSteps([createGuideStep(jdfLoadGuide.previouslyLoadedData)], 'editor')
      })
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadFoundJdfData = useCallback(
    (jdfDataId: string) => {
      jdfDispatch({ type: SET_LOADED_JDF_DATA_ID, value: jdfDataId })

      if (location.pathname.startsWith('/map')) {
        history.push(appUrls.jdf.map.withParams({ jdfDataId: jdfDataId }))
      } else {
        history.push(appUrls.jdf.lines.withParams({ jdfDataId: jdfDataId }))
      }
    },
    [history, jdfDispatch, location.pathname]
  )

  return <></>
}

JdfLoaderFromCookies.displayName = 'JdfLoaderFromCookies'
export default JdfLoaderFromCookies
