import React, {
  createContext, EventHandler,
  FC,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'

export type OnNavigateElsewhereFn = (confirmRedirect: () => void) => void

export interface NavigationContextState {
  onNavigateElsewhere: OnNavigateElsewhereFn | undefined
  getNavUrlProps: (url: string) => { to: string; onClick: EventHandler<any> }
}

export interface NavigationContextActions {
  setOnNavigateElsewhere: (fn: OnNavigateElsewhereFn | undefined) => void
}

const defaultState: NavigationContextState = {
  onNavigateElsewhere: undefined,
  getNavUrlProps: () => ({
    to: '',
    onClick: () => {},
  }),
}

export const NavigationContext = createContext<{
  state: NavigationContextState
  dispatch: NavigationContextActions
}>({
  state: defaultState,
  dispatch: {
    setOnNavigateElsewhere: () => {},
  },
})

const NavigationContextProvider: FC = (props) => {
  const history = useHistory()
  const [state, setState] = useState<NavigationContextState>(defaultState)

  const setOnNavigateElsewhere = (fn: OnNavigateElsewhereFn | undefined) => {
    setState((_) => ({ ..._, onNavigateElsewhere: fn }))
  }

  const onNavigationClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, url: string) => {      
      if (state.onNavigateElsewhere) {
        state.onNavigateElsewhere(() => {
          history.push(url)
          setOnNavigateElsewhere(undefined)
        })
        
        event.preventDefault()
      }
    },
    [state.onNavigateElsewhere, history]
  )

  const getNavUrlProps = useCallback(
    (url: string): { to: string; onClick: MouseEventHandler<HTMLAnchorElement> } => {
      return { to: url, onClick: (event) => onNavigationClick(event, url) }
    },
    [onNavigationClick]
  )

  useEffect(() => {
    setState((_) => ({ ..._, getNavUrlProps }))
  }, [getNavUrlProps])

  return (
    <NavigationContext.Provider value={{ state, dispatch: { setOnNavigateElsewhere } }}>
      {props.children}
    </NavigationContext.Provider>
  )
}

export default NavigationContextProvider
