import React, { createRef, useContext, useEffect, useState } from 'react'
import { Localizer } from '../../../utils/localizer'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { List } from 'immutable'
import { InputWithKeyActions } from '../../general/InputWithKeyActions'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { LineNumberExtended } from '../../../models/LineModels'
import { Logger } from '../../../utils/logger'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { guideContent } from '../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'

interface Props {
  jdfDataId: string
  lineNumber: LineNumberExtended
  allConnectionNumbers: List<number>
  onConnectionSaved: () => void
  onCancel: () => void
}

interface ValidationStatus {
  isValid: boolean
  error?: string
}

interface ApiModel {
  connectionNumber: number
}

export const ConnectionNewForm: React.FunctionComponent<Props> = (props) => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const [newConnectionNumber, setNewConnectionNumberPartial] = useState<number>()
  const [validationError, setValidationError] = useState<string>()
  const inputRef = createRef<HTMLInputElement>()

  useAppGuide([
    guideContent.connections.add.input,
    guideContent.connections.add.confirm,
    guideContent.connections.add.cancel,
  ])

  useEffect(() => {
    inputRef.current?.select()
  }, [])

  function addNewConnection(): void {
    const validationStatus = validate(newConnectionNumber)
    setValidationError(validationStatus.error)
    if (!validationStatus.isValid || !newConnectionNumber) return

    const newConnection: ApiModel = {
      connectionNumber: newConnectionNumber,
    }

    axios
      .post(
        UrlProvider.Api.Components.Connections.NewEditor.getUrl(props.jdfDataId, props.lineNumber),
        newConnection
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection was added'),
            type: NotificationType.Success,
          },
        })

        props.onConnectionSaved()
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection could not be added'),
            type: NotificationType.Error,
          },
        })

        Logger.logError(`Error in ${ConnectionNewForm.name}. Message: ${error.toString()}`)
      })
  }

  function validate(connectionNumber?: number): ValidationStatus {
    if (!connectionNumber) {
      return {
        isValid: false,
        error: Localizer.localize('Connection number is empty'),
      }
    } else if (props.allConnectionNumbers.contains(connectionNumber)) {
      return {
        isValid: false,
        error: Localizer.localize('Connection with this number already exists'),
      }
    } else if (connectionNumber > 9999999999) {
      return {
        isValid: false,
        error: Localizer.localize('This long? Seriously?'),
      }
    }

    return {
      isValid: true,
      error: undefined,
    }
  }

  function setNewConnectionNumber(connectionNumber: number): void {
    let newConnectionNumber: number | undefined = connectionNumber

    if (isNaN(newConnectionNumber)) {
      newConnectionNumber = undefined
    } else if (newConnectionNumber < 1) {
      newConnectionNumber = 1
    }

    const validationResult = validate(newConnectionNumber)
    setValidationError(validationResult.error)

    setNewConnectionNumberPartial(newConnectionNumber)
  }

  return (
    <div className={'d-flex flex-column px-2'}>
      <div className={'d-flex align-items-center w-100 mb-1'}>
        <InputWithKeyActions
          id={guideContent.connections.add.input.target}
          value={newConnectionNumber?.toString() ?? ''}
          ref={inputRef}
          onChange={(event) => setNewConnectionNumber(parseInt(event.target.value))}
          className={'form-control-sm d-flex mr-1'}
          placeholder={Localizer.localize('Enter new connection number')}
          onEnterPressed={addNewConnection}
          onEscPressed={props.onCancel}
        />
        <Button
          id={guideContent.connections.add.confirm.target}
          shape={ButtonShape.Rounded}
          size={ButtonSize.ExtraSmall}
          color={ButtonColor.Primary}
          onClick={addNewConnection}
          className={'m-0 mr-1'}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
        <Button
          id={guideContent.connections.add.cancel.target}
          shape={ButtonShape.Rounded}
          size={ButtonSize.ExtraSmall}
          color={ButtonColor.Danger}
          onClick={props.onCancel}
          className={'m-0'}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>

      <div className={'d-flex w-100'}>
        {validationError && <span className={'text-danger small'}>{validationError}</span>}
      </div>
    </div>
  )
}