import React, { FC, MouseEvent, ReactElement } from 'react'
import { Box, Dialog, DialogProps, Stack, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Button from '@mui/material/Button'
import { Localizer } from '../../utils/localizer'
import LoadingButton from '@mui/lab/LoadingButton'

export interface Props extends DialogProps {
  title: string
  content?: string | ReactElement
  onConfirm: () => void
  isLoading?: boolean
}

const ConfirmDialog: FC<Props> = ({ title, content, onConfirm, isLoading, ...dialogProps }) => {

  const onCancel = (event: MouseEvent<HTMLButtonElement>) => {
    if (dialogProps.onClose) {
      dialogProps.onClose(event, 'escapeKeyDown')
    }
  }

  return (
    <Dialog {...dialogProps}>
      <Stack sx={{ p: 4, minWidth: '300px' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ mb: 4 }}
        >
          <Typography fontWeight={'bold'}>
            {title}
          </Typography>
          <IconButton onClick={event => onCancel(event)}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>

        {content && <Box sx={{ mb: 3 }}>{content}</Box>}

        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
          <Button onClick={event => onCancel(event)}>{Localizer.localize('Cancel')}</Button>
          <LoadingButton
            variant={'contained'}
            onClick={onConfirm}
            loading={isLoading}
          >
            {Localizer.localize('Confirm')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ConfirmDialog
