import React, { createContext, Dispatch, FC, useReducer } from 'react'
import { JdfActionType, JdfReducer } from './JdfReducer'
import {JdfKonExitCode} from "../models/protocol";

export interface JdfState {
  jdfDataId?: string
  jdfKonExitCode?: JdfKonExitCode
}

const initialState: JdfState = {}

const JdfContext = createContext<{
  state: JdfState
  dispatch: Dispatch<JdfActionType>
}>({
  state: initialState,
  dispatch: () => {},
})

const JdfContextProvider: FC = (props) => {
  const [state, dispatch] = useReducer(JdfReducer, initialState)

  return (
    <JdfContext.Provider value={{ state, dispatch }}>
      {props.children}
    </JdfContext.Provider>
  )
}

export { JdfContext, JdfContextProvider }
