import React, {FC, useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { JdfContext } from '../../../../contexts/JdfContext'
import { Preloader } from '@inprop/tt-ui-elements'
import { SET_JDF_KON_EXIT_CODE, SET_LOADED_JDF_DATA_ID } from '../../../../contexts/JdfReducer'
import useAxios from 'axios-hooks'
import { apiUrls } from '../../../../utils/urls'
import { JdfKonExitCode, JdfProtocolApiModel } from '../../../../models/protocol'
import { LoadingFailed } from '../../../../to-refactor/LoadingHelpers'

export interface JdfRouteParams {
  jdfDataId: string
}

const JdfRouteWrapper: FC = (props) => {
  const { state: jdfState, dispatch: jdfDispatch } = useContext(JdfContext)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const params = useParams<JdfRouteParams>()

  const [
    { data: jdfProtocol, loading: isLoadingProtocol, error },
    loadJdfProtocol,
  ] = useAxios<JdfProtocolApiModel>({}, { manual: true })

  useEffect(() => {
    loadJdfProtocol({ url: apiUrls.jdf.protocol(params.jdfDataId) })
    jdfDispatch({ type: SET_LOADED_JDF_DATA_ID, value: params.jdfDataId })
    setIsFirstLoad(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.jdfDataId])

  useEffect(() => {
    jdfDispatch({
      type: SET_JDF_KON_EXIT_CODE,
      value: jdfProtocol?.jdfKonExitCode ?? JdfKonExitCode.FatalError,
    })
  }, [jdfProtocol])

  if (isLoadingProtocol || isFirstLoad) {
    return (
      <div className={'row'}>
        <div className={'col text-center'}>
          <Preloader overlay={false} centered />
        </div>
      </div>
    )
  }

  if (error || !jdfState.jdfDataId) {
    return (
      <div className={'row'}>
        <div className={'col text-center'}>
          <LoadingFailed />
        </div>
      </div>
    )
  }

  return <>{props.children}</>
}

export default JdfRouteWrapper
