import React, { FC, useContext, useEffect } from 'react'
import { JdfContext } from '../../contexts/JdfContext'
import useAxios from 'axios-hooks'
import { Preloader, useAppGuide } from '@inprop/tt-ui-elements'
import { LoadingFailed } from '../../to-refactor/LoadingHelpers'
import { JdfProtocolApiModel } from '../../models/protocol'
import { Localizer } from '../../utils/localizer'
import JdfProtocol from '../jdf/JdfProtocol'
import { apiUrls } from '../../utils/urls'
import { navigationWithJdf } from '../guide/content/guideContent'

const ProtocolPage: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  const [{ data: protocol, loading, error }, fetchProtocol] = useAxios<JdfProtocolApiModel>(
    {},
    { manual: true }
  )

  useEffect(() => {
    if (!jdfState.jdfDataId) {
      return
    }

    fetchProtocol({ url: apiUrls.jdf.protocol(jdfState.jdfDataId) })
  }, [jdfState])

  return (
    <div className={'row py-2'}>
      <div className={'col'}>
        <div className={'h2'}>{Localizer.localize('Protocol')}</div>

        {loading && <Preloader overlay={false} centered />}

        {error && <LoadingFailed />}

        {protocol && <JdfProtocol {...protocol} />}
      </div>
    </div>
  )
}

export default ProtocolPage
