import React, { useContext, useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs'
import {
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Preloader,
  useAppGuide,
} from '@inprop/tt-ui-elements'
import { LoadingState } from '../../to-refactor/LoadingHelpers'
import { Localizer } from '../../utils/localizer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiUrls, appUrls, signalRUrls } from '../../utils/urls'
import { parseJsonString } from '../../utils/jsonParser'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { JdfContext } from '../../contexts/JdfContext'
import { SET_LOADED_JDF_DATA_ID } from '../../contexts/JdfReducer'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { JDF_DATA_ID_COOKIE_KEY } from './JdfLoaderFromCookies'
import JdfUploadSettingsAccordion from './settings/JdfUploadSettingsAccordion'

import styles from './JdfUploader.module.scss'
import { jdfLoadGuide } from '../guide/content/jdfLoad'
import { useJdfUploaderLocalStorage } from '../../hooks/useJdfUploaderLocalStorage'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION, DEFAULT_AUTO_DISMISS_AFTER_SECONDS } from '../../contexts/NotificationsReducer'

interface ApiUploadResponse {
  jdfDataId: string
  recognizedFiles: string
}

interface UploadState {
  loadingState: LoadingState
  error?: string
}

interface DataLoadingState {
  loadingState: LoadingState
  currentLoadingAction?: string
  error?: {
    errorNumber: number
    text: string
  }
}

enum JdfKonExitCode {
  Ok = 0,
  Warn,
  Error,
  FatalError,
  
  NotSpecified = 10,
}

//this is just nasty hack for storing state, because Syncfusion component is not able to read from state
class SingletonState {
  public static uploadedJdfDataId: string | undefined
}

const jdfLoadMethod = 'jdfLoad'
const jdfLoadSuccessMethod = 'jdfLoadSuccess'
const jdfLoadFailureMethod = 'jdfLoadFailure'
const jdfLoadJdfKonFailureMethod = 'jdfLoadJdfKonFailure'

const showNotificationMethod = "showNotification";

// methods on BE called by FE
const performJdfLoad = 'PerformJdfLoad'

export function JDFUploader(): JSX.Element {
  const [signalRConnection, setSignalRConnection] = useState<HubConnection>()

  const [isUploaderVisible, setIsUploaderVisible] = useState<boolean>(true)
  const [uploadState, setUploadState] = useState<UploadState>({
    loadingState: LoadingState.NotStarted,
  })
  const [dataLoadingState, setDataLoadingState] = useState<DataLoadingState>({
    loadingState: LoadingState.NotStarted,
    currentLoadingAction: undefined,
  })

  const { dispatch: jdfDispatch } = useContext(JdfContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const history = useHistory()
  const [, setCookie] = useCookies([JDF_DATA_ID_COOKIE_KEY])
  useAppGuide([jdfLoadGuide.jdfUploader.dropArea], isUploaderVisible)

  const [jdfUploadSettings] = useJdfUploaderLocalStorage()

  useEffect(() => {
    return () => {
      signalRConnection?.stop()
    }
  }, [])

  useEffect(() => {
    if (
      dataLoadingState.loadingState === LoadingState.Loading ||
      dataLoadingState.loadingState === LoadingState.LoadingSucceeded
    ) {
      setIsUploaderVisible(false)
    } else {
      setIsUploaderVisible(true)
    }
  }, [dataLoadingState])

  const setupSignalR = async (): Promise<void> => {
    const newSignalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrls.jdf)
      .configureLogging(signalR.LogLevel.Error)
      .build()

    newSignalRConnection.on(jdfLoadMethod, (currentLoadingAction) => {
      if (dataLoadingState.currentLoadingAction !== currentLoadingAction) {
        setDataLoadingState((_) => {
          return { ..._, currentLoadingAction: currentLoadingAction }
        })
      }
    })

    newSignalRConnection.on(jdfLoadSuccessMethod, () => onDataLoadSucceeded())

    newSignalRConnection.on(jdfLoadFailureMethod, (errorText) => {
      setDataLoadingState((_) => {
        return {
          ..._,
          loadingState: LoadingState.LoadingFailed,
          currentLoadingAction: undefined,
          error: { errorNumber: JdfKonExitCode.NotSpecified, text: errorText },
        }
      })
    })

    newSignalRConnection.on(jdfLoadJdfKonFailureMethod, (errorNumber, errorText) => {
      setDataLoadingState((_) => {
        if (parseInt(errorNumber) === JdfKonExitCode.Warn) {
          onDataLoadSucceeded()
        }

        return {
          ..._,
          loadingState: LoadingState.LoadingFailed,
          currentLoadingAction: undefined,
          error: { errorNumber: errorNumber, text: errorText },
        }
      })
    })
    
    newSignalRConnection.on(showNotificationMethod, (notificationType, notificationTitleKey, notificationBodyKey) => {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: notificationType,
          title: Localizer.localize(notificationTitleKey),
          body: notificationBodyKey ? Localizer.localize(notificationBodyKey) : undefined,
          autoDismissAfterSeconds: 
            (notificationType == NotificationType.Success || notificationType == NotificationType.Neutral)
              ? DEFAULT_AUTO_DISMISS_AFTER_SECONDS
              : undefined,
        },
      })
    })

    try {
      await newSignalRConnection.start()
    } catch (exception) {
      console.log(exception)
    }

    setSignalRConnection(newSignalRConnection)
  }

  useEffect(() => {
    setupSignalR().catch((_) => console.log(_))
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onUploadSuccess(args: any): void {
    if (args.operation !== 'upload') {
      return
    }

    let response: ApiUploadResponse | undefined
    try {
      response = parseJsonString<ApiUploadResponse>(args.e.currentTarget.response)
    } catch {
      SingletonState.uploadedJdfDataId = undefined
      setUploadState({ loadingState: LoadingState.LoadingSucceeded })
      setDataLoadingState({
        loadingState: LoadingState.LoadingFailed,
        error: {
          text: Localizer.localize('Could not process uploaded file'),
          errorNumber: JdfKonExitCode.FatalError,
        },
      })

      return
    }

    const dataId = response.jdfDataId
    args.statusText = response.recognizedFiles

    if (dataId) {
      SingletonState.uploadedJdfDataId = dataId
      jdfDispatch({ type: SET_LOADED_JDF_DATA_ID, value: undefined })
      setUploadState({
        loadingState: LoadingState.LoadingSucceeded,
      })
      setDataLoadingState({
        loadingState: LoadingState.NotStarted,
        error: undefined,
        currentLoadingAction: undefined,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onUploadFailure(args: any): void {
    const header = args.response.headers
    const atoms = header.split('\r\n')
    const errorPrefix = 'error: '
    let error = ''

    for (let i = 0; i < atoms.length; i++) {
      if (atoms[i].startsWith(errorPrefix)) {
        error = decodeURIComponent(atoms[i].substring(errorPrefix.length).replace(/\+/g, ' '))
      }
    }

    SingletonState.uploadedJdfDataId = undefined
    setUploadState({
      loadingState: LoadingState.LoadingFailed,
      error: error,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onRemove(args: any): void {
    args.customFormData = [{ id: SingletonState.uploadedJdfDataId }]

    SingletonState.uploadedJdfDataId = undefined
    setUploadState({
      loadingState: LoadingState.NotStarted,
      error: undefined,
    })
    setDataLoadingState({
      loadingState: LoadingState.NotStarted,
      currentLoadingAction: undefined,
      error: undefined,
    })
  }

  function onDataLoadSucceeded(): void {
    if (!SingletonState.uploadedJdfDataId) {
      throw new Error('Uploaded JDF data ID must not be empty')
    }

    jdfDispatch({ type: SET_LOADED_JDF_DATA_ID, value: SingletonState.uploadedJdfDataId })
    setCookie(JDF_DATA_ID_COOKIE_KEY, SingletonState.uploadedJdfDataId, { path: '/' })
    history.push(appUrls.jdf.lines.withParams({ jdfDataId: SingletonState.uploadedJdfDataId }))
  }

  function startProcessingUploadedData(): void {
    setDataLoadingState((_) => {
      return {
        ..._,
        loadingState: LoadingState.Loading,
        currentLoadingAction: undefined,
        error: undefined,
      }
    })

    if (signalRConnection?.state !== HubConnectionState.Connected) {
      throw new Error('SignalR is not connected. Cannot load process uploaded JDF files.')
    }

    signalRConnection
      .invoke(
        performJdfLoad,
        SingletonState.uploadedJdfDataId,
        jdfUploadSettings.removeStands,
        jdfUploadSettings.optimizeRoutes,
        jdfUploadSettings.syncStations,
        jdfUploadSettings.syncProviders,
        jdfUploadSettings.fixPhoneConnections,
        // this settings property correspond to toggle name, but it is has opposite value as param used on BE, so it is needed to negate it
        !jdfUploadSettings.operationAccordingToSpojDatFile
      )
      .catch(() => {
        setDataLoadingState((_) => {
          return {
            ..._,
            loadingState: LoadingState.LoadingFailed,
            currentLoadingAction: undefined,
            error: undefined,
          }
        })
      })
  }

  function isDataLoadingErrorFatal(): boolean {
    if (!dataLoadingState.error) {
      return true
    }

    return dataLoadingState.error.errorNumber > JdfKonExitCode.Warn
  }

  function showLoadingButtonAndStatus(): boolean {
    return (
      uploadState.loadingState === LoadingState.LoadingSucceeded &&
      dataLoadingState.loadingState !== LoadingState.LoadingFailed
    )
  }

  function showDataLoadingErrorBox(): boolean {
    return dataLoadingState.loadingState === LoadingState.LoadingFailed && isDataLoadingErrorFatal()
  }

  const redirectToProtocol = () => {
    if (!SingletonState.uploadedJdfDataId) {
      throw new Error('Uploaded JDF data ID must not be empty when redirecting to protocol.')
    }

    history.push(appUrls.jdf.protocol.withParams({ jdfDataId: SingletonState.uploadedJdfDataId }))
  }

  return (
    <div className={'d-flex flex-column w-100 js-upload-drop-area'}>
      <div
        id={jdfLoadGuide.jdfUploader.dropArea.target}
        className={`jdf-upload-drop-area w-100 ${isUploaderVisible ? '' : 'd-none'}`}
      >
        <UploaderComponent
          id='uploader'
          asyncSettings={{
            saveUrl: apiUrls.jdfUploader.save,
            removeUrl: apiUrls.jdfUploader.remove,
          }}
          multiple={false}
          allowedExtensions='.7z,.zip,.rar'
          success={onUploadSuccess}
          failure={onUploadFailure}
          removing={onRemove}
          dropArea='.jdf-upload-drop-area'
          cssClass={styles.jdfUploader}
        />
      </div>

      {showLoadingButtonAndStatus() && (
        <div className={'d-flex flex-column mt-3'}>
          {dataLoadingState.loadingState !== LoadingState.Loading && <JdfUploadSettingsAccordion />}

          <div className={'d-flex flex-row align-items-center'}>
            <Button
              shape={ButtonShape.Rounded}
              color={ButtonColor.Primary}
              disabled={dataLoadingState.loadingState === LoadingState.Loading}
              onClick={startProcessingUploadedData}
              className={'load-button m-0 mr-4'}
            >
              {dataLoadingState.loadingState === LoadingState.Loading ? (
                <span className={'d-inline-block mr-2'}>
                  <Preloader small overlay={false} />
                </span>
              ) : (
                <FontAwesomeIcon icon={faChevronRight} className={'mr-2'} />
              )}
              {Localizer.localize('Load')}
            </Button>

            {dataLoadingState.loadingState !== LoadingState.Loading ? null : (
              <b className={'m-0'}>{dataLoadingState.currentLoadingAction} ...</b>
            )}
          </div>
        </div>
      )}

      {!showDataLoadingErrorBox() ? null : (
        <div className={'row mt-3'}>
          <div className={'col-12'}>
            <div className='alert alert-danger d-flex align-items-center justify-content-between'>
              <span className={'mr-3'}>
                {dataLoadingState.error?.text ??
                  Localizer.localize('Error while loading JDF batch.')}
              </span>

              {SingletonState.uploadedJdfDataId &&
                dataLoadingState.error?.errorNumber !== JdfKonExitCode.NotSpecified && (
                  <Button
                    size={ButtonSize.ExtraSmall}
                    color={ButtonColor.Light}
                    shape={ButtonShape.Rounded}
                    className={'m-0'}
                    onClick={redirectToProtocol}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className={'mr-2'} />
                    {Localizer.localize('Details')}
                  </Button>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
