import React, {createRef, FunctionComponent, useContext} from "react";
import {Button, ButtonColor, ButtonShape, ButtonSize} from "@inprop/tt-ui-elements";
import {Localizer} from "../utils/localizer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSadTear} from "@fortawesome/free-regular-svg-icons/faSadTear";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {JdfContext} from "../contexts/JdfContext";
import {useHistory} from "react-router-dom";
import {SET_LOADED_JDF_DATA_ID} from "../contexts/JdfReducer";
import {appUrls} from "../utils/urls";

export enum LoadingState {
  NotStarted = 1,
  Loading = 2,
  LoadingSucceeded = 3,
  LoadingFailed = 4,
}

export interface PreloaderRef {
  show: () => void;
  hide: () => void;
}

export interface PreloaderProps {
  setRef?: (preloader: PreloaderRef) => void;
  centered?: boolean;
  small?: boolean;
}

export const Preloader: FunctionComponent<PreloaderProps> = (props) => {
  const elementRef = createRef<HTMLDivElement>();

  function show(): void {
    elementRef.current?.classList.add('show')
  }

  function hide(): void {
    elementRef.current?.classList.remove('show');
  }

  function getCssClasses(): string {
    let toReturn = 'overlay-preloader show';

    if (props.centered) {
      toReturn += ' centered';
    }

    if (props.small) {
      toReturn += ' small';
    }

    return toReturn;
  }

  if (props.setRef) props.setRef({
    show: () => show(),
    hide: () => hide()
  });

  return <div ref={elementRef} className={getCssClasses()}/>;
}

Preloader.defaultProps = {
  centered: false,
  small: false,
}


interface LoadingFailedProps {
  tryAgainFn?: () => void,
}

export const LoadingFailed: FunctionComponent<LoadingFailedProps> = (props) => {
  const {dispatch: jdfDispatch} = useContext(JdfContext)
  const history = useHistory()
  
  const loadDataAgain = () => {
    jdfDispatch({type: SET_LOADED_JDF_DATA_ID, value: undefined})
    history.push(appUrls.home)
  }
  
  return (
    <div className={'d-flex justify-content-center align-items-center p-5'}>
      <FontAwesomeIcon icon={faSadTear} className={'mr-4 display-1'} style={{color: '#eeeeee'}}/>

      <div className={'mb-0'}>
        <span className={'h2 d-flex mb-1'} style={{fontWeight: 'normal'}}>
          {Localizer.localize('Data could not be loaded')}
        </span>
        <span className={'d-flex align-items-center'}>
          {!props.tryAgainFn ? null :
            <>
              <Button size={ButtonSize.ExtraSmall}
                      shape={ButtonShape.Rounded}
                      color={ButtonColor.Light}
                      onClick={props.tryAgainFn}
                      className={'ml-1'}>
                <FontAwesomeIcon icon={faSyncAlt} className={'mr-2'}/>
                {Localizer.localize('Try again')}
              </Button>
              <span className={'mx-1'}>{Localizer.localize('or')}</span>
            </>
          }

          <Button size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Rounded}
                  color={ButtonColor.Light}
                  onClick={loadDataAgain}>
            <FontAwesomeIcon icon={faArrowRight} className={'mr-2'}/>
            {Localizer.localize('Load your data again')}
          </Button>
        </span>
      </div>
    </div>
  )
};