export class ScrollService {
  private static isScrollingEnabled = true;
  private static isThisOneScrollingDisabled = false;

  public static setupToggleableScrolling(): void {
    document.addEventListener(
      'wheel',
      (event) => {
        if (!this.isScrollingEnabled) {
          event.preventDefault();
        }

        if (this.isThisOneScrollingDisabled) {
          this.isThisOneScrollingDisabled = false;
          this.isScrollingEnabled = true;
        }
      },
      {passive: false});
  }

  public static disableThisOneScrolling(): void {
    this.isThisOneScrollingDisabled = true;
    this.isScrollingEnabled = false;
  }

  public static enableScrolling(): void {
    this.isScrollingEnabled = true;
  }

  public static disableScrolling(): void {
    this.isScrollingEnabled = false;
  }
}