import { useContext } from 'react'
import { NotificationsContext, NotificationType } from '../contexts/NotificationsContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RegistrationAllowedEmail, RegistrationAllowedEmailViewModel } from '../models/AuthViewModels'
import {
  createRegistrationAllowedEmail,
  deleteRegistrationAllowedEmail,
  updateRegistrationAllowedEmail,
} from './apiFunctions'
import { ADD_NOTIFICATION } from '../contexts/NotificationsReducer'
import { Localizer } from '../utils/localizer'
import { queryKeys } from './queryKeys'

export const useCreateRegistrationAllowedEmail = () => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmailViewModel, void, RegistrationAllowedEmailViewModel, unknown>({
    mutationFn: async (registrationAllowedEmailViewModel) => {
      await createRegistrationAllowedEmail(registrationAllowedEmailViewModel)

      return registrationAllowedEmailViewModel
    },
    onSuccess: registrationAllowedEmailViewModel => {
      queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localizeR('Email address {0} was successfully invited', registrationAllowedEmailViewModel.emailAddress),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while inviting the email address'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useUpdateRegistrationAllowedEmail = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmailViewModel, void, {
    registrationAllowedEmailId: number,
    registrationAllowedEmailViewModel: RegistrationAllowedEmailViewModel
  }, unknown>({
    mutationFn: async ({ registrationAllowedEmailId, registrationAllowedEmailViewModel }) => {
      await updateRegistrationAllowedEmail(registrationAllowedEmailId, registrationAllowedEmailViewModel)

      return registrationAllowedEmailViewModel
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('The invitation has been successfully edited'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while editing the invitation'),
          type: NotificationType.Error,
        },
      })
    },
  })
}

export const useDeleteRegistrationAllowedEmail = () => {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const queryClient = useQueryClient()

  return useMutation<RegistrationAllowedEmail, void, RegistrationAllowedEmail, unknown>({
    mutationFn: async (registrationAllowedEmail) => {
      await deleteRegistrationAllowedEmail(registrationAllowedEmail.id)

      return registrationAllowedEmail
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.registrationAllowedEmails })

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('The invitation has been successfully deleted'),
          type: NotificationType.Success,
        },
      })
    },
    onError: () => {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while deleting the invitation'),
          type: NotificationType.Error,
        },
      })
    },
  })
}
