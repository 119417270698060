export const jdfLoadGuide = {
  jdfUploader: {
    dropArea: {
      target: 'jdf-uploader-drop-area',
      text:
        'Presunutie komprimovaného súboru s JDF dávkou alebo stlačením tlačidla PRECHÁDZAŤ a následným' +
        ' dohľadaním komprimovanej JDF dávky v adresárovej štruktúre počítača spôsobí načítanie dávky.',
    },
    loadedFiles: {
      target: 'jdf-uploader-loaded-files',
      text:
        'Zoznam textových súborov v prípade, že komprimovaný súbor obsahuje JDF dávku s cestovnými' +
        ' poriadkami.',
    },
    load: {
      target: 'jdf-uploader-load',
      text: 'Načítanie dát a vytvorenie protokolu pre načítané linky.',
    },
  },
  
  jdfLoadSettings: {
    title: {
      target: 'jdf-load-settings-title',
      text:
        'Zapnutím/vypnutím jednotlivých volieb sa v procese načítania vykonajú požadované operácie nad dátami.' +
        ' Pri prvom spustení sú všetky voľby zapnuté, aplikácia si pamätá posledné nastavenie volieb.',
    },
    removePlatforms: {
      target: 'jdf-load-setting-remove-platforms',
      text: 'Vymažú sa všetky nastavania nástupíšť v trase spoja.',
    },
    routesOptimization: {
      target: 'jdf-load-setting-routes-optimization',
      text:
        'Spustí sa algoritmus na optimalizáciu trasy každej načítanej linky. Výsledkom je, že' +
        ' výveska linky obsahuje minimálny počet zastávok na zobrazenie párnych aj nepárnych spojov.',
    },
    syncStations: {
      target: 'jdf-load-setting-sync-stations',
      text: 'Spustí sa párovanie zastávok s centrálnou databázou.',
    },
    syncProviders: {
      target: 'jdf-load-setting-sync-providers',
      text: 'Spustí sa párovanie dopravcov s centrálnou databázou.',
    },
    fixPhoneConnections: {
      target: 'jdf-load-setting-fix-phone-connections',
      text: 'Spustí sa oprava spojov na telefón.'
    },
    operationAccordingToSpojDatFile: {
      target: 'jdf-load-setting-operation-according-to-spoj-dat-file',
      text: 'Premávanie spojov sa bude brať zo súboru \'SpojDat\', časové kódy budú ignorované.'
    }
  },

  previouslyLoadedData: {
    target: 'previously-loaded-data',
    text: 'Nástroj na pokračovanie práce nad poslednou načítanou JDF dávkou.',
  },
}
