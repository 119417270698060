import axios from 'axios'

export const setAuthorizationHeader = (accessToken: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export const clearAuthorizationHeader = () => {
  setAuthorizationHeader('')
}

export const setInterceptorForUnauthorizedRequests = (onUnauthorizedRequest: () => void) => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        onUnauthorizedRequest()
      } else {
        return Promise.reject(error)
      }
    }
  )
}
