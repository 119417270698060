import React, { useContext, useState } from 'react'
import { Localizer } from '../../../utils/localizer'
import { Fade } from 'reactstrap'
import { ProviderSelectAutocomplete } from './ProviderSelectAutocomplete'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Logger } from '../../../utils/logger'
import { Provider } from './ProvidersViewer'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { guideContent } from '../../guide/content/guideContent'
import { useAppGuide } from '@inprop/tt-ui-elements'

interface Props {
  jdfDataId: string
  reloadProvidersFn: () => void
}

interface State {
  isNewProviderSelectorVisible: boolean
  selectedNewProvider?: Provider
}

export function ProvidersViewerToolbar(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const [state, setState] = useState<State>({
    isNewProviderSelectorVisible: false,
    selectedNewProvider: undefined,
  })

  useAppGuide(
    [guideContent.providers.addNew.cancelButton, guideContent.providers.addNew.input],
    state.isNewProviderSelectorVisible
  )
  useAppGuide(
    [guideContent.providers.addNew.addButton],
    !!state.selectedNewProvider || !state.isNewProviderSelectorVisible
  )

  function showNewLineSelector(): void {
    setState((_) => {
      return { ..._, isNewProviderSelectorVisible: true }
    })
  }

  function hideNewLineSelector(): void {
    setState((_) => {
      return { ..._, isNewProviderSelectorVisible: false, selectedNewProvider: undefined }
    })
  }

  function addSelectedProvider(): void {
    axios
      .post(
        UrlProvider.Api.Components.Providers.Editor.getUrl(props.jdfDataId),
        state.selectedNewProvider
      )
      .then(() => {
        setState((_) => {
          return { ..._, selectedNewProvider: undefined, isNewProviderSelectorVisible: false }
        })

        if (props.reloadProvidersFn) props.reloadProvidersFn()

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('New provider was added'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Could not add new provider'),
            type: NotificationType.Error,
          },
        })

        handleError(error)
      })
  }

  function handleError(error: any): void {
    Logger.logError(`Error in ${ProvidersViewerToolbar.name}. Message: ${error.toString()}`)
  }

  return (
    <div className='row'>
      <div className='col-12 d-flex flex-column align-items-center align-items-lg-end'>
        <div className='d-flex flex-wrap justify-content-center px-2'>
          {state.isNewProviderSelectorVisible ? (
            <>
              {!state.selectedNewProvider ? null : (
                <Button
                  id={guideContent.providers.addNew.addButton.target}
                  size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Circle}
                  onClick={addSelectedProvider}
                  className={'ml-0 mr-2 px-3'}
                >
                  <FontAwesomeIcon icon={faCheck} className={'mr-2'} />
                  {Localizer.localize('Add provider')}
                </Button>
              )}

              <Button
                id={guideContent.providers.addNew.cancelButton.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Circle}
                color={ButtonColor.Danger}
                onClick={hideNewLineSelector}
                className={'ml-0 mr-2 px-3'}
              >
                <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                {Localizer.localize('Cancel')}
              </Button>
            </>
          ) : (
            <Button
              id={guideContent.providers.addNew.addButton.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Circle}
              onClick={showNewLineSelector}
              className={'ml-0 mr-2 px-3'}
            >
              <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
              {Localizer.localize('Add new provider')}
            </Button>
          )}
        </div>
      </div>

      {state.isNewProviderSelectorVisible && (
        <Fade className={'col-12 col-md-6 offset-md-6'}>
          <div
            id={guideContent.providers.addNew.input.target}
            className='d-flex w-100 flex-wrap flex-row justify-content-center px-2 mb-2'
          >
            <ProviderSelectAutocomplete
              className={'w-100 mr-2'}
              onChange={(newProvider) =>
                setState((_) => ({ ..._, selectedNewProvider: newProvider }))
              }
            />
          </div>
        </Fade>
      )}
    </div>
  )
}