/* Action constants */
import { LayoutHeight, LayoutState, LayoutWidth } from './LayoutContext'

export const SET_LAYOUT_WIDTH = 'layout/set-width'
export const SET_LAYOUT_HEIGHT = 'layout/set-height'
export const SET_LAYOUT_IS_WITH_FOOTER = 'layout/is-with-footer'

/* Action interfaces */
export interface SetLayoutWidthAction {
  type: typeof SET_LAYOUT_WIDTH
  value: LayoutWidth
}

export interface SetLayoutHeightAction {
  type: typeof SET_LAYOUT_HEIGHT
  value: LayoutHeight
}

export interface SetLayoutIsWithFooterAction {
  type: typeof SET_LAYOUT_IS_WITH_FOOTER
  value: boolean
}

/* Action functions */
const setLayoutWidth = (value: LayoutWidth, state: LayoutState): LayoutState => {
  return { ...state, width: value }
}

const setLayoutHeight = (value: LayoutHeight, state: LayoutState): LayoutState => {
  return { ...state, height: value }
}

const setLayoutIsWithFooter = (value: boolean, state: LayoutState): LayoutState => {
  return { ...state, isWithFooter: value }
}

export type LayoutActionType =
  | SetLayoutWidthAction
  | SetLayoutHeightAction
  | SetLayoutIsWithFooterAction

export const LayoutReducer = (state: LayoutState, action: LayoutActionType): LayoutState => {
  switch (action.type) {
    case SET_LAYOUT_WIDTH:
      return setLayoutWidth(action.value, state)
    case SET_LAYOUT_HEIGHT:
      return setLayoutHeight(action.value, state)
    case SET_LAYOUT_IS_WITH_FOOTER:
      return setLayoutIsWithFooter(action.value, state)
    default:
      return state
  }
}
