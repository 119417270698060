import React, { createRef, RefObject, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { LineNumberExtended } from '../../../models/LineModels'
import { DateTime, Info } from 'luxon'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Logger } from '../../../utils/logger'
import { Localizer } from '../../../utils/localizer'
import { LoadingFailed } from '../../../to-refactor/LoadingHelpers'
import { Button, ButtonColor, ButtonShape, ButtonSize, Preloader } from '@inprop/tt-ui-elements'
import '../../../utils/dateExtensions'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { JdfConnectionEditorContext } from '../../../contexts/JdfConnectionEditorContext'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
}

interface State {
  apiData?: ApiData
  calendar: CalendarMonth[]
  newNote?: string
}

interface CalendarMonth {
  year: number
  month: number
  days: CalendarDay[]
}

interface CalendarDay {
  day: number
  connectionRuns: boolean
  isHoliday: boolean
  isWeekend: boolean
  dateRepresentation: DateTime
  htmlReference: RefObject<HTMLElement>
}

/**
 * Raw API data for calendar.
 */
interface ApiDataRaw {
  startDate: string
  endDate: string
  connectionRunsIn: string[]
  holidays: string[]
  note: string
}

/**
 * Processed API data for calendar.
 */
interface ApiData {
  startDate: DateTime
  endDate: DateTime
  connectionRunsIn: DateTime[]
  holidays: DateTime[]
  originalNote: string
}

export function ConnectionCalendarEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfConnectionEditorContext)

  const [state, setState] = useState<State>({
    apiData: undefined,
    calendar: [],
    newNote: undefined,
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useAppGuide([
    guideContent.connections.editor.calendar.toggles.daily,
    guideContent.connections.editor.calendar.toggles.one,
    guideContent.connections.editor.calendar.toggles.two,
    guideContent.connections.editor.calendar.toggles.three,
    guideContent.connections.editor.calendar.toggles.four,
    guideContent.connections.editor.calendar.toggles.five,
    guideContent.connections.editor.calendar.toggles.six,
    guideContent.connections.editor.calendar.toggles.seven,
    guideContent.connections.editor.calendar.toggles.x,
    guideContent.connections.editor.calendar.toggles.cross,
    guideContent.connections.editor.calendar.toggles.monthRow,
    guideContent.connections.editor.calendar.toggles.dayColumn,
    guideContent.connections.editor.calendar.toggles.dayCell,
  ])

  useEffect(() => {
    loadCalendar()
  }, [props.dataId, props.lineNumber, props.connectionNumber])

  useEffect(() => {
    editorDispatch.setSaveFn(saveCalendar)
  }, [state.calendar])

  /**
   * Load JDF data for calendar.
   */
  function loadCalendar(): void {
    setIsLoading(true)

    axios
      .get<ApiDataRaw>(
        UrlProvider.Api.Components.Connections.Calendar.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        )
      )
      .then((response) => {
        const processedApiData: ApiData = {
          startDate: DateTime.fromISO(response.data.startDate),
          endDate: DateTime.fromISO(response.data.endDate),
          connectionRunsIn: response.data.connectionRunsIn.map((_) => DateTime.fromISO(_)),
          holidays: response.data.holidays.map((_) => DateTime.fromISO(_)),
          originalNote: response.data.note,
        }
        const calendar = getCalendarFromApiData(processedApiData)

        setState((_) => {
          return {
            ..._,
            calendar: calendar,
            apiData: processedApiData,
            newNote: undefined,
            showPreloader: false,
          }
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection calendar could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleComponentError(error)
      })
      .finally(() => setIsLoading(false))
  }

  /**
   * Load new text note according to current days when connection runs.
   */
  function loadNewNote(): void {
    axios
      .post<string>(
        UrlProvider.Api.Components.Connections.Calendar.Note.getUrl(props.dataId, props.lineNumber),
        getDatesWhenConnectionRuns()
      )
      .then((response) => {
        setState((_) => {
          return { ..._, newNote: response.data }
        })
        editorDispatch.setHasUnsavedChanges(true)
      })
      .catch((error) => handleComponentError(error))
  }

  /**
   * Get dates when connection runs.
   */
  function getDatesWhenConnectionRuns(): string[] {
    const connectionRunsIn: string[] = []
    for (const month of state.calendar)
      for (const day of month.days)
        if (day.connectionRuns) connectionRunsIn.push(day.dateRepresentation.toISODate())

    return connectionRunsIn
  }

  /**
   * Create inner modifiable calendar
   * @param apiData Processed data loaded from API
   */
  function getCalendarFromApiData(apiData: ApiData): CalendarMonth[] {
    let currentDate = apiData.startDate
    let previousDate: DateTime | undefined = undefined
    let currentMonth: CalendarMonth = {
      year: apiData.startDate.year,
      month: apiData.startDate.month,
      days: [],
    }
    const calendar: CalendarMonth[] = []

    while (currentDate <= apiData.endDate) {
      if (previousDate && previousDate.month !== currentDate.month) {
        calendar.push(currentMonth)
        currentMonth = {
          year: currentDate.year,
          month: currentDate.month,
          days: [],
        }
      }

      currentMonth.days.push({
        day: currentDate.day,
        dateRepresentation: currentDate,
        connectionRuns: apiData.connectionRunsIn.some((_) => +_ === +currentDate),
        isHoliday: apiData.holidays.some((_) => +_ === +currentDate),
        isWeekend: currentDate.weekday === 6 || currentDate.weekday === 7,
        htmlReference: createRef(),
      })

      previousDate = currentDate
      currentDate = currentDate.plus({ days: 1 })
    }
    if (currentMonth.days.length > 0) {
      calendar.push(currentMonth)
    }

    return calendar
  }

  /**
   * Toggle connection runs in concrete day in calendar.
   * @param monthIndex Index of month in calendar
   * @param dayIndex Index of day in calendar
   */
  function toggleConnectionRunsInDay(monthIndex: number, dayIndex: number): void {
    const newState = { ...state }
    newState.calendar[monthIndex].days[dayIndex].connectionRuns =
      !newState.calendar[monthIndex].days[dayIndex].connectionRuns

    setState(newState)
    loadNewNote()
  }

  /**
   * Set connection runs in concrete month.
   * If not every day is "turned on", turn them on.
   * If all days are "turned on", turn them off.
   * @param monthIndex
   */
  function toggleConnectionRunsInMonth(monthIndex: number): void {
    const setConnectionRunsEveryDay = state.calendar[monthIndex].days.some((_) => !_.connectionRuns)
    const newState = { ...state }

    for (let i = 0; i < newState.calendar[monthIndex].days.length; i++) {
      newState.calendar[monthIndex].days[i].connectionRuns = setConnectionRunsEveryDay
    }

    setState(newState)
    loadNewNote()
  }

  /**
   * Set connection runs in days of concrete calendar column.
   * If not every day is "turned on", turn them on.
   * If all days are "turned on", turn them off.
   * @param columnIndex Calendar column to affect
   * @param calendarStartDay Start day of calendar
   */
  function toggleConnectionRunsInDayColumn(columnIndex: number, calendarStartDay: number): void {
    const newState = { ...state }
    let setConnectionRuns = false

    for (let i = 0; i < newState.calendar.length; i++) {
      const currentMonth = newState.calendar[i]
      const currentMonthStartIndex = getLeftDayPaddingForStartDay(
        currentMonth.days[0].dateRepresentation,
        calendarStartDay
      )
      const indexToChange = columnIndex - currentMonthStartIndex

      if (0 <= indexToChange && indexToChange < currentMonth.days.length)
        setConnectionRuns = setConnectionRuns || !currentMonth.days[indexToChange].connectionRuns
    }

    for (let i = 0; i < newState.calendar.length; i++) {
      const currentMonth = newState.calendar[i]
      const currentMonthStartIndex = getLeftDayPaddingForStartDay(
        currentMonth.days[0].dateRepresentation,
        calendarStartDay
      )
      const indexToChange = columnIndex - currentMonthStartIndex

      if (0 <= indexToChange && indexToChange < currentMonth.days.length)
        currentMonth.days[indexToChange].connectionRuns = setConnectionRuns
    }

    setState(newState)
    loadNewNote()
  }

  /**
   * Set connection runs in days, that concrete fixed code applies to.
   * If not every day is "turned on", turn them on.
   * If all days are "turned on", turn them off.
   * @param fixedCode
   */
  function toggleConnectionRunsInFixedCode(
    fixedCode: '1' | '2' | '3' | '4' | '5' | '6' | '7' | 'X' | 'cross' | 'all'
  ): void {
    const newState = { ...state }
    let setConnectionRuns = false

    let isDayAppliedToFixedCode: ((day: CalendarDay) => boolean) | undefined = undefined
    switch (fixedCode) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
        isDayAppliedToFixedCode = (day) => {
          return day.dateRepresentation.weekday.toString() === fixedCode
        }
        break
      case 'X':
        isDayAppliedToFixedCode = (day) => {
          return !day.isWeekend && !day.isHoliday
        }
        break
      case 'cross':
        isDayAppliedToFixedCode = (day) => {
          return day.isHoliday
        }
        break
      default:
        isDayAppliedToFixedCode = (_) => true
    }

    for (let i = 0; i < newState.calendar.length; i++)
      for (const day of newState.calendar[i].days)
        if (isDayAppliedToFixedCode(day))
          setConnectionRuns = setConnectionRuns || !day.connectionRuns

    for (let i = 0; i < newState.calendar.length; i++) {
      const currentMonth = newState.calendar[i]

      for (let j = 0; j < currentMonth.days.length; j++)
        if (isDayAppliedToFixedCode(currentMonth.days[j]))
          currentMonth.days[j].connectionRuns = setConnectionRuns
    }

    setState(newState)
    loadNewNote()
  }

  /**
   * Get month's days' left padding.
   * Month in calendar table has to be shifted according to day it starts in.
   * E.g.: Calendar table starts with Tuesday but current month starts in Thursday,
   *       so left padding will be 2.
   * @param startDay Current month's start day
   * @param calendarStartWeekDay Start day of calendar
   */
  function getLeftDayPaddingForStartDay(startDay: DateTime, calendarStartWeekDay: number): number {
    const monthFirstDay = DateTime.now().set({ year: startDay.year, month: startDay.month, day: 1 })
    const leftPaddingForDayOne = (monthFirstDay.weekday + 7 - calendarStartWeekDay) % 7

    return leftPaddingForDayOne + (startDay.day - 1)
  }

  /**
   * Save calendar data
   */
  const saveCalendar = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Connections.Calendar.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        ),
        getDatesWhenConnectionRuns()
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection calendar was saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        handleComponentError(`Error while saving calendar. ${error}`)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving connection calendar'),
            type: NotificationType.Error,
          },
        })

        throw error
      })

  /**
   * Handle error in component
   * @param error
   */
  function handleComponentError(error: any): void {
    Logger.logError(error.toString())
  }

  const nullArray = (size: number): null[] => {
    return new Array(size).fill(null)
  }

  const minStartDayOfMonths =
    state.calendar.length > 0
      ? Math.min(
          ...state.calendar.map((_) => {
            return _.days[0].dateRepresentation.weekday
          })
        )
      : 0
  let longestDayCountInView =
    state.calendar.length > 0
      ? Math.max(
          ...state.calendar.map((_) => {
            const date = _.days[0].dateRepresentation
            return date.weekday + date.daysInMonth
          })
        )
      : 0
  longestDayCountInView -= minStartDayOfMonths

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <div className='connection-calendar container-fluid'>
      <div className='col-12 connection-calendar-column'>
        {state.calendar.length === 0 ? (
          <LoadingFailed tryAgainFn={loadCalendar} />
        ) : (
          <>
            <div className='button-group fixed-codes-toggles'>
              <Button
                id={guideContent.connections.editor.calendar.toggles.daily.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('all')}
                className={'toggle-button'}
              >
                {Localizer.localize('Daily')}
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.one.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('1')}
                className={'toggle-button'}
              >
                <i className='tti tt-1' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.two.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('2')}
                className={'toggle-button'}
              >
                <i className='tti tt-2' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.three.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('3')}
                className={'toggle-button'}
              >
                <i className='tti tt-3' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.four.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('4')}
                className={'toggle-button'}
              >
                <i className='tti tt-4' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.five.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('5')}
                className={'toggle-button'}
              >
                <i className='tti tt-5' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.six.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('6')}
                className={'toggle-button'}
              >
                <i className='tti tt-6' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.seven.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('7')}
                className={'toggle-button'}
              >
                <i className='tti tt-7' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.x.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('X')}
                className={'toggle-button'}
              >
                <i className='tti tt-x-hammers' />
              </Button>
              <Button
                id={guideContent.connections.editor.calendar.toggles.cross.target}
                size={ButtonSize.ExtraSmall}
                shape={ButtonShape.Rounded}
                color={ButtonColor.Light}
                onClick={() => toggleConnectionRunsInFixedCode('cross')}
                className={'toggle-button'}
              >
                <i className='tti tt-cross' />
              </Button>
            </div>

            <table className='table table-responsive table-borderless table-sm'>
              <tbody>
                <tr>
                  <td />
                  {nullArray(longestDayCountInView).map((_, index) => (
                    <td style={{ padding: '1px' }} key={index}>
                      <button
                        id={
                          index === 0
                            ? guideContent.connections.editor.calendar.toggles.dayColumn.target
                            : ''
                        }
                        type='button'
                        className='button button-rounded button-light button-small w-100 px-2 m-0'
                        onClick={() => toggleConnectionRunsInDayColumn(index, minStartDayOfMonths)}
                      >
                        {Localizer.localize(
                          Date.getWeekDayShortName((index + minStartDayOfMonths) % 7)
                        )}
                      </button>
                    </td>
                  ))}
                </tr>

                {state.calendar.map((month, monthIndex) => (
                  <tr key={`${month.year}/${month.month}`}>
                    <td style={{ padding: '1px' }}>
                      <button
                        id={
                          monthIndex === 0
                            ? guideContent.connections.editor.calendar.toggles.monthRow.target
                            : ''
                        }
                        type='button'
                        className='button button-rounded button-light button-small w-100 px-2 m-0'
                        onClick={() => toggleConnectionRunsInMonth(monthIndex)}
                      >
                        {Info.months('long', { locale: 'sk' })[month.month - 1]} {month.year}
                      </button>
                    </td>

                    {nullArray(
                      getLeftDayPaddingForStartDay(
                        month.days[0].dateRepresentation,
                        minStartDayOfMonths
                      )
                    ).map((_, index) => (
                      <td key={index} />
                    ))}
                    {month.days.map((day, dayIndex) => (
                      <td style={{ padding: '1px' }} key={dayIndex}>
                        <button
                          id={
                            monthIndex === 0 && dayIndex === 0
                              ? guideContent.connections.editor.calendar.toggles.dayCell.target
                              : ''
                          }
                          className={
                            'toggle-button button button-rounded button-small px-2 ' +
                            (day.isWeekend || day.isHoliday ? 'weekend-holiday ' : '') +
                            (day.connectionRuns ? '' : 'button-light')
                          }
                          onClick={() => toggleConnectionRunsInDay(monthIndex, dayIndex)}
                        >
                          {day.day}
                        </button>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div>
              <b>{state.apiData?.originalNote ? `${Localizer.localize('Original note')}: ` : ''}</b>{' '}
              {state.apiData?.originalNote}
            </div>
            {state.newNote && (
              <div>
                <b>{Localizer.localize('New note')}:</b> {state.newNote}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
