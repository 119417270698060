import React from 'react'
import LineTypeGuide from '../LineTypeGuide'

export const linesGuide = {
  lineNumber: {
    target: 'jdf-line-number',
    text:
      'Okrem čísla linky sa uvádza v pravom hornom rohu (pokiaľ je k dispozícii) tzv. rozlíšenie linky, ktoré sa' +
      ' používa na rozlíšenie verzií liniek, ak v JDF dávke je linka uvedená viackrát. Kliknutie na číslo linky zobrazí vývesku linky.',
  },
  name: {
    target: 'jdf-line-name',
    text: 'Názov linky, tak ako je uvedený v JDF dávke. Kliknutie na názov linky zobrazí vývesku linky.',
  },
  validity: {
    target: 'jdf-line-validity',
    text: 'Začiatok a koniec platnosti linky.',
  },
  lineType: {
    target: 'jdf-line-type',
    text: <LineTypeGuide />,
  },
  unknownStations: {
    target: 'jdf-line-unknown-stations',
    text: 'Počet nespárovaných zastávok linky s centrálnou databázou.',
  },
  unknownProviders: {
    target: 'jdf-line-unknown-providers',
    text: 'Počet nespárovaných dopravcov linky s centrálnou databázou.',
  },
  toolbar: {
    connectionFilter: {
      target: 'jdf-lines-connection-filter',
      text: 'Filter na spoje zobrazené vo výveske, ktorý umožňuje zobraziť všetky spoje, párne spoje alebo len nepárne spoje linky.',
    },
    newConnection: {
      target: 'jdf-lines-add-connection',
      text: 'Pridanie spoja na vývesku linky. Aplikácia neumožní pridať existujúce číslo spoja.',
    },
    editLine: {
      target: 'jdf-lines-edit',
      text: 'Zobrazenie stránky s trasou linky, kde je umožnená jej úprava.',
    },
    optimizeRoute: {
      target: 'jdf-lines-optimize-route',
      text: 'Minimalizácia počtu zastávok potrebných na zobrazenie párnych aj nepárnych spojov.',
    },
    allLines: {
      target: 'jdf-lines-all',
      text: 'Zobrazenie čísiel všetkých liniek na zrýchlený výber konkrétnej linky.',
    },
    deleteLine: {
      target: 'jdf-lines-delete',
      text: 'Odstránenie linky.',
    },
  },
  renameLine: {
    target: 'jdf-line-rename',
    text: 'Premenovanie názvu linky.',
  },
  changeProvider: {
    target: 'jdf-line-change-provider',
    text: 'Zmena dopravcu linky. Aktivovaním sa zobrazí stránka na úpravu linky.',
  },
  changeStation: {
    target: 'jdf-change-station',
    text:
      'Vyvolanie nástroja na zmenu zastávky. Písaním začiatočných písmen oddelených medzerou sa ponúkajú' +
      ' vyhovujúce zastávky z databázy zastávok.',
  },
  showStationOnMap: {
    target: 'jdf-show-station-on-map',
    text: 'Zobrazenie zastávky na mape.',
  },
  connectionButton: {
    target: 'jdf-connection-button',
    text: 'Zobrazenie stránky s trasou spoja, kde je umožnená jeho úprava.',
  },
  editor: {
    toolbar: {
      showLineButton: {
        target: 'jdf-line-edit-toolbar-show-line',
        text: 'Zobrazenie vývesky linky.',
      },
      routeButton: {
        target: 'jdf-line-edit-toolbar-route',
        text: 'Zobrazenie trasy linky.',
      },
      providerButton: {
        target: 'jdf-line-edit-toolbar-provider',
        text: 'Zobrazenie dopravcu linky.',
      },
      notesButton: {
        target: 'jdf-line-edit-toolbar-notes',
        text: 'Zobrazenie poznámok linky.',
      },
      saveButton: {
        target: 'jdf-line-edit-toolbar-save',
        text: 'Uloženie zmien vykonaných nad linkou.',
      },
    },
    route: {
      addStationButton: {
        target: 'jdf-line-edit-route-add-station-button',
        text: 'Pridanie zastávky na koniec trasy linky.',
      },
      addStationInput: {
        target: 'jdf-line-edit-route-add-station-input',
        text: 'Písaním začiatočných písmen oddelených medzerou sa ponúkajú zastávky z databázy zastávok.'
      },
      addStationConfirm: {
        target: 'jdf-line-edit-route-add-station-confirm',
        text: 'Pridanie zastávky na koniec trasy linky.'
      },
      addStationCancel: {
        target: 'jdf-line-edit-route-add-station-cancel',
        text: 'Ukončenie nástroja na pridanie zastávky bez akceptovania pridania.'
      },
      changeStation: {
        target: 'jdf-line-edit-route-change-station',
        text: 'Vyvolanie nástroja na zmenu zastávky. Písaním začiatočných písmen oddelených medzerou sa ponúkajú vyhovujúce zastávky z databázy zastávok.',
      },
      showOnMap: {
        target: 'jdf-line-edit-route-show-on-map',
        text: 'Zobrazenie zastávky na mape.',
      },
      deleteStation: {
        target: 'jdf-line-edit-route-delete-station',
        text: 'Odstránenie zastávky z trasy linky.',
      },
    },
    provider: {
      changeProvider: {
        target: 'jdf-line-edit-provider-change-provider',
        text: 'Zmena dopravcu linky.',
      },
      providerDropdown: {
        target: 'jdf-line-edit-provider-list',
        text: 'Výberom zo zoznamu dopravcov sa zmení dopravca linky.',
      },
      confirmChange: {
        target: 'jdf-line-edit-provider-confirm',
        text:
          'Zmena dopravcu linky. Aby sa nový dopravca zapísal do dát, musí sa následne použiť oranžovo' +
          ' podfarbená voľba ULOŽIŤ na uloženie zmien vykonaných nad linkou.',
      },
      cancelChange: {
        target: 'jdf-line-edit-provider-cancel',
        text: 'Ukončenie nástroja na zmenu dopravcu bez akceptovania zmeny dopravcu.',
      },
    },
    notes: {
      editNote: {
        target: 'jdf-line-edit-notes-edit-button',
        text: 'Úprava linkovej poznámky.',
      },
      deleteNote: {
        target: 'jdf-line-edit-notes-delete-button',
        text: 'Odstránenie linkovej poznámky.',
      },
      add: {
        target: 'jdf-line-edit-notes-add-button',
        text: 'Pridanie novej linkovej poznámky.',
      },
      confirmChange: {
        target: 'jdf-line-edit-notes-confirm-change',
        text:
          'Pridanie/úprava linkovej poznámky. Aby sa linková poznámka zapísala do dát, musí sa' +
          ' následne použiť oranžovo podfarbená voľba ULOŽIŤ na uloženie zmien vykonaných nad linkou.',
      },
      cancelChange: {
        target: 'jdf-line-edit-notes-cancel-change',
        text: 'Ukončenie nástroja na pridanie/úpravu linkovej poznámky bez pridania/akceptovania zmien.',
      },
    },
  },
}
