import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { UrlProvider } from '../../../../to-refactor/UrlProvider'
import { LineNumberExtended } from '../../../../models/LineModels'
import { Logger } from '../../../../utils/logger'
import { Localizer } from '../../../../utils/localizer'
import { LoadingFailed, LoadingState } from '../../../../to-refactor/LoadingHelpers'
import { ConnectionAlternativeProviderEditor } from './ConnectionAlternativeProviderEditor'
import { Provider } from '../../provider/ProvidersViewer'
import {
  AppGuideContext,
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Preloader,
  useAppGuide,
} from '@inprop/tt-ui-elements'
import { NotificationsContext, NotificationType } from '../../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { JdfConnectionEditorContext } from '../../../../contexts/JdfConnectionEditorContext'
import cx from 'classnames'
import { guideContent } from '../../../guide/content/guideContent'
import { JdfAlternativeProvider } from '../../../../models/jdf/alternativeProvider'

export interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
}

interface State {
  mainProvider?: string
  alternativeProviders: JdfAlternativeProvider[]
  possibleAlternativeProviders: Provider[]
  isAlternativeProviderEditorVisible: boolean
  editingAlternativeProviderIndex?: number
}

interface ApiData {
  mainProvider: string
  alternativeProviders: JdfAlternativeProvider[]
  possibleAlternativeProviders: Provider[]
}

export function ConnectionProvidersEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfConnectionEditorContext)
  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  const [state, setState] = useState<State>({
    mainProvider: undefined,
    alternativeProviders: [],
    possibleAlternativeProviders: [],
    isAlternativeProviderEditorVisible: false,
    editingAlternativeProviderIndex: undefined,
  })
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading)

  useAppGuide(
    [
      guideContent.connections.editor.providers.edit,
      guideContent.connections.editor.providers.delete,
    ],
    state.alternativeProviders.length > 0 && isGuideVisible,
    'append'
  )
  useAppGuide(
    [guideContent.connections.editor.providers.manage],
    state.possibleAlternativeProviders.length === 0
  )
  useAppGuide(
    [guideContent.connections.editor.providers.add],
    state.possibleAlternativeProviders.length > 0
  )

  useEffect(() => {
    loadData()
  }, [props.dataId, props.lineNumber, props.connectionNumber])

  useEffect(() => {
    editorDispatch.setSaveFn(saveProviders)
  }, [state.alternativeProviders])

  function loadData(): void {
    setLoadingState(LoadingState.Loading)

    axios
      .get<ApiData>(
        UrlProvider.Api.Components.Connections.ProvidersEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        )
      )
      .then((response) => {
        setState((previousState) => {
          return {
            ...previousState,
            mainProvider: response.data.mainProvider,
            alternativeProviders: response.data.alternativeProviders,
            possibleAlternativeProviders: response.data.possibleAlternativeProviders,
          }
        })

        setLoadingState(LoadingState.LoadingSucceeded)
      })
      .catch((error) => {
        setLoadingState(LoadingState.LoadingFailed)

        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection providers could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleComponentError(error)
      })
  }

  function hideEditor(): void {
    setState((_) => {
      return {
        ..._,
        isAlternativeProviderEditorVisible: false,
        editingAlternativeProviderIndex: undefined,
      }
    })
  }

  function saveEditingAlternativeProvider(alternativeProvider: JdfAlternativeProvider): void {
    editorDispatch.setHasUnsavedChanges(true)

    if (state.editingAlternativeProviderIndex || state.editingAlternativeProviderIndex === 0) {
      setState((_) => {
        return {
          ..._,
          isAlternativeProviderEditorVisible: false,
          editingAlternativeProviderIndex: undefined,
          alternativeProviders: _.alternativeProviders.map((_, index) => {
            if (index === state.editingAlternativeProviderIndex) return alternativeProvider
            else return _
          }),
        }
      })

      return
    }

    // complete new providers' data
    alternativeProvider.lineNumber = props.lineNumber
    alternativeProvider.connection = props.connectionNumber

    setState((_) => {
      const newAlternativeProviders = _.alternativeProviders.slice(0, _.alternativeProviders.length)
      newAlternativeProviders.push(alternativeProvider)

      return {
        ..._,
        isAlternativeProviderEditorVisible: false,
        editingAlternativeProviderIndex: undefined,
        alternativeProviders: newAlternativeProviders,
      }
    })
  }

  function deleteAlternativeProvider(index: number): void {
    editorDispatch.setHasUnsavedChanges(true)

    const newAlternativeProviders = state.alternativeProviders.slice(
      0,
      state.alternativeProviders.length
    )
    newAlternativeProviders.splice(index, 1)

    setState((_) => {
      return {
        ..._,
        alternativeProviders: newAlternativeProviders,
        editingAlternativeProviderIndex: undefined,
        isAlternativeProviderEditorVisible: false,
      }
    })
  }

  function showEditorForNewAlternativeProvider(): void {
    setState((_) => {
      return {
        ..._,
        isAlternativeProviderEditorVisible: true,
        editingAlternativeProviderIndex: undefined,
      }
    })
  }

  function showEditorForExistingAlternativeProvider(index: number): void {
    setState((_) => {
      return {
        ..._,
        isAlternativeProviderEditorVisible: true,
        editingAlternativeProviderIndex: index,
      }
    })
  }

  const saveProviders = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Connections.ProvidersEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        ),
        state.alternativeProviders
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection providers were saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving connection providers'),
            type: NotificationType.Error,
          },
        })

        handleComponentError(error)

        throw error
      })

  const getEditingAlternativeProvider = (): JdfAlternativeProvider | undefined => {
    if (!state.editingAlternativeProviderIndex && state.editingAlternativeProviderIndex !== 0) {
      return undefined
    }

    return state.alternativeProviders[state.editingAlternativeProviderIndex]
  }

  function handleComponentError(error: any): void {
    Logger.logError(
      `Error in ${ConnectionProvidersEditor.name}. Error details: ${error.toString()}`
    )
  }

  if (loadingState === LoadingState.Loading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <div className='container connection-providers-editor'>
      {loadingState === LoadingState.LoadingFailed ? (
        <LoadingFailed tryAgainFn={loadData} />
      ) : (
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-4'>
              <h4 className='mb-2'>{Localizer.localize('Providers')}</h4>
              <b>{Localizer.localize('Main provider')}:</b> {state.mainProvider}
            </div>

            <h4 className='mb-2'>{Localizer.localize('Alternative providers')}</h4>
            {state.alternativeProviders.length !== 0 ? null : (
              <p>{Localizer.localize('Connection has no alternative providers')}</p>
            )}
            <ul className='list-unstyled mb-2'>
              {state.alternativeProviders.map((_, index) => (
                <li className='d-flex align-items-center mb-1 display-child-on-hover' key={index}>
                  <span className='mr-2'>{_.stringValue}</span>
                  <div
                    className={cx('button-group', {
                      'display-on-parent-hover': !(index === 0 && isGuideVisible),
                    })}
                  >
                    <Button
                      id={index === 0 ? guideContent.connections.editor.providers.edit.target : ''}
                      size={ButtonSize.ExtraSmall}
                      shape={ButtonShape.Rounded}
                      color={ButtonColor.Light}
                      onClick={() => showEditorForExistingAlternativeProvider(index)}
                      className={'m-0'}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Button
                      id={
                        index === 0 ? guideContent.connections.editor.providers.delete.target : ''
                      }
                      size={ButtonSize.ExtraSmall}
                      shape={ButtonShape.Rounded}
                      color={ButtonColor.Light}
                      onClick={() => deleteAlternativeProvider(index)}
                      className={'m-0'}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className='col-md-6'>
            {!state.isAlternativeProviderEditorVisible ? (
              state.possibleAlternativeProviders.length === 0 ? (
                <>
                  <p className={'d-flex align-items-center text-muted mt-4 mb-2'}>
                    <FontAwesomeIcon icon={faInfo} className={'ml-2 mr-4'} />
                    <span className={'small'}>
                      {Localizer.localize('There are no possible alternative providers.') + ' '}
                    </span>
                  </p>
                  <Button
                    id={guideContent.connections.editor.providers.manage.target}
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Light}
                    href={UrlProvider.Page.Jdf.Providers(props.dataId)}
                    className={'ml-0'}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} className={'mr-2'} />
                    {Localizer.localize('Manage providers')}
                  </Button>
                </>
              ) : (
                <Button
                  id={guideContent.connections.editor.providers.add.target}
                  size={ButtonSize.ExtraSmall}
                  shape={ButtonShape.Rounded}
                  onClick={showEditorForNewAlternativeProvider}
                >
                  <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                  {Localizer.localize('Add new alternative provider')}
                </Button>
              )
            ) : (
              <ConnectionAlternativeProviderEditor
                alternativeProvider={getEditingAlternativeProvider()}
                possibleAlternativeProviders={state.possibleAlternativeProviders}
                onSave={(alternativeProvider) =>
                  saveEditingAlternativeProvider(alternativeProvider)
                }
                onCancel={() => hideEditor()}
              />
            )}
          </div>

          {/* //TODO this code is probably not needed */}
          {/*{props.setSaveFn ? null : (*/}
          {/*  <Button*/}
          {/*    size={ButtonSize.ExtraSmall}*/}
          {/*    shape={ButtonShape.Rounded}*/}
          {/*    onClick={() =>*/}
          {/*      saveProviders(*/}
          {/*        () => {},*/}
          {/*        () => {}*/}
          {/*      )*/}
          {/*    }*/}
          {/*    iconType={IconType.Ok}*/}
          {/*    isActing={isSaving}*/}
          {/*    customTextWhileActing={`${Localizer.localize('Saving')} ...`}*/}
          {/*  >*/}
          {/*    {Localizer.localize('Save')}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>
      )}
    </div>
  )
}