import React, { createContext, Dispatch, FC, useReducer } from 'react'
import {
  JdfConnectionRouteEditorActionType,
  JdfConnectionRouteEditorReducer,
} from './JdfConnectionRouteEditorReducer'

export interface ActiveInput {
  tariffNumber: number
  type: 'arrival' | 'departure'
}

export interface JdfConnectionRouteEditorState {
  activeInput?: ActiveInput
  errors: string[]
}

const initialState: JdfConnectionRouteEditorState = {
  activeInput: undefined,
  errors: [],
}

const JdfConnectionRouteEditorContext = createContext<{
  state: JdfConnectionRouteEditorState
  dispatch: Dispatch<JdfConnectionRouteEditorActionType>
}>({
  state: initialState,
  dispatch: () => {},
})

const JdfConnectionRouteEditorContextProvider: FC = (props) => {
  const [state, dispatch] = useReducer(JdfConnectionRouteEditorReducer, initialState)

  return (
    <JdfConnectionRouteEditorContext.Provider value={{ state, dispatch }}>
      {props.children}
    </JdfConnectionRouteEditorContext.Provider>
  )
}

export { JdfConnectionRouteEditorContext, JdfConnectionRouteEditorContextProvider }
