/* Action constants */
import {ActiveInput, JdfConnectionRouteEditorState} from './JdfConnectionRouteEditorContext'

export const SET_ACTIVE_INPUT = 'jdf-connection-route-editor/set-active-input'

/* Action interfaces */
export interface SetActiveInputAction {
  type: typeof SET_ACTIVE_INPUT
  value?: ActiveInput
}

/* Action functions */
const setActiveInput = (
  value: ActiveInput | undefined,
  state: JdfConnectionRouteEditorState
): JdfConnectionRouteEditorState => {
  return { ...state, activeInput: value }
}

export type JdfConnectionRouteEditorActionType = SetActiveInputAction

export const JdfConnectionRouteEditorReducer = (
  state: JdfConnectionRouteEditorState,
  action: JdfConnectionRouteEditorActionType
): JdfConnectionRouteEditorState => {
  switch (action.type) {
    case SET_ACTIVE_INPUT:
      return setActiveInput(action.value, state)
    default:
      return state
  }
}
