import React, { FC, useCallback, useMemo, useState } from 'react'
import { RegistrationAllowedEmail } from '../../models/AuthViewModels'
import { getMuiGridTranslations } from '../../utils/muiTranslations'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Localizer } from '../../utils/localizer'
import { getDateTimeForHumanWithoutLocale } from '../../utils/dateTimeUtils'
import { DateTime } from 'luxon'
import { Alert, Box, Button, IconButton, Stack, Tooltip } from '@mui/material'
import { useAllRegistrationAllowedEmails } from '../../data/queryFunctions'
import RegistrationAllowedEmailDialog from './RegistrationAllowedEmailDialog'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import RegistrationAllowedEmailDeleteConfirmDialog from './RegistrationAllowedEmailDeleteConfirmDialog'

const RegistrationAllowedEmailsList: FC = () => {
  const { data: registrationAllowedEmails, isLoading, error } = useAllRegistrationAllowedEmails()
  
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<boolean>(false)
  const [editSelectedRegistrationAllowedEmail, setEditSelectedRegistrationAllowedEmail] = useState<RegistrationAllowedEmail>()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [deleteSelectedRegistrationAllowedEmail, setDeleteSelectedRegistrationAllowedEmail] = useState<RegistrationAllowedEmail>()

  const handleAddBtnClick = () => {
    setEditSelectedRegistrationAllowedEmail(undefined)
    setAddEditDialogOpen(true)
  }

  const handleEditBtnClick = useCallback((registrationAllowedEmail: RegistrationAllowedEmail) => {
    setEditSelectedRegistrationAllowedEmail(registrationAllowedEmail)
    setAddEditDialogOpen(true)
  }, [])

  const handleAddEditDialogClose = () => {
    setEditSelectedRegistrationAllowedEmail(undefined)
    setAddEditDialogOpen(false)
  }
  
  const handleDeleteBtnClick = useCallback((registrationAllowedEmail: RegistrationAllowedEmail) => {
    setDeleteSelectedRegistrationAllowedEmail(registrationAllowedEmail)
    setDeleteDialogOpen(true)
  }, [])

  const handleDeleteDialogClose = () => {
    setDeleteSelectedRegistrationAllowedEmail(undefined)
    setDeleteDialogOpen(false)
  }
 
  const columns: GridColDef<RegistrationAllowedEmail>[] = useMemo(
    () => [
      {
        field: 'emailAddress',
        headerName: Localizer.localize('Email address'),
        flex: 2,
      },
      {
        field: 'roleName',
        headerName: Localizer.localize('Role'),
        flex: 1,
        valueGetter: params => Localizer.localize(params.value)
      } as GridColDef<RegistrationAllowedEmail, string>,
      {
        field: 'invitationDateTime',
        headerName: Localizer.localize('Invitation date'),
        type: 'dateTime',
        flex: 1,
        valueFormatter: params => getDateTimeForHumanWithoutLocale(params.value),
      } as GridColDef<RegistrationAllowedEmail, DateTime, string>,
      {
        field: 'addedByUser',
        headerName: Localizer.localize('Invited by'),
        flex: 1.5,
        valueGetter: params =>
          (params.row.addedByUser.lastName && params.row.addedByUser.firstName)
          ? `${params.row.addedByUser.lastName} ${params.row.addedByUser.firstName}`
          : `${params.row.addedByUser.userName}`
      } as GridColDef<RegistrationAllowedEmail, string>,
      {
        field: 'isAlreadyRegistered',
        headerName: Localizer.localize('Is already registered?'),
        type: 'boolean',
        flex: 0.75,
        minWidth: 80,
      },
      {
        field: 'actions',
        headerName: Localizer.localize('Actions'),
        sortable: false,
        filterable: false,
        width: 100,
        renderCell: params => {
          return (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Tooltip title={!params.row.isAlreadyRegistered
                ? Localizer.localize('Edit')
                : Localizer.localize('There is a user already registered with this email address, so this invitation cannot be updated anymore')
              }>
                <Box>
                  <IconButton
                    onClick={() => handleEditBtnClick(params.row)}
                    disabled={params.row.isAlreadyRegistered}
                  >
                    <EditRoundedIcon fontSize={'small'}/>
                  </IconButton>
                </Box>
              </Tooltip>
              
              <Tooltip title={!params.row.isAlreadyRegistered
                ? Localizer.localize('Delete')
                : Localizer.localize('There is a user already registered with this email address, so this invitation cannot be deleted anymore')
              }>
                <Box>
                  <IconButton
                    onClick={() => handleDeleteBtnClick(params.row)}
                    disabled={params.row.isAlreadyRegistered}
                  >
                    <DeleteRoundedIcon fontSize={'small'}/>
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          ) 
        },
      } as GridColDef<RegistrationAllowedEmail>,
    ],
    [handleEditBtnClick, handleDeleteBtnClick],
  )

  const gridTranslations = useMemo(() => getMuiGridTranslations(), [])
  
  if (error) {
    console.error('Error when loading list of registration allowed emails: ', error)

    return (
      <div className={'row py-4 w-100'}>
        <div className={'col-12 d-flex'}>
          <Alert 
            severity={'error'}
            sx={{height: 50, mb: 3}}
            className={'w-100'}
          >
            {Localizer.localize('Could not load list of registration allowed emails')}
          </Alert>
        </div>
      </div>
    )
  }

  return (
    <>
      <RegistrationAllowedEmailDeleteConfirmDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        deleteSelectedRegistrationAllowedEmail={deleteSelectedRegistrationAllowedEmail}
      />

      <div id={'registrationAllowedEmailsContainer'}
           className={'d-flex flex-column flex-grow-1'}>

        <div className={'row pt-4 pb-1 w-100'}>
          <div className={'col-12 d-flex flex-row-reverse'}>
            <Button
              variant={'contained'}
              onClick={handleAddBtnClick}
            >
              {Localizer.localize('Add')}
            </Button>

            <RegistrationAllowedEmailDialog
              open={addEditDialogOpen}
              handleClose={handleAddEditDialogClose}
              editSelectedRegistrationAllowedEmail={editSelectedRegistrationAllowedEmail}
            />
          </div>
        </div>

        <div className={'row pb-4 w-100 flex-grow-1'}>
          <div className={'col-12 d-flex'}>
            <Box className={'w-100'}>
              <DataGrid<RegistrationAllowedEmail>
                columns={columns}
                rows={registrationAllowedEmails ?? []}
                loading={isLoading}
                pageSize={10}
                rowsPerPageOptions={[10, 15, 20]}
                localeText={gridTranslations}
                disableSelectionOnClick={true}
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationAllowedEmailsList
