import React, { FC } from 'react'
import { List, ListItem } from '@mui/material'

const LineTypeListItem: FC<{ mark: string; text: string }> = ({ mark, text }) => (
  <ListItem>
    <div className={'badge badge-light mr-2'}>{mark}</div>
    <span>{text}</span>
  </ListItem>
)

const LineTypeGuide: FC = () => {
  return (
    <>
      Typ linky, ktorý môže byť nasledovný:
      <List>
        <LineTypeListItem mark={'A'} text={'Mestská linka'} />
        <LineTypeListItem mark={'B'} text={'Mestská linka s obsluhou prímestských oblastí'} />
        <LineTypeListItem
          mark={'N'}
          text={'Medzinárodná linka s vylúčenou vnútroštátnou dopravou'}
        />
        <LineTypeListItem
          mark={'P'}
          text={'Medzinárodná linka s povolenou vnútroštátnou dopravou'}
        />
        <LineTypeListItem mark={'V'} text={'Vnútroštátna linka – vnútro krajská'} />
        <LineTypeListItem mark={'Z'} text={'Vnútroštátna linka – medzi krajská'} />
        <LineTypeListItem mark={'D'} text={'Vnútroštátna linka – diaľková'} />
      </List>
    </>
  )
}

export default LineTypeGuide
