import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, ButtonColor, ButtonShape, ButtonSize, JdfLineNumber } from '@inprop/tt-ui-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { Localizer } from '../../../utils/localizer'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import cx from 'classnames'
import { appUrls } from '../../../utils/urls'
import { Line } from '../../../models/LineModels'
import { useHistory } from 'react-router-dom'
import { JdfContext } from '../../../contexts/JdfContext'
import unwrap from '../../../utils/unwrap'
import { JdfLineEditorContext, LineEditor } from '../../../contexts/JdfLineEditorContext'
import { Dialog, DialogActions, DialogTitle } from '@mui/material'
import { NavigationContext } from '../../../contexts/NavigationContext'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

export interface Props {
  line: Line
}

const LineEditorToolbar: FC<Props> = ({ line }) => {
  const {
    state: { jdfDataId },
  } = useContext(JdfContext)
  const { state: editorState, dispatch: editorDispatch } = useContext(JdfLineEditorContext)
  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  const history = useHistory()

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [actionToConfirm, setActionToConfirm] = useState<(() => void) | undefined>(undefined)

  useAppGuide([
    guideContent.lines.editor.toolbar.showLineButton,
    guideContent.lines.editor.toolbar.routeButton,
    guideContent.lines.editor.toolbar.providerButton,
    guideContent.lines.editor.toolbar.notesButton,
    guideContent.lines.editor.toolbar.saveButton
  ])

  const redirectToLine = () => {
    history.push(
      appUrls.jdf.lineDetails.withParams({
        jdfDataId: unwrap(jdfDataId),
        lineNumber: line.lineNumber.lineNumber,
        lineNumberExtension: line.lineNumber.lineNumberExtension,
      })
    )
  }

  const changeEditor = (newEditor: LineEditor): void => {
    if (editorState.currentEditor === newEditor) return

    editorDispatch.setCurrentEditor(newEditor)
  }

  const doIfChangesSaved = (action: () => void): void => {
    if (!editorState.hasUnsavedChanges) {
      action()

      return
    }

    setActionToConfirm(() => action)
  }

  useEffect(() => {
    editorDispatch.setHasUnsavedChanges(false)
  }, [line])

  const save = async (): Promise<boolean> => {
    let saveSuccess = true

    setIsSaving(true)
    try {
      await editorState.saveFn()
      editorDispatch.setHasUnsavedChanges(false)
    } catch {
      saveSuccess = false
    }
    setIsSaving(false)

    return saveSuccess
  }

  const handleDialogClose = () => setActionToConfirm(undefined)

  const saveAndContinue = async () => {
    const saveSuccess = await save()

    if (saveSuccess) {
      confirmAndCloseDialog()
    }
  }

  const confirmAndCloseDialog = () => {
    if (actionToConfirm) {
      actionToConfirm()
      setActionToConfirm(undefined)
    }
  }

  useEffect(() => {
    if (editorState.hasUnsavedChanges) {
      navigationDispatch.setOnNavigateElsewhere((confirmRedirect: () => void) =>
        doIfChangesSaved(confirmRedirect)
      )
    } else {
      navigationDispatch.setOnNavigateElsewhere(undefined)
    }
  }, [editorState.hasUnsavedChanges])

  return (
    <>
      <Dialog onClose={handleDialogClose} open={actionToConfirm !== undefined}>
        <DialogTitle>
          {Localizer.localize('Do you really want to leave without saving your changes?')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={saveAndContinue} size={ButtonSize.ExtraSmall} isActing={isSaving}>
            {Localizer.localize('Save and continue')}
          </Button>
          <Button
            color={ButtonColor.Danger}
            size={ButtonSize.ExtraSmall}
            onClick={confirmAndCloseDialog}
          >
            {Localizer.localize('Leave without saving')}
          </Button>
        </DialogActions>
      </Dialog>

      <div className={'d-flex align-items-center flex-wrap'}>
        <Button
          id={guideContent.lines.editor.toolbar.showLineButton.target}
          size={ButtonSize.ExtraSmall}
          color={ButtonColor.Light}
          className={'m-0 mr-4'}
          onClick={() => doIfChangesSaved(redirectToLine)}
        >
          <FontAwesomeIcon icon={faChevronLeft} className={'mr-2'} />
          {Localizer.localize('Show line')}
        </Button>

        <div className={'d-flex align-items-center flex-grow-1 my-1'}>
          <h4 className={'mb-0 mr-3 d-flex align-items-center'}>
            <div className={'mr-1'}>{Localizer.localizeR('Edit line')}</div>
            <JdfLineNumber lineId={line.lineNumber} />
          </h4>

          <div className={'button-group'}>
            <Button
              id={guideContent.lines.editor.toolbar.routeButton.target}
              color={
                editorState.currentEditor === LineEditor.Route
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() => doIfChangesSaved(() => changeEditor(LineEditor.Route))}
              className={'mx-0'}
            >
              {Localizer.localize('Route')}
            </Button>
            <Button
              id={guideContent.lines.editor.toolbar.providerButton.target}
              color={
                editorState.currentEditor === LineEditor.Provider
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() => doIfChangesSaved(() => changeEditor(LineEditor.Provider))}
              className={'mr-0'}
            >
              {Localizer.localize('Provider')}
            </Button>
            <Button
              id={guideContent.lines.editor.toolbar.notesButton.target}
              color={
                editorState.currentEditor === LineEditor.Notes
                  ? ButtonColor.Primary
                  : ButtonColor.Light
              }
              shape={ButtonShape.Rounded}
              size={ButtonSize.ExtraSmall}
              onClick={() => doIfChangesSaved(() => changeEditor(LineEditor.Notes))}
              className={'mr-0'}
            >
              {Localizer.localize('Notes')}
            </Button>
          </div>
        </div>

        <div className={'button-group'}>
          <Button
            id={guideContent.lines.editor.toolbar.saveButton.target}
            size={ButtonSize.ExtraSmall}
            shape={ButtonShape.Rounded}
            color={editorState.hasUnsavedChanges ? ButtonColor.Amber : ButtonColor.Light}
            onClick={save}
            className={'m-0'}
            customTextWhileActing={`${Localizer.localize('Saving')} ...`}
            disabled={isSaving}
          >
            <FontAwesomeIcon
              icon={isSaving ? faSpinner : faCheck}
              className={cx('mr-2', { 'fa-spin': isSaving })}
            />
            {Localizer.localize('Save')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default LineEditorToolbar