import React, { FC, useContext, useEffect } from 'react'
import UsersList from '../../auth/UsersList'
import { LayoutContext, LayoutHeight } from '../../../contexts/LayoutContext'
import { SET_LAYOUT_HEIGHT } from '../../../contexts/LayoutReducer'

const UsersListPage: FC = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext)

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.Full })
    
    return () => layoutDispatch({ type: SET_LAYOUT_HEIGHT, value: LayoutHeight.ByContent })
  }, [layoutDispatch])
  
  return (
    <UsersList />
  )
}

export default UsersListPage
