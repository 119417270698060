import { jdfLoadGuide } from './jdfLoad'
import { navigationGuide } from './navigation'
import { Step } from 'react-joyride'
import { providersGuide } from './providers'
import { linesGuide } from './lines'
import { connectionsGuide } from './connections'
import { IAppGuideStep } from '@inprop/tt-ui-elements'

export const guideContent = {
  nav: navigationGuide,
  jdfLoad: jdfLoadGuide,
  providers: providersGuide,
  lines: linesGuide,
  connections: connectionsGuide,
}

export const navigationNoJdf = [navigationGuide.logo, navigationGuide.home, navigationGuide.map]

export const navigationWithJdf = [
  navigationGuide.logo,
  navigationGuide.home,
  navigationGuide.lines,
  navigationGuide.providers,
  navigationGuide.protocol,
  navigationGuide.map,
  navigationGuide.save,
]

export const createGuideStep = (data: IAppGuideStep): Step => ({
  target: `#${data.target}`,
  content: data.text,
  placement: data.placement ?? 'auto',
})
