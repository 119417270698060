import React, { FunctionComponent, useContext, useState } from 'react'
import { AppGuideContext, Preloader, StationNameWithWBR, useAppGuide } from '@inprop/tt-ui-elements'
import { Localizer } from '../../../utils/localizer'
import SelectStationForm from './SelectStationForm'
import { AutocompleteStation } from './StationSelectAutocomplete'
import useJdfStationApi from '../../../to-refactor/useJdfStationApi'
import { Logger } from '../../../utils/logger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap'
import { useHistory } from 'react-router-dom'
import { appUrls } from '../../../utils/urls'
import cx from 'classnames'
import { guideContent } from '../../guide/content/guideContent'
import { Button, ButtonGroup, Tooltip } from '@mui/material'

interface Props {
  stationId: string
  name: string
  singleLineName?: boolean
  isStationUnknown: boolean
  jdfDataId: string
  allowStationExchange?: boolean
  onStationExchangeDone?: () => void
  withGuide?: boolean
}

const StationName: FunctionComponent<Props> = (props) => {
  const history = useHistory()

  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  useAppGuide(
    [guideContent.lines.showStationOnMap],
    props.withGuide && !props.isStationUnknown && isGuideVisible,
    'append'
  )
  useAppGuide(
    [guideContent.lines.changeStation],
    props.withGuide && props.allowStationExchange && isGuideVisible,
    'append'
  )

  const [isStationFormVisible, setIsStationFormVisible] = useState<boolean>(false)
  const [stationToExchange, setStationToExchange] = useState<AutocompleteStation>()
  const { executeUpdate, updateState } = useJdfStationApi(
    props.jdfDataId,
    props.stationId,
    stationToExchange?.crwsId?.toString()
  )

  const exchangeStation = () => {
    if (!stationToExchange) {
      throw new Error('When exchanging station, some station must be selected.')
    }

    setIsStationFormVisible(false)

    executeUpdate()
      .then(() => {
        if (props.onStationExchangeDone) {
          props.onStationExchangeDone()
        }
      })
      .catch(() => {
        Logger.logError(
          `Could not exchange station '${props.stationId}'(JDF ID) to '${stationToExchange.crwsId}'(CRWS ID)`
        )
      })
  }

  const redirectToMap = () => {
    history.push(
      appUrls.jdf.map.withParams({
        jdfDataId: props.jdfDataId,
        sId: props.stationId,
        sIdType: 'jdf',
      })
    )
  }

  const stationName = (
    <span
      className={cx('mr-1', {
        'text-danger': props.isStationUnknown,
        'font-weight-bold': props.isStationUnknown,
      })}
    >
      {props.singleLineName ? props.name : <StationNameWithWBR name={props.name} />}
    </span>
  )

  return (
    <div className={'w-100'}>
      <div className={'d-flex align-items-center'}>
        {props.isStationUnknown ? (
          <Tooltip title={Localizer.localize('Unknown station')}>{stationName}</Tooltip>
        ) : (
          stationName
        )}

        <ButtonGroup variant={'contained'} size={'small'} sx={{ ml: 1 }}>
          {props.allowStationExchange && (
            <Tooltip title={Localizer.localize('Exchange station')}>
              <Button
                id={props.withGuide ? guideContent.lines.changeStation.target : ''}
                onClick={() => setIsStationFormVisible(true)}
                className={cx('mr-0 my-0', {
                  'display-on-parent-hover': !(props.withGuide && isGuideVisible),
                })}
                color={'leaf'}
                disabled={updateState === 'loading'}
              >
                {updateState === 'loading' ? (
                  <Preloader small overlay={false} centered />
                ) : (
                  <FontAwesomeIcon icon={faExchangeAlt} />
                )}
              </Button>
            </Tooltip>
          )}

          {!props.isStationUnknown && (
            <Tooltip title={Localizer.localize('Show on map')}>
              <Button
                id={props.withGuide ? guideContent.lines.showStationOnMap.target : ''}
                className={cx('my-0', {
                  'display-on-parent-hover': !(props.withGuide && isGuideVisible),
                })}
                onClick={redirectToMap}
              >
                <FontAwesomeIcon icon={faMap} />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      </div>

      {isStationFormVisible && (
        <SelectStationForm
          onConfirm={exchangeStation}
          onCancel={() => setIsStationFormVisible(false)}
          onChange={(_) => setStationToExchange(_)}
        />
      )}
    </div>
  )
}

StationName.defaultProps = {
  allowStationExchange: true,
  withGuide: false,
}

export default StationName
