import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { LineNumberExtended } from '../../../models/LineModels'
import { Logger } from '../../../utils/logger'
import { Localizer } from '../../../utils/localizer'
import { LoadingFailed } from '../../../to-refactor/LoadingHelpers'
import { Preloader } from '@inprop/tt-ui-elements'
import { TTFixedCode } from '../../general/TTFixedCode'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

export interface ConnectionFixedCodesEditorRef {
  saveData?: () => Promise<void>
}

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
  showSaveButton?: boolean

  onDataChanged?: () => void
  setRef?: (ref: ConnectionFixedCodesEditorRef) => void
}

interface State {
  fixedCodes: FixedCodeApiData[]
}

interface FixedCodeApiData {
  code: string
  text: string
  isChecked: boolean
}

export function ConnectionFixedCodesEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const [state, setState] = useState<State>({
    fixedCodes: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    refreshOutsideCallbacks()
    loadFixedCodes()
  }, [props.dataId, props.lineNumber, props.connectionNumber])

  useEffect(() => refreshOutsideCallbacks(), [state.fixedCodes])

  function refreshOutsideCallbacks(): void {
    if (props.setRef) props.setRef({ saveData: saveFixedCodes })
  }

  useAppGuide([guideContent.connections.editor.notes.fixedCodes], state.fixedCodes.length > 0)

  /**
   * Load all fixed codes that can be set to connection
   */
  function loadFixedCodes(): void {
    setIsLoading(true)

    axios
      .get<FixedCodeApiData[]>(
        UrlProvider.Api.Components.Connections.FixedCodesEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        )
      )
      .then((response) => {
        setState({
          ...state,
          fixedCodes: response.data,
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection fixed codes could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleComponentError(error)
      })
      .finally(() => setIsLoading(false))
  }

  /**
   * Save fixed codes to backend
   */
  function saveFixedCodes(): Promise<void> {
    return axios
      .put(
        UrlProvider.Api.Components.Connections.FixedCodesEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        ),
        state.fixedCodes
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection fixed codes were saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((_) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving connection fixed codes'),
            type: NotificationType.Error,
          },
        })

        handleComponentError(_)
        throw _
      })
  }

  /**
   * Handle error in component
   * @param error
   */
  function handleComponentError(error: any): void {
    Logger.logError(
      `Error in ${ConnectionFixedCodesEditor.toString()}. Error details: ${error.toString()}`
    )
  }

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <div>
      <h4 className='mb-2'>{Localizer.localize('Fixed codes')}</h4>

      {state.fixedCodes.length === 0 ? (
        <LoadingFailed tryAgainFn={loadFixedCodes} />
      ) : (
        state.fixedCodes.map((_, index) => (
          <div className='d-flex align-items-center mb-2' key={index}>
            <div
              id={index === 0 ? guideContent.connections.editor.notes.fixedCodes.target : ''}
              className='mr-1'
            >
              <input
                id={`fixed-code-${index}`}
                checked={_.isChecked}
                onChange={() => {
                  const newFixedCodes = state.fixedCodes.map((newFixedCode, newIndex) => {
                    if (index === newIndex)
                      return {
                        ...newFixedCode,
                        isChecked: !newFixedCode.isChecked,
                      }

                    return newFixedCode
                  })

                  setState({ ...state, fixedCodes: newFixedCodes })
                  if (props.onDataChanged) {
                    props.onDataChanged()
                  }
                }}
                className='switch-toggle switch-flat-mini switch-toggle-flat'
                type='checkbox'
              />
              <label htmlFor={`fixed-code-${index}`} className='mb-0' />
            </div>
            <span className='mx-1 text-center' style={{ width: '1.5em' }}>
              <TTFixedCode
                mark={_.code}
                relatedTo={'connection'}
                wrapperStyle={{ fontSize: '1.2em' }}
                disableTooltip
              />
            </span>
            <span style={{ fontWeight: _.isChecked ? 'bold' : 'normal' }}>{_.text}</span>
          </div>
        ))
      )}
    </div>
  )
}
