import React, { FC, useCallback, useMemo } from 'react'
import { UserExcerpt } from '../../models/AuthViewModels'
import { Localizer } from '../../utils/localizer'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { getMuiGridTranslations } from '../../utils/muiTranslations'
import { Alert, Box } from '@mui/material'
import VerifyUserButton from './VerifyUserButton'
import { DateTime } from 'luxon'
import { getDateTimeForHumanWithoutLocale } from '../../utils/dateTimeUtils'
import { useAllUsers } from '../../data/queryFunctions'

const UsersList: FC = () => {
  const { data: users, isLoading: isLoadingUsersList, refetch, error } = useAllUsers()

  const userVerifiedHandler = useCallback(
    async (user: UserExcerpt) => {
      refetch()
    }, [refetch],
  )

  const columns: GridColDef<UserExcerpt>[] = useMemo(
    () => [
      {
        field: 'email',
        headerName: Localizer.localize('Email address'),
        flex: 2,
      },
      {
        field: 'userName',
        headerName: Localizer.localize('Username'),
        flex: 1.5,
      },
      {
        field: 'registeredAt',
        headerName: Localizer.localize('Registered at'),
        type: 'dateTime',
        flex: 1.5,
        valueFormatter: params => getDateTimeForHumanWithoutLocale(params.value),
      } as GridColDef<UserExcerpt, DateTime, string>,
      {
        field: 'emailConfirmed',
        headerName: Localizer.localize('Email confirmed?'),
        flex: 0.75,
        minWidth: 80,
        type: 'boolean',
      },
      {
        field: 'isVerified',
        headerName: Localizer.localize('Verified?'),
        type: 'boolean',
        flex: 0.75,
        minWidth: 80,
      },
      {
        field: 'verifiedAt',
        headerName: Localizer.localize('Verified at'),
        type: 'dateTime',
        flex: 1.5,
        valueFormatter: params => getDateTimeForHumanWithoutLocale(params.value),
      } as GridColDef<UserExcerpt, DateTime | null, string>,
      {
        field: 'actions',
        headerName: Localizer.localize('Actions'),
        sortable: false,
        filterable: false,
        width: 100,
        renderCell: params => {
          return !params.row.isVerified
            ? <VerifyUserButton user={params.row} onUserVerified={userVerifiedHandler} />
            : null
        },
      } as GridColDef<UserExcerpt>,
    ], [userVerifiedHandler])

  const gridTranslations = useMemo(() => getMuiGridTranslations(), [])

  if (error) {
    console.error('Error when loading users list', error)
    return (
      <div className={'row py-4 w-100'}>
        <div className={'col-12 d-flex'}>
          <Alert severity={'error'}
                 sx={{ height: 50, mb: 3 }}
                 className={'w-100'}>
            {Localizer.localize('Could not load users list')}
          </Alert>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={'row py-4 w-100'}>
        <div className={'col-12 d-flex'}>
          <Box className={'w-100'}>
            <DataGrid<UserExcerpt>
              columns={columns}
              rows={users ?? []}
              loading={isLoadingUsersList}
              pageSize={10}
              rowsPerPageOptions={[10, 15, 20]}
              localeText={gridTranslations}
              disableSelectionOnClick={true}
            />
          </Box>
        </div>
      </div>
    </>
  )
}

export default UsersList
