import { DateTime, Settings } from 'luxon'

export const getDateTimeForHumanWithoutLocale = (date: DateTime | null) =>
  date?.toLocaleString(DateTime.DATETIME_SHORT) ?? ""

export const parseUTCDateTimeToLocal = (date: string) =>
  DateTime.fromISO(date, { zone: 'UTC' }).setZone(Settings.defaultZone)

export const setDefaultLuxonTimeZone = (timeZone: string) => {
  Settings.defaultZone = timeZone
}

export const luxonTimeZones = {
  sk: 'Europe/Bratislava',
  cs: 'Europe/Prague',
  utc: 'UTC'
}
