import { Button } from '@inprop/tt-ui-elements'
import React, { useEffect } from 'react'
import { LineNumberExtended } from '../../../models/LineModels'
import { LineService } from '../../../utils/lineService'

interface Props {
  lineNumber: LineNumberExtended
  type: LineNumberType
  onClick?: () => void
}

export enum LineNumberType {
  Button = 1,
  TextWithBadge = 2,
  Link = 3,
}

export const LineNumber: React.FunctionComponent<Props> = (props) => {
  useEffect(() => {
    validateProps()
  }, [])

  function validateProps(): void {
    if (props.type === LineNumberType.Button && !props.onClick) {
      throw new Error(`When LineNumberType is Button, onClick must be set in props.`)
    }
  }

  function getButtonsCss(): string {
    return (
      'align-items-center button button-light button-mini button-rounded d-flex jdf-line-label ' +
      'line-link mx-1 px-0'
    )
  }

  switch (props.type) {
    case LineNumberType.TextWithBadge:
      return (
        <>
          {props.lineNumber.lineNumber}
          {!LineService.isLineNumberExtensionNecessary(props.lineNumber) ? null : (
            <span className='badge badge-secondary mt-2 text-white' style={{ fontSize: '0.4em' }}>
              {props.lineNumber.lineNumberExtension}
            </span>
          )}
        </>
      )
    case LineNumberType.Button:
      return (
        <Button className={getButtonsCss()} style={{ fontSize: '0.85em' }} onClick={props.onClick}>
          <span className='d-flex mx-2 px-1' style={{ lineHeight: '1em' }}>
            {props.lineNumber.lineNumber}
          </span>
          {!LineService.isLineNumberExtensionNecessary(props.lineNumber) ? null : (
            <span className='align-self-start badge badge-secondary d-flex ml-n2'>
              {props.lineNumber.lineNumberExtension}
            </span>
          )}
        </Button>
      )
    case LineNumberType.Link:
      return (
        <Button className={getButtonsCss()} style={{ fontSize: '0.85em' }} onClick={props.onClick}>
          <span className='d-flex mx-2 px-1' style={{ lineHeight: '1em' }}>
            {props.lineNumber.lineNumber}
          </span>
          {!LineService.isLineNumberExtensionNecessary(props.lineNumber) ? null : (
            <span className='align-self-start badge badge-secondary d-flex ml-n2'>
              {props.lineNumber.lineNumberExtension}
            </span>
          )}
        </Button>
      )
  }
}
