import React, { FC, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'axios-hooks'
import { apiUrls, appUrls } from '../../utils/urls'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import { TextInput } from '@inprop/tt-ui-elements'
import LoadingButton from '@mui/lab/LoadingButton'
import { emailMaxLength, emailPattern } from '../../utils/constants'

interface ForgotPasswordFormData {
  emailAddress: string
}

const ForgotPasswordForm: FC = () => {
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false)
  const [requestedEmailAddress, setRequestedEmailAddress] = useState<string>('')

  const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<ForgotPasswordFormData>()

  const [ {error}, requestPasswordResetAxios] = useAxios({ method: 'GET' }, { manual: true })
  const { dispatch: notificationDispatch } = useContext(NotificationsContext)

  const onSubmit = async (data: ForgotPasswordFormData): Promise<void> => {
    try {
      const queryParams = { emailAddress: data.emailAddress }

      await requestPasswordResetAxios({ url: apiUrls.auth.requestPasswordResetEmail, params: queryParams })

      setIsRequestSent(true)
      setRequestedEmailAddress(data.emailAddress)
    } catch (error) {
      console.error(error)
      setIsRequestSent(true)

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Request password reset failed'),
        },
      })
    }
  }

  if (isRequestSent) {
    return <>
      <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
        <Card className={'w-100'}>
          <Card.Header>
            <h5 className={'my-0'}>{Localizer.localize('Password reset')}</h5>
          </Card.Header>

          <Card.Body>
            {!error &&
              <Alert severity={"success"} sx={{ mb: 3 }}>
                <Typography>
                  {Localizer.localizeR('We sent you an email to {0}. Please, check your inbox', requestedEmailAddress)}
                </Typography>
              </Alert>
            }

            {error &&
              <Alert severity={"error"} sx={{ mb: 3 }}>
                <Typography>
                  {Localizer.localize('Something went wrong, please try again later')}
                </Typography>
              </Alert>
            }
          </Card.Body>
        </Card>
      </div>
    </>
  }

  return (
    <>
      <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
        <Card className={'w-100'}>
          <Card.Header>
            <h5 className={'my-0'}>{Localizer.localize('Password reset')}</h5>
          </Card.Header>

          <Card.Body>
            <Typography className={'text-muted text-sm- mb-3'}>
              {Localizer.localize('Enter email address used during registration - we will send a password reset link there')}
            </Typography>

            <form id={'forgotPasswordForm'}
                  onSubmit={handleSubmit(onSubmit)}
                  className={'mb-0'}>
              <div id={'forgotPasswordFormInputs'}>
                <TextInput
                  id={'emailAddressInput'}
                  name={'emailAddress'}
                  type={'text'}
                  label={Localizer.localize('Email address')}
                  ref={register({
                    required: Localizer.localize('Please, enter your email address'),
                    pattern: {
                      value: emailPattern,
                      message: Localizer.localize('Please, enter valid email address'),
                    },
                    maxLength: {
                      value: emailMaxLength,
                      message: Localizer.localize('Entered email address is too long'),
                    },
                  })}
                  error={errors.emailAddress?.message}
                  disabled={isSubmitting}
                />
              </div>
            
              <div className={'d-flex justify-content-between align-items-center'}>
                <LoadingButton id={'sendPasswordResetEmailBtn'}
                               type={'submit'}
                               loading={isSubmitting}
                               variant={'contained'}
                >
                  {Localizer.localize('Send')}
                </LoadingButton>
                
                <Link to={appUrls.login}>
                  {Localizer.localize('Back to login')}
                </Link>
              </div>
            </form>
          </Card.Body>

          <Card.Footer className={'text-center'}>
            <Link to={appUrls.register}>
              {Localizer.localize('Do not have account yet? Register now')}
            </Link>
          </Card.Footer>
        </Card>
      </div>
    </>
  )
}

export default ForgotPasswordForm
