import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { LineNumberExtended } from '../../../../models/LineModels'
import axios from 'axios'
import { UrlProvider } from '../../../../to-refactor/UrlProvider'
import { Localizer } from '../../../../utils/localizer'
import { Logger } from '../../../../utils/logger'
import { LoadingFailed } from '../../../../to-refactor/LoadingHelpers'
import { ConnectionTextNoteEditor } from './ConnectionTextNoteEditor'
import {
  AppGuideContext,
  Button,
  ButtonColor,
  ButtonShape,
  ButtonSize,
  Preloader,
  useAppGuide,
} from '@inprop/tt-ui-elements'
import { TimeCodeMark } from '../../../../utils/ttTimeCodesService'
import { TTTimeCode } from '../../../general/TTTimeCode'
import { NotificationsContext, NotificationType } from '../../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import cx from 'classnames'
import { guideContent } from '../../../guide/content/guideContent'

export interface ConnectionTextNotesEditorRef {
  saveData?: () => Promise<void>
}

interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
  showSaveButton?: boolean

  setRef?: (ref: ConnectionTextNotesEditorRef) => void
  onDataChanged?: () => void
}

interface State {
  textNotes: TextNote[]
  showTextNoteEditor: boolean
  editingTextNoteIndex?: number
}

export interface TextNote {
  id?: string
  mark: TimeCodeMark
  text?: string
}

/**
 * Component for editing text notes of connection
 * @param props
 * @constructor
 */
export function ConnectionTextNotesEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const {
    state: { isGuideVisible },
  } = useContext(AppGuideContext)

  const [state, setState] = useState<State>({
    textNotes: [],
    showTextNoteEditor: false,
    editingTextNoteIndex: undefined,
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useAppGuide(
    [
      guideContent.connections.editor.notes.textNotes.edit,
      guideContent.connections.editor.notes.textNotes.delete,
    ],
    state.textNotes.length > 0 && isGuideVisible,
    'append'
  )
  useAppGuide([guideContent.connections.editor.notes.textNotes.add], !state.showTextNoteEditor)

  useEffect(() => {
    refreshOutsideCallbacks()
    loadTextNotes()
  }, [props.dataId, props.lineNumber, props.connectionNumber])

  useEffect(() => refreshOutsideCallbacks(), [state.textNotes])

  function refreshOutsideCallbacks(): void {
    if (props.setRef) props.setRef({ saveData: saveTextNotes })
  }

  /**
   * Load all text notes of connection
   */
  function loadTextNotes(): void {
    setIsLoading(true)

    axios
      .get<TextNote[]>(
        UrlProvider.Api.Components.Connections.TextNotesEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        )
      )
      .then((response) => {
        setState({ ...state, textNotes: response.data })
      })
      .catch((_) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection notes could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        handleComponentError(_)
      })
      .finally(() => setIsLoading(false))
  }

  const getEditingTextNote = (): TextNote | undefined => {
    if (!state.editingTextNoteIndex && state.editingTextNoteIndex !== 0) {
      return undefined
    }

    const textNote = state.textNotes[state.editingTextNoteIndex]
    if (!textNote) {
      throw new Error(`Could not find text note by index ${state.editingTextNoteIndex}.`)
    }

    return textNote
  }

  /**
   * Show editor for new text note
   */
  function showEditorForNewTextNote(): void {
    setState((_) => {
      return {
        ..._,
        showTextNoteEditor: true,
        editingTextNoteIndex: undefined,
      }
    })
  }

  /**
   * Show editor for text note that is already in list
   * @param textNoteIndex
   */
  function showEditorForExistingTextNote(textNoteIndex: number): void {
    setState((_) => {
      return {
        ..._,
        showTextNoteEditor: true,
        editingTextNoteIndex: textNoteIndex,
      }
    })
  }

  /**
   * Delete text note from list
   * @param textNoteIndex
   */
  function deleteTextNote(textNoteIndex: number): void {
    const newTextNotes = state.textNotes.slice(0, state.textNotes.length)
    newTextNotes.splice(textNoteIndex, 1)

    setState((_) => {
      return { ..._, textNotes: newTextNotes }
    })

    if (props.onDataChanged) props.onDataChanged()
  }

  function saveEditingTextNote(note: TextNote): void {
    if (state.editingTextNoteIndex || state.editingTextNoteIndex === 0) {
      setState((previousState) => {
        return {
          ...previousState,
          showTextNoteEditor: false,
          editingTextNoteIndex: undefined,
          textNotes: previousState.textNotes.map((_, index) => {
            if (index === previousState.editingTextNoteIndex) return note
            else return _
          }),
        }
      })

      if (props.onDataChanged) props.onDataChanged()

      return
    }

    setState((_) => {
      const newTextNotes = _.textNotes.slice(0, _.textNotes.length)
      newTextNotes.push(note)

      return {
        ..._,
        showTextNoteEditor: false,
        editingTextNoteIndex: undefined,
        textNotes: newTextNotes,
      }
    })

    if (props.onDataChanged) props.onDataChanged()
  }

  /**
   * Cancel adding new text note
   */
  function cancelEditingTextNote(): void {
    setState((_) => {
      return {
        ..._,
        showTextNoteEditor: false,
        editingTextNoteIndex: undefined,
      }
    })
  }

  /**
   * Save all text notes from list to backend
   */
  const saveTextNotes = (): Promise<void> =>
    axios
      .put(
        UrlProvider.Api.Components.Connections.TextNotesEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        ),
        state.textNotes
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection notes were saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((_) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving connection notes'),
            type: NotificationType.Error,
          },
        })

        handleComponentError(_)

        throw _
      })

  /**
   * Handle error
   * @param error
   */
  function handleComponentError(error: any): void {
    Logger.logError(
      `Error in ${ConnectionTextNotesEditor.name}. Error details: ${error.toString()}`
    )
  }

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <>
      <h4 className='mb-2'>{Localizer.localize('Text notes')}</h4>

      {!state.textNotes ? (
        <LoadingFailed tryAgainFn={loadTextNotes} />
      ) : (
        <>
          <ul className='list-unstyled mb-2'>
            {state.textNotes.map((_, index) => (
              <li className={'d-flex align-items-center mb-1 display-child-on-hover'} key={index}>
                <span className='text-center mr-1' style={{ width: '1.5em' }}>
                  <TTTimeCode mark={_.mark} relatedTo={'connection'} />
                </span>
                <span className='mr-2'>{_.text}</span>
                <div
                  className={cx('button-group', {
                    'display-on-parent-hover': !(index === 0 && isGuideVisible),
                  })}
                >
                  <Button
                    id={
                      index === 0 ? guideContent.connections.editor.notes.textNotes.edit.target : ''
                    }
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Light}
                    onClick={() => showEditorForExistingTextNote(index)}
                    className={'m-0'}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button
                    id={
                      index === 0
                        ? guideContent.connections.editor.notes.textNotes.delete.target
                        : ''
                    }
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Light}
                    onClick={() => deleteTextNote(index)}
                    className={'m-0'}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              </li>
            ))}
          </ul>

          {state.showTextNoteEditor ? (
            <ConnectionTextNoteEditor
              note={getEditingTextNote()}
              onSave={(newNote) => saveEditingTextNote(newNote)}
              onCancel={() => cancelEditingTextNote()}
            />
          ) : (
            <Button
              id={guideContent.connections.editor.notes.textNotes.add.target}
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              onClick={showEditorForNewTextNote}
              className={'ml-0'}
            >
              <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
              {Localizer.localize('Add new text note')}
            </Button>
          )}

          {props.showSaveButton && (
            <Button
              size={ButtonSize.ExtraSmall}
              shape={ButtonShape.Rounded}
              onClick={() => saveTextNotes()}
            >
              <FontAwesomeIcon icon={faCheck} />
              {Localizer.localize('Save notes')}
            </Button>
          )}
        </>
      )}
    </>
  )
}
