export const providersGuide = {
  addNew: {
    addButton: {
      target: 'jdf-add-new-provider-button',
      text: 'Pridanie nového dopravcu pre načítané dáta.',
    },
    input: {
      target: 'jdf-add-new-provider-input',
      text: 'Písaním ucelených slov oddelených medzerou sa ponúkajú vhodný dopravcovia z databázy dopravcov.'
    },
    cancelButton: {
      target: 'jdf-add-new-provider-cancel',
      text: 'Ukončenie nástroja na pridanie dopravcu bez akceptovania pridania dopravcu pre načítané dáta.'
    }
  },
  edit: {
    button: {
      target: 'jdf-edit-provider-button',
      text: 'Úprava dopravcu v načítaných dátach. Používa sa hlavne v prípade, že dopravca je vypísaný červený farbou a treba ho ručne spárovať s centrálnou databázou. '
    },
    delete: {
      target: 'jdf-edit-provider-delete',
      text: 'Odstránenie dopravcu z načítanej JDF dávky.'
    },
    input: {
      target: 'jdf-edit-provider-input',
      text: 'Písaním ucelených slov oddelených medzerou sa ponúkajú vhodný dopravcovia z databázy dopravcov.'
    },
    confirm: {
      target: 'jdf-edit-provider-confirm',
      text: 'Potvrdenie úpravy dopravcu.'
    },
    cancel: {
      target: 'jdf-edit-provider-cancel',
      text: 'Ukončenie nástroja na úpravu dopravcu bez akceptovania zmien.'
    }
  },
  delete: {
    button: {
      target: 'jdf-delete-provider-button',
      text: 'Odstránenie dopravcu po upozornení, že s dopravcom sa vymažú aj súvisiace záznamy.'
    }
  },
  lineNumber: {
    target: 'jdf-provider-line-number',
    text: 'Zobrazenie vývesky linky'
  }
}