import React, {FC, useContext, useEffect} from 'react'
import {MapContext, MapStationDetails, TTMap} from '@inprop/tt-maps'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'
import {LatLngBounds} from 'leaflet'

const slovakiaBounds = new LatLngBounds([47.73381, 16.83362], [49.61371, 22.56573])

const TTMapLayout: FC = () => {
  const {
    state: { stationDetails, map },
  } = useContext(MapContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  
  useEffect(() => {
    map?.fitBounds(slovakiaBounds)
  }, [map])

  const onLoadingStationsFailed = () => {
    notificationsDispatch({
      type: ADD_NOTIFICATION,
      value: {
        type: NotificationType.Error,
        title: Localizer.localize('Error while loading stations to map'),
      },
    })
  }

  const onLoadingConnectionFailed = (connectionNumber: number) => {
    notificationsDispatch({
      type: ADD_NOTIFICATION,
      value: {
        type: NotificationType.Error,
        title: Localizer.localize('Error while loading connection') + ' ' + connectionNumber,
      },
    })
  }

  return (
    <div className={'row ml-0 w-100 h-100'}>
      {stationDetails && (
        <div
          className={'col-md-3 px-4 py-3 h-100 shadow'}
          style={{ backgroundColor: '#f2f2f2', overflowY: 'scroll', zIndex: 99 }}
        >
          <MapStationDetails />
        </div>
      )}

      <div
        className={(stationDetails ? 'col-md-9' : 'col-12') + ' p-0 w-100 h-100'}
        style={{ zIndex: 0 }}
      >
        <TTMap
          onLoadingStationsFailed={onLoadingStationsFailed}
          onLoadingConnectionFailed={onLoadingConnectionFailed}
          loadingText={Localizer.localize('Loading')}
        />
      </div>
    </div>
  )
}

export default TTMapLayout