import React, { FC, RefObject } from 'react'
import { Overlay } from 'react-bootstrap'

export interface Props {
  tooltipTargetRef: RefObject<HTMLElement>
  isVisible: boolean
}

const Tooltip: FC<Props> = ({ tooltipTargetRef, isVisible, children }) => {
  return (
    <Overlay target={tooltipTargetRef} show={isVisible} transition={false}>
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '2px 10px',
            color: 'white',
            borderRadius: 3,
            zIndex: 10000,
            maxWidth: '15em',
            ...props.style,
          }}
        >
          {children}
        </div>
      )}
    </Overlay>
  )
}

export default Tooltip
