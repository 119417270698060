import React, { createContext, Dispatch, FC, ReactElement, useReducer } from 'react'
import { NotificationActionType, NotificationReducer } from './NotificationsReducer'

export enum NotificationType {
  Neutral,
  Success,
  Error,
  Warning,
}

export interface Notification {
  id: string
  title: string
  body?: string | ReactElement | ((closeFn: () => void) => ReactElement)
  type?: NotificationType
  autoDismissAfterSeconds?: number
  disableAutoDismiss?: boolean
  htmlId?: string
  onClose?: () => void
}

export interface NotificationsState {
  notifications: Notification[]
}

const initialState: NotificationsState = {
  notifications: [],
}

const NotificationsContext = createContext<{
  state: NotificationsState
  dispatch: Dispatch<NotificationActionType>
}>({
  state: initialState,
  dispatch: () => {},
})

const NotificationsContextProvider: FC = (props) => {
  const [state, dispatch] = useReducer(NotificationReducer, initialState)

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </NotificationsContext.Provider>
  )
}

export { NotificationsContext, NotificationsContextProvider }
