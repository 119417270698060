import React, { createContext, Dispatch, FC, useReducer } from 'react'
import { LayoutActionType, LayoutReducer } from './LayoutReducer'

export enum LayoutWidth {
  Full = 'full',
  Fixed = 'fixed'
}

export enum LayoutHeight {
  ByContent = 'by-content',
  Full = 'full'
}

export interface LayoutState {
  width: LayoutWidth
  height: LayoutHeight
  isWithFooter: boolean
}

const initialState: LayoutState = {
  width: LayoutWidth.Fixed,
  height: LayoutHeight.ByContent,
  isWithFooter: true
}

const LayoutContext = createContext<{
  state: LayoutState
  dispatch: Dispatch<LayoutActionType>
}>({
  state: initialState,
  dispatch: () => {},
})

const LayoutContextProvider: FC = (props) => {
  const [state, dispatch] = useReducer(LayoutReducer, initialState)

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>{props.children}</LayoutContext.Provider>
  )
}

export { LayoutContext, LayoutContextProvider }
