import React, { FC, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LineViewer } from '../jdf/line/LineViewer'
import { JdfContext } from '../../contexts/JdfContext'
import { JdfRouteParams } from '../layout/navigation/routes/JdfRouteWrapper'
import { LayoutContext, LayoutWidth } from '../../contexts/LayoutContext'
import { SET_LAYOUT_WIDTH } from '../../contexts/LayoutReducer'
import { LoadingFailed } from '../../to-refactor/LoadingHelpers'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { navigationWithJdf } from '../guide/content/guideContent'

export type JdfLineDetailsPageRouteParams = JdfRouteParams & {
  lineNumber: string
  lineNumberExtension?: string
}

const JdfLineDetailsPage: FC = () => {
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: layoutDispatch } = useContext(LayoutContext)
  const params = useParams<JdfLineDetailsPageRouteParams>()

  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Fixed })
  }, [layoutDispatch])

  if (!jdfState.jdfDataId) {
    return <LoadingFailed />
  }

  return (
    <div className={'px-2'}>
      <LineViewer
        dataId={jdfState.jdfDataId}
        lineNumber={{
          lineNumber: params.lineNumber,
          lineNumberExtension: params.lineNumberExtension ?? '',
          isLineNumberUnique: true,
        }}
      />
    </div>
  )
}

export default JdfLineDetailsPage
