import React, { FC, useMemo } from 'react'
import CheckboxInput from '../../general/inputs/CheckboxInput'
import { Localizer } from '../../../utils/localizer'
import { HtmlIdProvider } from '../../../utils/htmlIdProvider'
import { jdfLoadGuide } from '../../guide/content/jdfLoad'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { useJdfUploaderLocalStorage } from '../../../hooks/useJdfUploaderLocalStorage'

const JdfUploadSettings: FC = () => {
  const [settings, setSettings] = useJdfUploaderLocalStorage()

  useAppGuide([
    jdfLoadGuide.jdfLoadSettings.removePlatforms,
    jdfLoadGuide.jdfLoadSettings.routesOptimization,
    jdfLoadGuide.jdfLoadSettings.syncStations,
    jdfLoadGuide.jdfLoadSettings.syncProviders,
    jdfLoadGuide.jdfLoadSettings.fixPhoneConnections,
    jdfLoadGuide.jdfLoadSettings.operationAccordingToSpojDatFile,
  ])

  const removeStationSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-remove-stands'),
    []
  )
  const optimizeRouteSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-optimize-route'),
    []
  )
  const syncStationsSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-sync-stations'),
    []
  )
  const syncProvidersSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-sync-providers'),
    []
  )
  const fixPhoneConnectionsSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-fix-phone-connections'),
    []
  )
  const operationAccordingToSpojDatFileSettingId = useMemo(
    () => HtmlIdProvider.getNewId('jdf-upload-settings-operation-according-to-spoj-dat-file'),
    []
  )

  const columnClassNames = 'col-md-6 d-flex align-items-center mb-2'

  return (
    <div className={'container px-0'}>
      <div className={'row'}>
        <div id={jdfLoadGuide.jdfLoadSettings.removePlatforms.target} className={columnClassNames}>
          <CheckboxInput
            id={removeStationSettingId}
            label={Localizer.localize('Remove stands')}
            checked={settings.removeStands}
            onChange={(_) => setSettings((__) => ({ ...__, removeStands: _ }))}
          />
        </div>
        <div
          id={jdfLoadGuide.jdfLoadSettings.routesOptimization.target}
          className={columnClassNames}
        >
          <CheckboxInput
            id={optimizeRouteSettingId}
            label={Localizer.localize('Optimize routes')}
            checked={settings.optimizeRoutes}
            onChange={(_) => setSettings((__) => ({ ...__, optimizeRoutes: _ }))}
          />
        </div>
        <div id={jdfLoadGuide.jdfLoadSettings.syncStations.target} className={columnClassNames}>
          <CheckboxInput
            id={syncStationsSettingId}
            label={Localizer.localize('Synchronize stations')}
            checked={settings.syncStations}
            onChange={(_) => setSettings((__) => ({ ...__, syncStations: _ }))}
          />
        </div>
        <div id={jdfLoadGuide.jdfLoadSettings.syncProviders.target} className={columnClassNames}>
          <CheckboxInput
            id={syncProvidersSettingId}
            label={Localizer.localize('Synchronize providers')}
            checked={settings.syncProviders}
            onChange={(_) => setSettings((__) => ({ ...__, syncProviders: _ }))}
          />
        </div>
        <div id={jdfLoadGuide.jdfLoadSettings.fixPhoneConnections.target} className={columnClassNames}>
          <CheckboxInput
            id={fixPhoneConnectionsSettingId}
            label={Localizer.localize('Fix phone connections')}
            checked={settings.fixPhoneConnections}
            onChange={(_) => setSettings((__) => ({ ...__, fixPhoneConnections: _ }))}
          />
        </div>
        <div id={jdfLoadGuide.jdfLoadSettings.operationAccordingToSpojDatFile.target} className={columnClassNames}>
          <CheckboxInput
            id={operationAccordingToSpojDatFileSettingId}
            label={Localizer.localize('Operations according to SpojDat')}
            checked={settings.operationAccordingToSpojDatFile}
            onChange={(_) => setSettings((__) => ({ ...__, operationAccordingToSpojDatFile: _ }))}
          />
        </div>
      </div>
    </div>
  )
}

export default JdfUploadSettings
