import React, { FC, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxios from 'axios-hooks'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'
import { Card } from 'react-bootstrap'
import { ResetPasswordViewModel } from '../../models/AuthViewModels'
import { Link, useHistory, useLocation } from 'react-router-dom'
import TextInput from '../general/inputs/TextInput'
import { emailPattern, passwordMaxLength, passwordMinLength, passwordPattern } from '../../utils/constants'
import LoadingButton from '@mui/lab/LoadingButton'
import { apiUrls, appUrls } from '../../utils/urls'
import { Alert, Button, Stack, Typography } from '@mui/material'

interface PasswordResetFormData {
  newPassword: string
  repeatedPassword: string
}

const PasswordResetForm: FC = () => {
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState<boolean>(false)

  const search = useLocation().search
  const history = useHistory()

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm<PasswordResetFormData>()
  const [, resetPasswordAxios] =
    useAxios({ method: 'PATCH' }, { manual: true })

  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)

  const urlSearchParams = new URLSearchParams(search)
  const emailAddress = urlSearchParams.get('emailAddress') ?? ''
  const code = urlSearchParams.get('code') ?? ''

  const onSubmit = async (data: PasswordResetFormData): Promise<void> => {
    try {
      const body: ResetPasswordViewModel = {
        emailAddress: emailAddress,
        code: code,
        password: data.newPassword,
      }

      await resetPasswordAxios({ url: apiUrls.auth.resetPassword, data: body })

      setPasswordResetSuccessful(true)
    } catch (error) {
      console.error(error)

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Password reset failed'),
        },
      })
    }
  }

  const goToLogin = () => {
    history.push(appUrls.login)
  }

  if (emailAddress.isNullOrEmpty() || code.isNullOrEmpty()
    || !(new RegExp(emailPattern).test(emailAddress))) {
    return (
      <>
        <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
          <Card className={'w-100'}>
            <Card.Header>
              <h5 className={'my-0'}>{Localizer.localize('An error happened somewhere...')}</h5>
            </Card.Header>

            <Card.Body>
              <Alert severity={'error'} sx={{ mb: 3 }}>
                <Typography>
                  {Localizer.localize('Could not continue password reset. Email address or code is missing, or email address has a wrong format.')}
                </Typography>
              </Alert>
            </Card.Body>
          </Card>
        </div>
      </>
    )
  }

  if (passwordResetSuccessful) {
    return (
      <>
        <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
          <Card className={'w-100'}>
            <Card.Header>
              <h5 className={'my-0'}>{Localizer.localize('Password reset')}</h5>
            </Card.Header>

            <Card.Body>
              <Stack direction={'column'}>
                <Alert severity={'success'} sx={{ mb: 3 }}>
                  <Typography>
                    {Localizer.localize('Your password was changed. You can log in.')}
                  </Typography>
                </Alert>

                <Button onClick={goToLogin}>
                  {Localizer.localize('Login')}
                </Button>
              </Stack>

            </Card.Body>
          </Card>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={'col-12 col-md-8 offset-md-2 d-flex align-items-center'}>
        <Card className={'w-100'}>
          <Card.Header>
            <h5 className={'my-0'}>{Localizer.localize('Password reset')}</h5>
          </Card.Header>

          <Card.Body>
            <Typography className={'text-muted text-sm- mb-3'}>
              {Localizer.localize('Enter new password')}
            </Typography>

            <form id={'passwordResetForm'}
                  onSubmit={handleSubmit(onSubmit)}
                  className={'mb-0'}>
              <div className={'mb-4'}>
                <TextInput
                  id={'newPasswordInput'}
                  name={'newPassword'}
                  type={'password'}
                  label={Localizer.localize('New password')}
                  ref={register({
                    required: Localizer.localize('Please, enter your password'),
                    maxLength: {
                      value: passwordMaxLength,
                      message: Localizer.localize('Entered password is too long'),
                    },
                    minLength: {
                      value: passwordMinLength,
                      message: Localizer.localize('Entered password is too short'),
                    },
                    pattern: {
                      value: passwordPattern,
                      message: Localizer.localize('Password must contain at least one lower case character, one upper case character, one number and one special character (-?!@#$%^&*_~+)'),
                    },
                  })}
                  error={errors.newPassword?.message}
                  disabled={isSubmitting}
                />
                <TextInput
                  id={'repeatedPasswordInput'}
                  name={'repeatedPassword'}
                  type={'password'}
                  label={Localizer.localize('Repeat password')}
                  ref={register({
                    required: Localizer.localize('Please, repeat your password'),
                    validate: value =>
                      value === watch('newPassword')
                      || Localizer.localize('Entered passwords do not match'),
                  })}
                  error={errors.repeatedPassword?.message}
                  disabled={isSubmitting}
                />
              </div>
              <div className={'d-flex justify-content-between align-items-center'}>
                <LoadingButton
                  type={'submit'}
                  loading={isSubmitting}
                  variant={'contained'}
                >
                  {Localizer.localize('Reset password')}
                </LoadingButton>

                <Link to={appUrls.login}>
                  {Localizer.localize('Back to login')}
                </Link>
              </div>

            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default PasswordResetForm
