import { createTheme } from '@mui/material'
import { common } from '@mui/material/colors'
import './muiTheme'
import { Palette } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    leaf: Palette['primary']
  }

  interface PaletteOptions {
    leaf: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    leaf: true
  }
}

const textFont = 'Quicksand'

export const theme = createTheme({
  typography: {
    fontFamily: `"${textFont}"`,
    fontSize: 13,
  },
  palette: {
    primary: { main: '#1abc9c' },
    secondary: { main: '#eeeeee' },
    success: { main: '#1abc9c' },
    error: { main: '#C02942' },
    leaf: { main: '#A8CABA', contrastText: common.white },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
        arrow: true,
        PopperProps: {
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, -7] } }],
          },
        },
      },
      styleOverrides: {
        arrow: {
          color: common.black,
        },
        tooltip: {
          background: common.black,
          fontSize: 12,
        },
        tooltipPlacementTop: {
          top: 0,
          bottom: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: 12,
          fontWeight: 'bold',
          minWidth: 0,
          boxShadow: 'none',
          '&:hover': {
            background: theme.palette.grey['800'],
            boxShadow: 'none',
          },
        }),
        containedPrimary: {
          color: common.white,
        },
        contained: ({ ownerState }) => ({
          '&:hover': {
            color: common.white,
          },
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        grouped: {
          minWidth: 30,
          '&:not(:last-of-type), &:not(:last-of-type).Mui-disabled': {
            borderRight: 'none',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 0,
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        transformOrigin: { horizontal: 'center', vertical: 'top' },
      },
      styleOverrides: {
        paper: ({ ownerState, theme }) => ({
          maxWidth: 300,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.grey['400'],
          marginTop: 10,
          overflow: 'visible',
          '&::before': {
            backgroundColor: common.white,
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 12,
            height: 12,
            top: -6,
            transform: 'rotate(45deg)',
            left: 'calc(50% - 6px)',
            borderWidth: 1,
            borderTopStyle: 'solid',
            borderLeftStyle: 'solid',
            borderColor: theme.palette.grey['400'],
          },
        }),
      },
    },
  },
})