import React, { FC, useContext } from 'react'
import { NotificationsContext } from '../../contexts/NotificationsContext'
import Notification from '../general/Notification'
import cx from 'classnames'

import styles from './NotificationStack.module.scss'

const NotificationStack: FC = () => {
  const {
    state: { notifications },
  } = useContext(NotificationsContext)

  return (
    <div className={cx(styles.notificationsStack, 'mx-2')}>
      {notifications.map((_) => (
        <Notification key={_.id} {..._} />
      ))}
    </div>
  )
}

export default NotificationStack
