import React, { FC, useContext, useEffect, useState } from 'react'
import { AppGuideContext, Button, ButtonColor, ButtonSize } from '@inprop/tt-ui-elements'
import { Badge } from '@mui/material'
import styles from './AppGuideButton.module.scss'
import { common } from '@mui/material/colors'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import useTimeout from '../../../hooks/useTimeout'

const animationDuration = 3000

const AppGuideButton: FC = (props) => {
  const { state: appGuide } = useContext(AppGuideContext)
  const [badgeClass, setBadgeClass] = useState<string>(styles.animated)
  const [delay, setDelay] = useState<number | undefined>(animationDuration)

  useTimeout(() => {
    setBadgeClass('')
    setDelay(undefined)
  }, delay)

  useEffect(() => {
    setBadgeClass(styles.animated)
    setDelay(animationDuration)
  }, [appGuide.editorSteps.length])

  return (
    <Button
      color={ButtonColor.TransparentDark}
      size={ButtonSize.Small}
      onClick={appGuide.startTour}
    >
      <Badge
        badgeContent={appGuide.navigationSteps.length + appGuide.editorSteps.length}
        color='primary'
        className={badgeClass}
        sx={{
          '& .MuiBadge-badge': {
            right: -3,
            top: -3,
            border: `2px solid ${common.white}`,
            padding: '0 4px',
            color: common.white,
            fontWeight: 'bold',
          },
        }}
      >
        <HelpOutlineIcon style={{ fontSize: '1.3em' }} />
      </Badge>
    </Button>
  )
}

export default AppGuideButton
