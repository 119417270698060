import { UrlProvider } from '../../../../to-refactor/UrlProvider'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import { Localizer } from '../../../../utils/localizer'
import {NotificationsContext, NotificationType} from '../../../../contexts/NotificationsContext'
import { LineNumberExtended } from '../../../../models/LineModels'
import useAxios from 'axios-hooks'
import {useContext} from "react";

const useJdfConnectionDelete = (
  jdfDataId: string,
  lineNumber: LineNumberExtended,
  connectionId: string,
  onDelete: () => void
) => {
  const {dispatch: notificationsDispatch} = useContext(NotificationsContext)
  
  const [{ loading, error }, deleteConnectionAxios] = useAxios(
    { method: 'DELETE' },
    { manual: true }
  )

  const deleteConnection = async () => {
    try {
      await deleteConnectionAxios({
        url: UrlProvider.Api.JdfData.Connections.getUrl(jdfDataId, lineNumber, connectionId),
      })

      onDelete()

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Connection was deleted'),
          type: NotificationType.Success,
        },
      })
    } catch {
      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Error while deleting connection'),
          type: NotificationType.Error,
        },
      })
    }    
  }
  
  return {
    deleteConnection,
    isDeleting: loading,
    deleteFailed: !!error
  }
}

export default useJdfConnectionDelete
