import React, { FC } from 'react'
import ForgotPasswordForm from '../auth/ForgotPasswordForm'

const ForgotPasswordPage: FC = () => {
  return (
    <div className={'row py-4'}>
      <ForgotPasswordForm />
    </div>
  )
}

export default ForgotPasswordPage
