import useLocalStorage, { localStorageKeys } from './useLocalStorage'
import { useEffectOnce } from './useEffectOnce'

// const appVersionUrl = removeHttpOrHttps(process.env.REACT_APP_HOMEPAGE ?? '')
const appVersionUrl = '/'

const useAppVersionChecker = () => {
  const [appVersion, setAppVersion] = useLocalStorage<string>(localStorageKeys.appVersion.v1, '')

  const processFetchedAppVersion = (fetchedAppVersion: string) => {
    if (!appVersion) {
      setAppVersion(fetchedAppVersion)

      return
    }

    if (appVersion !== fetchedAppVersion) {
      console.log('App has new version. Reloading ...')
      setAppVersion(fetchedAppVersion)
      window.location.reload()
    }
  }

  useEffectOnce(() => {
    fetch(appVersionUrl).then((_) => {
      _.text().then((text) => {
        processFetchedAppVersion(text)
      })
    })
  })
}

export default useAppVersionChecker
