import React, { FC, useContext, useEffect } from 'react'
import {
  Notification as INotification,
  NotificationsContext,
  NotificationType,
} from '../../contexts/NotificationsContext'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { REMOVE_NOTIFICATION } from '../../contexts/NotificationsReducer'
import cx from 'classnames'

import styles from './Notification.module.scss'

const Notification: FC<INotification> = (props) => {
  const { dispatch } = useContext(NotificationsContext)

  if (props.autoDismissAfterSeconds && props.disableAutoDismiss)
    throw new Error(
      'Notification.autoDismissAfterSeconds cannot be combined with Notification.disableAutoDismiss=true'
    )

  useEffect(() => {
    if (
      props.disableAutoDismiss ||
      !props.autoDismissAfterSeconds ||
      props.autoDismissAfterSeconds === 0
    ) {
      return
    }

    const timeout = setTimeout(() => {
      dispatch({ type: REMOVE_NOTIFICATION, value: props.id })
    }, props.autoDismissAfterSeconds * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [props.autoDismissAfterSeconds, props.disableAutoDismiss, props.id, dispatch])

  const getBorderCssByType = (type: NotificationType | undefined): string => {
    switch (type) {
      case NotificationType.Success:
        return styles.borderNtfOk
      case NotificationType.Warning:
        return styles.borderNtfWarning
      case NotificationType.Error:
        return styles.borderNtfFail
      default:
        return ''
    }
  }

  const onRemove = (id: string) => {
    if (props.onClose) {
      props.onClose()
    }
    
    dispatch({ type: REMOVE_NOTIFICATION, value: id })
  }

  return (
    <div id={props.htmlId} className={cx(styles.notification, 'bg-light mx-0 mb-2', styles.borderNtf, getBorderCssByType(props.type))}>
      <div
        className={cx(
          styles.header,
          'd-flex justify-content-between align-items-center pl-3 pr-2 py-1',
          { 'border-bottom-0': !props.body }
        )}
      >
        <div className={'font-weight-bold'}>{props.title}</div>
        <Button
          shape={ButtonShape.Circle}
          color={ButtonColor.TransparentDark}
          size={ButtonSize.ExtraSmall}
          onClick={() => onRemove(props.id)}
          className={'mr-0'}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>

      {!props.body ? null : (
        <div className={cx(styles.body, 'px-3 py-2')}>
          {typeof props.body === 'function' ? props.body(() => onRemove(props.id)) : props.body}
        </div>
      )}

      {!props.autoDismissAfterSeconds ? null : (
        <div
          className={styles.ntfProgress}
          style={{ animationDuration: `${props.autoDismissAfterSeconds}s` }}
        />
      )}
    </div>
  )
}

export default Notification
