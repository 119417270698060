import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { appUrls } from '../../../../utils/urls'
import HomePage from '../../../pages/HomePage'
import JdfLineDetailsPage from '../../../pages/JdfLineDetailsPage'
import ProvidersPage from '../../../pages/ProvidersPage'
import ProtocolPage from '../../../pages/ProtocolPage'
import MapPage from '../../../pages/MapPage'
import LoginPage from '../../../pages/LoginPage'
import JdfRouteWrapper from './JdfRouteWrapper'
import JdfLinesPage from '../../../pages/JdfLinesPage'
import ManageDataPage from '../../../pages/admin/ManageDataPage'
import PrivateRoute from './PrivateRoute'
import JdfLineEditPage from '../../../pages/JdfLineEditPage'
import { JdfConnectionEditPage } from '../../../pages/JdfConnectionEditPage'
import useAppVersionChecker from '../../../../hooks/useAppVersionChecker'
import RegisterPage from '../../../pages/RegisterPage'
import EmailAddressConfirmationPage from '../../../pages/EmailAddressConfirmationPage'
import ForgotPasswordPage from '../../../pages/ForgotPasswordPage'
import PasswordResetPage from '../../../pages/PasswordResetPage'
import UsersListPage from '../../../pages/admin/UsersListPage'
import { Role } from '../../../../models/AuthViewModels'
import RegistrationAllowedEmailsPage from '../../../pages/admin/RegistrationAllowedEmailsPage'

const AppRoutes: FC = () => {
  useAppVersionChecker()

  return (
    <Switch>
      <Route path={appUrls.home} exact>
        <HomePage />
      </Route>

      {/*<Route path={appUrls.internationalStations}>*/}
      {/*  <InternationalStationsPage />*/}
      {/*</Route>*/}

      <Route path={appUrls.jdf.lines.pattern} exact>
        <JdfRouteWrapper>
          <JdfLinesPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.jdf.connectionEdit.pattern}>
        <JdfRouteWrapper>
          <JdfConnectionEditPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.jdf.lineEdit.pattern}>
        <JdfRouteWrapper>
          <JdfLineEditPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.jdf.lineDetails.pattern}>
        <JdfRouteWrapper>
          <JdfLineDetailsPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.jdf.providers.pattern}>
        <JdfRouteWrapper>
          <ProvidersPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.jdf.protocol.pattern}>
        <JdfRouteWrapper>
          <ProtocolPage />
        </JdfRouteWrapper>
      </Route>

      <Route path={appUrls.jdf.map.pattern}>
        <JdfRouteWrapper>
          <MapPage />
        </JdfRouteWrapper>
      </Route>
      <Route path={appUrls.map}>
        <MapPage />
      </Route>

      <Route path={appUrls.login}>
        <LoginPage />
      </Route>

      <PrivateRoute path={appUrls.admin.manageDate}
                    roles={[Role.Admin]}>
        <ManageDataPage />
      </PrivateRoute>
      <PrivateRoute path={appUrls.admin.usersList}
                    roles={[Role.Admin]} >
        <UsersListPage />
      </PrivateRoute>
      <PrivateRoute path={appUrls.admin.registrationAllowedEmails}
                    roles={[Role.Admin]}>
        <RegistrationAllowedEmailsPage />
      </PrivateRoute>
      
      <Route path={appUrls.register}>
        <RegisterPage />
      </Route>
      <Route path={appUrls.emailAddressConfirmation}>
        <EmailAddressConfirmationPage />
      </Route>
      <Route path={appUrls.forgotPassword}>
        <ForgotPasswordPage />
      </Route>
      <Route path={appUrls.passwordReset}>
        <PasswordResetPage />
      </Route>
    </Switch>
  )
}

export default AppRoutes
