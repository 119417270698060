import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { UrlProvider } from '../../../to-refactor/UrlProvider'
import { Localizer } from '../../../utils/localizer'
import { LineNumberExtended } from '../../../models/LineModels'
import { List } from 'immutable'
import { Logger } from '../../../utils/logger'
import { LoadingState } from '../../../to-refactor/LoadingHelpers'
import { ProvidersViewerToolbar } from './ProvidersViewerToolbar'
import ProviderEditor from './ProviderEditor'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'

interface Props {
  jdfDataId: string
}

interface ProviderApiModel {
  name: string
  companyId: string
  providerDistinction: string
  isUnknown: boolean
  businessName: string
  naturalPersonName: string
  address: string
  usedInLines: LineNumberExtended[]
}

export interface Provider {
  name: string
  companyId: string
  providerDistinction: string
  isUnknown: boolean
  businessName: string
  naturalPersonName: string
  address: string
  usedInLines: List<LineNumberExtended>
}

export function ProvidersViewer(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Loading)
  const [providers, setProviders] = useState<List<Provider>>(List([]))

  useEffect(() => {
    loadProviders()
  }, [])

  function loadProviders(): void {
    setLoadingState(LoadingState.Loading)

    axios
      .get<ProviderApiModel[]>(UrlProvider.Api.Components.Providers.Viewer.getUrl(props.jdfDataId))
      .then((response) => {
        const processedProviders: Provider[] = response.data.map((_) => {
          return {
            name: _.name,
            companyId: _.companyId,
            isUnknown: _.isUnknown,
            providerDistinction: _.providerDistinction,
            businessName: _.businessName,
            naturalPersonName: _.naturalPersonName,
            address: _.address,
            usedInLines: List(_.usedInLines),
          }
        })

        setProviders(List(processedProviders))
        setLoadingState(LoadingState.LoadingSucceeded)
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Providers could not be loaded'),
            type: NotificationType.Error,
          },
        })

        setLoadingState(LoadingState.LoadingFailed)
        handleComponentError(error)
      })
  }

  function handleComponentError(error: any): void {
    Logger.logError(`Error in ${ProvidersViewer}. Message: ${error.toString()}`)
  }

  return (
    <>
      <div className={'container mb-0'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <ProvidersViewerToolbar jdfDataId={props.jdfDataId} reloadProvidersFn={loadProviders} />
          </div>
        </div>
      </div>

      <div className={'row mb-3 border-bottom'} />

      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <div className={'h2'}>{Localizer.localize('Providers')}</div>
            {loadingState !== LoadingState.LoadingSucceeded
              ? null
              : providers.size === 0
              ? Localizer.localize('Data contains no providers')
              : providers.map((_, index) => (
                  <ProviderEditor
                    key={`${_.companyId}${_.providerDistinction}`}
                    className={'mb-4'}
                    jdfDataId={props.jdfDataId}
                    onSave={loadProviders}
                    provider={_}
                    withGuide={index === 0}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  )
}