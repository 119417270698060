import React, { FC, useContext, useMemo } from 'react'
import { JdfConnectionRouteEditorContextProvider } from '../../../contexts/JdfConnectionRouteEditorContext'
import { ConnectionRouteEditor } from './route/ConnectionRouteEditor'
import { ConnectionCalendarEditor } from './ConnectionCalendarEditor'
import { ConnectionNotesEditor } from './notes/ConnectionNotesEditor'
import { ConnectionProvidersEditor } from './providers/ConnectionProvidersEditor'
import { Line } from '../../../models/LineModels'
import { JdfContext } from '../../../contexts/JdfContext'
import unwrap from '../../../utils/unwrap'
import {Editor, JdfConnectionEditorContext} from "../../../contexts/JdfConnectionEditorContext";

export interface Props {
  line: Line
  connectionId: string
}

const JdfConnectionEditor: FC<Props> = ({line, connectionId}) => {
  const { state: jdfState } = useContext(JdfContext)
  const { state: editorState} = useContext(JdfConnectionEditorContext)

  const jdfDataId = useMemo(() => unwrap(jdfState.jdfDataId), [jdfState.jdfDataId])

  switch (editorState.currentEditor) {
    case Editor.RouteEditor:
      return (
        <JdfConnectionRouteEditorContextProvider>
          <ConnectionRouteEditor
            dataId={jdfDataId}
            lineNumber={line.lineNumber}
            connectionNumber={connectionId}
            allStations={line.stations}
          />
        </JdfConnectionRouteEditorContextProvider>
      )
    case Editor.CalendarEditor:
      return (
        <ConnectionCalendarEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          connectionNumber={connectionId}
        />
      )
    case Editor.NotesEditor:
      return (
        <ConnectionNotesEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          connectionNumber={connectionId}
        />
      )
    case Editor.ProvidersEditor:
      return (
        <ConnectionProvidersEditor
          dataId={jdfDataId}
          lineNumber={line.lineNumber}
          connectionNumber={connectionId}
        />
      )
  }
}

export default JdfConnectionEditor