import React, { useEffect } from 'react'
import { Props as TTFixedCodeProps, TTFixedCode } from './TTFixedCode'
import { TimeCodeMark, timeCodeMarks } from '../../utils/ttTimeCodesService'

interface Props {
  mark: TimeCodeMark | string
}

export const TTTimeCode: React.FunctionComponent<Props & TTFixedCodeProps> = (props) => {
  useEffect(() => {
    if (!([...timeCodeMarks] as string[]).includes(props.mark)) {
      throw new Error(`Unknown time code mark '${props.mark}'`)
    }
  }, [])

  return <TTFixedCode {...props} />
}