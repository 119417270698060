import React, { FC } from 'react'
import { Localizer } from '../../../utils/localizer'
import CISStationsRefresh from '../../admin/CisStationsRefresh'
import stationsSvg from '../../../assets/images/admin-stations.svg'

const ManageDataPage: FC = () => {
  return (
    <div className={'row py-5'}>
      <div className={'col-md-6 d-flex align-items-center justify-content-center'}>
        <img src={stationsSvg} alt={'Stations'} className={'w-50'} />
      </div>
      <div className={'col-md-6 d-flex align-items-center'}>
        <div>
          <div className={'h2'}>{Localizer.localize('Manage data')}</div>
          <CISStationsRefresh />
        </div>
      </div>
    </div>
  )
}

export default ManageDataPage