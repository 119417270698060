import React, { createRef, ReactElement, useCallback, useContext, useState } from 'react'
import { LineViewerConnectionFilters } from './LineViewerConnectionFilters'
import { Localizer } from '../../../utils/localizer'
import { LineNumberExtended } from '../../../models/LineModels'
import { ConnectionType } from './LineViewer'
import { Fade, Popover, PopoverBody } from 'reactstrap'
import { Button, ButtonColor, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { ConnectionNewForm } from '../connection/ConnectionNewForm'
import { List } from 'immutable'
import { LineNumber, LineNumberType } from './LineNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { useHistory } from 'react-router-dom'
import { JdfContext } from '../../../contexts/JdfContext'
import { apiUrls, appUrls } from '../../../utils/urls'
import Tooltip from '../../general/Tooltip'
import useAxios from 'axios-hooks'
import { NotificationsContext, NotificationType } from '../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../contexts/NotificationsReducer'
import { LineEditor } from '../../../contexts/JdfLineEditorContext'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import useJdfLineDelete from '../../../hooks/data/jdf/line/useJdfLineDelete'
import { useAppGuide } from '@inprop/tt-ui-elements'
import { guideContent } from '../../guide/content/guideContent'

interface Props {
  jdfDataId: string
  currentLineNumber: LineNumberExtended
  allLineNumbers: List<LineNumberExtended>
  allConnectionNumbers: List<number>
  activeConnectionType: ConnectionType
  showConnectionsWithType: (connectionType: ConnectionType) => void
  refreshLine: () => void
}

enum SubToolbar {
  AllLines = 1,
  NewConnection = 2,
}

export function LineViewerToolbar(props: Props): JSX.Element {
  const history = useHistory()
  const { state: jdfState } = useContext(JdfContext)
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const [subToolbar, setSubToolbar] = useState<SubToolbar>()

  const [{ loading: isOptimizingRoute, error: routeOptimizationError }, optimizeRouteAxios] =
    useAxios({ method: 'POST' }, { manual: true })

  const [isPreviousLineTooltipVisible, setIsPreviousLineTooltipVisible] = useState<boolean>(false)
  const [isNextLineTooltipVisible, setIsNextLineTooltipVisible] = useState<boolean>(false)
  const previousLineButtonRef = createRef<HTMLDivElement>()
  const nextLineButtonRef = createRef<HTMLDivElement>()

  const redirectToLinesList = useCallback(() => {
    history.push(appUrls.jdf.lines.withParams({ jdfDataId: props.jdfDataId }))
  }, [props.jdfDataId])

  const { deleteLine, isDeleting } = useJdfLineDelete(
    props.jdfDataId,
    props.currentLineNumber,
    redirectToLinesList
  )
  const [isLineDeletePopoverOpen, setIsLineDeletePopoverOpen] = useState<boolean>(false)

  useAppGuide([
    guideContent.lines.toolbar.newConnection,
    guideContent.lines.toolbar.editLine,
    guideContent.lines.toolbar.optimizeRoute,
    guideContent.lines.toolbar.allLines,
    guideContent.lines.toolbar.deleteLine,
  ])

  const navigateToLine = (lineNumber: LineNumberExtended | undefined) => {
    if (!lineNumber) {
      return
    }

    if (!jdfState.jdfDataId) {
      throw new Error('JDF data ID must be set when redirecting to line details.')
    }

    history.push(
      appUrls.jdf.lineDetails.withParams({
        jdfDataId: jdfState.jdfDataId,
        lineNumber: lineNumber.lineNumber,
        lineNumberExtension: lineNumber.lineNumberExtension,
      })
    )
  }

  function getLineForNavigation(
    whichLine: 'previousLine' | 'nextLine'
  ): LineNumberExtended | undefined {
    let previousLineNumber: LineNumberExtended | undefined = undefined
    let returnNextLineNumber = false

    for (const lineNumber of props.allLineNumbers.toArray()) {
      if (returnNextLineNumber) {
        return lineNumber
      }

      if (
        lineNumber.lineNumber === props.currentLineNumber.lineNumber &&
        lineNumber.lineNumberExtension === props.currentLineNumber.lineNumberExtension
      ) {
        if (whichLine === 'previousLine') {
          return previousLineNumber
        } else if (whichLine === 'nextLine') {
          returnNextLineNumber = true
        }
      }

      previousLineNumber = lineNumber
    }

    if (returnNextLineNumber) {
      return undefined
    }
  }

  function getSubToolbar(): ReactElement | undefined {
    switch (subToolbar) {
      case SubToolbar.AllLines:
        return (
          <Fade className={'col-12'}>
            <div className='mb-2'>
              <div className='d-flex w-100 flex-wrap flex-row justify-content-center'>
                {props.allLineNumbers.map((lineNumber) => (
                  <div
                    className='d-flex'
                    key={`${lineNumber.lineNumber}/${lineNumber.lineNumberExtension}`}
                  >
                    <LineNumber
                      lineNumber={lineNumber}
                      type={LineNumberType.Button}
                      onClick={() => {
                        navigateToLine(lineNumber)
                        toggleAllLinesSubToolbar()
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Fade>
        )
      case SubToolbar.NewConnection:
        return (
          <Fade className={'col-12 col-md-6 offset-md-6 mt-1 mb-2'}>
            <ConnectionNewForm
              jdfDataId={props.jdfDataId}
              lineNumber={props.currentLineNumber}
              allConnectionNumbers={props.allConnectionNumbers}
              onConnectionSaved={() => {
                props.refreshLine()
                setSubToolbar(undefined)
              }}
              onCancel={() => setSubToolbar(undefined)}
            />
          </Fade>
        )
      default:
        return undefined
    }
  }

  function toggleNewConnectionSubToolbar(): void {
    if (subToolbar === SubToolbar.NewConnection) setSubToolbar(undefined)
    else setSubToolbar(SubToolbar.NewConnection)
  }

  function toggleAllLinesSubToolbar(): void {
    if (subToolbar === SubToolbar.AllLines) setSubToolbar(undefined)
    else setSubToolbar(SubToolbar.AllLines)
  }

  const redirectToLineEdit = () => {
    if (!jdfState.jdfDataId) {
      throw new Error('JDF data ID must be set when redirecting to line editor')
    }

    history.push(
      appUrls.jdf.lineEdit.withParams({
        jdfDataId: jdfState.jdfDataId,
        lineNumber: props.currentLineNumber.lineNumber,
        lineNumberExtension: props.currentLineNumber.lineNumberExtension,
        whatToEdit: LineEditor.Route,
      })
    )
  }

  const optimizeRoute = async () => {
    if (!jdfState.jdfDataId) {
      return
    }

    try {
      await optimizeRouteAxios({
        url: apiUrls.jdf.lines.route.optimize(jdfState.jdfDataId, props.currentLineNumber),
      })

      props.refreshLine()

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Success,
          title: Localizer.localize('Line route optimization succeeded'),
        },
      })
    } catch (error) {
      console.error(error)

      notificationsDispatch({
        type: ADD_NOTIFICATION,
        value: {
          type: NotificationType.Error,
          title: Localizer.localize('Line route optimization failed'),
        },
      })
    }
  }

  return (
    <div className='row mb-3 border-bottom'>
      <div className='col-12 d-flex flex-column align-items-center align-items-lg-end'>
        <div className='d-flex flex-wrap justify-content-center px-2'>
          <LineViewerConnectionFilters
            activeConnectionType={props.activeConnectionType}
            showConnectionsWithType={props.showConnectionsWithType}
          />

          <Button
            id={guideContent.lines.toolbar.newConnection.target}
            shape={ButtonShape.Circle}
            size={ButtonSize.ExtraSmall}
            className={'ml-0 mr-2 px-3'}
            onClick={() => toggleNewConnectionSubToolbar()}
          >
            {Localizer.localize('Add connection')}
          </Button>

          <Button
            id={guideContent.lines.toolbar.editLine.target}
            shape={ButtonShape.Circle}
            size={ButtonSize.ExtraSmall}
            className={'ml-0 mr-2 px-3'}
            onClick={redirectToLineEdit}
          >
            {Localizer.localize('Edit line')}
          </Button>

          <Button
            id={guideContent.lines.toolbar.optimizeRoute.target}
            shape={ButtonShape.Circle}
            size={ButtonSize.ExtraSmall}
            className={'ml-0 mr-2 px-3'}
            onClick={optimizeRoute}
            isActing={isOptimizingRoute}
          >
            {Localizer.localize('Optimize route')}
          </Button>

          <div className='button-group my-1 mr-2'>
            <Button
              id={'button-previous-line'}
              shape={ButtonShape.Circle}
              size={ButtonSize.ExtraSmall}
              className={'m-0 px-2'}
              color={ButtonColor.Light}
              onClick={() => navigateToLine(getLineForNavigation('previousLine'))}
              disabled={!getLineForNavigation('previousLine')}
            >
              <div
                ref={previousLineButtonRef}
                onMouseEnter={() => setIsPreviousLineTooltipVisible(true)}
                onMouseLeave={() => setIsPreviousLineTooltipVisible(false)}
              >
                <FontAwesomeIcon icon={faChevronLeft} className={'mx-1'} />
              </div>
            </Button>
            <Tooltip
              tooltipTargetRef={previousLineButtonRef}
              isVisible={isPreviousLineTooltipVisible}
            >
              {Localizer.localize('Previous line')}
            </Tooltip>

            <Button
              id={guideContent.lines.toolbar.allLines.target}
              shape={ButtonShape.Circle}
              size={ButtonSize.ExtraSmall}
              className={'m-0 px-2'}
              color={ButtonColor.Light}
              onClick={() => toggleAllLinesSubToolbar()}
            >
              <FontAwesomeIcon icon={faTh} className={'mr-2'} />
              {Localizer.localize('Lines')}
            </Button>

            <Button
              id={'button-next-line'}
              shape={ButtonShape.Circle}
              size={ButtonSize.ExtraSmall}
              color={ButtonColor.Light}
              className={'m-0 px-2'}
              onClick={() => navigateToLine(getLineForNavigation('nextLine'))}
              disabled={!getLineForNavigation('nextLine')}
            >
              <div
                ref={nextLineButtonRef}
                onMouseEnter={() => setIsNextLineTooltipVisible(true)}
                onMouseLeave={() => setIsNextLineTooltipVisible(false)}
              >
                <FontAwesomeIcon icon={faChevronRight} className={'mx-1'} />
              </div>
            </Button>
            <Tooltip tooltipTargetRef={nextLineButtonRef} isVisible={isNextLineTooltipVisible}>
              {Localizer.localize('Next line')}
            </Tooltip>
          </div>

          <div className={'my-1'}>
            <Button
              id={guideContent.lines.toolbar.deleteLine.target}
              className={'m-0'}
              color={ButtonColor.Light}
              shape={ButtonShape.Circle}
              size={ButtonSize.ExtraSmall}
              onClick={() => setIsLineDeletePopoverOpen(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Popover
              placement='bottom'
              isOpen={isLineDeletePopoverOpen}
              target={guideContent.lines.toolbar.deleteLine.target}
              toggle={() => setIsLineDeletePopoverOpen((_) => !_)}
            >
              <PopoverBody className={'p-3'}>
                <p className={'mb-3'}>
                  {Localizer.localize('All related records will be deleted with the line.')}{' '}
                  {Localizer.localize('Do you really want to delete this line?')}
                </p>
                <div className={'d-flex justify-content-end'}>
                  <Button
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Danger}
                    onClick={deleteLine}
                    className={'m-0 mr-2'}
                    isActing={isDeleting}
                  >
                    <FontAwesomeIcon icon={faTrash} className={'mr-1'} />
                    {Localizer.localize('Delete')}
                  </Button>
                  <Button
                    size={ButtonSize.ExtraSmall}
                    shape={ButtonShape.Rounded}
                    color={ButtonColor.Light}
                    onClick={() => setIsLineDeletePopoverOpen((_) => !_)}
                    className={'m-0'}
                  >
                    <FontAwesomeIcon icon={faTimes} className={'mr-2'} />
                    {Localizer.localize('Cancel')}
                  </Button>
                </div>
              </PopoverBody>
            </Popover>
          </div>
        </div>
      </div>

      {getSubToolbar()}
    </div>
  )
}