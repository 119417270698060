import React, { FC, useContext, useEffect } from 'react'
import { Preloader, useAppGuide } from '@inprop/tt-ui-elements'
import { useJdfLineApi } from '../../hooks/useJdfLineApi'
import { useParams } from 'react-router-dom'
import { JdfLineDetailsPageRouteParams } from './JdfLineDetailsPage'
import { JdfContext } from '../../contexts/JdfContext'
import { LoadingFailed } from '../../to-refactor/LoadingHelpers'
import { LayoutContext, LayoutWidth } from '../../contexts/LayoutContext'
import { SET_LAYOUT_WIDTH } from '../../contexts/LayoutReducer'
import JdfLineEditorContextProvider, {
  LineEditor as LineEditorEnum,
} from '../../contexts/JdfLineEditorContext'
import LineEditorToolbar from '../jdf/line/LineEditorToolbar'
import LineEditor from '../jdf/line/LineEditor'
import { navigationWithJdf } from '../guide/content/guideContent'

interface Props {
  onClose?: () => void
}

interface State {
  footer?: JSX.Element
  getFooterFn?: () => JSX.Element
}

export type JdfLineEditPageRouteParams = JdfLineDetailsPageRouteParams & {
  whatToEdit: LineEditorEnum
}

const JdfLineEditPage: FC<Props> = (props) => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext)
  const { state: jdfState } = useContext(JdfContext)
  if (!jdfState.jdfDataId) {
    throw new Error('JDF data must be set when showing JDF line edit page')
  }

  useAppGuide(navigationWithJdf, undefined, undefined, 'navigation')

  const params = useParams<JdfLineEditPageRouteParams>()
  const {
    line,
    isLoading,
    error: loadingError,
    fetchLine,
  } = useJdfLineApi(jdfState.jdfDataId, {
    lineNumber: params.lineNumber,
    lineNumberExtension: params.lineNumberExtension ?? '',
    isLineNumberUnique: false,
  })

  useEffect(() => {
    layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Full })

    return () => layoutDispatch({ type: SET_LAYOUT_WIDTH, value: LayoutWidth.Fixed })
  }, [layoutDispatch])

  useEffect(() => {
    fetchLine()
  }, [jdfState.jdfDataId, params.lineNumber, params.lineNumberExtension])

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  if (loadingError || !line) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <LoadingFailed tryAgainFn={fetchLine} />
      </div>
    )
  }

  return (
    <JdfLineEditorContextProvider>
      <div className='row mb-3 border-bottom'>
        <div className='col'>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col'}>
                <LineEditorToolbar line={line} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'d-flex justify-content-center'}>
        <div className={'container pb-4'}>
          <LineEditor line={line} />
        </div>
      </div>
    </JdfLineEditorContextProvider>
  )
}

export default JdfLineEditPage