import React, { createRef, CSSProperties, ReactElement, useEffect, useState } from 'react'
import { HtmlIdProvider } from '../../utils/htmlIdProvider'
import { TTFixedCodesService } from '../../utils/ttFixedCodesService'
import Tooltip from './Tooltip'

const fixedCodeMarks = [
  'X',
  '+',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  'R',
  '#',
  '|',
  '<',
  '@',
  '%',
  'W',
  'w',
  'x',
  '~',
  '(',
  ')',
  '$',
  '{',
  '}',
  '[',
  'O',
  'v',
  '§',
  'A',
  'B',
  'C',
  'T',
  '!',
  't',
  'b',
  'U',
  'S',
  'J',
  'P',
  's',
] as const
type FixedCodeMark = typeof fixedCodeMarks[number]

export interface Props {
  mark: FixedCodeMark | string
  relatedTo?: 'connection' | 'connectionStop'
  wrapperClassName?: string
  wrapperStyle?: CSSProperties
  disableTooltip?: boolean
  tooltipTarget?: string
  customTooltipText?: string
}

export const TTFixedCode: React.FunctionComponent<Props> = (props) => {
  const [wrapperId, setWrapperId] = useState<string>(
    shouldGenerateId() ? HtmlIdProvider.getNewId('tt-fixed-code') : ''
  )
  const [markType, setMarkType] = useState<'known' | 'unknown'>('unknown')
  const wrapperRef = createRef<HTMLSpanElement>()
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)

  useEffect(() => {
    if (([...fixedCodeMarks] as string[]).includes(props.mark)) {
      setMarkType('known')
    } else {
      setMarkType('unknown')
    }
  }, [props.mark])

  function getIconCssClass(): string {
    switch (props.mark) {
      case '!':
        return 'tt-exclamation-mark-circle'
      case '(':
        return 'tt-circle-left-half'
      case ')':
        return 'tt-circle-right-half'
      case 'X':
        return 'tt-x-hammers'
      case 'A':
        return 'tt-paragraph'
      case 'B':
        return 'tt-paragraph'
      case 'C':
        return 'tt-paragraph'
      case 'T':
        return 'tt-phone'
      case '§':
        return 'tt-paragraph'
      case '|':
        return 'tt-pipe'
      case '<':
        return 'tt-broken-pipe'
      case 'R':
        return 'tt-r'
      case '#':
        return 'tt-r-in-square'
      case '@':
        return 'tt-wheelchair'
      case '%':
        return 'tt-cutlery'
      case '{':
        return 'tt-wheelchair-and-person'
      case '}':
        return 'tt-blind-person'
      case '[':
        return 'tt-case'
      case 'O':
        return 'tt-bike'
      case '1':
        return 'tt-1'
      case '2':
        return 'tt-2'
      case '3':
        return 'tt-3'
      case '4':
        return 'tt-4'
      case '5':
        return 'tt-5'
      case '6':
        return 'tt-6'
      case '7':
        return 'tt-7'
      case 'x':
        return 'tt-x'
      case '+':
        return 'tt-cross'
      case 'w':
        return 'tt-wheelchair'
      case 'v':
        return 'tt-locomotive'
      case 't':
        return 'tt-wheelchair'
      case 'b':
        return 'tt-bus'
      case 's':
        return 'tt-psi'

      // TODO this icon differs from JDF specification, is it ok?
      case 'U':
        return 'tt-m-logo'
      case 'S':
        return 'tt-ship'
      case 'J':
        return 'tt-plane'
      case 'P':
        return 'tt-p-plus-r'

      default:
        return ''
    }
  }

  function getContentBeforeIcon(): ReactElement | undefined {
    let text = ''

    switch (props.mark) {
      case 'W':
      case 'w':
        text = 'WC'
        break
      case '~':
        text = 'MHD'
        break
      case '$':
        text = 'CLO'
        break
      case 't':
        text = 'T'
        break
    }

    if (text) {
      return (
        <span
          style={{
            fontSize: '0.8em',
            paddingRight: '0.1em',
          }}
        >
          {text}
        </span>
      )
    }

    return undefined
  }

  function getIcon(): ReactElement | undefined {
    switch (props.mark) {
      case 'W':
      case '~':
      case '$':
        return undefined

      default:
        return <i className={`tti m-0 ${getIconCssClass()}`} />
    }
  }

  function getContentAfterIcon(): ReactElement | undefined {
    let text = ''

    switch (props.mark) {
      case 'A':
        text = '1'
        break
      case 'B':
        text = '2'
        break
      case 'C':
        text = '3'
        break
    }

    if (text) {
      return (
        <span
          style={{
            fontSize: '0.8em',
            paddingLeft: '0.1em',
          }}
        >
          {text}
        </span>
      )
    }

    return undefined
  }

  function getMark(): ReactElement {
    return <span style={{ fontSize: '0.8em' }}>{props.mark}</span>
  }

  const isWithTooltip =
    !props.disableTooltip && !(markType === 'unknown' && !props.customTooltipText)

  function getTooltipContent(): ReactElement | string | undefined {
    if (!isWithTooltip) {
      return undefined
    }

    const text = TTFixedCodesService.getDescription(props.mark, props.relatedTo)
    if (props.customTooltipText) {
      return props.customTooltipText
    }

    return text
  }

  function shouldGenerateId(): boolean {
    return !props.tooltipTarget
  }

  let additionalWrapperParams = {}
  if (shouldGenerateId()) {
    additionalWrapperParams = { ...additionalWrapperParams, id: wrapperId }
  }

  return (
    <>
      <span
        className={props.wrapperClassName}
        style={props.wrapperStyle}
        ref={wrapperRef}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        {...additionalWrapperParams}
      >
        {markType === 'known' ? (
          <>
            {getContentBeforeIcon()}
            {getIcon()}
            {getContentAfterIcon()}
          </>
        ) : (
          <>{getMark()}</>
        )}
      </span>

      {isWithTooltip && (
        <Tooltip tooltipTargetRef={wrapperRef} isVisible={isTooltipVisible}>
          {getTooltipContent()}
        </Tooltip>
      )}
    </>
  )
}