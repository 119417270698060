import React, {
  createRef,
  ReactElement,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { LineNumberExtended, Station, TTChars } from '../../../../models/LineModels'
import { UrlProvider } from '../../../../to-refactor/UrlProvider'
import { Logger } from '../../../../utils/logger'
import { Localizer } from '../../../../utils/localizer'
import { LoadingFailed } from '../../../../to-refactor/LoadingHelpers'
import { List, Map } from 'immutable'
import { ConnectionStopEditorRow } from '../stop/ConnectionStopEditorRow'
import '../../../../utils/stringTimeExtensions'
import '../../../../utils/dateExtensions'
import { ConnectionRouteEditorHints } from './ConnectionRouteEditorHints'
import ConnectionRouteRowsExpander from '../route/ConnectionRouteRowsExpander'
import { NotificationsContext, NotificationType } from '../../../../contexts/NotificationsContext'
import { ADD_NOTIFICATION } from '../../../../contexts/NotificationsReducer'
import {
  ConnectionRouteValidationError,
  createDefaultConnectionStopForStation,
  prepareConnectionStopsForValidation,
  validateConnectionStops,
} from '../../../../utils/connectionStopsService'
import { Preloader } from '@inprop/tt-ui-elements'
import { JdfConnectionEditorContext } from '../../../../contexts/JdfConnectionEditorContext'
import { JdfConnectionStop } from '../../../../models/jdf/connectionStop'

export interface Props {
  dataId: string
  lineNumber: LineNumberExtended
  connectionNumber: string
  allStations: Station[]
}

export type AfterConnectionStopsChangedFn = (connectionStops: List<ConnectionStopExtended>) => void
export type ConnectionStopExtended = JdfConnectionStop & {
  arrivalTime?: Date
  departureTime?: Date
}

export function ConnectionRouteEditor(props: Props): JSX.Element {
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext)
  const { dispatch: editorDispatch } = useContext(JdfConnectionEditorContext)

  const [connectionStops, setConnectionStops] = useState<List<ConnectionStopExtended>>(List())
  const [afterConnectionStopsChanged, setAfterConnectionStopsChanged] =
    useState<AfterConnectionStopsChangedFn>()
  const [errors, setErrors] = useState<ConnectionRouteValidationError[]>([])

  const sortByNumberIncreasing = (a: number, b: number) => {
    if (a < b) return -1
    if (a === b) return 0
    return 1
  }
  const sortByNumberDecreasing = (a: number, b: number) => {
    if (a < b) return 1
    if (a === b) return 0
    return -1
  }

  const [allStations, setAllStations] = useState<Station[]>(
    sortStations(props.allStations, getSortingFnByConnectionNumber(props.connectionNumber))
  )
  const [stationsSortingFn, setStationsSortingFn] = useState<(a: number, b: number) => number>(
    getSortingFnByConnectionNumber(props.connectionNumber)
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [inputs, setInputs] = useState<RefObject<HTMLInputElement>[]>(getInitialInputRefs())

  useEffect(() => {
    if (afterConnectionStopsChanged) {
      afterConnectionStopsChanged(connectionStops ?? List([]))

      setAfterConnectionStopsChanged(undefined)
    }

    editorDispatch.setSaveFn(saveConnection)
  }, [connectionStops])

  useEffect(() => {
    const newSortingFn = getSortingFnByConnectionNumber(props.connectionNumber)
    const sortedStations = sortStations(props.allStations, newSortingFn)

    setStationsSortingFn(() => newSortingFn)
    setAllStations(sortedStations)

    loadRelatedJDFFiles(sortedStations)
  }, [props.dataId, props.lineNumber, props.connectionNumber, props.allStations])

  useEffect(() => {
    const newInputs = getInitialInputRefs()
    if (newInputs.length > 0) {
      newInputs[0].current?.select()
    }

    setInputs(newInputs)
  }, [allStations])

  function getSortingFnByConnectionNumber(
    connectionNumber: string
  ): (a: number, b: number) => number {
    return parseInt(connectionNumber) % 2 === 0 ? sortByNumberDecreasing : sortByNumberIncreasing
  }

  function getInitialInputRefs(): RefObject<HTMLInputElement>[] {
    // in first and last row there is just one input
    // -> that is because we are subtracting 2
    const length = allStations.length * 2 - 2

    return new Array(length).fill(0).map((_) => createRef<HTMLInputElement>())
  }

  /**
   * Load JDF ZasSpoje data for current connection.
   */
  function loadRelatedJDFFiles(stations: Station[]): void {
    setIsLoading(true)

    axios
      .get<JdfConnectionStop[]>(
        UrlProvider.Api.Components.Connections.RouteEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        )
      )
      .then((response) => {
        setConnectionStops(processLoadedConnectionStops(response.data, stations))
      })
      .catch((reason) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection route could not be loaded'),
            type: NotificationType.Error,
            disableAutoDismiss: true,
          },
        })

        resolveComponentError(reason)
      })
      .finally(() => setIsLoading(false))
  }

  /**
   * Make necessary processing of loaded connection stops data.
   */
  function processLoadedConnectionStops(
    connectionStops: JdfConnectionStop[],
    stations: Station[]
  ): List<ConnectionStopExtended> {
    let connectionStopList: ConnectionStopExtended[] = []

    for (let i = 0; i < connectionStops.length; i++) {
      const currentStop = connectionStops[i] as ConnectionStopExtended

      currentStop.arrival = currentStop.arrival?.addColonToTime()
      currentStop.arrivalMin = currentStop.arrivalMin?.addColonToTime()
      currentStop.arrivalTime = currentStop.arrival?.isTime()
        ? currentStop.arrival.getDateFromTimeString()
        : undefined

      currentStop.departure = currentStop.departure?.addColonToTime()
      currentStop.departureMax = currentStop.departureMax?.addColonToTime()
      currentStop.departureTime = currentStop.departure?.isTime()
        ? currentStop.departure.getDateFromTimeString()
        : undefined

      connectionStopList.push(currentStop)
    }

    connectionStopList = stations.map((station) => {
      const connectionStopFiltered = connectionStopList.filter(
        (_) => _.tariffNumber === station.tariffNumber.toString()
      )
      let connectionStop =
        connectionStopFiltered.length === 1 ? connectionStopFiltered[0] : undefined

      if (!connectionStop) {
        connectionStop = createDefaultConnectionStopForStation(
          station.tariffNumber.toString(),
          station.id,
          props.lineNumber,
          props.connectionNumber
        )
      }

      return connectionStop
    })

    return List(connectionStopList)
  }

  function sortStations(
    stations: Station[],
    sortingFn: (a: number, b: number) => number
  ): Station[] {
    return stations.sort((a, b) => sortingFn(a.tariffNumber, b.tariffNumber))
  }

  /**
   * Select following input for editing (used when TAB is pressed in some input)
   * @param currentRow Index of input in which TAB was pressed
   * @param currentType Type of current input
   */
  function selectNextInput(currentRow: number, currentType: 'arrival' | 'departure'): void {
    const nextIndex = (getCurrentInputIndex(currentRow, currentType) + 1) % inputs.length

    inputs[nextIndex].current?.select()
  }

  function getCurrentInputIndex(currentRow: number, currentType: 'arrival' | 'departure'): number {
    if (currentRow === 0) return 0
    else if (currentRow === allStations.length - 1)
      // from count of stations we subtract 1 (to have last index) and then
      // we subtract 2, which represents non-displayed inputs in first and last row
      return allStations.length * 2 - 3
    else if (currentType === 'arrival') return currentRow * 2 - 1
    else return currentRow * 2
  }

  /**
   * Get index of related connection stop by station.
   * @param station
   */
  const getConnectionStopIndexByStation = useCallback(
    (station: Station): number | undefined => {
      const connectionStopIndex = connectionStops.findIndex(
        (_) => _.tariffNumber === station.tariffNumber.toString()
      )

      if (connectionStopIndex === -1) {
        return undefined
      }

      return connectionStopIndex
    },
    [connectionStops]
  )

  /**
   * Update connection stop in state.
   * @param connectionStopIndex Index in state
   * @param newConnectionStop
   * @param afterConnectionStopChanged
   */
  function updateConnectionStop(
    connectionStopIndex: number,
    newConnectionStop: ConnectionStopExtended,
    afterConnectionStopChanged?: AfterConnectionStopsChangedFn
  ): void {
    editorDispatch.setHasUnsavedChanges(true)
    setConnectionStops((_) => _.set(connectionStopIndex, newConnectionStop))
    setAfterConnectionStopsChanged(() => afterConnectionStopChanged)
  }

  function updateConnectionStops(connectionStopsMap: Map<number, ConnectionStopExtended>): void {
    editorDispatch.setHasUnsavedChanges(true)

    setConnectionStops((_) => {
      let newConnectionStops = List<ConnectionStopExtended>()

      for (let i = 0; i < _.size; i++) {
        const updatedConnectionStop = connectionStopsMap.get(i)

        if (updatedConnectionStop) {
          newConnectionStops = newConnectionStops.push(updatedConnectionStop)
        } else {
          const previousStop = _.get(i)
          if (!previousStop) {
            throw new Error(`Could not get stop by index ${i} in connection stops.`)
          }

          newConnectionStops = newConnectionStops.push(previousStop)
        }
      }

      return newConnectionStops
    })
  }

  /**
   * Change time value of inputs.
   *
   * ! IMPORTANT We cannot use state here, because this function is enclosed in callback
   *             function and does not have access to current state.
   *
   * @param changedRowIndex Start row index
   * @param changedInputType Input which was changed
   * @param minutes How many minutes to increase or decrease
   * @param currentConnectionStops
   */
  function updateFollowingConnectionStopsTime(
    changedRowIndex: number,
    changedInputType: 'arrival' | 'departure',
    minutes: number,
    currentConnectionStops: List<ConnectionStopExtended>
  ): void {
    let updatedConnectionStops = Map<number, ConnectionStopExtended>()

    for (let i = changedRowIndex; i < allStations.length; i++) {
      const connectionStopIndex = currentConnectionStops.findIndex(
        (_) => _.tariffNumber === allStations[i]?.tariffNumber.toString()
      )

      const connectionStop = currentConnectionStops.get(connectionStopIndex)
      if (!connectionStop) {
        throw new Error(
          `Could not get stop by index ${connectionStopIndex} from current connection stops.`
        )
      }
      const newConnectionStop: ConnectionStopExtended = { ...connectionStop }
      let changed = false

      if (i !== changedRowIndex) {
        changed = true
        newConnectionStop.arrivalTime = connectionStop.arrivalTime?.addMinutes(minutes)
        newConnectionStop.arrival = connectionStop.arrival?.isTimeishChar()
          ? connectionStop.arrival
          : newConnectionStop.arrivalTime?.toTimeString()
      }
      if (i !== changedRowIndex || changedInputType === 'arrival') {
        changed = true
        newConnectionStop.departureTime = connectionStop.departureTime?.addMinutes(minutes)
        newConnectionStop.departure = connectionStop.departure?.isTimeishChar()
          ? connectionStop.departure
          : newConnectionStop.departureTime?.toTimeString()
      }

      if (changed) {
        updatedConnectionStops = updatedConnectionStops.set(connectionStopIndex, newConnectionStop)
      }
    }

    updateConnectionStops(updatedConnectionStops)
  }

  /**
   * Get nearest time to input
   * @param connectionStopIndex Current connection stop index
   * @param inputType Current input type
   */
  function getNearestTimeToInput(
    connectionStopIndex: number,
    inputType: 'arrival' | 'departure'
  ): Date {
    let nearestTime = getLastTimeBeforeInput(inputType, connectionStopIndex)
    if (nearestTime) {
      return nearestTime
    }

    nearestTime = getFirstTimeAfterInput(inputType, connectionStopIndex)
    if (nearestTime) {
      return nearestTime
    }

    return new Date(2020, 0, 1, 0, 0, 0)
  }

  /**
   * Get last time before input.
   * @param inputType Current input type
   * @param connectionStopIndex Current connection stop index
   */
  function getLastTimeBeforeInput(
    inputType: 'arrival' | 'departure',
    connectionStopIndex: number
  ): Date | undefined {
    if (connectionStopIndex === connectionStops.size - 1 && inputType === 'departure') {
      throw new Error('There is no departure on last connection stop')
    }

    if (connectionStopIndex === 0) {
      return undefined
    }

    if (inputType === 'departure') {
      const connectionStop = connectionStops.get(connectionStopIndex)
      if (connectionStop?.arrivalTime) {
        return connectionStop.arrivalTime
      }
    }

    for (let i = connectionStopIndex - 1; i >= 0; i--) {
      const connectionStop = connectionStops.get(i)

      if (connectionStop?.departureTime) {
        return connectionStop.departureTime
      }

      if (i !== 0) {
        if (connectionStop?.arrivalTime) {
          return connectionStop.arrivalTime
        }
      }
    }

    return undefined
  }

  /**
   * Get first next time after input
   * @param inputType Current input type
   * @param connectionStopIndex Current connection stop index
   */
  function getFirstTimeAfterInput(
    inputType: 'arrival' | 'departure',
    connectionStopIndex: number
  ): Date | undefined {
    if (connectionStopIndex === 0 && inputType === 'arrival') {
      throw new Error('There is no arrival on first connection stop')
    }

    if (connectionStopIndex === connectionStops.size - 1) {
      return undefined
    }

    if (inputType === 'arrival') {
      const connectionStop = connectionStops.get(connectionStopIndex)
      if (connectionStop?.departureTime) {
        return connectionStop.departureTime
      }
    }

    for (let i = connectionStopIndex + 1; i < connectionStops.size; i++) {
      const connectionStop = connectionStops.get(i)

      if (connectionStop?.arrivalTime) {
        return connectionStop.arrivalTime
      }

      if (i !== connectionStops.size - 1) {
        if (connectionStop?.departureTime) {
          return connectionStop.departureTime
        }
      }
    }

    return undefined
  }

  /**
   * Handler for "save changes" button
   */
  function saveConnection(): Promise<void> {
    const validationResult = validateConnectionStops(
      prepareConnectionStopsForValidation(props.connectionNumber, connectionStops)
    )
    if (!validationResult.valid) {
      setErrors(validationResult.errors)

      return Promise.reject()
    } else {
      setErrors([])
    }

    return axios
      .put(
        UrlProvider.Api.Components.Connections.RouteEditor.getUrl(
          props.dataId,
          props.lineNumber,
          props.connectionNumber
        ),
        prepareConnectionStopsForValidation(props.connectionNumber, connectionStops)
      )
      .then(() => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Connection route was saved'),
            type: NotificationType.Success,
          },
        })
      })
      .catch((error) => {
        notificationsDispatch({
          type: ADD_NOTIFICATION,
          value: {
            title: Localizer.localize('Error while saving connection route'),
            type: NotificationType.Error,
          },
        })

        resolveComponentError(
          `Error in ${ConnectionRouteEditor.name} while saving connection stops. ${error}`
        )

        throw error
      })
  }

  /**
   * Resolve error.
   * @param reason
   */
  function resolveComponentError(reason: any): void {
    Logger.logError(reason.toString())
  }

  const getConnectionStopByStation = useCallback(
    (station: Station): ConnectionStopExtended | undefined => {
      const connectionStopIndex = getConnectionStopIndexByStation(station)
      if (!connectionStopIndex && connectionStopIndex !== 0) {
        return undefined
      }

      return connectionStops.get(connectionStopIndex)
    },
    [connectionStops, getConnectionStopIndexByStation]
  )

  const getConnectionStopsEmptyRanges = useCallback(() => {
    const minimalRangeSize = 2
    const emptyRanges: { startIndex: number; endIndex: number }[] = []
    let currentRangeStartIndex = -1
    let currentRangeEndIndex = -1

    const allConnectionStops = allStations.map(getConnectionStopByStation)

    for (let i = 0; i < allConnectionStops.length; i++) {
      const currentStop = allConnectionStops[i]

      if (
        !currentStop?.km &&
        !currentStop?.arrivalTime &&
        !currentStop?.departureTime &&
        currentStop?.arrival !== TTChars.ConnectionGoThroughStop &&
        currentStop?.departure !== TTChars.ConnectionGoThroughStop
      ) {
        if (currentRangeStartIndex === -1) {
          currentRangeStartIndex = i
        }

        currentRangeEndIndex = i
      } else {
        if (
          currentRangeStartIndex !== -1 &&
          currentRangeEndIndex !== -1 &&
          currentRangeEndIndex - currentRangeStartIndex + 1 >= minimalRangeSize
        ) {
          emptyRanges.push({ startIndex: currentRangeStartIndex, endIndex: currentRangeEndIndex })
        }

        currentRangeStartIndex = -1
        currentRangeEndIndex = -1
      }
    }

    if (currentRangeStartIndex !== -1 && currentRangeEndIndex !== -1) {
      emptyRanges.push({ startIndex: currentRangeStartIndex, endIndex: currentRangeEndIndex })
    }

    return emptyRanges
  }, [allStations, getConnectionStopByStation])

  const [connectionStopsRowVisibility, setConnectionStopsRowVisibility] =
    useState<{ startIndex: number; endIndex: number; isVisible: boolean }[]>()

  useEffect(() => {
    if (connectionStops.size === 0) {
      return
    }

    setConnectionStopsRowVisibility((_) => {
      const emptyRanges = getConnectionStopsEmptyRanges()

      return emptyRanges.map((emptyRange) => {
        const previousSimilarRange = _?.find(
          (__) => __.startIndex === emptyRange.startIndex || __.endIndex === emptyRange.endIndex
        )

        return { ...emptyRange, isVisible: previousSimilarRange?.isVisible ?? true }
      })
    })
  }, [props.allStations, connectionStops])

  const isConnectionStopRowVisible = (index: number): boolean => {
    for (const range of connectionStopsRowVisibility ?? []) {
      if (range.startIndex <= index && index <= range.endIndex) {
        return range.isVisible
      }
    }

    return true
  }

  const toggleEmptyRows = (rangeEndIndex: number) => {
    setConnectionStopsRowVisibility((_) => {
      return _?.map((__) => {
        return __.endIndex === rangeEndIndex ? { ...__, isVisible: !__.isVisible } : __
      })
    })
  }

  const getRowsExpander = useCallback(
    (rowIndex: number): ReactElement | undefined => {
      for (const range of connectionStopsRowVisibility ?? []) {
        if (range.endIndex === rowIndex) {
          return (
            <ConnectionRouteRowsExpander
              rowsCount={range.endIndex - range.startIndex + 1}
              onClick={() => toggleEmptyRows(range.endIndex)}
              isRowsRangeVisible={range.isVisible}
            />
          )
        }
      }

      return undefined
    },
    [connectionStopsRowVisibility]
  )

  if (isLoading) {
    return (
      <div className={'w-100 py-5 text-center'}>
        <Preloader overlay={false} centered />
      </div>
    )
  }

  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-xl-9'}>
          {connectionStops.size === 0 ? (
            <LoadingFailed tryAgainFn={() => loadRelatedJDFFiles(allStations)} />
          ) : (
            <div className='connection-editor-inner-container'>
              {errors && errors.length > 0 && (
                <div className='text-danger mb-3'>
                  <ul className='mb-0'>
                    {errors.map((_, index) => (
                      <li key={index}>{_.text}</li>
                    ))}
                  </ul>
                </div>
              )}

              <table className='table-responsive table-hover mb-0'>
                <thead>
                  <tr>
                    <th className='text-center'>{Localizer.localize('Tar. nr.')}</th>
                    <th className='text-center'>{Localizer.localize('Station')}</th>
                    <th className='text-center'>{Localizer.localize('Platf.')}</th>
                    <th className='text-center'>{Localizer.localize('Km')}</th>
                    <th className='text-center'>{Localizer.localize('Arrival')}</th>
                    <th className='text-center'>{Localizer.localize('Departure')}</th>
                  </tr>
                </thead>

                <tbody>
                  {allStations.map((station, index) => {
                    const type: 'first' | 'middle' | 'last' =
                      index === 0 ? 'first' : index === allStations.length - 1 ? 'last' : 'middle'

                    const connectionStopIndex = getConnectionStopIndexByStation(station)
                    if (!connectionStopIndex && connectionStopIndex !== 0) {
                      throw new Error(
                        `Connection stop index not found by station (tar.num.: ${station.tariffNumber})`
                      )
                    }

                    const connectionStop = connectionStops.get(connectionStopIndex)
                    if (!connectionStop) {
                      throw new Error(`No connection stop found by index ${connectionStopIndex}`)
                    }

                    return (
                      <React.Fragment key={station.tariffNumber}>
                        {isConnectionStopRowVisible(index) && (
                          <ConnectionStopEditorRow
                            station={station}
                            jdfDataId={props.dataId}
                            connectionStop={connectionStop}
                            type={type}
                            isWithGuide={index === 1 && allStations.length > 2}
                            arrivalInputRef={inputs[getCurrentInputIndex(index, 'arrival')]}
                            departureInputRef={inputs[getCurrentInputIndex(index, 'departure')]}
                            updateConnectionStop={(newConnectionStop, afterConnectionStopUpdated) =>
                              updateConnectionStop(
                                connectionStopIndex,
                                newConnectionStop,
                                afterConnectionStopUpdated
                              )
                            }
                            updateFollowingConnectionStopsTime={(
                              currentInputType,
                              minutes,
                              currentConnectionStops
                            ) =>
                              updateFollowingConnectionStopsTime(
                                index,
                                currentInputType,
                                minutes,
                                currentConnectionStops
                              )
                            }
                            selectNextInput={(currentInputType) =>
                              selectNextInput(index, currentInputType)
                            }
                            getNearestTimeToInput={(currentInputType) =>
                              getNearestTimeToInput(connectionStopIndex, currentInputType)
                            }
                            refreshConnectionRoute={() => loadRelatedJDFFiles(allStations)}
                          />
                        )}
                        {getRowsExpander(index)}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className={'col-xl-3 d-none d-xl-block'}>
          <ConnectionRouteEditorHints />
        </div>
      </div>
    </div>
  )
}