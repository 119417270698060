import React, {FC, useEffect} from 'react'
import LoginForm from '../auth/LoginForm'
import {appUrls} from '../../utils/urls'
import {useAuthContext} from '../../contexts/AuthContext'
import {useHistory} from 'react-router-dom'

const LoginPage: FC = () => {
  const {isUserLoggedIn} = useAuthContext()
  const history = useHistory()

  useEffect(() => {
    if (isUserLoggedIn) {
      history.push(appUrls.home)
    }
  }, [isUserLoggedIn, history])

  return (
    <div className={'row py-4'}>
      <LoginForm/>
    </div>
  )
}

export default LoginPage
