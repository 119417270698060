export {}

declare global {
  // for static extension methods
  interface DateConstructor {
    getWeekDayShortName(day: number): string;
  }

  // for non-static extension methods
  interface Date {
    addDays(days: number): Date;
    addMinutes(minutes: number): Date;
    toTimeString(): string;
  }
}

/**
 * Get short name of day in week.
 * @param day Number of day in week: Sunday - 0, Monday - 1, etc.
 */
Date.getWeekDayShortName = function (day: number): string {
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return dayNames[day];
};

// Non-static methods
/**
 * Add days to concrete date.
 * @param days How many days to add
 */
Date.prototype.addDays = function(days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);

  return date;
};

Date.prototype.addMinutes = function(minutes: number): Date {
  return new Date(this.getTime() + minutes*60000);
}

Date.prototype.toTimeString = function(): string {
  return this.getHours().toString().padStart(2, '0') +
    ":" + this.getMinutes().toString().padStart(2, '0');
}