import React, { createRef, FC, useContext, useState } from 'react'
import { appUrls } from '../../../utils/urls'
import { NavLink } from 'react-router-dom'
import { Localizer } from '../../../utils/localizer'
import { Nav } from 'react-bootstrap'
import { JdfContext } from '../../../contexts/JdfContext'
import { JdfKonExitCode } from '../../../models/protocol'
import Tooltip from '../../general/Tooltip'
import cx from 'classnames'
import { NavigationContext } from '../../../contexts/NavigationContext'
import {navigationGuide} from "../../guide/content/navigation";

interface Props {
  jdfDataId: string
}

const JdfNavigation: FC<Props> = ({ jdfDataId }) => {
  const { state: jdfState } = useContext(JdfContext)
  const {
    state: { getNavUrlProps },
  } = useContext(NavigationContext)

  const protocolBadgeRef = createRef<HTMLDivElement>()
  const [isProtocolTooltipVisible, setIsProtocolTooltipVisible] = useState<boolean>(false)

  const getProtocolDescription = (): string => {
    switch (jdfState.jdfKonExitCode) {
      case JdfKonExitCode.Ok:
        return Localizer.localize('Data is ok')
      case JdfKonExitCode.Warn:
        return Localizer.localize('Data is not quite all right')
      case JdfKonExitCode.Error:
      case JdfKonExitCode.FatalError:
        return Localizer.localize('Data is not usable')
    }

    return ''
  }

  return (
    <>
      {(jdfState.jdfKonExitCode === JdfKonExitCode.Ok ||
        jdfState.jdfKonExitCode === JdfKonExitCode.Warn) && (
        <>
          <Nav.Link id={navigationGuide.lines.target} as={NavLink} {...getNavUrlProps(appUrls.jdf.lines.withParams({ jdfDataId }))}>
            {Localizer.localize('Lines')}
          </Nav.Link>
          <Nav.Link
            id={navigationGuide.providers.target}
            as={NavLink}
            {...getNavUrlProps(appUrls.jdf.providers.withParams({ jdfDataId }))}
          >
            {Localizer.localize('Providers')}
          </Nav.Link>
        </>
      )}

      <Nav.Link id={navigationGuide.protocol.target} as={NavLink} {...getNavUrlProps(appUrls.jdf.protocol.withParams({ jdfDataId }))}>
        <div className={cx({ 'mr-1': jdfState.jdfKonExitCode !== JdfKonExitCode.Ok })}>
          {Localizer.localize('Protocol')}
        </div>
        
        {jdfState.jdfKonExitCode !== JdfKonExitCode.Ok && (
          <div
            className='align-items-center d-flex'
            data-toggle='tooltip'
            data-placement='bottom'
            title={''}
          >
            <div
              className={cx('badge badge-pill', {
                'badge-warning': jdfState.jdfKonExitCode === JdfKonExitCode.Warn,
                'badge-danger':
                  jdfState.jdfKonExitCode === JdfKonExitCode.Error ||
                  jdfState.jdfKonExitCode === JdfKonExitCode.FatalError,
              })}
              style={{ fontSize: '0.5em' }}
              ref={protocolBadgeRef}
              onMouseEnter={() => setIsProtocolTooltipVisible(true)}
              onMouseLeave={() => setIsProtocolTooltipVisible(false)}
            >
              &nbsp;
            </div>
            <Tooltip tooltipTargetRef={protocolBadgeRef} isVisible={isProtocolTooltipVisible}>
              {getProtocolDescription()}
            </Tooltip>
          </div>
        )}
      </Nav.Link>
    </>
  )
}

export default JdfNavigation
