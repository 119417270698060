import React, { FC, useCallback, useContext, useState } from 'react'
import useAxios from 'axios-hooks'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Tooltip } from '@mui/material'
import { UserExcerpt } from '../../models/AuthViewModels'
import { NotificationsContext, NotificationType } from '../../contexts/NotificationsContext'
import { apiUrls } from '../../utils/urls'
import { ADD_NOTIFICATION } from '../../contexts/NotificationsReducer'
import { Localizer } from '../../utils/localizer'

interface Props {
  user: UserExcerpt,
  onUserVerified: (user: UserExcerpt) => Promise<void>
}

const VerifyUserButton: FC<Props> = props => {
  // using custom loading flag, because verify btn should remain disabled until new users list is loaded
  const [isVerifyingUser, setIsVerifyingUser] = useState<boolean>(false)

  const [, verifyUserAxios] = useAxios<void>({ method: 'PATCH' }, { manual: true })

  const { dispatch: notificationDispatch } = useContext(NotificationsContext)

  const verifyUser = async () => {
    setIsVerifyingUser(true)

    try {
      await verifyUserAxios({ url: apiUrls.users.verify(props.user.id) })
      await props.onUserVerified(props.user)

      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localizeR('User {0} was successfully verified', props.user.userName),
          type: NotificationType.Success,
        },
      })

      // there is no need to call 'setIsVerifyingUser(false)' because verifying user will cause reload of users list
    } catch (e) {
      notificationDispatch({
        type: ADD_NOTIFICATION,
        value: {
          title: Localizer.localize('Could not verify user'),
          type: NotificationType.Error,
        },
      })
      setIsVerifyingUser(false)
    }
  }

  return (
    <Tooltip title={!props.user.emailConfirmed
      ? Localizer.localize('User does not have email confirmed yet')
      : Localizer.localize('Verify user')
    }>
      <Box>
        <LoadingButton
          variant={'outlined'}
          size={'small'}
          loading={isVerifyingUser}
          disabled={!props.user.emailConfirmed}
          onClick={verifyUser}
        >
          {Localizer.localize('Verify')}
        </LoadingButton>
      </Box>
    </Tooltip>
  )
}

export default VerifyUserButton
