import React, { FC } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import JdfUploadSettings from './JdfUploadSettings'
import { Localizer } from '../../../utils/localizer'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import styles from './JdfUploadSettingsAccordion.module.scss'
import { jdfLoadGuide } from '../../guide/content/jdfLoad'
import { useAppGuide } from '@inprop/tt-ui-elements'

const JdfUploadSettingsAccordion: FC = () => {
  useAppGuide([jdfLoadGuide.jdfLoadSettings.title])

  return (
    <Accordion defaultActiveKey='0'>
      <div id={jdfLoadGuide.jdfLoadSettings.title.target}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey='0'
          className={cx(styles.accordionHeader, styles.cursorPointer)}
        >
          <FontAwesomeIcon icon={faCog} className={'mr-3'} />
          <b>{Localizer.localize('Load settings')}</b>
        </Accordion.Toggle>
      </div>

      <Accordion.Collapse eventKey='0' className={styles.accordionBody}>
        <div className={'p-3'}>
          <JdfUploadSettings />
        </div>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default JdfUploadSettingsAccordion
