import React, { FC } from 'react'
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Localizer } from '../../utils/localizer'
import TextInput from '../general/inputs/TextInput'
import { emailMaxLength, emailPattern } from '../../utils/constants'
import { Controller, useForm } from 'react-hook-form'
import { RegistrationAllowedEmail, RegistrationAllowedEmailViewModel, Role } from '../../models/AuthViewModels'
import LoadingButton from '@mui/lab/LoadingButton'
import { useCreateRegistrationAllowedEmail, useUpdateRegistrationAllowedEmail } from '../../data/mutations'

interface Props {
  open: boolean
  handleClose: () => void
  editSelectedRegistrationAllowedEmail: RegistrationAllowedEmail | undefined
}

const RegistrationAllowedEmailDialog: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
  } = useForm<RegistrationAllowedEmailViewModel>()

  const {
    mutateAsync: createRegistrationAllowedEmailAsync,
    isLoading: isCreating,
  } = useCreateRegistrationAllowedEmail()

  const {
    mutateAsync: updateRegistrationAllowedEmailAsync,
    isLoading: isUpdating,
  } = useUpdateRegistrationAllowedEmail()

  const addingNew = props.editSelectedRegistrationAllowedEmail ? false : true
  const isMutating = isCreating || isUpdating;

  const onSubmit = async (data: RegistrationAllowedEmailViewModel) => {
    if (!props.editSelectedRegistrationAllowedEmail) {
      await createRegistrationAllowedEmailAsync(data)
    } else {
      await updateRegistrationAllowedEmailAsync({
        registrationAllowedEmailId: props.editSelectedRegistrationAllowedEmail.id,
        registrationAllowedEmailViewModel: data
      })
    }

    props.handleClose()
  }
  
  const registrationAllowedEmailFormId = 'registrationAllowedEmailForm'
  
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        {addingNew 
          ? Localizer.localize('Invite new user')
          : Localizer.localize('Edit invitation') 
        }
      </DialogTitle>

      <DialogContent>
        <form id={registrationAllowedEmailFormId}
              onSubmit={handleSubmit(onSubmit)}
              className={'mb-0'}
        >
          <div>
            <TextInput
              id={'emailAddressInput'}
              name={'emailAddress'}
              type={'text'}
              label={Localizer.localize('Email address')}
              defaultValue={props.editSelectedRegistrationAllowedEmail?.emailAddress ?? ''}
              ref={register({
                required: Localizer.localize('Please, enter email address which you want to invite'),
                pattern: {
                  value: emailPattern,
                  message: Localizer.localize('Please, enter valid email address'),
                },
                maxLength: {
                  value: emailMaxLength,
                  message: Localizer.localize('Entered email address is too long'),
                },
              })}
              error={errors.emailAddress?.message}
            />

            <FormControl fullWidth component={'fieldset'}>
              <FormLabel
                component={'legend'}
                className={'font-size-80'}
              >
                {Localizer.localize('Role')}
              </FormLabel>
              <Controller
                name={'roleName'}
                control={control}
                rules={{ required: true }}
                defaultValue={props.editSelectedRegistrationAllowedEmail?.roleName ?? Role.TransportOffice}
                render={({ onBlur, onChange, value }) => (
                  <RadioGroup
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={Role.TransportOffice}
                      control={<Radio />}
                      label={Localizer.localize(Role.TransportOffice)}
                      className={'mb-0'}
                    />
                    <FormControlLabel
                      value={Role.Provider}
                      control={<Radio />}
                      label={Localizer.localize(Role.Provider)}
                      className={'mb-0'}
                    />
                    <FormControlLabel
                      value={Role.Admin}
                      control={<Radio />}
                      label={Localizer.localize(Role.Admin)}
                      className={'mb-0'}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

          </div>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose}>{Localizer.localize('Cancel')}</Button>
        <LoadingButton
          form={registrationAllowedEmailFormId}
          loading={isMutating}
          type={'submit'}
          variant={'contained'}
        >
          {addingNew
            ? Localizer.localize('Invite')
            : Localizer.localize('Save changes')
          }
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default RegistrationAllowedEmailDialog
