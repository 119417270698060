import React, {FC} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope'

import './Footer.module.scss'
import { cpEditEmailAddress } from '../../utils/constants'

const Footer: FC = () => {
  const companyWeb = 'https://inprop.sk'
  const companyName = 'INPROP, s.r.o.'
  const companyPhone = '+421-(41) 723-4456'
  const appEmail = cpEditEmailAddress
  const appVersion = process.env.REACT_APP_VERSION

  return (
    <footer id='footer' className='dark container-fluid small'>
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row py-5'>
              <div className='col-12 col-md-6 mb-3 mb-md-0 text-center text-md-left'>
                v{appVersion} | Copyright &copy; {new Date().getFullYear()},{' '}
                <a href={companyWeb} target='_blank' rel='noreferrer'>
                  {companyName}
                </a>
              </div>
              <div className='col-12 col-md-6 d-flex justify-content-center justify-content-md-end'>
                <div className='d-flex'>
                  <span className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faEnvelope} className={'mr-2'}/>
                    <a href={`mailto:${appEmail}`}>{appEmail}</a>
                  </span>
                  <span className='d-flex align-items-center ml-3'>
                    <FontAwesomeIcon icon={faMobileAlt} className={'mr-2'}/>
                    <span>{companyPhone}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
