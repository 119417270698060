import React, { createContext, FC, useEffect, useState } from 'react'

export enum Editor {
  RouteEditor = 1,
  CalendarEditor,
  NotesEditor,
  ProvidersEditor,
}

export type ConnectionEditorSaveFn = () => Promise<void>

export interface JdfConnectionEditorContextState {
  hasUnsavedChanges: boolean
  currentEditor: Editor
  saveFn: ConnectionEditorSaveFn
}

export interface JdfConnectionEditorContextActions {
  setHasUnsavedChanges: (newValue: boolean) => void
  setCurrentEditor: (editor: Editor) => void
  setSaveFn: (newFn: ConnectionEditorSaveFn) => void
}

const defaultState: JdfConnectionEditorContextState = {
  hasUnsavedChanges: false,
  currentEditor: Editor.RouteEditor,
  saveFn: () => Promise.resolve(),
}

export const JdfConnectionEditorContext = createContext<{
  state: JdfConnectionEditorContextState
  dispatch: JdfConnectionEditorContextActions
}>({
  state: defaultState,
  dispatch: {
    setHasUnsavedChanges: () => {},
    setCurrentEditor: () => {},
    setSaveFn: () => {},
  },
})

const JdfConnectionEditorContextProvider: FC = (props) => {
  const [state, setState] = useState<JdfConnectionEditorContextState>(defaultState)

  const setHasUnsavedChanges = (newValue: boolean) => {
    setState((_) => ({ ..._, hasUnsavedChanges: newValue }))
  }

  const setCurrentEditor = (newValue: Editor) => {
    setState((_) => ({ ..._, currentEditor: newValue }))
  }

  const setSaveFn = (newValue: ConnectionEditorSaveFn) => {
    setState((_) => ({ ..._, saveFn: newValue }))
  }

  useEffect(() => {
    setState((_) => ({ ..._, hasUnsavedChanges: false }))
  }, [state.currentEditor])

  return (
    <JdfConnectionEditorContext.Provider
      value={{ state, dispatch: { setHasUnsavedChanges, setCurrentEditor, setSaveFn } }}
    >
      {props.children}
    </JdfConnectionEditorContext.Provider>
  )
}

export default JdfConnectionEditorContextProvider
