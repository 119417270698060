import React, { FunctionComponent, useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { HubConnection } from '@microsoft/signalr'
import { Button, ButtonShape, ButtonSize } from '@inprop/tt-ui-elements'
import { Localizer } from '../../utils/localizer'
import { signalRUrls } from '../../utils/urls'
import { useAuthContext } from '../../contexts/AuthContext'

const refreshCisStationsMethod = 'RefreshCISStationsCache'
const progressChangedMethod = 'RefreshCISStationsCacheProgress'
const errorMethod = 'RefreshCISStationsCacheError'

const CisStationsRefresh: FunctionComponent = () => {
  const { onUnauthorizedRequest, accessToken } = useAuthContext()

  const [signalRConnection, setSignalRConnection] = useState<HubConnection>()
  const [hasStarted, setHasStarted] = useState<boolean>(false)
  const [loadingPercentage, setLoadingPercentage] = useState<number>()
  const [error, setError] = useState<string>()

  const setupSignalR = async (): Promise<void> => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(signalRUrls.cis, {
        accessTokenFactory: () => accessToken,
        withCredentials: false
      })
      .configureLogging(signalR.LogLevel.Error)
      .build()

    connection.on(progressChangedMethod, (percentage) => {
      if (percentage === 100) {
        setHasStarted(false)
        setLoadingPercentage(undefined)
      } else {
        setHasStarted(true)
        setLoadingPercentage(percentage)
      }
    })
    connection.on(errorMethod, (error) => {
      setHasStarted(false)
      setError(error)
    })

    try {
      await connection.start()
    } catch (error) {
      if (error.statusCode === 401) {
        onUnauthorizedRequest()
      }
    }

    setSignalRConnection(connection)
  }

  useEffect(() => {
    setupSignalR().catch((_) => console.log(_))

    return () => {
      signalRConnection?.stop()
    }
  }, [])

  const refreshStations = () => {
    setHasStarted(true)
    signalRConnection?.invoke(refreshCisStationsMethod)
  }

  return (
    <div className={'d-flex align-items-center'}>
      <span className={'mr-2'}>{Localizer.localize('CIS stations loaded in app')}</span>
      {!hasStarted && (
        <Button
          shape={ButtonShape.Rounded}
          size={ButtonSize.ExtraExtraSmall}
          onClick={refreshStations}
        >
          {Localizer.localize('Refresh')}
        </Button>
      )}

      {hasStarted && !error ? (
        <Button shape={ButtonShape.Rounded} size={ButtonSize.ExtraExtraSmall} disabled>
          {Localizer.localize('Refresh is in progress')}
          {loadingPercentage && `: ${loadingPercentage}%`}
        </Button>
      ) : (
        <span>{error}</span>
      )}

      {error && (
        <Button
          shape={ButtonShape.Rounded}
          size={ButtonSize.ExtraExtraSmall}
          onClick={refreshStations}
        >
          {Localizer.localize('Try again')}
        </Button>
      )}
    </div>
  )
}

export default CisStationsRefresh