import React, { FunctionComponent, ReactElement } from 'react'
import { Localizer } from '../../../../utils/localizer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

export interface Props {
  rowsCount: number
  onClick: () => void
  isRowsRangeVisible: boolean
}

const ConnectionRouteRowsExpander: FunctionComponent<Props> = (props) => {
  const getIcon = (): ReactElement => {
    return props.isRowsRangeVisible ? (
      <FontAwesomeIcon icon={faMinus} style={{ fontSize: '.4em' }} className={'m-0 mr-2'} />
    ) : (
      <FontAwesomeIcon icon={faPlus} style={{ fontSize: '.4em' }} className={'m-0 mr-2'} />
    )
  }

  return (
    <tr className='m-0 p-0' style={{ background: 'transparent' }}>
      <td colSpan={6} className='m-0 p-0'>
        <div className='align-items-center d-flex justify-content-center'>
          <div
            className='d-flex flex-grow-1'
            style={{ height: '1px', borderBottom: '1px solid #dedede' }}
          />
          <button
            className='align-items-center button button-rounded button-transparent-dark d-flex m-0 mx-2 px-1 py-2'
            style={{ height: '7px' }}
            onClick={() => props.onClick()}
            type='button'
          >
            {getIcon()}
            <span style={{ fontSize: '.5em' }}>
              ({props.rowsCount} {Localizer.localizePlural('Lines', props.rowsCount)})
            </span>
          </button>
          <div
            className='d-flex flex-grow-1'
            style={{ height: '1px', borderBottom: '1px solid #dedede' }}
          />
        </div>
      </td>
    </tr>
  )
}

export default ConnectionRouteRowsExpander
